import React, { useEffect, useState } from 'react';
import { BarHorizonStyled } from './styled';
import cx from 'classnames';
import {
  BoxCustom
} from 'components'
import Chart from 'react-apexcharts'

export const BarHorizon = (props) => {
  const { theme_standard, title, data } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({})
  useEffect(() => {
    if (data?.series) {
      setSeries(data.series)
    }
  }, [data])

  useEffect(() => {
    if (data?.categories) {
      setOptions(
        {
          chart: {
            type: 'line',
            stacked: true,
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          stroke: {
            width: 1,
          },
          xaxis: {
            categories: data?.categories,
          },
          legend: {
            show : true,
            position: 'right',
          }
        }
      )
    }
  }, [data])

  return (
    <BarHorizonStyled {...props}>
      <div className={customClass}>
        <BoxCustom
          theme_standard_box_custom_title
          title={title}
          child={
            <div style={{ margin: 'auto' , padding : '1rem 2rem 1rem 1rem' }}>
              <Chart
                options={options}
                series={series}
                type='bar'
                height={300}
                width='100%'
              />
            </div>
          }
        />
      </div>
    </BarHorizonStyled>
  );
};
