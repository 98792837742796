import styled from "styled-components";

export const ThemeFromStyles = styled.div`
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};

    .ThemeBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label{
            font-weight : 500;
        } 
    }
    .ThemeGrid{
        display: grid;
        grid-template-rows: repeat(5 ,1fr);
        /* grid-gap: 1fr; */
    }
    .ThemeLeftBox{
        margin-left: 10px;
        width: 90%;
        /* background-color: #8dd92374; */
    }
    .ThemeRightBox{
        margin-right: 10px;
        width: 90%;
        /* background-color: #a09b9b2b; */
    }

    .field{
        .item-field{
            margin: 10px;
            /* background-color: #efefef; */
            width: 10px;
        }
    }

    .item_col_container{
        display: flex;
        justify-content: space-between;
    }


    .SaveBTN{
        padding-top: 20px;
        
        background-color: #fff;
        display: flex;
        justify-content: end;
    }
`
