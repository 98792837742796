import React from 'react';
import { BoxCustomStyled } from './styled';
import cx from 'classnames';
// import '@material/react-linear-progress/dist/linear-progress.css';
// import LinearProgress from '@material/react-linear-progress';


export const BoxCustom = (props) => {
  const {
    theme_standard_box_custom,
    theme_standard_box_custom_2,
    theme_standard_box_custom_3,
    theme_standard_box_custom_notification,
    theme_standard_box_custom_message,
    theme_standard_box_custom_message_2,
    theme_standard_box_custom_gray,
    theme_standard_box_custom_blue,
    theme_standard_box_custom_title,
    theme_standard_box_custom_yellow,
    style
  } = props;
  const customClass = cx({
    theme_standard_box_custom: theme_standard_box_custom,
    theme_standard_box_custom_2: theme_standard_box_custom_2,
    theme_standard_box_custom_3: theme_standard_box_custom_3,
    theme_standard_box_custom_notification: theme_standard_box_custom_notification,
    theme_standard_box_custom_message: theme_standard_box_custom_message,
    theme_standard_box_custom_message_2: theme_standard_box_custom_message_2,
    theme_standard_box_custom_gray: theme_standard_box_custom_gray,
    theme_standard_box_custom_blue: theme_standard_box_custom_blue,
    theme_standard_box_custom_title: theme_standard_box_custom_title,
    theme_standard_box_custom_yellow: theme_standard_box_custom_yellow,

  });
  return (
    <BoxCustomStyled {...props} style={style}>
      <div className={customClass} style={{ border: props.loading ? 'none' : '' }}>
        {props.title
          ? <div>
            <div className='title'>
              <div className='title_wrapper'>
                {props.title}
              </div>
              <div className='title_wrapper'>
                {props.title_right}
              </div>
            </div>
            <div className='container_custom'>
              {props.noData ? <div className='nodata'>ไม่มีข้อมูล</div> : props.child}
            </div>
          </div>
          : props.noData ? <div className='nodata'>ไม่มีข้อมูล</div> : props.child
        }
        {props.loading &&
          <div className="loading_container">
            <label>
              {/* <LinearProgress
                color="black"
                indeterminate={true}
                style={{width : '97%' , borderRadius : '6rem' , color : 'red'}}
                className="loading_bar"
              /> */}
            </label>
          </div>
        }
      </div>
    </BoxCustomStyled>
  );
};