import React from 'react';
import { TableCustomStyled } from './styled';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { iconImages } from 'assets';
import { Optionbox } from './checkbox';
import { BoxFilter } from 'components/BoxFilter';
export const TableCustom = (props) => {
  const {
    theme_standard,
    theme_standard_2,
    columns,
    data,
    useNo,
    offset,
    sort,
    bgColor,
    responsiveWidth,
    isLongResponsive,
    onClick,
    onSort,
    headOnly,
    padding_bottom,
    handelToEditRole,
    isFilter,
    intitalValue

  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2
  });
  const isResponsiveTable = useMediaQuery({ query: `(max-width: ${responsiveWidth ? responsiveWidth : '1260px'})` })
  const getDescendantProp = (index,obj, desc, highlight) => {
    var arr = desc.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return highlight ? (obj ? obj : <div style={{ color: '#eb4034' }}>.</div>) : obj;
  };
  const setResponsiveStyled = (columns) => {
    return columns && columns.map((col, i) => {
      return isResponsiveTable
        ? (
          {
            ...col,
            headerStyle: {
              ...col.headerStyle,
              width: convertPercent(col.headerStyle.width)
            },
            style: {
              ...col.style,
              width: convertPercent(col.style.width)
            }
          }
        )
        : { ...col }
    })
  }
  const convertPercent = (number) => {
    const width = isLongResponsive
      ? (parseInt(number) / 1).toString()
      : (parseInt(number) / 1.5).toString()
    return width + "rem";
  }

  const serSortIcon = (field) => {
    return (sort && sort.sort_by === field)
      ? sort.order_by === 'DESC'
        ? 'sort_active_down.svg'
        : sort.order_by === 'ASC'
          ? 'sort_active_up.svg'
          : 'sort_unactive.svg'
      : 'sort_unactive.svg'
  }

  return (
    <TableCustomStyled cursor={onClick} padding_bottom={padding_bottom}>
      <div className={customClass} >
        <div className="table_container">
          <div className="table_header" style={{ background: bgColor }}>
            <div className="header_row">
              {columns &&
                (columns).map((e, i) =>
                (
                <>
                  <div className="header_col" key={i} style={e.headerStyle}>
                    {(e.renderTitle ? e.renderTitle : e.title)}
                    {e.sort && <img className='icon' onClick={() => onSort(e.field)} src={iconImages[serSortIcon(e.field)]} alt='sort' />}
                    
                  {
                  e?.isFilter && <div className='filterBox'>
                    {e?.component}
                  </div>
                  }
                  </div>
                </>
                ))}
            </div>
          </div>
          <div className="table_body">
            {data && data.length !== 0 &&
              data.map((e, i) => {
                return (
                  <div
                    key={i}
                    style={e.style}
                    className='body_row'
                  >
                    {setResponsiveStyled(columns).map((e1, i2) =>
                    (
                      <div
                        key={i2}
                        className="body_col"
                        style={{ ...e1.style }}
                        onClick={() => (onClick && !e1?.disabled) ? onClick(e) : ''}
                      >
                        {/* {isCheckbox && i2 === 0 && (
                          <div className="checkbox_layout">
                            <CheckBoxCustom
                              theme_standard
                              onChange={() => handleCheckbox(i, e)}
                            />
                          </div>
                        )} */}
                        {useNo && i2 === 0 ? (
                          <div className="no">
                            {e1.render
                              ? e1.render({
                                origin: e,
                                value: (offset ? offset : 0) + i + 1,
                              })
                              : (offset ? offset : 0) + i + 1}
                          </div>
                        ) : e1.render ? (
                          <div>
                            {
                            e1.render({
                              origin: e,
                              value: getDescendantProp(i,e, e1.field),
                            })
                            }
                          </div>
                        ) : (
                          <div>
                            {getDescendantProp(i,e, e1.field, e.isHighlight)}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
          {!headOnly &&
            (!data || data.length === 0) &&
            <div className="empty_list">
              ไม่มีข้อมูล
            </div>
          }
        </div>
      </div>
    </TableCustomStyled >
  );
};
