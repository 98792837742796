import styled from "styled-components"

export const BoxModalStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    .theme_standard {
        position : relative;
        .icons{
            display:flex;
            justify-content:center;
            margin-bottom:-2rem;
            width:10rem;
        }
    }
    .theme_standard_2 {
        position : relative;
        .icons{
            display:flex;
            justify-content:center;
            margin-bottom:-2rem;
            width:10rem;
        }
    }
    .theme_standard_3 {
        position : relative;
        .icons{
            display:flex;
            justify-content:center;
            margin-bottom:-2rem;
            width:10rem;
        }
    }
    
`