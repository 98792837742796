function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    return (images[item.replace('./', '')] = r(item).default);
  });
  return images;
}

const backgroundImages = importAll(
  require.context('./backgrounds', false, /\.(png|jpe?g|svg)$/)
);
const iconImages = importAll(
  require.context('./icons', false, /\.(png|jpe?g|svg)$/)
);
const logoImages = importAll(
  require.context('./icons', false, /\.(png|jpe?g|svg)$/)
);
const UploadIconsImages = importAll(
  require.context('./uploadIcons', false, /\.(png|jpe?g|svg)$/)
);

export { backgroundImages, iconImages, logoImages, UploadIconsImages };
