import styled from "styled-components"
 
export const  SettingListsContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
 
`