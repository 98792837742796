import React from 'react';
import { RecommendDoctorFormStyled } from './styled';
import { InputTextArea, ButtonCustom } from 'components';
import { VALIDATE } from 'assets';
import { Field, reduxForm } from 'redux-form';
// import { connect } from 'react-redux';

class RecommendDoctorForm extends React.Component {
  state = {};

  render() {
    const { handleSubmit,is_button,is_modal ,onCancel } = this.props;
    return <RecommendDoctorFormStyled column = {is_modal} >
      <form className='form_layout' onSubmit={handleSubmit}>
        <div className='input_row'>
          <Field
            theme_standard_max_length
            component={InputTextArea}
            name='message'
            type='text'
            validate={[VALIDATE.REQUIRE_FIELD, VALIDATE.NUMBER_MAX_LENGTH_2000]}
            maxLength={2000}
            placeholder='ข้อความใหม่'
          />
        </div>
        {
          is_button &&
          <div className='button_wrapper'>
            <ButtonCustom
              theme_blue_7
              label='ข้อความ'
              onClick={handleSubmit}
              icon='plus.svg'
            />
          </div>
        }
        {
          is_modal &&
          <div className='modal_button'>
            <div className='button_wrapper'>
              <ButtonCustom
                theme_blue_9
                label='ยืนยัน'
                onClick={handleSubmit}
              />
            </div>
            <div className='button_wrapper'>
              <ButtonCustom
                theme_gray_2
                label='ยกเลิก'
                onClick={onCancel}
              />
            </div>
          </div>
        }
          
      </form>
    </RecommendDoctorFormStyled>;
  }
}

// RecommendDoctorForm = reduxForm({
//   form: 'RecommendDoctorForm', // a unique identifier for this form
//   enableReinitialize: true,
// })(RecommendDoctorForm);

// RecommendDoctorForm = connect(
//   state => ({
//     initialValues: {
//       message: state.message
//     }
//   }),
//   {}
// )(RecommendDoctorForm)

// export default RecommendDoctorForm


export default reduxForm({
  form: 'RecommendDoctorForm', // a unique identifier for this form
  enableReinitialize: true,
})(RecommendDoctorForm);
