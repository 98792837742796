import { intersection } from 'lodash';

export const isArrayWithLength = (arr) => {
    return (Array.isArray(arr) && arr.length)
}

export const getAllowedRoutes = (routes, role) => {
    return routes.filter(({ permission }) => {
        if (!permission) return true;
        else return permission.some((role_permission) => role_permission === role.toUpperCase())
    });
} 
