import styled from "styled-components"

export const UserAccountFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    width : 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    .field{
        width : 100%;
        margin-top:0.5rem;
    }
    .label{
        font-weight : 500;
    }
    .title{
        margin:1rem 0 1.5rem 0;
        padding-bottom : 0.5rem;
        border-bottom : 1px solid  ${({ theme }) => theme.COLORS.BLUE_15};
    }
    .error{
        color: ${({ theme }) => theme.COLORS.RED_1};
        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        text-align:left;
        //margin-left:1rem;
        margin-top:0.3rem;
    }
    .error2{
        margin : 1rem 0 1.5rem 0;
        color: ${({ theme }) => theme.COLORS.RED_1};
        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        text-align:left;
        //margin-left:1rem;
    }
    .button_container{
        margin-left : auto;
        margin-right : 0.1rem;
        padding-top : 0rem;
        width : 10rem;
    }
    .doctor_sync_title{
        font-size:${({ theme }) => theme.FONTS.SIZE_22px};
        /* color: ${({ theme }) => theme.COLORS.BLUE_17}; */
    }
    .header_btn_container{
        display: flex;
        justify-content: space-between;
        /* margin-left: 1rem; */
        align-items: center;
        margin-bottom : 1rem;
        .title_text{
            .create_title{
                font-size:${({ theme }) => theme.FONTS.SIZE_22px};
            }
        }
    }
    .row_container2{
        width: 100%;
        margin-bottom:1rem;
        .label{
            font-weight : 600;
            color: ${({ theme }) => theme.COLORS.BLACK_10};
        }
    }
    .row_container{
        margin-bottom:1rem;
        display : flex;
        width : 100%;
        &:first-child{
            margin-top : 1rem;
        }
        .col_container{
            width : 50%;
            &:nth-child(odd) {
                margin-right : 1rem;
            }
            &:nth-child(even) {
                margin-left : 1rem;
            }
        }
        .col_container_3{
            width : 30%;
            margin-right : 1rem;
        }
        .col_container_7{
            background-color : ${({ theme }) => theme.COLORS.WHITE_1};
            width : 100%;
            /* margin-left : 1rem; */
            padding: 10px 20px 10px 20px;
            border-radius : 10px;
            .checkpasswordContainer{
                display: flex;
                flex-direction: column;
                /* align-items: flex-end; */
                /* background-color: red ; */
                &.left{
                }
                &.right{
                    margin-left: 50.5%;
                }
            }
        }
        .fluid_col_container{
            width : 100%;
        }
        .fluid_col_container_Img{
            padding-left : 1rem;
        }
    }
    .row_container3{
        margin-bottom:0.2rem;
        padding-top:0.5rem;
        display : flex;
        width : 100%;
        &:first-child{
            margin-top : 1rem;
        }
        .col_container{
            width : 60%;
            &:nth-child(odd) {
                margin-right : 1rem;
            }
            &:nth-child(even) {
                margin-left : 1rem;
            }
        }
        .fluid_col_container{
            .fluid_col_wrapper{
                width : 100%;
            }
        }
    }
    .username_password_box{
        display: flex;
        /* width: 100%; */
        column-gap: 10px;
        .username_box{
            /* column-gap: 10px; */
            width: 50%;
        }
        .password_box{
            /* column-gap: 10px; */
            width: 50%;
        }
    }
    .doctorInfomationTitle{
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-weight: 600;
        font-size:${({ theme }) => theme.FONTS.SIZE_21px};
        margin: 10px 0px;
    }
    .clinics_box{
        width: 100%;
    }
    .clinics_title_box{
        display: flex;
        column-gap: 10px;
        width: 100%;
        .title_box{
            width: 100%;
        }
    }
    .doctor_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 880px;
        .doctor_title_text{
            margin-bottom:40px;
            .doctor_title{
            display: flex;
            justify-content: center;
            color: ${({ theme }) => theme.COLORS.BLUE_17};
            font-size:${({ theme }) => theme.FONTS.SIZE_22px};
            }
            .doctor_description{
                display: flex;
                justify-content: center;
                color: ${({ theme }) => theme.COLORS.GRAY_13};
                font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            }
            
        }
        .doctor_username_password_box{
            height: 500px;
            border-radius:10px;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            padding: 50px 50px 0px 50px;
            display: flex;
            flex-direction: column;
            .doctor_username_password_title{
                margin-bottom:30px;
                /* color: ${({ theme }) => theme.COLORS.BLACK_9}; */
                font-size:${({ theme }) => theme.FONTS.SIZE_21px};
            }
        }
        .doctor_username_password_container{
            display: grid;
            grid-template-columns:1fr 1fr ;
            grid-column-gap:100px;
        }
        .doctor_box{
            /* border: 1px solid #000; */
            /* display: flex; */
        }
        .doctor_sync_container{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius:10px;
            margin-bottom:10px;
            height: 163px;
            width: 880px;
            /* background-color: ${({ theme }) => theme.COLORS.WHITE_1}; */
            
            .doctor_sync_box{
                width: 100%;
                /* background-color: ${({ theme }) => theme.COLORS.BLACK_1}; */
                .doctor_filter_box{
                    display: flex;
                    display: grid;
                    grid-template-columns:82% 20%;
                    /* grid-template-rows:1fr; */
                    grid-column-gap:10px;
                    /* justify-content: space-between; */
                }
                .doctor_sync_box_title{
                    margin-bottom:10px;
                    font-weight:800;
                    font-size: ${({ theme }) => theme.FONTS.SIZE_21px}
                }
            }
        }
        .doctor_profile_container{
            border-radius:10px;
            width: 880px;   
            /* height: 120vh; */
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            .doctor_profile_box{
                padding: 50px 50px 0px 50px;
                font-weight:bold;
                font-size: ${({ theme }) => theme.FONTS.SIZE_18px};
                .mb10{
                    margin-bottom:10px
                }
                .pic_box_border{
                    border:1px solid ${({ theme }) => theme.COLORS.BLUE_18}; 
                    border-radius:25rem;
                }
            }
            .doctor_profile_description{
                padding: 10px 50px 50px 50px;
                display: flex;
                flex-direction: column;
                /* grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 50px 50px 1fr 50px; */
                /* grid-column-gap: 0px; */                
                color: ${({ theme }) => theme.COLORS.BLACK_10};

                .content_profile_container{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    width: 100%;
                    justify-content:space-between;
                    border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK_8};
                }

                span{
                    font-weight:500;
                    color: ${({ theme }) => theme.COLORS.BLACK_9};
                }
                .bb{
                }
                .pt10{
                    padding-top: 10px;
                }
                .subItemWapper{
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                .dp_name{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .dp_id{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                
                .dp_hospital{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .dp_clinic{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
            }
        }
        .doctor_not_found_container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            min-height: 343px;
            .doctor_not_found_title{
                color: ${({ theme }) => theme.COLORS.RED_1};
                font-size:${({ theme }) => theme.FONTS.SIZE_22px};
            }
        }
    }
`