import styled from "styled-components"
 
export const BoxConfirmStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
       .box{
        width : 25rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 1rem;
                padding-bottom : 1rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align :left;
                padding-bottom : 1rem;
            }
       }
       .button_layout{
           margin-top:1rem;
           display :flex ;
           justify-content : center;
           column-gap:10px;
           .div1{
               width : 40%;
           }
           .div2{
               width : 40%;
           }
       }
    }
    .theme_standard_2 {
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
       .box{
        width : 25rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: start;
                margin-bottom : 1rem;
                padding-bottom : 1rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align :left;
                padding-bottom : 1rem;
            }
       }
       .button_layout{
           margin-top:1rem;
           display :flex ;
           justify-content : center;
           column-gap:10px;
           .div1{
               width : 40%;
           }
           .div2{
               width : 40%;
           }
       }
    }
    .theme_error {
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
       .box{
        max-width : 25rem;
        min-width : 14rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                padding-bottom : 0.5rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 2rem;
                margin-top : 1.7rem;
                color : ${({ theme }) => theme.COLORS.BLUE_3}; 
                font-weight: 500;
            }
       }
       
    }
    .theme_success{
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
       .box{
        width : 25rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 1rem;
                padding-bottom : 1rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align :center;
                font-weight : lighter;
                padding-bottom : 2rem;
            }
       }
    }
    .theme_confirm {
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
        border: 5px solid ${({theme})=>theme.COLORS.YELLOW_2};
        border-radius:15px;
        .box{
        width : 25rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 1rem;
                padding-bottom : 1rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align :left;
                padding-bottom : 1rem;
            }
        }
        .button_layout{
            margin-top:1rem;
            display :flex ;
            justify-content : center;
            column-gap:10px;
            .div1{
                width : 40%;
            }
            .div2{
                width : 40%;
            }
        }
    }
`