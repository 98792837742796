import React, { useEffect, useState } from 'react';
import { TabCustomStyled } from './styled';
import cx from 'classnames';
import { setTabActive } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux';

export const TabCustom = (props) => {
  const { theme_standard, theme_standard_2,theme_standard_3, tabLists } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3,
  });
  const dispatch = useDispatch()
  const { tab_active } = useSelector(state => state)
  const [indexTab, setIndexTab] = useState(tab_active)
  useEffect(() => {

  }, [])
  // dispatch(setTabActive(0))
  // const renderTab = (tabLists) => {
  //   console.log('tabLists',tabLists)
  //   return tabLists && tabLists.map((tabList, i) => {
  //     return (
  //       <div id={tabList.name} key={i}
  //         className={(tabList.id === indexTab ? 'tab_item active' : 'tab_item')}
  //         onClick={(e) => {
  //           e.preventDefault()
  //           setIndexTab(tabList.id)
  //           dispatch(setTabActive(tabList.id))
  //         }}
  //       >
  //         {tabList.name}
  //       </div >
  //     )
  //   })

  // }
  const renderTab = (tabLists) => {
    let showTab = []
    showTab = tabLists && tabLists.filter((e) => {
      return e.isShow === true
    })

    return showTab && showTab.map((tabList, i) => {
      return (
        <div id={tabList.name} key={i}
          className={(tabList.id === indexTab ? 'tab_item active' : 'tab_item')}
          onClick={(e) => {
            e.preventDefault()
            setIndexTab(tabList.id)
            dispatch(setTabActive(tabList.id))
          }}
        >
          {tabList.name}
        </div >
      )
    })
  }

  const renderComponents = () => {
    const comp = tabLists && tabLists.find((tabList => tabList.id === indexTab))
    const first_comp = !comp && tabLists.find((tabList => tabList.id === 0))
    !comp && dispatch(setTabActive(0))
    return comp ? comp.components : first_comp.components
  }
  return (
    <TabCustomStyled {...props}>
      <div className={customClass}>
        <div className="tab_container">
          <div className="tab_container_list">
            {tabLists && renderTab(tabLists)}
          </div>
        </div>
        <div className="content_container">
          {tabLists && renderComponents()}
        </div>
      </div>
    </TabCustomStyled>
  );
};
