import styled from "styled-components"

export const PrivacyPolicyEditerStyle = styled.div`

/*===============================================
Theme 
===============================================*/

    /* background-color : #fff; */
    margin-right: auto;
    margin-left: auto;
    max-width : 55rem;
    box-sizing : border-box;
    overflow : hidden;
    margin-bottom : 5rem;
    display : flex;
    justify-content :center;
    /* flex-direction: column; */
    /* max-width:1083px; */
    /* height : 100vh; */
    /* background-image: linear-gradient(157deg, ${({ theme }) => theme.COLORS.GREEN_3},${({ theme }) => theme.COLORS.GREEN_3}, ${({ theme }) => theme.COLORS.GREEN_2}); */
    /* background-color : ${({ theme }) => theme.COLORS.GREEN_2}; */
    /* background-image: linear-gradient(135deg #fff 0%, #f3f6f8 100%); */
    /* background-image: linear-gradient(135deg, #fff, #f3f6f8); */
    /* background-image: linear-gradient(135deg, #fff, #f3f6f8); */
    /* background-repeat: no-repeat; */
    /* background-size: auto; */




`






