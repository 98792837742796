import {
  BoxConfirm,
  BoxModal,
  ButtonCustom,
  ImgUpload,
  Typography,
} from 'components'
import { UserAccountForm } from 'forms'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AdminEditProfileStyled } from './styled'

import theme from 'assets/theme.json'
import AdminForm from '../../forms/Adminform'
import { ROUTE_PATH } from 'assets'
import { userService } from 'apiService'
import { useDispatch, useSelector } from 'react-redux'
import { change, initialize } from 'redux-form'
import { setReduxUserAuth } from 'store/actions'

const AdminEditProfile = () => {
  const [profile_picture_url, setProfile_picture_url] = useState()
  const [AdminProfile, setAdminProfile] = useState()
  const [modal, setModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const AdminReduxform = useSelector((state) => state.form.AdminForm)
  const auth = useSelector((state) => state.auth)
  const InstitutionDetail = useSelector((state) => state.institution_detail)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const res = await userService.GET_PROFILE()
    if (res && res.success) {
      dispatch(initialize('AdminForm', res?.data))
      setAdminProfile(res?.data)
      setProfile_picture_url(res?.data?.profile_picture_url)
    }
  }
  const handleSubmit = (e) => {
    const params = {
      ...e,
    }
    e?.password ? e?.password : delete params.password
    e?.id ? e?.id : delete params.id
    e?.name ? e?.name : delete params.name
    e?.role ? e?.role : delete params.role
    e?.title ? e?.title : delete params.title
    e?.profile_picture_base64
      ? (params.profile_picture_url = e.profile_picture_base64)
      : delete params.profile_picture_url
    pacthProfile(AdminProfile?.id, params)
  }

  const pacthProfile = async (id, params) => {
    const res = await userService.PATCH_PROFILE(params)
    if (res && res?.success) {
      dispatch(
        setReduxUserAuth({
          ...auth,
          profile_picture_url: res?.data?.profile_picture_url,
        }),
      )
      setIsOpen(true)
      setModal(
        <BoxConfirm
          theme_confirm
          title='อัพเดทบัญชีสำเร็จ'
          confirm='ตกลง'
          onConfirm={async () => {
            setIsOpen(false)
            await fetchData()
          }}
          onCancel={false}
        />,
      )
    }
  }

  return (
    <AdminEditProfileStyled>
      <BoxModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        theme_standard>
        {modal}
      </BoxModal>

      <div className='back'>
        <ButtonCustom
          theme_back
          label='BACK'
          icon='arrow_left.svg'
          onClick={() => history.goBack()}
        />
      </div>

      <Typography
        label='Admin Account'
        weight={500}
        loading={false}
        color={theme.COLORS.BLUE_17}
        size={theme.FONTS.SIZE_30px}
      />
      <div className='body_section'>
        <AdminForm
          readonly={AdminProfile}
          institution_id={InstitutionDetail.id}
          realm={InstitutionDetail.realm}
          profile_picture_url={profile_picture_url}
          onSubmit={(e) => handleSubmit(e)}
          isReset={!isOpen}
        />
      </div>
    </AdminEditProfileStyled>
  )
}

export default AdminEditProfile
