import React from 'react';
import { PageNotFoundStyled } from './styled';
import cx from 'classnames';
import { Typography, ButtonCustom } from 'components';
import theme from 'assets/theme.json'
import { Emitter } from 'assets';

export const PageNotFound = (props) => {
  const { notfound, permission } = props;
  const customClass = cx({
    notfound: notfound,
    permission: permission,
  });
  return (
    <PageNotFoundStyled {...props}>
      <div className={customClass}>
        {notfound &&
          <div className='container_custom'>
            <div className='container_custom_wrapper'>
              <div className='title'>
                <Typography
                  label="404 Page not found"
                  size={theme.FONTS.SIZE_50px}
                  color={theme.COLORS.PURPLE_1}
                />
              </div>
              <div style={{ display: 'flex' , justifyContent : "center" }}>
                There's nothing to see here.
              <div className='button' onClick={() => Emitter.emit('REDIRECT_HOME')}>
                  HOME PAGE
              </div>
              </div>
            </div>
          </div>
        }
        {permission &&
          <div className='container_custom'>
            <div className='container_custom_wrapper'>
              <div className='title'>
                <Typography
                  label="Access denied"
                  size={theme.FONTS.SIZE_50px}
                  color={theme.COLORS.PURPLE_1}
                />
              </div>
              <div style={{ display: 'flex' ,justifyContent : "center"}}>
                You do not have permission to access this page.
                <div className='button' onClick={() => Emitter.emit('REDIRECT_HOME')}>
                HOME PAGE
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </PageNotFoundStyled>
  );
};
