import styled from 'styled-components';

export const CmsMainLayoutStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .sidebar_layout{
     /* width : 120px; */
  } 
  .content_width{
    width: 100%;
  }
  .content_ml{
    margin-left : 100px;
    width : calc( 100% - 100px);
  }
  .content_layout {
    .inner_content_layout{
      height: 100%;
      box-sizing: border-box;
      overflow : hidden;
      background: ${({ theme }) => theme.COLORS.GREY_1};
      margin-right: auto;
      margin-left: auto;
      /* min-width : 768px; */
      max-width : 1600px;
      min-width : 1023px;
      padding : 2rem 3rem;
    }
    
  }
  @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
    .content_layout {
      margin-left : 0;
      min-width : 768px;
      width : 100%;
    }
  }
  
`;
