import React from 'react';
import { InputDateFormStyled } from './styled';
import { Field, reduxForm } from 'redux-form';
import { getBeginDate, getCurrentDate, iconImages, VALIDATE } from 'assets';
import { InputDatePicker, InputDateRangePicker } from 'components';
import { connect } from 'react-redux';
import moment from 'moment'

class InputDateForm extends React.Component {
  state = { isOpen: false };

  componentDidUpdate = (prevProps, prevState) => {
    const { mode } = this.props
    if (prevProps?.mode !== mode) {
      if (mode === 'range') {
        this.props.change('date', [getBeginDate(7), getCurrentDate()])
      } else if (mode === 'dropdown') {
        this.props.change('dropdown_date', getCurrentDate())
      }
    }
  }

  handleChangeDate = (dates) => {
    const { handleChangeDate, mode } = this.props

    if (mode === 'range') {
      const [begin_date, end_date] = dates
      if (begin_date && end_date) {
        this.setState({ isOpen: false })
        handleChangeDate(moment(begin_date).format('YYYY-MM-DD'), moment(end_date).format('YYYY-MM-DD'))
      }
    } else if (mode === 'dropdown') {
      this.setState({ isOpen: false })
      handleChangeDate(moment(dates).format('YYYY-MM-DD'), moment(dates).format('YYYY-MM-DD'))
    }
  }
  renderDate = () => {
    const { reduxInputDateForm, mode } = this.props
    let key = reduxInputDateForm?.values?.date || reduxInputDateForm?.values?.dropdown_date ? 'values' : 'initial'
    let date = mode === 'range'
      ? reduxInputDateForm?.[key]?.date
        ? moment(reduxInputDateForm?.[key]?.date[0]).format('DD MMM YYYY') + ' - ' + moment(reduxInputDateForm?.values?.date[1]).format('DD MMM YYYY')
        : moment(getBeginDate(7)).format('DD MMM YYYY') + ' - ' + moment(getCurrentDate()).format('DD MMM YYYY')
      : reduxInputDateForm?.[key]?.dropdown_date
        ? moment(reduxInputDateForm?.[key]?.dropdown_date).format('DD MMM YYYY')
        : moment(getCurrentDate()).format('DD MMM YYYY')
    return date
  }

  render() {
    const { isOpen } = this.state
    const { mode } = this.props
    return <InputDateFormStyled>
      <div className='content'>
        <div className='input-date'>
          <div className='input-label' onClick={() => this.setState({ isOpen: true })}>
            {this.renderDate()}
          </div>
          <img className='icon ' onClick={() => this.setState({ isOpen: true })} src={iconImages['dropdown_down.svg']} alt='icon' />
        </div>
        {isOpen &&
          <>
            <div className='box-date'>
              {mode === 'range'
                ? <Field
                  name={'date'}
                  component={InputDateRangePicker}
                  onChange={this.handleChangeDate}
                />
                : <Field
                  name="dropdown_date"
                  component={InputDatePicker}
                  onChange={this.handleChangeDate}
                />
              }
            </div>
            <div className='backdrop'>
              <div className='backdrop_outer' onClick={() => this.setState({ isOpen: false })}>
              </div>
            </div>
          </>
        }
      </div>
    </InputDateFormStyled>;
  }
}


const mapStateToProps = (state) => ({
  reduxInputDateForm: state.form?.InputDateForm,
})

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
})

InputDateForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InputDateForm)

InputDateForm = reduxForm({
  form: 'InputDateForm', // a unique identifier for this form
  enableReinitialize: true,
})(InputDateForm)

InputDateForm = connect(
  (state) => ({
    initialValues: {
      dropdown_date: getCurrentDate(),
      date: [
        getBeginDate(7),
        getCurrentDate(),
      ],
    },
  }),
  {},
)(InputDateForm)

export default InputDateForm
