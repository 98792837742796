
import styled from "styled-components"

export const TextEditerStyle = styled.div`

/*===============================================
Theme 
===============================================*/

font-size: ${({ theme }) => theme.FONTS.SIZE_16px};

    .demo-wrapper{
        max-width : 55rem
        height: 500px;
        background-color: #fff;
        border: #0000002b 1px solid;
        border-radius: 3px;
        overflow: hidden
    }

    .demo-editor{
        padding: 10px;
        overflow: hidden

    }
    .PrivacyBTN{
        display: flex;
        
    }

    .rdw-image-wrapper{
        display: none;
    }
    .grid_label_te{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        .te_title{
            font-weight : 500;
        }
    }
    .maxLength_text{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-right: 10px;
        font-size: ${({ theme }) => theme.FONTS.SIZE_12px};
    }





`






