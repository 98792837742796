import styled from "styled-components";

export const BoxFilterStyled = styled.div`

    .theme_standard{
        .content_box{
            min-width:120px;
            min-height:100px;
            padding:10px;
            background-color:#ffffff;
            display:flex;
            justify-content:center;
            align-items:center;
            box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
            border-radius:8px;
            .content_display{
                width:100%;
                height:100%;
                display:grid;
                justify-content:center;
                align-items:center;
            }
            .item_box{
                display:flex;
                /* justify-content:center; */
                align-items:center;
                column-gap:10px;
                .checkInputbox{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                }
                .checkInputLabel{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                }
            }
        }
    }


`


