import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player/file'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { userService } from 'apiService'
import moment from 'moment'
import { VideoLogStyled } from './styled'
import { BoxCustom, BoxModal } from 'components';

export const VideoLog = (props) => {
  const { video_room_id, closeVideo, video_id, videos_date } = props
  const [video, setVideo] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState('')
  useEffect(() => {
    if (video_room_id && video_id) {
      setIsOpen(true)
      fetchVisitVideo(video_room_id, video_id)
    }
  }, [video_room_id, video_id])

  const fetchVisitVideo = async (video_room_id) => {
    // let room_id = '68537d9cded74737'
    let res = await userService.GET_VISIT_VIDEO_LOG(video_room_id, video_id)
    if (res?.success) {
      setType(res.data.file_name)
      setVideo(res.data.file_uri)
    }
  }
  const onRequestClose = () => {
    setIsOpen(false)
    closeVideo()
    setVideo([])
    setType('')
  }

  return (
    <VideoLogStyled>
      <BoxModal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      theme_standard
        >
        <div style={{background: 'white', padding: '20px', borderRadius: '10px', position: 'relative' }}>
        <div style={{fontSize: '18px',color: '#0C3C60'}}>Visit Video Log
        </div>
        <div style={{fontSize: '14px',color: '#0C3C60',opacity: '0.9'}}>
          {moment
            .utc(videos_date)
            .utcOffset('+0700')
            .format('DD MMMM YYYY HH:mm')}
        </div>
        <button onClick={onRequestClose} style={{ position: 'absolute', borderRadius: '50%', width: '40px', height: '40px', cursor: 'pointer', fontSize: '20px', border: 'none', top: '-20px', right: '-15px'}}>X</button>
        <ReactPlayer
          style={{marginTop: '18px'}}
          url={video}
          width='100%'
          height='600px'
          controls='true'
        />
        </div>
      </BoxModal>
    </VideoLogStyled>
  )
}
