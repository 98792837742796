import React, { useEffect, useState } from 'react'
import { UserListsStyled } from './styled'
import {
  ButtonCustom,
  TableCustom,
  BoxProfile,
  Pagination,
  BoxModal,
  BoxFilter,
} from 'components'
import { useHistory } from 'react-router-dom'
import { ROUTE_PATH, calculateSkeleton, iconImages } from 'assets'
import { userService } from 'apiService'
import moment from 'moment'
import { SearchBar } from 'components/SearchBar'
import { Optionbox } from 'components/TableCustom/checkbox'
import BoxComponent from 'components/BoxComponent'

export const UserLists = (props) => {
  const history = useHistory()
  const { institutionDetailData } = props
  const [uniqueRoles, setUniqueRoles] = useState([])
  const [FilterRoles, setFilterRoles] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [modal, setModal] = useState()
  const [keyword, setKeyword] = useState()
  const [page, setPage] = useState()
  const [sort, setSort] = useState({
    sort_by: '',
    order_by: 'DESC',
  })

  const [filter, setFilter] = useState({
    offset: 0,
    per_page: 10,
  })

  useEffect(() => {
    if (institutionDetailData && institutionDetailData.id) {
      // fetchData()
      newFetchData()
    }
  }, [institutionDetailData, filter, FilterRoles])

  const fetchData = async () => {
    let res = await userService.GET_USERS(institutionDetailData.id)
    if (res && res.success) {
      setUserLists(setNewUserLists(res.data))
    } else {
    }
  }

  const getuniqueRoles = async (data) => {
    const allRoles = [
      {
        title: 'แพทย์',
        icon: 'doctorRole.svg',
        value: 'doctor',
        description:
          'ระบบจะทำการสร้างบัญชีและเชื่อมต่อข้อมูลของแพทย์แค่ครั้งแรกเท่านั้น',
        permission: true,
      },
      {
        title: 'พยาบาล',
        icon: 'nurseRole.svg',
        value: 'nurse',
        description: 'ระบบจะทำการสร้างบัญชีของพยาบาลเพื่อใช้สำหรับ Telecare',
        permission: true,
      },
      {
        title: 'เภสัชกร',
        value: 'pharmacist',
        icon: 'pharmacistRole.svg',
        description: 'ระบบจะทำการสร้างบัญชีของเภสัชกรเพื่อใช้สำหรับ Telecare',
        permission: institutionDetailData?.can_provider_pharmacist,
      },
      {
        title: 'การเงิน',
        value: 'accounting',
        icon: 'accountingRole.svg',
        description: 'ระบบจะทำการสร้างบัญชีของการเงินเพื่อใช้สำหรับ Telecare',
        permission: institutionDetailData?.should_accounting_on_finishing,
      },
      {
        title: 'ล่าม',
        value: 'translator',
        icon: 'translatorRole.svg',
        description: 'ระบบจะทำการสร้างบัญชีของล่ามเพื่อใช้สำหรับ Telecare',
        permission: institutionDetailData?.can_consult_translator,
      },
    ]
    const uniqueRolesSet = allRoles
      .filter((item) => item?.permission)
      .map((name) => name?.value)
    const uniqueRolesArray = Array.from(uniqueRolesSet)
    await setUpCheckbox(uniqueRolesArray)
  }

  const setUpCheckbox = async (intitalValue) => {
    const result = intitalValue.map((item, index) => {
      let label = ''
      let isCheck = item ? true : false
      switch (item) {
        case 'doctor':
          label = 'แพทย์'

          break
        case 'nurse':
          label = 'พยาบาล'
          break

        case 'pharmacist':
          label = 'เภสัชกร'
          break

        case 'accounting':
          label = 'การเงิน'
          break
        case 'translator':
          label = 'ล่าม'
          break

        default:
          break
      }

      return {
        value: item,
        label: label,
        isCheck: isCheck,
      }
    })
    setUniqueRoles(result)
  }

  const newFetchData = async (type, keyword, role) => {
    let res

    switch (type) {
      case 'search':
        res = await userService.GET_USERS_PAGINATION(
          institutionDetailData.id,
          '10',
          '0',
          keyword,
        )
        if (res && res.success) {
          setUserLists(setNewUserLists(res.data))
          setPage(res.page)
          setSort(res.sort)
        }
        break
      default:
        const { per_page, offset } = filter
        if (FilterRoles) {
          res = await userService.GET_USERS_PAGINATION(
            institutionDetailData.id,
            per_page,
            offset,
            false,
            FilterRoles,
          )
          if (res && res.success) {
            setUserLists(setNewUserLists(res.data))
            setPage(res.page)
            setSort(res.sort)
          }
        } else {
          res = await userService.GET_USERS_PAGINATION(
            institutionDetailData.id,
            per_page,
            offset,
          )
          if (res && res.success) {
            await getuniqueRoles(res.data)
            setUserLists(setNewUserLists(res.data))
            setPage(res.page)
            setSort(res.sort)
          }
        }
        break
    }
  }

  const setNewUserLists = (data) => {
    return data
      ? data.map((item, i) => {
          return {
            ...item,
            lastseen_at: item.lastseen_at
              ? moment
                  .utc(item.lastseen_at)
                  .utcOffset('+0700')
                  .format('DD MMM YYYY')
              : '-',
            created_at: item.created_at
              ? moment
                  .utc(item.created_at)
                  .utcOffset('+0700')
                  .format('DD MMM YYYY')
              : '-',
            role: (
              <div style={{ textTransform: 'capitalize' }}>{item.role}</div>
            ),
            // name: <BoxProfile
            //   theme_standard
            //   src={item.profile_picture_url}
            //   label={item.name}
            //   desc={item.title}
            // />,
            last_activity: item.id,
            checkbox: (
              <div>
                <Optionbox
                  intitalValue={item}
                  handelToEditRole={handelToEditRole}
                />
              </div>
            ),
          }
        })
      : []
  }

  const PositionBox = () => {
    return (
      <div className='positionBox'>
        <div className='boxContener'>
          <div className='positionTitle'>ตำแหน่ง</div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsFilter(!isFilter)
            }}>
            <img src={iconImages['filter.svg']} />
          </div>
        </div>
      </div>
    )
  }

  const handeltoFilterRole = async (e) => {
    const roleString = e
      .filter((item) => item.isCheck === true)
      .map((item) => item.value)
      .join(',')
    const length = e.filter((item) => item.isCheck).length
    if (length === uniqueRoles.length) {
      setFilterRoles(false) //Set Role to filter
    } else {
      setFilterRoles(roleString) //Set Role to filter
    }
  }

  const columns = [
    {
      title: 'ลำดับ',
      field: 'index',
      headerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '4%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'left', width: '4%' },
      icon: 'setting.svg',
    },
    {
      title: 'ชื่อ-นามสกุล',
      field: 'name',
      headerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '40%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'center', width: '40%' },
      icon: 'setting.svg',
    },
    {
      title: <PositionBox />,
      isFilter: isFilter,
      component: (
        <BoxComponent>
          <BoxFilter
            theme_standard
            onFilter={handeltoFilterRole}
            intitalValue={uniqueRoles}
            _isModal={isFilter}
            handleModal={() => {
              setIsFilter(!isFilter)
            }}
          />
        </BoxComponent>
      ),
      field: 'role',
      headerStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '15%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'left', width: '15%' },
    },
    {
      title: 'บัญชีผู้ใช้งาน',
      field: 'username',
      headerStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '15%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'left', width: '15%' },
    },
    {
      title: 'วันที่สร้างบัญชี',
      field: 'created_at',
      headerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '15%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'center', width: '15%' },
    },
    {
      title: 'ใช้งานครั้งล่าสุด',
      field: 'lastseen_at',
      headerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '15%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'center', width: '15%' },
    },
    {
      title: <div>&nbsp;</div>,
      field: 'checkbox',
      headerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '20%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'center', width: '20%' },
      icon: 'setting.svg',
    },
  ]
  const [userLists, setUserLists] = useState(calculateSkeleton(columns))
  const loading = userLists[0]?.loading

  const handelToEditRole = (e) => {
    const outCheckEdit = e
    history.push(
      ROUTE_PATH.SETTINGS_DETAIL +
        institutionDetailData.id +
        '-' +
        institutionDetailData.realm +
        ROUTE_PATH.USER_ACCOUNT +
        outCheckEdit.id +
        ROUTE_PATH.USER_ROLE +
        outCheckEdit.role,
    )
  }

  const SetState = async (perpage, offset) => {
    setFilter({
      offset: offset,
      per_page: perpage,
    })
  }

  const onPagination = async (perpage, offset, current_page) => {
    await SetState(perpage, offset)
  }

  const onSort = (field) => {
    const params = {
      sort_by: field,
      order_by: sort.order_by === 'DESC' ? 'ASC' : 'DESC',
    }
    setSort({
      sort_by: field,
      order_by: sort.order_by === 'DESC' ? 'ASC' : 'DESC',
    })
    // newFetchData(params) ==> SortData
  }

  // const handelChange = async (e) => {
  //   const value = e.target.value
  //   if (value === '') {
  //     setFilter({
  //       offset: 0,
  //       per_page: 10,
  //     })
  //   }
  //   await newFetchData('search', value);
  // }

  const handleChange = async (e, type) => {
    switch (type) {
      case '_onchange':
        setKeyword(e.target.value)
        break
      case '_onkeypress':
        if (e.key === 'Enter') {
          await newFetchData('search', keyword)
        }
        break
      default:
        break
    }
  }

  return (
    <UserListsStyled {...props}>
      <div className='HeaderUserList'>
        <div className='SearchBox'>
          <div className='Header_Title'>บัญชีผู้ใช้งาน</div>
          <div className='search_bar'>
            <SearchBar
              placeholder='ค้นหารายชื่อ'
              onChange={(e) => handleChange(e, '_onchange')}
              onKeyPress={(e) => handleChange(e, '_onkeypress')}
            />
          </div>
        </div>
        <div style={{ width: '8rem' }}>
          <ButtonCustom
            theme_yellow
            label='สร้างบัญชี'
            onClick={() =>
              history.push(
                ROUTE_PATH.SETTINGS_DETAIL +
                  institutionDetailData.id +
                  ROUTE_PATH.USER_ROLE +
                  'create',
              )
            }
            // onClick={() => history.push(ROUTE_PATH.SETTINGS_DETAIL + institutionDetailData.id + ROUTE_PATH.USER_ACCOUNT + 'create')}
            icon='plus2.svg'
          />
        </div>
      </div>

      <div className='middleline'>
        <div className='middleline_text'>
          <b>รายชื่อผู้ใช้งาน</b>
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <TableCustom
          checkBox
          theme_standard_2
          offset={filter.offset}
          sort={sort}
          onSort={onSort}
          useNo={!loading}
          columns={columns}
          data={userLists}
          handelToEditRole={(e) => handelToEditRole(e)}
          isFilter={isFilter}
          intitalValue={uniqueRoles}
          // isClick={goToSettingOntarget}
          // responsiveWidth='970px'
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Pagination
          label
          arrows
          title
          onPagination={onPagination}
          sort={sort}
          page={page}
          per_page={filter.per_page}
        />
      </div>
    </UserListsStyled>
  )
}
{
  /* <Optionbox  handelToEditRole={handelToEditRole} intitalValue = {obj}/> */
}
