import React from "react";
import cx from 'classnames';
import theme from 'assets/theme.json'
import { MiddlelineStyle } from "./style";

export const Middleline = ({
    theme_standard,
    title
}) =>{
    const customClass = cx({
        theme_standard: theme_standard,
    });

    return(
        <MiddlelineStyle>
            <div className={customClass}>
                <div className='middleline_text' >
                    {
                        title && <b>{title}</b>
                    }
                </div>
            </div>
        </MiddlelineStyle>
    );
}

