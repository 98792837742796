import React, { useEffect, useState } from 'react';
import { BoxIconsStyled } from './styled';
import { iconImages ,UploadIconsImages} from 'assets';
import cx from 'classnames';
import { BoxCustom, TransparentIconsBox } from 'components';
import { Typography } from 'components/Typography';
import theme from 'assets/theme.json'
import { ButtonCustom } from 'components/ButtonCustom';

export const BoxIcons = (props) => {
  const { theme_standard,theme_Icons, theme_success, theme_error,theme_confirm,title, desc, confirm, cancel, loading,onConfirm, onCancel ,
    handleModal,
    handleChange,
    morepicture,
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_success: theme_success,
    theme_confirm: theme_confirm,
    theme_error : theme_error,
    theme_Icons : theme_Icons,
  });
  const selectionOption = [
    {
      title:'อัพโหลดไอคอน',
      key:'IconsUpload'
    },
    {
      title:'ไอคอนคลินิก',
      key:'ClinicIcons'
    },
  ]

  const [submittedImage, setSubmittedImage] = useState('');
  const [SelectIndex, setSelectIndex] = useState(null);
  const [SelectionKey, setSelectionKey] = useState('IconsUpload');
  const [previewsIcons,setPreviewsIcons] = useState([])

  const beforehandleChange = (e) =>{
    setPreviewsIcons(URL.createObjectURL(e.target.files[0]))
    setSubmittedImage(e);
  }

  const beforehandleSelectChange = async () =>{
    handleChange(submittedImage,true);
  }

  const handleSubmit = async (event) => {
    if (submittedImage) {      
      SelectionKey === 'ClinicIcons' ?  await beforehandleSelectChange() : handleChange(submittedImage)
    }else{

    }
  };

  const handleImageClick = (event,index) => {
    setSelectIndex(index);
    fetch(event.target.src)
      .then((response) => response.blob())
      .then(async (blob) => {
      
      setSubmittedImage(blob); // => Set it to Blob
        // Create an object URL from the Blob
        // const objectUrl = URL.createObjectURL(blob);
        // Use the object URL as the image source
        // setPreviewsIcons(objectUrl)
      })
      .catch((error) => {
          // Handle any errors that occurred during fetching or Blob creation
          console.error('Error:', error);
      });
  };

  const uploadIcons = [
    {
      name: 'Paolo icon-05.png',
      showName:'คลินิกคอ.PNG',
      path: 'assets/images/uploadIcons/Paolo icon-05.png'
    },
    {
      name: 'Paolo icon-06.png',
      path: 'assets/images/uploadIcons/Paolo icon-06.png'
    },
    {
      name: 'Paolo icon-07.png',
      path: 'assets/images/uploadIcons/Paolo icon-07.png'
    },
    {
      name: 'Paolo icon-08.png',
      path: 'assets/images/uploadIcons/Paolo icon-08.png'
    },
    {
      name: 'Paolo icon-09.png',
      path: 'assets/images/uploadIcons/Paolo icon-09.png'
    },
    {
      name: 'Paolo icon-10.png',
      path: 'assets/images/uploadIcons/Paolo icon-10.png'
    },
    {
      name: 'Paolo icon-11.png',
      path: 'assets/images/uploadIcons/Paolo icon-11.png'
    },
    {
      name: 'Paolo icon-12.png',
      path: 'assets/images/uploadIcons/Paolo icon-12.png'
    },
    {
      name: 'Paolo icon-13.png',
      path: 'assets/images/uploadIcons/Paolo icon-13.png'
    },
    {
      name: 'Paolo icon-14.png',
      path: 'assets/images/uploadIcons/Paolo icon-14.png'
    },
    {
      name: 'Paolo icon-15.png',
      path: 'assets/images/uploadIcons/Paolo icon-15.png'
    },
    {
      name: 'Paolo icon-16.png',
      path: 'assets/images/uploadIcons/Paolo icon-16.png'
    },
    {
      name: 'Paolo icon-17.png',
      path: 'assets/images/uploadIcons/Paolo icon-17.png'
    },
    {
      name: 'Paolo icon-18.png',
      path: 'assets/images/uploadIcons/Paolo icon-18.png'
    },
    {
      name: 'Paolo icon-19.png',
      path: 'assets/images/uploadIcons/Paolo icon-19.png'
    },
    {
      name: 'Paolo icon-20.png',
      path: 'assets/images/uploadIcons/Paolo icon-20.png'
    },
    {
      name: 'Paolo icon-21.png',
      path: 'assets/images/uploadIcons/Paolo icon-21.png'
    },
    {
      name: 'Paolo icon-22.png',
      path: 'assets/images/uploadIcons/Paolo icon-22.png'
    },
    {
      name: 'Paolo icon-23.png',
      path: 'assets/images/uploadIcons/Paolo icon-23.png'
    },
    {
      name: 'Paolo icon-24.png',
      path: 'assets/images/uploadIcons/Paolo icon-24.png'
    },
    {
      name: 'Paolo icon-25.png',
      path: 'assets/images/uploadIcons/Paolo icon-25.png'
    },
    {
      name: 'Paolo icon-26.png',
      path: 'assets/images/uploadIcons/Paolo icon-26.png'
    },
    {
      name: 'Paolo icon-27.png',
      path: 'assets/images/uploadIcons/Paolo icon-27.png'
    },
    {
      name: 'Paolo icon-28.png',
      path: 'assets/images/uploadIcons/Paolo icon-28.png'
    },
    {
      name: 'Paolo icon-29.png',
      path: 'assets/images/uploadIcons/Paolo icon-29.png'
    },
    {
      name: 'Paolo icon-30.png',
      path: 'assets/images/uploadIcons/Paolo icon-30.png'
    },
    {
      name: 'Paolo icon-31.png',
      path: 'assets/images/uploadIcons/Paolo icon-31.png'
    },
  ]

  return (
    <BoxIconsStyled {...props}>
      <div className={customClass}>
      <div className='iconsModalSection'>
        <div style={{fontSize:'21px' ,fontWeight:'bold',color:'#404458'}}>เลือกไอคอนคลินิก</div>
        <div onClick={()=> handleModal()}><img src={iconImages['close_x_2.svg']}/> </div>
      </div>
      <div className='bodySection'>
          <div className='optionSection'>
            {
              selectionOption.map((item)=>{
                return(
                    <div key={item.key} onClick={()=>setSelectionKey(item.key)} className={`optionItem ${SelectionKey === item?.key ? 'active':''}`}>
                      {item.title}
                    </div>
                );
              })
            }
          </div>
          {
            SelectionKey === 'IconsUpload' &&
            <div className='uploadContainer'>
              <div className='body_Container'>
                {
                  previewsIcons.length > 0 &&
                  <div className='previewsIconsContainer'>
                    <img width='100px' height='100px' src={previewsIcons}/>
                  </div>
                }
                <label for="file-upload" className="custom-file-uploads">
                  <div className='btn-upload'>
                    <ButtonCustom 
                      theme_yellow
                      label2 = 'อัพโหลดจากคอมพิวเตอร์'
                    />
                  </div>
                </label>
                <input
                hidden
                id="file-upload"
                type="file"
                accept="image/png, image/jpeg , image/bmp, image/jpg"
                onChange={(e)=>{beforehandleChange(e)}}
                onClick={(event) => {
                  event.target.value = null
                }}
                />              
              </div>
          </div> 
          }
          {
            SelectionKey === 'ClinicIcons' &&
              <div className='selectIconsContainer'>
                <div className='iconsDisplay'>
                {
                  
                  uploadIcons.map((item,index)=>(
                    <div className={`transparent_container ${index === SelectIndex  ? 'active':''}`}>
                        <div className="icons">
                          <img src={UploadIconsImages[item.name]}/>
                        </div>
                        <label className="icons_name">
                          <input
                            hidden
                            onClick={(e)=>handleImageClick(e,index)}
                            key='e' 
                            type="image"  
                            alt="Submit" 
                            src={UploadIconsImages[item?.name]}
                          />
                            {/* <div className="icons_text">{item?.name}</div>  // disable Clinic Icons Name */} 
                        </label>
                    </div>
                  ))
                }
                </div>
              </div>
          }
          <div className='uploadSelectIconsContainer'>
            <div className='uploadButtonSelect' onClick={handleSubmit}>
                {
                  submittedImage ? 
                  <ButtonCustom
                  theme_blue_8
                    label2='ยืนยัน'
                  />
                  :
                  <ButtonCustom
                    theme_gray_2
                    label2='ยืนยัน'
                  />
                }
            </div>
          </div>
      </div>
    </div>
    </BoxIconsStyled>
  );
};
