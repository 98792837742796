import styled from "styled-components"
 
export const ClinicListsStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    .header_section_content{
        display:flex;
        justify-content: space-between;
        width: 100%;
        .header_title{
            color: ${({ theme }) => theme.COLORS.BLUE_17};
            font-size:${({ theme }) => theme.FONTS.SIZE_21px};
            font-weight:600;
        }
        .btn_container{
            display: flex;
            /* background-color: red; */
            .btn_edit{
                padding-right: 10px ;
            }
            .btn_create{
                padding-left: 10px;
            }
        }
    }
/*===============================================
Theme 
===============================================*/

`