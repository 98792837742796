import React from 'react'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment'
import { HistoryContainerStyled } from './styled'
import {
  TableToggleCustom,
  // TableCustom,
  InputDateRangePicker,
  Typography,
  // Button,
  InputSelect,
  ButtonCustom,
  Middleline
} from 'components'
import { userService } from 'apiService'
import theme from 'assets/theme.json'
import { setReduxUserAuth, setReduxInstitutions, setReduxClinics, setReduxInstitutionActive } from 'store/actions'
import { connect } from 'react-redux'
import { iconImages, } from 'assets'
import ReactLoading from 'react-loading';
import { MessageLog, VideoLog } from 'widgets'
import { getMedicineStatus } from 'assets/helpers/functions/getMedicineStatus'
import { toast, ToastContainer } from 'react-toastify'

class HistoryContainer extends React.Component {
  state = {
    institutions: '',
    renderPage: true,
    loading: false,
    loadingClinic: false,
    date: ' ',
    dateSelected: moment().format('YYYY-MM-DD'),
    begin_date: new Date().getTime() - 10 * 24 * 60 * 60 * 1000,
    end_date: new Date(),
    isOpen: false,
    detailOpen: false,
    detailID: null,
    institution_id: '',
    no_clinic: false,
    chat_room_id: null,
    chat_internal_id: null,
    video_room_id: null,
    video_id: null,
    videos_date: '',
  }

  componentDidMount = async () => {
    const setValues = (id) => {
      this.setState({ institution_id: id })
      this.beforeToFetch(id)
    }
    const { role } = this.props.reduxAuth
    const { reduxInstitutionActive, reduxInstitutions } = this.props;
    this.setState({ loadingClinic: true })
    const institutions = role === 'system_admin'
      ? reduxInstitutions ? reduxInstitutions : await this.fetchAccounts()
      : reduxInstitutionActive && [{ id: reduxInstitutionActive }]
    if (institutions) {
      let id = reduxInstitutionActive ? reduxInstitutionActive : institutions[0]?.id
      setValues(id)
    } else {
      this.setState({ loadingClinic: false })

    }
  }

  componentWillUnmount = () => {
    const { setReduxClinics } = this.props
    setReduxClinics(false)
  }

  beforeToFetch = async (institution_id) => {
    const { setReduxInstitutionActive } = this.props;
    setReduxInstitutionActive(institution_id)
    const { begin_date, end_date } = this.state
    const clinics = await this.fetchClinics(institution_id)
    clinics && this.fetchData(clinics[0]?.id, begin_date, end_date)
  }

  fetchClinics = async (institution_id) => {
    this.setState({ loadingClinic: true })
    const { setReduxClinics, reduxClinics } = this.props;
    let res = await userService.GET_CLINICS_BY_ACCOUNT(institution_id)
    if (res && res.success) {
      if (res.data?.length > 0) {
        setReduxClinics(this.setNewClinics(res.data))
        this.setState({ loadingClinic: false, no_clinic: false })
        return res.data
      } else {
        this.setState({ no_clinic: true, loadingClinic: false })
      }
    } else {
      this.setState({ loadingClinic: false })
    }
  }
  fetchAccounts = async () => {
    const { setReduxInstitutions } = this.props;
    let res = await userService.GET_ACCOUNTS()
    if (res && res.success) {
      this.setState({ institutions: this.setNewtInstitutions(res.data) })
      setReduxInstitutions(this.setNewtInstitutions(res.data))
      return res.data
    } else {
    }
  }
  setNewClinics = (data) => {
    return data && data.map(item => {
      return {
        ...item,
        name: `${item.name} (${item.description})`
      }
    })
  }
  setNewtInstitutions = (data) => {
    return data && data.map(item => {
      return {
        ...item,
        name: `${item.name}`,
        created_at: item.created_at ? moment(item.created_at).format('DD MMM YYYY') : '-'
      }
    })
  }

  fetchData = async (clinic_id, begin_date, end_date) => {
    this.setState({ loading: true, })
    const params = {
      begin_date: moment(begin_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
    }

    let res = await userService.GET_VISIT_LOGS(clinic_id, params)

    if (res && res.success) {
      let setData = []
      res.data.map((e, i) =>
        setData.push({
          ...e,
          visit_chat: { visit_id: e.id, messages_count: e?.messages_count },
          time_start: e.patient_session.firstseen_at
            ? moment.utc(e.patient_session.firstseen_at).tz('Asia/Bangkok').format('HH:mm')
            : '-',
          time_end: e.patient_session.lastseen_at
            ? moment.utc(e.patient_session.lastseen_at).tz('Asia/Bangkok').format('HH:mm')
            : '-',
          // time_use: this.diffMinutes(moment.utc(e.patient_session.firstseen_at),
          //   moment.utc(e.patient_session.lastseen_at))
          time_use: this.diffMinutes(
            moment.utc(e.created_at),
            moment.utc(e.laststate_at),
          ),
        }),
      )
      this.setState({
        // dataOriginal: [...res.data],
        dataOriginal: [...setData],
        dataGroup: this.groupDate(setData),
        loading: false
      })
    } else {
      this.setState({
        loading: false,
        dataGroup: []
      })
    }
  }
  diffMinutes = (dt2, dt1) => {
    const setTime = (time) => {
      let condition = time && time.toString().length === 1
      return condition ? `0${time}` : time
    }
    let delta = Math.abs(dt2 - dt1) / 1000;
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    var seconds = Math.floor(delta % 60);
    let time = (hours !== 0 ? setTime(hours) + ':' : '') + (minutes !== 0 ? setTime(minutes) + ':' : (hours !== 0 ? '00:' : '')) + (seconds !== 0 ? setTime(seconds) : (minutes !== 0 || hours !== 0 ? '00' : ''))
    let unit = hours !== 0 ? 'ชั่วโมง' : (minutes !== 0 ? 'นาที' : (seconds !== 0 ? 'วินาที' : '0 วินาที'))
    return time + " " + unit
  }

  groupDate = (e) => {
    const temp =
      e &&
      e.reduce((result, data, i) => {
        if (i === 0) {
          result.push({
            date: moment(data.created_at)
              .add(543, 'years')
              .format('DD MMMM YYYY'),
            dataDetail: [data],
          })
        } else {
          let findIndex = result.findIndex(
            (f) =>
              f.date ===
              moment(data.created_at).add(543, 'years').format('DD MMMM YYYY'),
          )
          if (findIndex === -1) {
            result.push({
              date: moment(data.created_at)
                .add(543, 'years')
                .format('DD MMMM YYYY'),
              dataDetail: [data],
            })
          } else {
            result[findIndex].dataDetail.push(data)
          }
        }
        return result
      }, [])
    return temp?.reverse()
  }

  // DATE SEARCH PROCESS
  // ========================================
  // ========================================
  handleChangeDate = async (dates) => {
    // const {reduxForm} = this.props;
    const [begin_date, end_date] = dates
    if (begin_date && end_date) {
      // reduxForm(change('HistoryContainer', 'begin_date', begin_date))
      // reduxForm(change('HistoryContainer', 'end_date', end_date))
      this.setState({
        begin_date: begin_date,
        end_date: end_date,
        isOpen: false,
      })
      // this.fetchData(begin_date, end_date)
    }
    // let date_calendar = moment(e).format("YYYY-MM-DD");
    // this.setState({
    //   dateSelected: date_calendar,
    // });
  }
  handdleChangeDate = async (e) => {
    let date_calendar = moment(e).format('YYYY-MM-DD')
    this.setState({
      dateSelected: date_calendar,
    })
  }
  handleResetSearchDate = () => {
    const { dataOriginal } = this.state
    this.setState({
      dataGroup: this.groupDate(dataOriginal),
    })
  }

  handleChangeDateTotal = async (e) => { }

  handleClose = () => {
    const { begin_date, end_date } = this.state
    this.setState({ isOpen: false })
    this.props.change('date', [begin_date, end_date])
  }

  renderSelectedDate = () => {
    const { begin_date, end_date } = this.state
    const startDate = begin_date && moment(begin_date)
    const newStartDate = startDate.add(543, 'years').format('DD MMM YYYY')
    const endDate = end_date && moment(end_date)
    const newEndDate = endDate?.add(543, 'years').format('DD MMM YYYY')
    if (new Date(begin_date).getTime() === new Date(end_date).getTime()) {
      return newStartDate
    } else {
      return newStartDate + ' - ' + newEndDate
    }
  }

  renderOptions = () => {
    const { reduxClinics } = this.props;
    return reduxClinics && reduxClinics.map((clinic, i) => {
      return <option key={i} value={clinic.id} >{clinic.name}</option>
    })
  }
  renderInstitutionOptions = () => {
    const { reduxInstitutions, reduxInstitutionActive } = this.props;
    return reduxInstitutions && reduxInstitutions.map((institution, i) => {
      return <option key={i} value={institution.id} selected={reduxInstitutionActive === institution.id} >{institution.name}</option>
    })
  }
  handleChangeInsitution = (e) => {
    const { setReduxClinics } = this.props;
    const id = parseInt(e.target.value)
    setReduxClinics(false)
    this.setState({ dataGroup: '' })
    this.beforeToFetch(id)
  }
  handleSubmit = (e) => {
    const { begin_date, end_date } = this.state;
    const { values } = this.props.reduxForm

    if (e === 'download') {
      this.handleDownload(values.clinic, begin_date, end_date)
    } else {
      this.fetchData(values.clinic, begin_date, end_date)
    }
  }

  handleDownload = async (clinic_id, begin_date, end_date) => {
    this.setState({
      isDownload: true,
    });

    let v_begin_date = moment(begin_date).format('YYYY-MM-DD')
    let v_end_date = moment(end_date).format('YYYY-MM-DD')

    let res = await userService.GET_VISIT_LOGS_BY_CLINIC_ID(
      clinic_id,
      `?begin_date=${v_begin_date}&end_date=${v_end_date}`
    );

    if (res && res.success) {
      toast.success("ดาวน์โหลดสำเร็จ");
      const link = document.createElement("a");
      link.href = res.data.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.setState({
        isDownload: false,
      });
    } else {
      toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
      this.setState({
        isDownload: true,
      });
    }
  };

  handleSelectInternalChat = (e) => {
    if (e) {
      this.setState({ chat_internal_id: e?.visit_id })
    }
  }
  handleSelectVisitChat = (e) => {
    if (e) {
      this.setState({ chat_room_id: e?.id })
    }
  }

  closeModal = () => {
    this.setState({ chat_internal_id: null, chat_room_id: null })
  }
  closeVideo = () => {
    this.setState({ video_room_id: null })
  }

  // handleDetail = (value) => {
  //   if (value) {
  //     this.setState({ detailOpen: !this.state.detailOpen, detailID: value })
  //   }
  // }

  handleSelectVisitVideo = (e) => {
    if (e) {
      this.setState({ video_room_id: e?.id, video_id: e?.videos[0]?.id, videos_date: e?.begin_at })
    }
  }


  render() {
    const {
      dataGroup,
      isOpen,
      video_id,
      videos_date,
      detailID,
      video_room_id,
      loading,
      loadingClinic,
      no_clinic,
      chat_internal_id,
      chat_room_id,
      isDownload,
    } = this.state
    const { reduxPermission } = this.props
    const { role } = this.props.reduxAuth
    return (
      <HistoryContainerStyled>
         <ToastContainer/>
        <div style={{ marginBottom: '2rem' }}></div>
        <MessageLog
          theme_standard
          visit_id={chat_internal_id}
          room_id={chat_room_id}
          closeModal={this.closeModal}
        />
        <VideoLog
          video_room_id={video_room_id}
          video_id={video_id}
          videos_date={videos_date}
          closeVideo={this.closeVideo}
        />
        <div className='header_title' style={{ display: 'flex', justifyContent: "space-between" }}>
          <Typography
            label='บันทึกการสนทนา'
            size={theme.FONTS.SIZE_30px}
            color={theme.COLORS.BLUE_17}
          />
          {role !== 'account_admin' &&
            <InputSelect
              theme_standard_2
              name='institution'
              maxWidth='300px'
              onChange={this.handleChangeInsitution}
            >
              {this.renderInstitutionOptions()}
            </InputSelect>
          }
        </div>
        <div className='title_layout'>
          <div className='group_head'>
          </div>
        </div>
        <div className='date_select_layer'>
          <div className='label_group'>
            {/* <img src={iconImages['calendar.svg']} alt='calendar' /> */}
            <div className='text_layout'>แสดงประวัติการโทร : </div>
          </div>
          {!no_clinic &&
            <div style={{ marginRight: '1rem' }}>
              <Field
                theme_standard_2
                name='clinic'
                component={InputSelect}
                maxWidth='300px'
              // onChange={this.handleChangeDate}
              // handleClose={this.handleClose}
              >
                {this.renderOptions()}
              </Field>
            </div>
          }
          <div
            className='input_date'
            onClick={() => this.setState({ isOpen: true })}>
            <div> {this.renderSelectedDate()}</div>
            <img src={iconImages['dropdown_down.svg']} alt='icon' />
          </div>
          <div style={{ marginLeft: '1rem', width: '6rem' }}>
            {(!loadingClinic && !no_clinic) &&
              <ButtonCustom
                theme_yellow
                label='แสดง'
                onClick={this.handleSubmit}
              />
            }
          </div>
          <div style={{ marginLeft: '1rem', width: '6rem' }}>
            {(!loadingClinic && !no_clinic) &&
              <ButtonCustom
                theme_yellow
                loading={isDownload}
                label='Download'
                onClick={() => this.handleSubmit('download')}
              />
            }
          </div>
          {isOpen && (
            <div className='backdrop'>
              <div
                className='backdrop_outer'
                onClick={() => this.handleClose()}></div>
              <div className='backdrop_inner'>
                <Field
                  theme_standard
                  name='date'
                  component={InputDateRangePicker}
                  onChange={this.handleChangeDate}
                // handleClose={this.handleClose}
                />
              </div>
            </div>
          )}
        </div>
        {
          loading
            ? <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '10rem' }}>
              <div >
                <ReactLoading type='bubbles' color={theme.COLORS.BLUE_3} width='2rem' height='2rem' />
              </div>
            </div>
            : dataGroup && dataGroup.length > 0
              ? dataGroup.map((e, i) => (
                <div key={i}>
                  {(this.state.date === ' ' ||
                    this.state.date === 'total' ||
                    this.state.date === e.date) && (
                      <div>
                        <div className='bar_layout'>
                          <Middleline
                            theme_standard
                            title={e.date}
                          />
                        </div>
                        <div className='table_layout'>
                          <TableToggleCustom
                            theme_standard_2
                            useNo
                            columns={columns()}
                            columns2={columns2({ reduxPermission: reduxPermission })}
                            columns3={columns3()}
                            columns4={columns4({
                              handleSelectInternalChat: (e) => this.handleSelectInternalChat(e),
                              handleSelectVisitChat: (e) => this.handleSelectVisitChat(e),
                              handleSelectVisitVideo: (e) => this.handleSelectVisitVideo(e),
                            })}
                            data={e.dataDetail}
                            detailID={detailID}
                          />
                        </div>
                      </div>
                    )}
                </div>
              ))
              : <div style={{ height: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', fontWeight: 300, opacity: 0.5 }}>
                <div>
                  ไม่มีข้อมูล
                </div>
              </div>
        }

      </HistoryContainerStyled >
    )
  }
}

const columns = () => [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: {
      textAlign: 'left',
      width: '9%',
      flexShrink: 0,
      paddingLeft: '1rem'
    },
    style: {
      textAlign: 'left',
      width: '9%',
      flexShrink: 0,
      paddingLeft: '1rem'

    },
    render: ({ origin, value }) => <div className='height'>{value}</div>,
  },
  {
    title: 'ชื่อ-นามสกุล',
    field: 'fname',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div className='height'>{origin.fname + ' ' + origin.lname}</div>
    ),
  },
  {
    title: 'ผู้ดูแล',
    field: 'nurse_provider.name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div className='height'>{value}</div>,
  },
  {
    title: 'ผู้ให้คำปรึกษา',
    field: 'doctor_provider.name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div className='height'>{value}</div>,
  },
  {
    title: 'เวลาเข้า',
    field: 'time_start',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div className='height'>{value + ' น.'}</div>
    ),
  },
  {
    title: 'ระยะเวลาในการให้บริการ',
    field: 'time_use',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div className='height'>{value}</div>
    ),
  },
]

const columns2 = ({ reduxPermission }) => {
  let col = [
    {
      title: 'เบอร์โทรติดต่อ',
      field: 'phone',
      headerStyle: { textAlign: 'left', flexShrink: 0 },
      style: { textAlign: 'left', flexShrink: 0 },
      render: ({ origin, value }) => <div className='height2'>{value}</div>,
    },
    {
      title: 'สถานะยา',
      field: 'drug_status',
      headerStyle: { textAlign: 'left' },
      style: { textAlign: 'left' },
      render: ({ origin, value }) => {
        const medicineStatus = getMedicineStatus(origin)
        return (
          <div className='height3'>
            {medicineStatus ? (
              <div className='medicine-label'>{medicineStatus?.desc}</div>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
  ]
  if (reduxPermission?.features?.can_visit_appointment) {
    col.push({
      title: 'นัดหมายครั้งถัดไป',
      field: 'next_appoint',
      headerStyle: { textAlign: 'left' },
      style: { textAlign: 'left' },
      render: ({ origin, value }) => (
        // <div className='heiheight2ght' style={{ margin: '0px 0px 0px 13%' }}>
        <div className='height2'>{value}</div>
      ),
    })
  } else {
    col.push({
      title: '',
      field: 'video-log',
      headerStyle: { textAlign: 'left' },
      style: { textAlign: 'left' },
      render: () => <div className='height2' />,
    })
  }
  return col
}

const columns3 = () => [
  {
    title: 'คะแนน',
    field: 'feedback',
    headerStyle: { textAlign: 'left', flexShrink: 0 },
    style: { textAlign: 'left', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div className='height2 '>{value ? value.rating : '-'}
      </div>

    ),
  },
  {
    title: 'ข้อเสนอแนะ',
    field: 'feedback',
    headerStyle: { textAlign: 'left', flexShrink: 0 },
    style: { textAlign: 'left', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div className='height2 '>{value ? value.suggestion : '-'}</div>
    ),
  },
  // {
  //   title: '',
  //   field: 'id',
  //   headerStyle: { textAlign: 'left', flexShrink: 0 },
  //   style: { textAlign: 'left', flexShrink: 0 },
  //   render: ({ origin, value, state }) => (
  //     <div className='button'>
  //       <ButtonCustom
  //         theme_blue_4
  //         label={'ดูรายละเอียด'}
  //         onClick={() => handleDetail(value)}
  //       />
  //     </div>
  //   ),
  // },
]

const columns4 = ({ handleSelectInternalChat, handleSelectVisitChat, handleSelectVisitVideo }) => [
  {
    title: '',
    field: 'rooms',
    headerStyle: { textAlign: 'left', flexShrink: 0 },
    style: { textAlign: 'left', flexShrink: 0 },
    render: ({ origin, value, state }) => (
      value?.length > 0 && (
        <div className='message-wrapper'>ข้อความการสนทนาคนไข้{ }
          {value?.map((e, i) => {
            return <div
              className='message'
              onClick={() => handleSelectVisitChat(e)}>
              {e.messages_count > 0 ?
                `${e?.begin_at
                  ? moment.utc(e?.begin_at).utcOffset("+0700").format('DD MMM YYYY; HH:mm')
                  : '-'}
                 (${e.messages_count} ข้อความ)`
                : ''}
            </div>
          })}
        </div>
      )

    ),
  },
  {
    title: '',
    field: 'visit_chat',
    headerStyle: { textAlign: 'left', flexShrink: 0 },
    style: { textAlign: 'left', flexShrink: 0 },
    render: ({ origin, value, state }) => (
      value?.messages_count > 0 && (
        <div className='message-wrapper'>ดูข้อความการสนทนาภายใน { }
          <div onClick={() => handleSelectInternalChat(value)} className='message'>
            <img style={{ marginRight: '0.5rem' }} src={iconImages['search.png']} alt='search' />
            ดูข้อความ
          </div>
        </div>
      )
    ),
  },
  {
    title: '',
    field: 'rooms',
    headerStyle: { textAlign: 'left', flexShrink: 0 },
    style: { textAlign: 'left', flexShrink: 0 },
    render: ({ origin, value, state }) => (
      value?.length > 0 && (
        <div>วิดีโอการโทร
          {value?.map((e, i) => {
            return <div className='message' key={i} onClick={() => handleSelectVisitVideo(e)}>
              {e.videos.length > 0 ? <div> {e.videos[0].file_name}
              </div> : ''}
            </div>
          })}
        </div>
      )
    ),
  },
]




const mapStateToProps = (state) => ({
  reduxAuth: state.auth,
  reduxInstitutions: state.institutions,
  reduxClinics: state.clinics,
  reduxForm: state.form.HistoryContainer,
  reduxPermission: state.permission,
  reduxInstitutionActive: state.institution_active,
})

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxInstitutions: (data) => dispatch(setReduxInstitutions(data)),
  setReduxClinics: (data) => dispatch(setReduxClinics(data)),
  setReduxInstitutionActive: (data) => dispatch(setReduxInstitutionActive(data)),
})

HistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoryContainer)

HistoryContainer = reduxForm({
  form: 'HistoryContainer', // a unique identifier for this form
  enableReinitialize: true,
})(HistoryContainer)

HistoryContainer = connect(
  (state) => ({
    initialValues: {
      clinic: state.clinics ? state.clinics[0]?.id : 'ไม่มีข้อมูล',
      date: [
        // begin_date : new Date().getTime() - (2 * 24 * 60 * 60 * 1000),
        // end_date : new Date()
        moment(new Date().getTime() - 10 * 24 * 60 * 60 * 1000).format(
          'YYYY-MM-DD',
        ),
        moment(new Date()).format('YYYY-MM-DD'),
      ],
    },
  }),
  {},
)(HistoryContainer)

export default HistoryContainer
