import styled from "styled-components"
 
export const UserListsStyled = styled.div`
/*===============================================
 Container 
===============================================*/

    .filterModal{
        background-color: red;
        width: 500px;
        height: 500px;
    }
    .positionBox{
        /* background-color:red; */
        position:relative;
        .boxContener{
            display: flex;
            column-gap:1px;
            .positionTitle{
                
            }
            .positionFilter{
            }
        }
    }
    
    .HeaderUserList{
        display: flex;
        justify-content: space-between;
        .SearchBox{
            display: flex;
            column-gap: 15px;
            .search_bar{
                
            }
        }
        .Header_Title{
            color: ${({ theme }) => theme.COLORS.BLUE_17};
            font-size:${({ theme }) => theme.FONTS.SIZE_22px};
            font-weight:600;
            /* font-family: noto_sans_thai_extra_bold, noto_sans_thai_extra_bold; */

            /* font-family:"noto_sans_thai_medium, noto_sans_regular"; */
        }

    }
    .middleline{
        /* แถวสีฟ้าแก้ ตรงนี้ */
        margin-top: 1rem;
        display: flex;
        justify-content: flex-start;
        height: 38px;
        align-items: center;
        background-color: ${({ theme }) => theme.COLORS.BLUE_17};
        border-radius: 5px;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size:${({ theme }) => theme.FONTS.SIZE_16px};
        .middleline_text{
            display: flex;
            justify-content: center;
            padding-left: 20px;
        }
    }
/*===============================================
Theme 
===============================================*/

`