import React from 'react';
import { connect } from 'react-redux';
import { HomeContainerStyled } from './styled';
import { setReduxUserAuth } from 'store/actions';
import {} from 'components'; 

class HomeContainer extends React.Component {
  componentDidMount = () =>{
  }
  render() {
    return <HomeContainerStyled></HomeContainerStyled>;
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
