import React, { useState } from 'react'
import { InputDateRangePickerStyled } from './styled'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

export const InputDateRangePicker = ({
  theme_standard,
  input,
  handleClose,
}) => {
  const [begin, end] = input.value
  const [startDate, setStartDate] = useState(begin ? moment(begin).toDate() : null)
  const [endDate, setEndDate] = useState(end ? moment(end).toDate() : null)

  const onChange = (dates) => {
    if (
      (!startDate && !endDate) ||
      (startDate && endDate) ||
      new Date(dates).getTime() < new Date(startDate).getTime()
    ) {
      setStartDate(dates)
      setEndDate(null)
      input.onChange([dates, null])
    } else if (startDate) {
      setEndDate(dates)
      input.onChange([startDate, dates])
    }
  }
  return (
    <InputDateRangePickerStyled>
      <DatePicker
        selected={startDate ? startDate : null}
        onSelect={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        disabledKeyboardNavigation
        inline
      />
      {/* <div className='close' onClick={handleClose}>Close</div> */}
    </InputDateRangePickerStyled>
  )
}
