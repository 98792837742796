import React, { useState } from 'react'
import { SidebarStyled } from './styled'
import cx from 'classnames'
import { Emitter, iconImages, ROLES, ROUTE_PATH } from 'assets'
// import { iconImages } from 'assets';
import { NavLink, useHistory } from 'react-router-dom'
// import { userService } from 'apiService';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { userService } from 'apiService'
import { ImgUpload } from 'components'
// import {
//   setReduxUserAuth,
//   clearStore
// } from 'store/actions';
// import { Tooltip } from 'components';

export const Sidebar = (props) => {
  const { theme_standard, isActive } = props
  const customClass = cx({
    theme_standard: theme_standard,
  })
  const { name, username, profile_picture_url, role } = useSelector(
    (state) => state.auth,
  )
  const institution_detail = useSelector((state) => state?.institution_detail)
  const institution_active = useSelector((state) => state?.institution_active)
  // const account_id = useSelector(state => state.auth.account && state.auth.account.id)
  // const { firstname, lastname } = useSelector(state => state.auth && state.auth)
  const [isOpen, setIsOpen] = useState(false)

  const handelIsOpen = () => {
    isActive({ event: !isOpen })
    setIsOpen(!isOpen)
  }
  // const [pathname, setPathname] = useState('/');
  // const location = useLocation();
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   setPathname(location.pathname);
  // }, [location]);
  // const handleLogout = async () => {
  //   await userService.GET_LOGOUT();
  //   localStorage.removeItem('ontarget-token');
  //   sessionStorage.removeItem('ontarget-token');
  //   dispatch(setReduxUserAuth(false));
  //   dispatch(clearStore(false))
  //   history.push(ROUTE_PATH.LOGIN);
  // }
  // const permission = {
  //   accounting: ['SUMMARY', 'PROFILE'],
  //   nurse: ['ORDER', 'SUMMARY', 'PROFILE'],
  //   sale: ['ORDER', 'SUMMARY', 'PROFILE'],
  //   junior_sale: ['ORDER', 'SUMMARY', 'PROFILE'],
  //   scientist: ['ORDER', 'SUMMARY', 'CALENDAR', 'PROFILE'],
  //   system_admin: ['SETTING', 'PROFILE'],
  //   account_admin: ['SETTING', 'PROFILE'],
  //   management: ['SUMMARY', 'CALENDAR', 'DASHBOARD', 'SETTING', 'PROFILE'],
  //   doctor: ['ORDER', 'SUMMARY', 'PROFILE']
  // }

  // const renderMenu = (role, permission) => {
  //   return permission && permission[role].map((page, i) => {
  //     return (
  //       <NavLink
  //         key={i}
  //         to={role === 'account_admin'
  //           ? (page === 'PROFILE'
  //             ? ROUTE_PATH[page] + (firstname && (firstname.toLowerCase() + '.' + lastname.toLowerCase()))
  //             : ROUTE_PATH.SETTING_INSTITUTION + account_id)
  //           : (page === 'PROFILE'
  //             ? ROUTE_PATH[page] + (firstname && (firstname.toLowerCase() + '.' + lastname.toLowerCase()))
  //             : ROUTE_PATH[page])
  //         }
  //         activeClassName="active">
  //         <div className={`menu_center
  //         ${i === 0 && ' first_menu '}
  //         ${pathname.includes('/' + page.toLowerCase()) && 'active'}
  //         ${page === 'PROFILE' && 'menu_profile'}
  //         `}>
  //           <img src={iconImages[pathname.includes('/' + page.toLowerCase()) ? `${findIconMenu(page)}_active.svg` : `${findIconMenu(page)}_unactive.svg`]} alt='menu' />
  //           <div className="name_layout">
  //             <Tooltip
  //               standard
  //               label={findNameMenu(page)}
  //             />
  //           </div>
  //         </div>
  //       </NavLink>
  //     )
  //   })
  // }
  // const findIconMenu = (page) => {
  //   switch (page) {
  //     case 'ORDER':
  //       return 'menu1'
  //     case 'SUMMARY':
  //       return 'menu2'
  //     case 'CALENDAR':
  //       return 'menu3'
  //     case 'DASHBOARD':
  //       return 'menu_dashboard'
  //     case 'SETTING':
  //       return 'menu_setting'
  //     case 'PROFILE':
  //       return 'menu_profile'
  //     default:
  //   }
  // }
  // const findNameMenu = (page) => {
  //   switch (page) {
  //     case 'ORDER':
  //       return 'Order Management'
  //     case 'SUMMARY':
  //       return 'Summary'
  //     case 'CALENDAR':
  //       return 'Calendar'
  //     case 'DASHBOARD':
  //       return 'Dashboard'
  //     case 'SETTING':
  //       return 'Setting'
  //     case 'PROFILE':
  //       return 'My Profile'
  //     case 'LOGOUT':
  //       return 'Logout'
  //     default:
  //   }
  // }

  const getLogout = () => {
    Emitter.emit('GET_LOGOUT', '')
  }
  const history = useHistory()
  // const authRedux = useSelector(state => state.auth)
  // const dispatch = useDispatch()
  const redirectEditAdmin = () => {
    history.push(`${ROUTE_PATH.SETTINGS + ROUTE_PATH.USER_ADMIN + 'me'}`)
  }

  // useEffect(() => {

  //   if (authRedux) {
  //     fetchProfile({id:authRedux?.id})
  //   }

  // }, [authRedux]);

  // const fetchProfile = async (user) => {
  //   let res = await userService.GET_USER_PROFILE_ADMIN(user.id)
  //   if (res && res.success) {
  //     console.log(res);
  //     // setReduxUserProfile(res.data)
  //     // return res.data
  //   } else {
  //   }
  // }

  const LogoutBtn = ({ color = '#354b62' }) => {
    return (
      <svg
        id='Group_87992'
        data-name='Group 87992'
        xmlns='http://www.w3.org/2000/svg'
        width='15.777'
        height='15.777'
        viewBox='0 0 15.777 15.777'>
        <defs>
          <clipPath id='clip-path'>
            <rect
              id='Rectangle_30271'
              data-name='Rectangle 30271'
              width='15.777'
              height='15.777'
              fill={color}
            />
          </clipPath>
        </defs>
        <g id='Group_87949' data-name='Group 87949' clip-path='url(#clip-path)'>
          <path
            id='Path_106732'
            data-name='Path 106732'
            d='M8.244,13.432v.8a1.681,1.681,0,0,1-.973,1.509,2.094,2.094,0,0,1-1.945,0L1.375,13.693A1.681,1.681,0,0,1,.4,12.185V1.742A1.854,1.854,0,0,1,2.347,0h8.7A1.854,1.854,0,0,1,13,1.742v2.2a.642.642,0,0,1-1.277,0v-2.2a.637.637,0,0,0-.668-.6H3.424l3.847,1.99a1.682,1.682,0,0,1,.973,1.509v7.645h2.807a.637.637,0,0,0,.668-.6V9.76A.642.642,0,0,1,13,9.76v1.93a1.854,1.854,0,0,1-1.945,1.742Zm5.918-6.144-.7.63a.531.531,0,0,0,0,.809.693.693,0,0,0,.9,0l1.793-1.607a.531.531,0,0,0,0-.809L14.361,4.705a.692.692,0,0,0-.9,0,.531.531,0,0,0,0,.809l.7.63H9.45a.608.608,0,0,0-.638.572.608.608,0,0,0,.638.572Z'
            transform='translate(-0.383 -0.094)'
            fill={color}
            fill-rule='evenodd'
          />
        </g>
      </svg>
    )
  }

  return (
    <SidebarStyled {...props}>
      <div className={customClass}>
        <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
          <div className='logo_container'>
            <img src={iconImages['logo_2.svg']} alt='logo' />
            <div className='arrow_menu' onClick={() => handelIsOpen()}>
              <img src={iconImages['arrow-triangle.svg']} alt='arrow' />
            </div>
            <div>{/* here is Img Cilick */}</div>

            {/* <img src={iconImages['logo.svg']} alt="logo" />
          {renderMenu(role, permission)}
          */}
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ paddingLeft: '35px' }} onClick={redirectEditAdmin}>
              <ImgUpload readOnly profile_picture_url={profile_picture_url} />
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ color: '#1D77B9' }}>{username}</div>
              <div style={{ color: '#1D77B9' }}>
                <small>{name}</small>
              </div>
            </div>
          </div>
          <div activeClassName='active' style={{ textDecoration: 'none' }}>
            <div
              className='menu_center first_menu'
              onClick={() => Emitter.emit('REDIRECT_HOME')}>
              <img
                style={{ width: '1.4rem' }}
                src={iconImages['menu_1.svg']}
                alt='menu'
              />
              <div className='name_layout'>ระบบหลังบ้าน</div>
            </div>
          </div>
          <NavLink to={ROUTE_PATH.DASHBOARD} activeClassName='active'>
            <div className='menu_center  '>
              <img src={iconImages['dashboard.svg']} alt='menu' />
              <div className='name_layout'>Dashboard</div>
            </div>
          </NavLink>
          <NavLink to={ROUTE_PATH.HISTORY} activeClassName='active'>
            <div className='menu_center  '>
              <img
                style={{ width: '1.7rem' }}
                src={iconImages['history.svg']}
                alt='menu'
              />
              <div className='name_layout'>บันทึกการโทร</div>
            </div>
          </NavLink>
          {institution_active &&
            institution_detail?.can_patient_satisfaction_survey && (
              <NavLink to={ROUTE_PATH.ASSESSMENT} activeClassName='active'>
                <div className='menu_center  '>
                  <img
                    style={{ width: '1.7rem' }}
                    src={iconImages['gears.svg']}
                    alt='menu'
                  />
                  <div className='name_layout'>แบบประเมิน</div>
                </div>
              </NavLink>
            )}
          <div className='menu_bottom' onClick={() => getLogout()}>
            {/* <img style={{color:'#354b62'}} src={iconImages['logout.svg']} alt='menu' /> */}
            <div className='name_layout'>
              <LogoutBtn />
              <div>ออกจากระบบ</div>
            </div>
          </div>
        </div>
        {isOpen && (
          // <div className="block" onClick={() => handelIsOpen()}></div>
          <div className='block'>
            <div
              className={`arrow_menu ${isOpen ? 'active' : ''}`}
              onClick={() => handelIsOpen()}>
              <img src={iconImages['arrow-triangle.svg']} alt='arrow' />
            </div>
          </div>
        )}
        {/* {role === 'accounting' &&
          <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
            <div >ONTARGET</div>
            <img src={iconImages['logo.svg']} alt="logo" />
            <NavLink to={ROUTE_PATH.SUMMARY} activeClassName="active">
              <div className="menu_center first_menu">
                <img src={iconImages[pathname.includes('/summary') ? 'menu2_active.svg' : 'menu2_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <div className="menu_bottom" onClick={() => handleLogout()}>
              <img src={iconImages['menu4_logout.svg']} alt='menu' />
            </div>
          </div>
        }
        {role === 'nurse' &&
          <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
            <div >ONTARGET</div>
            <img src={iconImages['logo.svg']} alt="logo" />
            <NavLink to={ROUTE_PATH.ORDER} activeClassName="active">
              <div className="menu_center first_menu ">
                <img src={iconImages[pathname.includes('/order') ? 'menu1_active.svg' : 'menu1_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <NavLink to={ROUTE_PATH.SUMMARY} activeClassName="active">
              <div className="menu_center">
                <img src={iconImages[pathname.includes('/summary') ? 'menu2_active.svg' : 'menu2_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <div className="menu_bottom" onClick={() => handleLogout()}>
              <img src={iconImages['menu4_logout.svg']} alt='menu' />
            </div>
          </div>
        }
        {(role === 'sale') &&
          <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
            <div >ONTARGET</div>
            <img src={iconImages['logo.svg']} alt="logo" />
            <NavLink to={ROUTE_PATH.ORDER} activeClassName="active">
              <div className="menu_center first_menu ">
                <img src={iconImages[pathname.includes('/order') ? 'menu1_active.svg' : 'menu1_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <NavLink to={ROUTE_PATH.SUMMARY} activeClassName="active">
              <div className="menu_center">
                <img src={iconImages[pathname.includes('/summary') ? 'menu2_active.svg' : 'menu2_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <div className="menu_bottom" onClick={() => handleLogout()}>
              <img src={iconImages['menu4_logout.svg']} alt='menu' />
            </div>
          </div>
        }
        {(role === 'scientist') &&
          <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
            <div >ONTARGET</div>
            <img src={iconImages['logo.svg']} alt="logo" />
            <NavLink to={ROUTE_PATH.ORDER} activeClassName="active">
              <div className="menu_center first_menu ">
                <img src={iconImages[pathname.includes('/order') ? 'menu1_active.svg' : 'menu1_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <NavLink to={ROUTE_PATH.SUMMARY} activeClassName="active">
              <div className="menu_center">
                <img src={iconImages[pathname.includes('/summary') ? 'menu2_active.svg' : 'menu2_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <NavLink to={ROUTE_PATH.CALENDAR} activeClassName="active">
              <div className="menu_center">
                <img src={iconImages[pathname.includes('/calendar') ? 'menu3_active.svg' : 'menu3_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <div className="menu_bottom" onClick={() => handleLogout()}>
              <img src={iconImages['menu4_logout.svg']} alt='menu' />
            </div>
          </div>
        }
        {(role === 'system_admin') &&
          <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
            <div >ONTARGET</div>
            <img src={iconImages['logo.svg']} alt="logo" />
            <NavLink to={ROUTE_PATH.SETTING} activeClassName="active">
              <div className="menu_center first_menu">
                <img src={iconImages[pathname.includes('/setting') ? 'menu_setting_active.svg' : 'menu_setting_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <div className="menu_bottom" onClick={() => handleLogout()}>
              <img src={iconImages['menu4_logout.svg']} alt='menu' />
            </div>
          </div>
        }
        {(role === 'account_admin') &&
          <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
            <div >ONTARGET</div>
            <img src={iconImages['logo.svg']} alt="logo" />
            <NavLink to={ROUTE_PATH.SETTING_INSTITUTION + account_id} activeClassName="active">
              <div className="menu_center first_menu">
                <img src={iconImages[pathname.includes('/setting') ? 'menu_setting_active.svg' : 'menu_setting_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <div className="menu_bottom" onClick={() => handleLogout()}>
              <img src={iconImages['menu4_logout.svg']} alt='menu' />
            </div>
          </div>
        }
        {(role === 'management') &&
          <div className={isOpen ? 'sidenav_modal' : 'sidenav'}>
            <div >ONTARGET</div>
            <img src={iconImages['logo.svg']} alt="logo" />
            <NavLink to={ROUTE_PATH.SUMMARY} activeClassName="active">
              <div className="menu_center first_menu">
                <img src={iconImages[pathname.includes('/summary') ? 'menu2_active.svg' : 'menu2_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <NavLink to={ROUTE_PATH.CALENDAR} activeClassName="active">
              <div className="menu_center">
                <img src={iconImages[pathname.includes('/calendar') ? 'menu3_active.svg' : 'menu3_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <NavLink to={ROUTE_PATH.DASHBOARD} activeClassName="active">
              <div className="menu_center">
                <img src={iconImages[pathname.includes('/dashboard') ? 'menu_dashboard_active.svg' : 'menu_dashboard_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <NavLink to={ROUTE_PATH.SETTING} activeClassName="active">
              <div className="menu_center">
                <img src={iconImages[pathname.includes('/setting') ? 'menu_setting_active.svg' : 'menu_setting_unactive.svg']} alt='menu' />
              </div>
            </NavLink>
            <div className="menu_bottom" onClick={() => handleLogout()}>
              <img src={iconImages['menu4_logout.svg']} alt='menu' />
            </div>
          </div>
        } */}
      </div>
    </SidebarStyled>
  )
}
