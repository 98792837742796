
import Skeleton from 'react-loading-skeleton';

export const calculateSkeleton = (fields, height) => {
  const keys = fields && fields.map((field) => {
    return field.field
  })
  const setWidth = (index, key) => {
    let fluid_skeleton = fields[0].fluid_skeleton;
    let number = Math.floor(Math.random() * 80) + 20
    return fluid_skeleton ? '100%' : number.toString() + '%'
  }
  const myObj = (index) => {
    return keys.reduce((a, key) => {
      return key !== 'index' && Object.assign(a, { [key]: <Skeleton height={height} width={setWidth(index, key)} />, loading: true })
    }, {});
  }
  return [1, 2, 3].map((field, index) => {
    return { ...myObj(index) }
  })
}