import React from 'react';
import { connect } from 'react-redux';
import { LoginContainerStyled } from './styled';
import { setReduxUserAuth } from 'store/actions';
import { Typography } from 'components';
import { LoginForm } from 'forms';
import theme from 'assets/theme.json'
import { userService } from 'apiService';
import { Emitter } from 'assets';

class LoginContainer extends React.Component {
  state = {
    error_msg: '',
    loading: false
  }
  handleSubmit = async () => {
    this.setState({loading : true})
    const { values } = this.props.reduxForm
    let res = await userService.POST_LOGIN(values)
    if (res && res.success) {
      localStorage.setItem('itele_admin_protal_token', res.token)
      sessionStorage.setItem('itele_admin_protal_token', res.token)
      this.fetchProfile()
    } else {
      this.setState({ error_msg: 'ไม่พบข้อมูลในระบบ', loading: false })
    }
  }
  fetchProfile = async () => {
    const { setReduxUserAuth } = this.props;
    let res = await userService.GET_PROFILE()
    if (res && res.success) {
      setReduxUserAuth(res.data)
      this.setState({ error_msg: '', loading: false })
      Emitter.emit('REDIRECT_HOME')
    } else {
      this.setState({ error_msg: 'ไม่พบข้อมูลในระบบ', loading: false })
    }
  }
  render() {
    const { error_msg , loading } = this.state
    return <LoginContainerStyled>
      <div className='wrapper'>
        <div className='contianer_login'>
          <div className='left'>
            <div className='left_title'>
              <Typography
                label='iTele Virtual Hospital Platform'
                size='37px'
                color={theme.COLORS.GREEN_2}
              />
            </div>
            <div className='left_desc1'>
              <div>Consult with Doctor Anywhere Anytime</div>
            </div>
            <div className='left_desc2'>
              <div>iTele is a platform for creating channels designed to be used with a variety of hospital systems. Providing remote medical service through the internet network in the form of audio and video</div>
            </div>
          </div>
          <div className='right'>
            <LoginForm onSubmit={this.handleSubmit} error_msg={error_msg} loading={loading} />
          </div>
        </div>
      </div>
    </LoginContainerStyled>;
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.LoginForm
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
