import React from 'react';
import { AaFormStyled } from './styled';
import {  reduxForm } from 'redux-form';
// import { iconImages, VALIDATE } from 'assets';
import {} from 'components';

class AaForm extends React.Component {
  state = {};

  render() {
    return <AaFormStyled></AaFormStyled>;
  }
}

export default reduxForm({
  form: 'AaForm', // a unique identifier for this form
  enableReinitialize: true,
})(AaForm);
