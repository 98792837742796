import styled from "styled-components"
 
export const RecommendDoctorFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    
    .form_layout {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: ${ props => props?.column ? 'column': ''};
        padding-left: ${ props => props?.column ? '': '48px'};
        padding-right: ${ props => props?.column ? '': '13px'};
        .input_row {
            width: ${ props => props?.column ? '100%': '530px'};
        }
        .modal_button{
            margin-top:10px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            width: 100%;
            column-gap:10px;
        }
        .button_wrapper {
            width: 20%;
        }
    }
`