import React, { useState } from 'react'
import cx from 'classnames'
import { CheckPasswordBoxStyled } from './styled'

export const CheckPasswordBox = ({
    CheckIsNumberpassword,
    CheckLenghtpassword,
    CheckUpperPassword,
    }) => {

    const customClass = cx({
    })

    const IconsCheck = (
        {
        color
        }
    )=>{
        // #00A59B
        //#777777
        return(
        
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <g id="Ellipse_7297" data-name="Ellipse 7297" fill={color ? color : '#fff'} stroke={color ? 'none': '#707070'} stroke-width="1">
            <circle cx="8.5" cy="8.5" r="8.5" stroke="none"/>
            <circle cx="8.5" cy="8.5" r="8" fill="none"/>
            </g>
            <g id="Group_94987" data-name="Group 94987" transform="translate(-3664.673 15350.141)">
            <rect id="Rectangle_33296" data-name="Rectangle 33296" width="2" height="4.668" rx="1" transform="translate(3667.929 -15341.198) rotate(-45)" fill={color ? '#fff':'#777'}/>
            <rect id="Rectangle_33297" data-name="Rectangle 33297" width="2" height="10.002" rx="1" transform="translate(3677.002 -15346.385) rotate(45)" fill={color ? '#fff':'#777'}/>
            </g>
        </svg>


        );
    }

    const IconsCrossCheck = ()=>{
        return(
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <circle id="Ellipse_7300" data-name="Ellipse 7300" cx="8.5" cy="8.5" r="8.5" fill="#ea5555"/>
            <g id="Group_94990" data-name="Group 94990" transform="translate(-5899 15902.5)">
            <rect id="Rectangle_33297" data-name="Rectangle 33297" width="2" height="10.002" rx="1" transform="translate(5910.329 -15898.244) rotate(45)" fill="#fff"/>
            <rect id="Rectangle_33307" data-name="Rectangle 33307" width="2.001" height="10.002" rx="1" transform="translate(5911.744 -15891.172) rotate(135)" fill="#fff"/>
            </g>
        </svg>

        );
    }
    
    return (
        <CheckPasswordBoxStyled>
            <div className={customClass}>
                {
                CheckIsNumberpassword === null ? <div className='checkPasswordBox'>
                <IconsCheck/>
                <div className='text' style={{color:'#777777'}}>มีตัวเลข</div>
                </div>
                : CheckIsNumberpassword === true ? <div className='checkPasswordBox'>
                <IconsCheck color='#00A59B' />
                <div className='text' style={{color:'#00A59B'}}>มีตัวเลข</div>
                </div>
                : <div className='checkPasswordBox'>
                <IconsCrossCheck/>
                <div className='text' style={{color:'#EA5555'}} >มีตัวเลข</div>
                </div>
                }
                {
                CheckLenghtpassword === null ? <div className='checkPasswordBox'>
                <IconsCheck/>
                <div className='text' style={{color:'#777777'}}>ใส่รหัส 8 ตัวอักษร</div>
                </div>
                : CheckLenghtpassword === true ? <div className='checkPasswordBox'>
                <IconsCheck color='#00A59B' />
                <div className='text' style={{color:'#00A59B'}}>ใส่รหัส 8 ตัวอักษร</div>
                </div>
                : <div className='checkPasswordBox'>
                <IconsCrossCheck/>
                <div className='text' style={{color:'#EA5555'}} >ใส่รหัส 8 ตัวอักษร</div>
                </div>
                }
                {
                CheckUpperPassword === null ? <div className='checkPasswordBox'>
                <IconsCheck/>
                <div className='text' style={{color:'#777777'}}>มีตัวพิมใหญ่อย่างน้อย 1 ตัวอักษร</div>
                </div>
                : CheckUpperPassword === true ? <div className='checkPasswordBox'>
                <IconsCheck color='#00A59B' />
                <div className='text' style={{color:'#00A59B'}}>มีตัวพิมใหญ่อย่างน้อย 1 ตัวอักษร</div>
                </div>
                : <div className='checkPasswordBox'>
                <IconsCrossCheck/>
                <div className='text' style={{color:'#EA5555'}} >มีตัวพิมใหญ่อย่างน้อย 1 ตัวอักษร</div>
                </div>
                }
            </div>
        </CheckPasswordBoxStyled>
    )
}
