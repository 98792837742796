import styled from 'styled-components';

export const PaginationStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
display : flex;
/* justify-content : center; */
justify-content : end;

margin-top : 1rem;
  .pagination {
    display: flex;
    vertical-align: middle;
    justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
  }

  .pagination div {
    width: 1.8rem;
    height: 1.8rem;
    margin-left : 1rem;
    margin-right : 1rem;
    line-height: 1.8rem;
    border-radius: 50%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
    color: ${({ theme }) => theme.COLORS.GRAY_9};
    text-align: center;
    margin: 0.2rem;
    cursor: pointer;
  }

  .pagination div.active {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    background: ${({ theme }) => theme.COLORS.YELLOW_1};
    border: 2px solid ${({ theme }) => theme.COLORS.YELLOW_1};
  }
  .pagination div:not(.active) {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
  }

  .pagination div:hover:not(.active) {
    background-color: #ddd;
    transform : translateY(1px);
    /* border-radius: 50%; */
  }

  .pagination_warp{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }
`;
