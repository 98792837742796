import styled from "styled-components"

export const ClinicProfileFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    width : 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};

    .button_container2{
        margin-left : auto;
        margin-right : 0.1rem;
        margin-bottom : 1rem;
        padding-top : 1rem;
        width : 10rem;
    }
    .form_container{
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        /* padding: 20px; */
        .form_content{
            width: 100%;
            .header_title{
                border-bottom: 0.5px solid #DBDBDB;
                padding: 10px 30px;
                .title{
                    font-size: 21px;
                    font-weight: 800;
                }
                .sub_title{
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .body_container{
                padding: 10px 30px;
                .body_call_data{
                    display: flex;
                    width: 100%;
                    column-gap:10px
                }
                .body_form_container{
                    margin-top:10px;
                    display: grid;
                    grid-column-gap: 10px;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }
            }
        }
    }
    .field{
       width : 100%;
       margin-top:0.5rem;
    }
    .field2{
       width : 95%;
       margin-top:0.5rem;
    }
    .field_button{

    }
    .label{
        font-weight : 500;
    }
    .button_container{
        margin-left : auto;
        margin-right : 0.1rem;
        margin-bottom : 1rem;
        padding-top : 1rem;
        width : 10rem;
    }
    .error{
        color: ${({ theme }) => theme.COLORS.RED_1};
        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        text-align:left;
        //margin-left:1rem;
        margin-top:0.3rem;
    }
    .error2{
        margin : 1rem 0 1.5rem 0;
        color: ${({ theme }) => theme.COLORS.RED_1};
        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        text-align:left;
        //margin-left:1rem;
    }
    .row_container{
        margin-bottom:1.4rem;
        display : flex;
        width : 100%;
        &:first-child{
            margin-top : 1rem;
        }
        .col_container{
            width : 50%;
            &:nth-child(odd) {
                margin-right : 1rem;
            }
            &:nth-child(even) {
                margin-left : 1rem;
            }
        }
        .col_container_2{
            width : 50%;
            &:nth-child(odd) {
                margin-right : 0.25rem;
            }
            &:nth-child(even) {
                margin-left : 0.25rem;
            }
        }
        .col_container_9{
            width : 96%;
            margin-right : 1rem;
        }
        .col_container_1{
        }
        .fluid_col_container{
            width : 100%;
        }
    }
    .checkbox{
        margin-bottom: 1rem;
    }
   
`