

export const getPathnameID = (id) => {
  const has_realm = id.indexOf('-')
  const result_id = has_realm === -1
    ? parseInt(id)
    : parseInt(id.substring(0, has_realm))
  const institution = {
    id: result_id,
    has_realm: has_realm !== -1 ? true : false,
    is_creating: isNaN(result_id)
  }
  return institution
}