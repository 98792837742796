import React from 'react';
import { BoxConfirmStyled } from './styled';
import cx from 'classnames';
import { BoxCustom } from 'components';
import { Typography } from 'components/Typography';
import theme from 'assets/theme.json'
import { ButtonCustom } from 'components/ButtonCustom';

export const BoxConfirm = (props) => {
  const { theme_standard, theme_standard_2, theme_success, theme_error,theme_confirm,title, desc, confirm, cancel, loading,onConfirm, onCancel } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_success: theme_success,
    theme_confirm: theme_confirm,
    theme_error : theme_error
  });
  return (
    <BoxConfirmStyled {...props}>
      <div className={customClass}>
        <BoxCustom
          theme_standard_box_custom_yellow
          child={
            <div className='box'>
              {title &&
                <div className='title'>
                  <Typography
                    label={title}
                    size={theme.FONTS.SIZE_21px}
                    color={theme.COLORS.BLUE_17}
                  />
                </div>
              }
              <div className='desc'>
                {desc}
              </div>
              <div className='button_layout'>
                {onConfirm &&
                  <div className='div1'>
                    <ButtonCustom
                      theme_blue_9
                      loading={loading}
                      label={confirm ? confirm : 'ยืนยัน'}
                      onClick={onConfirm}
                    />
                  </div>
                }
                {onCancel &&
                  <div className='div2'>
                    <ButtonCustom
                      theme_gray_2
                      label={cancel ? cancel : 'ยกเลิก'}
                      onClick={onCancel}
                    />
                  </div>
                }
              </div>
            </div>
          }
        />
      </div>
    </BoxConfirmStyled>
  );
};
