import styled from "styled-components";

export const MiddlelineStyle = styled.div`

    .theme_standard{
        /* แถวสีฟ้าแก้ ตรงนี้ */
        margin-top: 1rem;
        display: flex;
        height: 38px;
        background-color: ${({ theme }) => theme.COLORS.BLUE_17};
        border-radius: 5px;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size:${({ theme }) => theme.FONTS.SIZE_16px};

    }
    .middleline_text{
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 20px;
    }

`