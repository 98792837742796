import apiService from '../apiService'
// import { parse, queryString } from 'query-string'

const apiPath = '/admin-api'
const apiPath2 = '/patient-api'

export const userService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params)
  },
  POST_LOGIN_WITHTOKEN: () => {
    return apiService.post(`${apiPath}/loginWithToken`)
  },
  POST_LOGIN_WITHTOKEN_BY_LOCALSTORGAE: () => {
    return apiService.post_by_localstorge(`${apiPath}/loginWithToken`)
  },
  GET_LOGOUT: () => {
    return apiService.get(`${apiPath}/logout`)
  },
  GET_PROFILE: () => {
    return apiService.get(`${apiPath}/users/me`)
  },
  PATCH_PROFILE: (params) => {
    return apiService.patch(`${apiPath}/users/me`, params)
  },
  GET_ACCOUNTS: (query, per_page, offset, sort_by, order_by) => {
    return apiService.get(
      `${apiPath}/accounts` +
      `${query ? '?' : ''}` +
      `${per_page ? `&per_page=${per_page}` : ''}` +
      `${offset ? `&offset=${offset}` : ''}` +
      `${sort_by ? `&sort_by=${sort_by}` : ''}` +
      `${order_by ? `&order_by=${order_by}` : ''}`,
    )
  },
  GET_ACCOUNT_BY_ID: (account_id) => {
    return apiService.get(`${apiPath}/accounts/${account_id}`)
  },
  GET_ACCOUNT_BY_ID_SETTING: (account_id, setting_type) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/settings/${setting_type}`,
    )
  },

  GET_DOCTOR_LIST_BY_ACCOUNT_ID: (account_id, doctor_code, include_code) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/doctors?code=${doctor_code}` +
      `${include_code ? `&include_code=${include_code}` : ''}` +
      `&has_user=0&per_page=1`,
    )
  },
  GET_DOCTOR_BY_ACCOUNT_ID: (account_id, doctor_id) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/doctors/${doctor_id}`,
    )
  },
  GET_ALL_USERS: (per_page, offset, order_by, sort_by, keyword, roles) => {
    return apiService.get(`${apiPath}/users?per_page=${per_page}&offset=${offset}
        ${order_by ? '&order_by=' + order_by : ''}
        ${sort_by ? '&sort_by=' + sort_by : ''}
        ${keyword ? '&keyword=' + keyword : ''}
        ${roles ? `&roles=${roles}` : ''}
        `)
  },
  GET_USER_PROFILE_ADMIN: (user_id) => {
    return apiService.get(`${apiPath}/users/${user_id}`)
  },
  GET_USERS: (account_id, is_query, per_page, offset) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/users` +
      `${is_query ? '?' : ''}` +
      `${per_page ? `&per_page=${per_page}` : ''}` +
      `${offset ? `&offset=${offset}` : ''}`,
    )
  },
  GET_USERS_PAGINATION: (account_id, per_page, offset, keyword, roles) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/users?` +
      `${keyword ? `&keyword=${keyword}` : ''}` +
      `${roles ? `&roles=${roles}` : ''}` +
      `${per_page ? `&per_page=${per_page}` : ''}` +
      `${offset ? `&offset=${offset}` : ''}`,
    )
  },
  GET_USER_PROFILE: (account_id, user_id) => {
    return apiService.get(`${apiPath}/accounts/${account_id}/users/${user_id}`)
  },
  GET_CLINICS_BY_ACCOUNT: (account_id) => {
    return apiService.get(`${apiPath}/accounts/${account_id}/clinics`)
  },
  GET_USERS_BY_CLINIC: (account_id) => {
    return apiService.get(`${apiPath}/clinics/${account_id}/users`)
  },
  GET_CLINIC_BY_ID: (clinic_id) => {
    return apiService.get(`${apiPath}/clinics/${clinic_id}`)
  },
  CHECK_CLINIC_CODE: (clinic_code) => {
    return apiService.get(`${apiPath}/clinic_codes/${clinic_code}`)
  },
  POST_CLINIC_BY_ACCOUNT: (account_id, params) => {
    return apiService.post(`${apiPath}/accounts/${account_id}/clinics`, params)
  },
  PATCH_CLINIC_BY_ACCOUNT: (clinic_id, params) => {
    return apiService.patch(`${apiPath}/clinics/${clinic_id}`, params)
  },
  CHECK_USERNAME: (account_id, username) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/usernames/${username}`,
    )
  },
  POST_USER_BY_ACCOUNT: (account_id, params) => {
    return apiService.post(`${apiPath}/accounts/${account_id}/users`, params)
  },
  PATCH_USER_BY_ACCOUNT: (account_id, user_id, params) => {
    return apiService.patch(
      `${apiPath}/accounts/${account_id}/users/${user_id}`,
      params,
    )
  },
  PATCH_USER_BY_ID: (user_id, params) => {
    return apiService.patch(`${apiPath}/users/${user_id}`, params)
  },
  ADD_USER_TO_CLINIC: (clinic_id, user_id) => {
    return apiService.put(`${apiPath}/clinics/${clinic_id}/users/${user_id}`)
  },
  REMOVE_USER_FORM_CLINIC: (clinic_id, user_id) => {
    return apiService.delete(`${apiPath}/clinics/${clinic_id}/users/${user_id}`)
  },
  GET_VISIT_LOGS: (clinic_id, params) => {
    return apiService.get(
      `${apiPath}/clinics/${clinic_id}/visit_logs?begin_date=${params.begin_date}&end_date=${params.end_date}`,
    )
  },
  GET_VISIT_LOGS_BY_CLINIC_ID: (clinic_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/clinics/${clinic_id}/visit_logs.xlsx${stringQuery ? stringQuery : ""
      }`
    );
  },

  GET_RECOMMENDATION_BY_CLINIC_ID: (clinic_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/clinics/${clinic_id}/recommendations${stringQuery ? stringQuery : ''
      }`,
    )
  },
  POST_RECOMMENDATION_BY_CLINIC_ID: (clinic_id, params) => {
    return apiService.post(
      `${apiPath}/clinics/${clinic_id}/recommendations`,
      params,
    )
  },
  REMOVE_RECOMMENDATION_BY_CLINIC_ID: (clinic_id, id) => {
    return apiService.delete(
      `${apiPath}/clinics/${clinic_id}/recommendations/${id}`,
    )
  },

  //// DASHBOARD /////
  GET_DASHBOARD_HIGHLIGHT: (account_id) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/statistics/highlight`,
    )
  },
  GET_DASHBOARD_ACTIVE: (account_id) => {
    return apiService.get(`${apiPath}/accounts/${account_id}/statistics/active`)
  },
  GET_DASHBOARD_DAILY_USER_SESSION: (account_id, begin_date, end_date) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/statistics/daily/session?begin_date=${begin_date}&end_date=${end_date}`,
    )
  },
  GET_DASHBOARD_HOUR_USER_SESSION: (account_id, begin_date, end_date) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/statistics/hourly/session?begin_date=${begin_date}&end_date=${end_date}`,
    )
  },
  GET_DASHBOARD_DAILY_VISIT: (account_id, begin_date, end_date) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/statistics/daily/visit?begin_date=${begin_date}&end_date=${end_date}`,
    )
  },
  GET_DASHBOARD_HOUR_VISIT: (account_id, begin_date, end_date) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/statistics/hourly/visit?begin_date=${begin_date}&end_date=${end_date}`,
    )
  },
  GET_DASHBOARD_HEALTH_STATUS: (account_id, period, begin_date, end_date) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/healthchecks/service/status?period=${period}&begin_date=${begin_date}&end_date=${end_date}`,
    )
  },
  GET_DASHBOARD_HEALTH_ERROR: (account_id, per_page, offset) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/healthchecks/service/error?per_page=${per_page}&offset=${offset}`,
    )
  },

  GET_VISIT_CHAT_LOG: (room_id) => {
    return apiService.get(`${apiPath}/visitrooms/${room_id}/messages`)
  },
  GET_INTERNAL_CHAT_LOG: (visit_id) => {
    return apiService.get(`${apiPath}/chatrooms/${visit_id}/messages`)
  },
  GET_VISIT_VIDEO_LOG: (video_room_id, video_id) => {
    return apiService.get(
      `${apiPath}/visitrooms/${video_room_id}/videos/${video_id}`,
    )
  },

  PATCH_PRIVACY_POLICY: (account_id, params) => {
    return apiService.patch(
      `${apiPath}/accounts/${account_id}/settings/theme`,
      params,
    )
  },
  GET_PRIVACY_POLICY_THEME: (account_id) => {
    return apiService.get(`${apiPath}/accounts/${account_id}/settings/theme`)
  },

  GET_APPOINTMENT_SCHEDULE_BY_CLINIC_ID_USER_ID: ({
    clinic_id,
    user_id,
    begin_date,
    end_date,
  }) => {
    return apiService.get(
      `${apiPath2}/clinics/${clinic_id}/doctors/${user_id}/appointment_schedules?` +
      `${begin_date ? `begin_date=${begin_date}` : ''}` +
      `${end_date ? `&end_date=${end_date}` : ''}`,
    )
  },

  GET_SATISFACTION_SURVEYS_LIST_BY_ACCOUNT_ID: (
    account_id,
    per_page,
    offset,
    sort_by,
    order_by,
  ) => {
    return apiService.get(
      `${apiPath}/accounts/${account_id}/satisfaction_surveys?` +
      `${per_page ? `&per_page=${per_page}` : ''}` +
      `${offset ? `&offset=${offset}` : ''}` +
      `${sort_by ? `&sort_by=${sort_by}` : ''}` +
      `${order_by ? `&order_by=${order_by}` : ''}`,
    )
  },
  POST_SATISFACTION_SURVEYS_LIST_BY_ACCOUNT_ID: (account_id, params) => {
    return apiService.post(
      `${apiPath}/accounts/${account_id}/satisfaction_surveys`,
      params,
    )
  },
  PATCH_SATISFACTION_SURVEYS_LIST_BY_ID: (surveys_id, params) => {
    return apiService.patch(
      `${apiPath}/satisfaction_surveys/${surveys_id}`,
      params,
    )
  },
  DELETE_SATISFACTION_SURVEYS_LIST_BY_ID: (surveys_id, params) => {
    return apiService.delete(`${apiPath}/satisfaction_surveys/${surveys_id}`)
  },
}

// GET_DOCTOR_NOTE_BY_VISIT_ID: (id) => {
//   return apiService.get(`${ apiPath } / visits / ${ id } / doctornotes`);
// },
// POST_DOCTOR_NOTE_ADD: (id, params) => {
//   return apiService.post(`${ apiPath } / visits / ${ id } / doctornotes`, params);
// },
// PATCH_DOCTOR_NOTE_DETAIL: (id, note_id, params) => {
//   return apiService.patch(
//     `${ apiPath } / visits / ${ id } / doctornotes / ${ note_id }`,
//     params
//   );
// },
// DELETE_DOCTOR_NOTE_DETAIL: (id, note_id) => {
//   return apiService.delete(`${ apiPath } / visits / ${ id } / doctornotes / ${ note_id }`);
// },
