import styled from "styled-components"

export const BoxCustomStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    .theme_standard_box_custom {
        padding: 1.25rem;
        line-height: 1.25;
        // box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18);
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        /* border: 1px solid #f1f1f1; */
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }
    }
    .theme_standard_box_custom_yellow {
        padding: 1.25rem;
        line-height: 1.25;
        // box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18);
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: 5px solid ${({theme})=>theme.COLORS.YELLOW_2};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }
    }
    .theme_standard_box_custom_2 {
        padding: 1.25rem;
        line-height: 1.25;
        box-shadow: 0 20px 80px  rgba(112,112,112,0.18);
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid #f1f1f1;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }
    }
    .theme_standard_box_custom_notification {
        padding: 0.5rem 0.8rem;
        line-height: 1.25;
        // box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18);
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid #f1f1f1;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }

    }
    .theme_standard_box_custom_message{
        padding: 0;
        line-height: 1.25;
        // box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18);
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        // border: 0.5em solid #f1f1f1;
        border:solid ${({ theme }) => theme.COLORS.GRAY_2};
        border-width: 0.001rem;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }

    }
    .theme_standard_box_custom_message_2{
        padding: 0;
        line-height: 1.25;
        // box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18);
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        // border:1px solid ${({ theme }) => theme.COLORS.GRAY_2};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }

    }
    .theme_standard_box_custom_gray{
        padding: 1.5rem 2rem;
        //box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18);
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid #f1f1f1;
        background: ${({ theme }) => theme.COLORS.GRAY_3};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.6;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                padding-top:0.1rem;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }
    }
    .theme_standard_box_custom_blue{
        padding: 1.25rem;
        line-height: 1.25;
        box-shadow: 5px 5px 10px -4px ${({ theme }) => theme.COLORS.BLUE_13};
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: 2px solid ${({ theme }) => theme.COLORS.BLUE_12};
        background: ${({ theme }) => theme.COLORS.BLUE_14};
        position: relative;
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }
    }
    .theme_standard_box_custom_title{
        padding: 0;
        line-height: 1.25;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #f1f1f1;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        .title{
            font-weight : 600;
            border-bottom : 1px solid #f1f1f1;
            display : flex;
            justify-content : space-between;
            align-items : flex-start;
            .title_wrapper{
                padding : 0.7rem 1.25rem;
            }
        }
        .container_custom{
            padding : 0.25rem;
        }
        .nodata{
            display : flex;
            justify-content : center;
            height : 100%;
            padding : 6.78rem 0;
            opacity : 0.5;
            font-size : 14px;
        }
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }
    }
    .theme_standard_box_custom_3{
        padding: 0.75rem;
        line-height: 1.25;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
        width: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #f1f1f1;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
       
        .loading_container{
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            opacity:0.8;
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            height:100%;
            width: 100%;
            label{
                height:100%;
                justify-content:center;
                display:flex;
                width:100%;
                // margin-left:0.5rem;
                text-align:center;
                .loading_bar{
                    background: ${({ theme }) => theme.COLORS.RED_1};

                }
            }
        }
    }
    @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_MINI}) {
        .theme_standard_box_custom{
             padding-left:1rem;
             padding-right:1rem;
        }
        .theme_standard_box_custom_2{
             padding-left:1rem;
             padding-right:1rem;
        }
        .theme_standard_box_custom_notification{
             padding-left:1rem;
             padding-right:1rem;
        }
        .theme_standard_box_custom_message{
             padding-left:1rem;
             padding-right:1rem;
        }
        .theme_standard_box_custom_message_2{
             padding-left:1rem;
             padding-right:1rem;
        }
        .theme_standard_box_custom_gray{
             padding-left:1.5rem;
             padding-right:1.5rem;
        }
        .theme_standard_box_custom_blue{
                    padding-left:1rem;
             padding-right:1rem;
        }
    }
`