import React from 'react';
import { connect } from 'react-redux';
import { InstitutionProfileContainerStyled } from './styled';
import { setReduxUserAuth } from 'store/actions';
import {
  ButtonCustom,
  // TableCustom, 
  Typography,
  Stepper
} from 'components';
import { ROUTE_PATH } from 'assets';
import theme from 'assets/theme.json'
import { InstitutionProfile, InstitutionSettings } from 'widgets';

class InstitutionProfileContainer extends React.Component {
  state = {
    is_creating: false,
    step: 0
  }
  componentDidMount = () => {
    const { params } = this.props.match
    const { history } = this.props
    const creating = params.institution_id && isNaN(params.institution_id)
    this.setState({
      is_creating: creating
    })
    if (!creating) {
      history.push(ROUTE_PATH.SETTINGS)
    }
  }
  handleStep = (step) => {
    this.setState({ step: step })
  }

  renderStepActive = () => {
    const { step, is_creating } = this.state
    switch (step) {
      case 0:
        return <InstitutionProfile
          is_creating={is_creating}
          handleStep={this.handleStep}
        />
      case 1:
        return <InstitutionSettings
          is_creating={is_creating}
          handleStep={this.handleStep}
        />
      default:
    }
  }

  render() {
    const { step } = this.state;
    return <InstitutionProfileContainerStyled>
      <div className='back'>
        <ButtonCustom
          theme_back
          label="BACK"
          icon='arrow_left.svg'
          onClick={() => this.props.history.push(ROUTE_PATH.SETTINGS)}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: '1rem' }}>
      </div>

      <div className='container_custom'>
        <div style={{ marginBottom: '1rem' }}>
          <Typography label='Create New Institution' weight={500} color={theme.COLORS.BLUE_3} size={theme.FONTS.SIZE_30px} />
        </div>
        <div style={{ padding: '1rem 0 1rem 0' }}>
          <Stepper
            theme_standard
            steps={[{ label: 'Informations' }, { label: 'Features' }]}
            activeStep={step}
          />
        </div>
        {this.renderStepActive()}
      </div>

    </InstitutionProfileContainerStyled>;
  }
}

const styleConfig = {
  activeColor: 'yellow'
}
const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionProfileContainer);
