import { iconImages } from 'assets';
import React from 'react';
import { PaginationStyled } from './styled';

export const Pagination = (props) => {
  const { page, onPagination , per_page , title ,arrows, label} = props

  const beforeOnpagination = () => {
    const current_page = page.current_page;
    if (current_page >= page.last_page) {
      return;
    }else{
      onPagination(per_page,(page.per_page * ((current_page + 1) - 1)),current_page+1)
    }
  }

  const renderPagination = () => {
    const paginations = []
    for (let i = 1; i <= page.last_page; i++) {
      paginations.push(i);
    }
    return paginations.map((i, index) => {
      return <div
        key={index}
        className={page.current_page === i ? "active" : ''}
        onClick={() => {
          onPagination(per_page, (page.per_page * (i - 1)),i)
        }}
      >{i}
      </div>
    })
  }
  return (
    <PaginationStyled {...props}>
      
      <div className='pagination_warp'>
        {
          label && <div>หน้า</div>
        }
        <div className="pagination">
          {page && renderPagination()}
        </div>
        {
          arrows && <div onClick={()=>{beforeOnpagination()}}>
            <img src={iconImages['pagination_arrow.svg']}/>
          </div>
        }
      </div>
    </PaginationStyled>
  );
};
