import styled from "styled-components"
 
export const AaPlatformFunctionStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        
    }
`