import styled from "styled-components"
 
export const  SettingDetailContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    .hr_line{
        border: 1px solid #a6a6a6;
    }
    .content_space{
        margin-top: 90px;
    }
    .TopBGImage{
        /* display: flex;
        justify-content: right; */
        /* border-bottom:1px solid ${({ theme }) => theme.COLORS.GRAY_1}; */
        z-index: 1;
        position: absolute;
        right: 50px;
        top: 93.5px;
    }
    .background_content{
        position: relative;
        padding-bottom:10px;
    }
    
/*===============================================
Theme 
===============================================*/
@media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
        .back{
            margin-left : 3rem;
            margin-bottom : 1rem;
        }
        .TopBGImage{
        right: 50px;
        top: 110.5px;
        }
    }
`