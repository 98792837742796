import styled from "styled-components"

export const ImgUpload2Styled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    .row{
        padding-right:0;
        display: flex;
        justify-content: flex-start;
        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            background: ${({ theme }) => theme.COLORS.BLUE_3};
            color:${({ theme }) => theme.COLORS.WHITE_1};
            border: none;
            outline:none;
            font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
            font-weight: bold;
            width: 24rem;
            border-radius: 0.5rem;
            padding: 0.5rem 0;
            text-align : center;
            cursor: pointer;
            &:hover {  
                opacity:0.9
            } 
        }
    }
    h3{
        font-size:${({ theme }) => theme.FONTS.SIZE_22px}; 
        color:${({ theme }) => theme.COLORS.PURPLE_1}; 
    }
    .upload_box{
        //position:relative;
        background-color:${({ theme }) => theme.COLORS.WHITE_1}; 
        border:1px solid ${({ theme }) => theme.COLORS.BLUE_3}; 
        // min-width:60%;
        // max-width:100%;
        width: 24rem;
        height:13rem;
        text-align:center; 
        border-radius:0.5rem;
        padding: 0.3rem 0.3rem; 
        box-sizing : border-box;
        cursor:potiner;
        .upload_box_border{
            background-color:${({ theme }) => theme.COLORS.WHITE_1}; 
            border:1px dashed ${({ theme }) => theme.COLORS.BLUE_2}; 
            border-radius:0.5rem;
            text-align:center; 
            height : 100%;
            img{
                width : 3rem;
                display : flex;
                justify-content : center;
                height : 100%;
                flex-direction : column;
                text-align : center;
                margin-left : auto ;
                margin-right : auto;
            }
            /* padding: 1.5625rem 1.5625rem; */
        }
        .profile{
            margin: auto;
            object-fit: cover;
            width: 23rem;
            height:12.2rem;
            //position:absolute
            box-sizing : border-box;
            position: relative;
            img{ 
                width : 100%;
                height : 100%;
                border-radius:0.5rem;
                object-fit: cover;
            }
            .del_img {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                width: 25px;
                height: 25px;
            }
        }
    }
    .mt-3{
        margin-top:1rem;
    }
}
    
`