import styled from "styled-components"

export const AdminEditProfileStyled = styled.div`

    .body_section{
        width: 100%;
        /* background-color:red; */
        height: 80vh;
        padding: 40px;
    }

`