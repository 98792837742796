import styled from 'styled-components'
import { iconImages } from 'assets'
export const HistoryContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  height: 100%;
  background-image: url(${iconImages['bg_header.png']});
  background-repeat: no-repeat;
  background-position: top 0;
  background-size: 1550px 200px;
  @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
      .header_title{
          margin-top : 2.5rem;
          margin-bottom : 1rem;
      }
  }
  .title_layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    .group_head {
    }
  }
  .date_select_layer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    .backdrop {
      position: relative;
      .backdrop_outer {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        opacity: 0;
      }
      .backdrop_inner {
        z-index: 2;
        position: absolute;
        top: 1.5rem;
        left : -20.3rem;
        bottom: 0;
      }
    }
    Button {
      margin-left: 10px;
    }
    .label_group {
      display: flex;
      align-items: center;
      margin-right: 10px;
      img {
        width: 21px;
        height: 22px;
        margin-right: 5px;
      }
      .text_layout {
        font-weight: 500;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    }
    .input_date {
      width: fit-content;
      cursor: pointer;
      line-height: 1.5;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      padding: 0 1.4rem;
      border-radius: 3px;
      font-weight: 300;
      min-width: 11rem;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      border: 1px solid rgb(0, 0, 0, 0.1);
      text-align: left;
      position: relative;
      div {
        min-width: fit-content;
        padding: 0.6rem 0;
        /* border-right: 1px solid rgb(0, 0, 0, 0.1); */
        margin-right: 1rem;
        padding-right: 1rem;
        white-space: nowrap;
      }
      img {
        position: absolute;
        right: 0.8rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .bar_layout {
    margin-top: 37px;
  }
  .table_layout {
    margin: 0px 0px 60px 0px;
  }
  .table_layout2 {
  }
  .message-wrapper{
    margin-bottom: 1rem;
  }
  .message{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({theme}) => theme.COLORS.BLUE_6};
    :hover{
      transform: translateY(0.5px);
    }
    :first-child{
      margin-top: 0.25rem;
    }
  }
  .height {
    line-height: 2.2rem;
    width: 100%;
    align-items: center;
  }
  .height2 {
    line-height: 2.2rem;
    display: flex;
    align-items: center;
  }
  .button {
    width: 100%;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
  }
  .btn-room {
    padding-top: 50px;
    height: 200px;
  }

`
