import React,{ Component,useEffect,useRef,useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PrivacyPolicyEditerStyle } from "./style";
import { Field ,reduxForm} from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import ThemeFrom from "components/ThemeForm";
import { connect } from 'react-redux';
import { setReduxTheme, setReduxUserAuth } from 'store/actions';
import { userService } from "apiService";
import ReactLoading from 'react-loading';
import theme from 'assets/theme.json'

import swal from 'sweetalert';

function PrivacyPolicyContainer(){

    const [ThemeData,setThemeData] = useState()

    let id = useSelector(state => state.institution_detail.id)

    let themeParams = useSelector(state => state.ThemePrivacy)

    const dispatch = useDispatch()

    function handledSubmit(e){
        let params = {
            'platform_name':e?.platform_name, 
            'sitename':e?.sitename,
            'description':e?.description, 
            'address':e?.address,
            'cancellation_url_en':e?.cancellation_url_en, 
            'cancellation_url_th':e?.cancellation_url_th,
            'contact_url_en':e?.contact_url_en, 
            'contact_url_th':e?.contact_url_th,
            'privacy_url_en':e?.privacy_url_en, 
            'privacy_url_th':e?.privacy_url_th,
            'terms_en': themeParams?.terms?.en, 
            'terms_th': themeParams?.terms?.th

        }

        userService.PATCH_PRIVACY_POLICY(id,params).then((res)=>{
            if (res && res.success) {
                dispatch(setReduxTheme(res?.data))
                swal({
                    title: "Success!",
                    icon: "success",
                });   
            }
        })
    }

    async function fetchPrivacy_Policy () {
        await userService.GET_PRIVACY_POLICY_THEME(id).then((response) => {

            if (response&&response?.success) {
                setThemeData(response?.data)
                dispatch(setReduxTheme(response?.data))
            } else {
                return ;
            }

        })
    }

    useEffect(() => {
        fetchPrivacy_Policy();
    },[]);


    const Loading = () => {
        setTimeout(() => {
        },3000)
        return(
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '10rem' }}>
                <div >
                    <ReactLoading type='bubbles' color={theme.COLORS.BLUE_3} width='2rem' height='2rem' />
                </div>
            </div>
        )
    }


        return(
            <PrivacyPolicyEditerStyle>
                {
                    ThemeData? (<ThemeFrom
                        onSubmit={handledSubmit}
                        data={ThemeData}
                    />):(Loading())
                }
                
            </PrivacyPolicyEditerStyle>
        )


}

const mapStateToProps = (state) => ({
    EditTextTH: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapDispatchToProps,mapStateToProps,reduxForm({
    form: 'PrivacyPolicyForm'
}))(PrivacyPolicyContainer)  