import React from 'react';
import { BoxModalStyled } from './styled';
import cx from 'classnames';
import Modal from 'react-modal';
import { iconImages } from 'assets';

export const BoxModal = (props) => {
  const { theme_standard,theme_standard_2,theme_standard_3, center,children, isOpen, onRequestClose, showIcon, icon_colors, top } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3,
    theme_standard_3: theme_standard_3,
  });

  const customStyles = {
    position: 'relative',
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'tranparent',
      padding: '40px',
      // paddingTop : '20rem',
      // paddingBottom : '20rem',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: top ? center ? 'center' :'flex-start': 'center',
      // minHeight: '50vh',
      border: 'none',
      zIndex: top && 9999,

    },
    overlay: {
      zIndex: top && 9999,
      background: 'rgb(0,0,0,0.45)',
    }
  };
  const customStyles2 = {
    position: 'relative',
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      // marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'tranparent',
      // // padding: '10px',
      // // paddingTop : '20rem',
      // // paddingBottom : '20rem',
      height: 'auto',
      display: 'flex',
      // flexDirection: 'column',
      justifyContent: top ? 'flex-start' : 'center',
      // // minHeight: '50vh',
      border: 'none',
      zIndex: top && 9999,
      width: 'fit-content'
    },
    overlay: {
      zIndex: top && 9998,
      background: 'rgb(0,0,0,0.45)',
    }
  };

  const customStyles3 = {
    position: 'relative',
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      // marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'tranparent',
      // // padding: '10px',
      // // paddingTop : '20rem',
      // // paddingBottom : '20rem',
      height: 'auto',
      display: 'flex',
      // flexDirection: 'column',
      justifyContent: top ? 'flex-start' : 'center',
      // // minHeight: '50vh',
      border: 'none',
      zIndex: top && 9999,
      width: 'fit-content'
    },
    overlay: {
      zIndex: top && 9998,
      background: 'rgb(0,0,0,0.0)',
    }
  };
  return (
    <BoxModalStyled {...props}>
      <div className={customClass}>
        <Modal
          isOpen={isOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={onRequestClose}
          style={theme_standard_2 ? customStyles2:
            theme_standard_3 ? customStyles3 :customStyles}
          contentLabel="Example Modal"
        >
          {/* 
          <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2>
          */}
          {showIcon && <div style={{ textAlign: 'right', marginBottom: '', marginRight: '1rem', cursor: 'pointer' }}>
            <img src={iconImages[icon_colors ? 'cross2.svg' : 'cross.svg']} alt="cross" onClick={() => onRequestClose()} />
          </div>}
          {children}
        </Modal>
      </div>
    </BoxModalStyled>
  );
};
