import { ROLES, ROUTE_PATH } from "assets";
import { BoxModal, ButtonCustom } from "components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SelectRoleStyles } from "./styled";
import { iconImages } from "assets";
import { getPermissionActions } from "store/actions/getPermissionActions";
import Loading from "react-loading";

const SelectRoleContainer = (props)=>{

    const reduxInstitutionDetail = useSelector(state => state.institution_detail)
    const reduxPermission = useSelector(state => state.permission)
    // const authRedux = useSelector(state => state.auth)
    const [isOpen, setisOpen] = useState();
    const [modal, setModal] = useState();
    const [institution_id, setInstitution_id] = useState('');
    const history = useHistory()
    const dispatch = useDispatch()
    // renderOptions = () => {
    //     const { reduxPermission } = this.props
    //     const options = [
    //       { value: 'nurse', label: 'Nurse', permission: true },
    //       { value: 'doctor', label: 'Doctor', permission: true },
    //       { value: 'pharmacist', label: 'Pharmacist', permission: reduxPermission?.can_provider_pharmacist },
    //       { value: 'translator', label: 'Translator', permission: reduxPermission?.can_consult_translator },
    //       { value: 'accounting', label: 'Accounting', permission: reduxPermission?.should_accounting_on_finishing },
    //     ]
    //     return options
    //       .filter((option) => option?.permission)
    //       .map((option, i) => {
    //         return (
    //           <option key={i} value={option?.value}>{option?.label}</option>
    //         )
    //       })
    //   }
    const allRoles = [
        {
            title: 'แพทย์',
            icon: 'doctorRole.svg',
            value:'doctor',
            description:'ระบบจะทำการสร้างบัญชีและเชื่อมต่อข้อมูลของแพทย์แค่ครั้งแรกเท่านั้น',
            permission:true
        },
        {
            title: 'พยาบาล',
            icon: 'nurseRole.svg',
            value:'nurse',
            description:'ระบบจะทำการสร้างบัญชีของพยาบาลเพื่อใช้สำหรับ Telecare',
            permission:true
        },
        {
            title: 'เภสัชกร',
            value: 'pharmacist',
            icon: 'pharmacistRole.svg',
            description:'ระบบจะทำการสร้างบัญชีของเภสัชกรเพื่อใช้สำหรับ Telecare',
            permission: reduxPermission?.can_provider_pharmacist
        },
        {
            title: 'การเงิน',
            value: 'accounting',
            icon: 'accountingRole.svg',
            description:'ระบบจะทำการสร้างบัญชีของการเงินเพื่อใช้สำหรับ Telecare',
            permission: reduxPermission?.should_accounting_on_finishing
        },
        {
            title: 'ล่าม',
            value: 'translator',
            icon: 'translatorRole.svg',
            description:'ระบบจะทำการสร้างบัญชีของล่ามเพื่อใช้สำหรับ Telecare',
            permission: reduxPermission?.can_consult_translator
        },
        // {
        //     title: 'ผู้ดูแล',
        //     icon: 'adminRole.svg',
        //     value:'account_admin',
        //     description:'ระบบจะทำการสร้างบัญชีของพยาบาลเพื่อใช้สำหรับ Telecare',
        //     permission: authRedux.role === 'system_admin'
        // },
    ]
    const fetchPermissionFeatures = async (institution) => {
        let params = {
            institution_id: institution,
            setting_type: 'features',
        }
        dispatch(getPermissionActions(params));
    }
    const setup = async(institution_id)=>{
        setInstitution_id(institution_id)
        await fetchPermissionFeatures(institution_id);
    }

    useEffect(async () => {
        if (props?.match?.params) {
            await setup(props.match.params.institution_id)
        }
    }, []);

    const [roleAccount, setRoleAccount] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsloading(false)
        }, 300);
        const result = allRoles?.filter((item)=> item.permission)
        setRoleAccount(result)
    }, [reduxPermission]);
    return(
        <SelectRoleStyles>
        <div className="TopSection">
            <BoxModal
                isOpen={isOpen}
                onRequestClose={() => setisOpen({ isOpen: false })}
                theme_standard
            >
                {modal}
            </BoxModal>
            <div className="back">
                <ButtonCustom
                    theme_back
                    label='BACK'
                    icon='arrow_left.svg'
                    onClick={() =>
                    history.push(
                        ROUTE_PATH.SETTINGS_DETAIL + reduxInstitutionDetail.id + '-' + reduxInstitutionDetail.realm,
                    )
                    }
                />
            </div>
        </div>
        <div className="bodySection">
            <div className="body_item">
                {
                    isLoading ?
                        <div className="loading">
                            <Loading
                                type="bubbles"
                                color="#1D77B9"
                            />
                        </div>
                                : 
                            <div className="item_grid_container">
                                <div className="item_title">
                                    <div className="item_title_container">
                                        <div className="title"><b>สร้างบัญชี</b></div>
                                        <div className="description">กรุณาเลือกตำแหน่งที่ต้องการสร้างบัญชี</div>
                                    </div>
                                </div>
                                { roleAccount?.map((item)=>{
                                    return item?.permission && (
                                            <div className="item">
                                                <div className="icons_container">
                                                    <img src={iconImages[`${item.icon}`]} alt="role"/>
                                                </div>
                                                <div className="text_container">
                                                    <div>
                                                        <div>สร้างบัญชีสำหรับ{item.title}</div>
                                                        <div>{item.description}</div>
                                                    </div>
                                                </div>
                                                <div className="btn_container">
                                                    <div style={{ width: '8rem' }}>
                                                        <ButtonCustom
                                                        theme_yellow
                                                        label="สร้างบัญชี"
                                                        // onClick={() => history.push(ROUTE_PATH.SETTINGS_DETAIL + institutionDetailData.id + ROUTE_PATH.USER_ROLE + 'create')}
                                                        onClick={() => history.push(ROUTE_PATH.SETTINGS_DETAIL + reduxInstitutionDetail.id + ROUTE_PATH.USER_ACCOUNT + 'create' + ROUTE_PATH.USER_ROLE + `${item.value}`)}
                                                        icon='plus2.svg'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })}
                            </div>
                    }
            </div>
        </div>
        </SelectRoleStyles>
    );
}

export default SelectRoleContainer