import React from 'react';
import { InputCheckboxNormalStyled } from './styled'
import cx from 'classnames';


export const InputCheckboxNormal = (props) => {
  const {
    theme_standard,
    theme_standard_2,
    theme_standard_3,
    theme_standard_4,
    theme_transparent_1,
    theme_transparent_2,
    theme_transparent_3,
    theme_transparent_4,
    theme_transparent_5,
    theme_transparent_6,
    theme_transparent_7,
    label,
    label2,
    placeholder,
    desc,
    input,
    value,
    checked,
    disabled,
    onChange
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3,
    theme_standard_4: theme_standard_4,
    theme_transparent_1: theme_transparent_1,
    theme_transparent_2: theme_transparent_2,
    theme_transparent_3: theme_transparent_3,
    theme_transparent_4: theme_transparent_4,
    theme_transparent_5: theme_transparent_5,
    theme_transparent_6: theme_transparent_6,
    theme_transparent_7: theme_transparent_7,
  });
  return (
    <InputCheckboxNormalStyled>
      <div className={customClass}>
        {label2 && <div className="label">{label2}</div>}
        <label className="container">
          <input
            // {...props}
            // {...input}
            type='checkbox'
            onChange={(e) => {
              onChange && onChange(e)
            }}
            value={value}
            checked={checked}
            disabled={disabled}
            placeholder={placeholder}
          />
          <span className={disabled && 'disabled'}>{label}</span>
        </label>
        <small className={disabled && 'disabled'}>
          <div style={{ marginTop: '-0.1rem' }}>
            {desc}
          </div>
        </small>
      </div>
    </InputCheckboxNormalStyled>
  );
};
