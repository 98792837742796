import React, { useEffect, useState } from 'react';
import { InputTextAreaStyled } from './styled'
import cx from 'classnames';
import { iconImages } from 'assets';

export const InputTextArea = (props) => {
  const {
    theme_standard,
    theme_standard_2,
    theme_standard_3,
    theme_standard_4,
    theme_standard_max_length,
    theme_outline,
    theme_outline_2,
    theme_search,
    label,
    language,
    placeholder,
    input,
    icon,
    type,
    meta,
    disabled,
    maxLength,
    value,
    upperMaxLength,
    defaultValue,
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3,
    theme_standard_4: theme_standard_4,
    theme_search: theme_search,
    theme_outline: theme_outline,
    theme_outline_2: theme_outline_2,
    theme_standard_max_length: theme_standard_max_length,
  });

  const [showLength, setShowLength] = useState(defaultValue?.length || 0);
  const [TriggertshowLength, setTriggertshowLength] = useState(false);

  useEffect(() => {
    if (!input?.value && defaultValue) {
      setShowLength(defaultValue?.length);
    }
  }, [input?.value, defaultValue]);

  const handleInput = (e) => {
    input.onChange(e);
    setTriggertshowLength(true)
    setShowLength(e.target.value.length);
  };

  return (
    <InputTextAreaStyled>
      <div className={customClass} isinvalid={meta && (meta.touched && meta.error)} >
        {label && <div className="label">{label}</div>}
        {language &&<div>{language}</div>}
        {maxLength && upperMaxLength && (
          <div className="num_show_theme_form">
            {`${TriggertshowLength ? input?.value?.length : showLength}/${maxLength}`}
          </div>
        )}
        <textarea
          onInput={handleInput}
          {...props}
          {...input}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          className={meta && ((meta.touched && meta.error) && ' isvalid')}
        />
        {meta && (meta.touched && meta.error && <div className="error">{meta.error}</div>)}
        {icon && <img className="icon" src={iconImages[icon]} alt={icon} />}
        {maxLength && !upperMaxLength && (
          <div className="num_show">
            {TriggertshowLength
              ? `${input?.value?.length}/${maxLength}`
              : `${showLength}/${maxLength}`}
          </div>
        )}
      </div>
    </InputTextAreaStyled>
  );
};
