import React, { useEffect, useState } from 'react'
import { AssessmentContainerStyled } from './styled'
import {
  Button,
  ButtonCustom,
  Middleline,
  Pagination,
  TableCustom,
  Typography,
} from 'components'
import theme from 'assets/theme.json'
import { ROUTE_PATH, calculateSkeleton } from 'assets'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { userService } from 'apiService'
import moment from 'moment'
import { Optionbox } from 'components/TableCustom/checkbox'

const columns = [
  {
    // title: <div>&nbsp;</div>,
    title: 'ลำดับ',
    field: 'index',
    // headerStyle: { display: 'flex', justifyContent: 'center', width: '1%', paddingTop: '1rem', paddingBottom: '1rem' },
    headerStyle: {
      display: 'flex',
      justifyContent: 'left',
      width: '6%',
      backgroundColor: 'none',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #b3b3b3',
    },
    style: { textAlign: 'left', width: '6%' },
    icon: 'setting.svg',
  },
  {
    title: 'ชื่อแบบประเมิน',
    field: 'name',
    // headerStyle: { display: 'flex', justifyContent: 'center', width: '54%',  paddingTop: '1rem', paddingBottom: '1rem' },
    headerStyle: {
      display: 'flex',
      justifyContent: 'left',
      width: '40%',
      paddingLeft: '80px',
      backgroundColor: 'none',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #b3b3b3',
    },
    style: { textAlign: 'left', width: '40%', paddingLeft: '80px' },
    icon: 'setting.svg',
  },
  {
    title: 'วันที่สร้าง',
    field: 'created_at',
    // headerStyle: { display: 'flex', justifyContent: 'center', width: '21%',  paddingTop: '1rem', paddingBottom: '1rem' },
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '20%',
      backgroundColor: 'none',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #b3b3b3',
    },
    style: { textAlign: 'center', width: '20%' },
  },
  {
    title: 'สถานะ',
    field: 'is_active',
    // headerStyle: { display: 'flex', justifyContent: 'center', width: '25%',  paddingTop: '1rem', paddingBottom: '1rem' },
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '40%',
      backgroundColor: 'none',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #b3b3b3',
    },
    style: { textAlign: 'center', width: '40%' },
  },
  {
    title: <div>&nbsp;</div>,
    field: 'checkbox',
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '20%',
      backgroundColor: 'none',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #b3b3b3',
    },
    style: { textAlign: 'center', width: '20%' },
    icon: 'setting.svg',
  },
]

const AssessmentContainer = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const accountIdRedux = useSelector((state) => state?.institution_detail?.id)

  const [page, setPage] = useState()
  const [sort, setSort] = useState({
    sort_by: '',
    order_by: 'DESC',
  })

  const [filter, setFilter] = useState({
    offset: 0,
    per_page: 10,
  })

  const [institutions, setInstitutions] = useState(calculateSkeleton(columns))
  const [surveys, setSurveysList] = useState([])
  const loading = institutions[0].loading

  const onPagination = async (perpage, offset, current_page) => {
    console.log('this is pagination')
    setFilter({
      offset: offset,
      per_page: perpage,
    })
  }

  useEffect(() => {
    fetchData() //setUp data Here
  }, [filter])

  const fetchData = async () => {
    try {
      const res = await userService.GET_SATISFACTION_SURVEYS_LIST_BY_ACCOUNT_ID(
        accountIdRedux,
        filter?.per_page,
        filter?.offset,
      )

      if (res && res?.success) {
        setPage(res?.page)
        setSort(res?.sort)
        setSurveysList(setNewAssessmentLists(res?.data))
      } else {
        throw 'error API'
      }
    } catch (error) {
      return error
    }
  }

  const handelToEditRole = (e) => {
    history.push(ROUTE_PATH.ASSESSMENT + accountIdRedux + '/' + 'edit', {
      initialiData: e,
    })
  }

  const setNewAssessmentLists = (data) => {
    return data
      ? data.map((item, i) => {
          return {
            ...item,
            is_active: item?.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน',
            index: i + 1,
            created_at: item.created_at
              ? moment
                  .utc(item.created_at)
                  .utcOffset('+0700')
                  .format('DD MMM YYYY')
              : '-',
            checkbox: (
              <div>
                <Optionbox
                  intitalValue={item}
                  handelToEditRole={handelToEditRole}
                />
              </div>
            ),
          }
        })
      : []
  }

  return (
    <AssessmentContainerStyled>
      <Typography
        label='แบบประเมิน'
        size={theme.FONTS.SIZE_30px}
        color={theme.COLORS.BLUE_17}
      />
      <div
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
        }}>
        <div style={{ width: '12rem' }}>
          <ButtonCustom
            theme_yellow
            label='สร้างเเบบประเมิน'
            // onClick={() => history.push(ROUTE_PATH.SETTINGS_DETAIL + institutionDetailData.id + ROUTE_PATH.USER_ROLE + 'create')}
            onClick={() =>
              history.push(
                ROUTE_PATH.ASSESSMENT + accountIdRedux + '/' + 'create',
              )
            }
            icon='plus2.svg'
          />
        </div>
      </div>
      <Middleline theme_standard title='เเบบประเมิน' />
      <TableCustom
        checkBox
        theme_standard_2
        offset={filter.offset}
        sort={sort}
        // onSort={onSort}
        useNo={!loading}
        columns={columns}
        data={surveys}
        handelToEditRole={(e) => handelToEditRole(e)}
      />

      <div>
        <Pagination
          label
          arrows
          title
          onPagination={onPagination}
          sort={sort}
          page={page}
          per_page={filter.per_page}
        />
      </div>
    </AssessmentContainerStyled>
  )
}

export default AssessmentContainer
