import React, { useEffect, useState } from 'react';
import { RecommendDoctorStyled } from './styled';
import { BoxConfirm, BoxModal, TableCustom, InputSelect, Middleline, Button, ButtonCustom, InputTextArea, } from 'components'
import { useHistory } from 'react-router-dom';
// import { ROUTE_PATH, calculateSkeleton } from 'assets';
import { userService } from 'apiService';
// import moment from 'moment';
import theme from 'assets/theme.json'
import { RecommendDoctorForm } from 'forms'
import { Field, reset } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { VALIDATE } from 'assets';

export const RecommendDoctor = (props) => {
  const history = useHistory()
  const { institutionDetailData } = props
  const reduxForm = useSelector((state) => state.form.RecommendDoctorForm);
  const dispatch = useDispatch();

  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
  })
  const [clinic_data, set_clinic_data] = useState([])
  const [clinic_id, set_clinic_id] = useState(null)
  const [data, set_data] = useState([])

  useEffect(() => {
    if (institutionDetailData && institutionDetailData.id) {
      fetchData()
    }
  }, [institutionDetailData])

  const fetchData = async () => {
    let res = await userService.GET_CLINICS_BY_ACCOUNT(institutionDetailData.id)

    if (res && res.success) {
      set_clinic_id(res.data[0].id)
      fetchRecommendData(res.data[0].id)
      set_clinic_data(setNewClinics(res.data))
    }
  }

  const fetchRecommendData = async (clinic_id) => {
    let res = await userService.GET_RECOMMENDATION_BY_CLINIC_ID(clinic_id, '?owner_id=0')

    if (res && res.success) {
      set_data(res.data)
    }
  }

  const setNewClinics = (data) => {
    return data && data.map(item => {
      return {
        ...item,
        name: `${item.name} (${item.description})`
      }
    })
  }

  const handlerClickDelete = (e) => {
    setModal({
      isOpen: true,
      content: (
        <BoxConfirm
          theme_standard_2
          confirm='ยืนยัน'
          cancel='ยกเลิก'
          title='ต้องการลบข้อมูลใช่หรือไม่?'
          onConfirm={() => handleDeleteData(e.id)}
          onCancel={() => closeModal()}
        />
      )
    })
  }


  const handlerCreateButton = (e) => {
    setModal({
      isOpen: true,
      content: (
        <BoxConfirm
          theme_standard_2
          confirm='ยืนยัน'
          cancel='ยกเลิก'
          desc={
            <RecommendDoctorForm
              onSubmit={handleSubmit}
              onCancel={() => closeModal()}
              is_modal
            />
          }
          title='ข้อความที่ต้องการเพิ่ม'
        />
      )
    })
  }

  const handleDeleteData = async (id) => {
    let res = await userService.REMOVE_RECOMMENDATION_BY_CLINIC_ID(clinic_id, id)
    if (res?.success) {
      fetchRecommendData(clinic_id)
    }
    closeModal()
  }

  const closeModal = () => {
    setModal({ isOpen: false, content: null })
    // setIsCheck({ id: null, checked: null, is_active: null, is_visible: null, })
  }

  const renderOptions = () => {
    return clinic_data && clinic_data.map((clinic, i) => {
      return <option key={i} value={clinic.id} >{clinic.name}</option>
    })
  }

  const columns = ({ handlerClickDelete }) => [
    {
      title: 'ข้อความ',
      field: 'index',
      headerStyle: {  borderTopLeftRadius:'5px',borderBottomLeftRadius:'5px',display: 'flex',justifyContent: 'start',alignItems: 'center',height: '38px', width: '10%', backgroundColor: '#1D77B9',marginTop:'1rem',color:'#fff',fontSize: '16px' },
      style: { textAlign: 'left', width: '1%', },
      icon: 'setting.svg',
    },
    {
      title: <div>&nbsp;</div>,
      field: 'message',
      headerStyle: { display: 'flex',justifyContent: 'start',alignItems: 'center', marginTop:'1rem',height: '38px', backgroundColor: '#1D77B9'},
      style: {
        textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
      },
      icon: 'setting.sg',
    },
    {
      title: <div>&nbsp;</div>,
      field: 'action',
      headerStyle: { borderTopRightRadius:'5px',borderBottomRightRadius:'5px', display: 'flex',justifyContent: 'start',alignItems: 'center',height: '38px', width: '1%', backgroundColor: '#1D77B9',marginTop:'1rem' },
      style: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '14%' },
      render: ({ origin, value }) => (
        <div
          style={{ background: '#d22222', cursor: 'pointer', textAlign: 'center', width: '100px', borderRadius: '6px', color: 'white', fontWeight: 'bold',height: '38px', padding: '10px' }}
          className='btn_del'
          onClick={() => {
            handlerClickDelete(origin)
          }}
        >
          ลบ
        </div>
      ),
    },
  ]

  const changeClinic = (e) => {
    set_clinic_id(e.target.value)
    fetchRecommendData(e.target.value)
  }

  const handleSubmit = async (values) => {
    let params = { ...values }
    let res = await userService.POST_RECOMMENDATION_BY_CLINIC_ID(clinic_id, params)
    if (res?.success) {
      dispatch(reset('RecommendDoctorForm'));
      fetchRecommendData(clinic_id)
      if (!modal?.isOpen) {
        closeModal()
      }
    }
  }

  return (
    <RecommendDoctorStyled {...props}>
      <BoxModal
        isOpen={modal?.isOpen}
        onRequestClose={() => closeModal()}
        theme_standard
      >
        {modal?.content}
      </BoxModal>
      <div className='clinic_search_container'>
        <div style={{ width: '30rem', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px' }}>
            Clinic :
          </div>
          <InputSelect
            theme_standard_3
            onChange={changeClinic}
            name='clinic'
            maxWidth='300px'
          >
            {renderOptions()}
          </InputSelect>
        </div>
        <div>
          <div style={{ width: '8rem' }}>
            <ButtonCustom
              theme_yellow
              label="ข้อความ"
              onClick={()=> handlerCreateButton()}
              icon='plus2.svg'
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <TableCustom
          theme_standard_2
          useNo
          padding_bottom='0px'
          columns={
            columns({
              handlerClickDelete: handlerClickDelete,
            })
          }
          data={data}
        />
      </div>
      {/* <div style={{ marginTop: '10px' }}>
        <RecommendDoctorForm
          is_button
          onSubmit={handleSubmit}
        />
      </div> */}
    </RecommendDoctorStyled>
  );
};
