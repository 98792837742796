import styled from "styled-components"

export const DashboardHealthCheckStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
.row_custom{
        height : 100%;
        display : flex;
        .col_custom_2{
            width : 20%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_3{
            width : 25%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_9{
            width : 65%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_4_3{
            width : 35%;
            align-items: stretch;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_6{
            width : 50%;
            &:first-child{
                margin-right : 0.5rem;
            }
            &:last-child{
                margin-left : 0.5rem;
            }
        }
        .col_custom_4{
            width : 33.3333333%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .health-error{
            font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
            margin-bottom:  0.9rem;
            .health-error-date{
                margin-bottom:  0.2rem;
                font-weight: 600;
            }
            .health-error-label{
                opacity: 0.8;
            }
        }
        .items{
            overflow-y: auto;
            word-break: break-all;
            padding : 1rem 0.5rem 1rem 1rem;
            .load-more{
                width: 100%;
                display: flex;
                justify-content: center;
                .button{
                    font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
                    cursor: pointer;
                    color: ${({ theme }) => theme.COLORS.LINK};

                }
            }
            /* width */
            ::-webkit-scrollbar {
            width: 6px;
            border-radius: 20px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
            /* box-shadow: inset 0 0 5px grey;  */
            border-radius: 20px;
            margin-top  : 1rem;
            margin-bottom  : 1rem;
            }
            
            /* Handle */
            ::-webkit-scrollbar-thumb {
            background:${({ theme }) => theme.COLORS.BLUE_4};
            border-radius: 10px;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
            background: #b30000; 
            }
        }
       
    }

    
   
`