import styled from 'styled-components'

export const AssessmentFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  .loading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color:${({ theme }) => theme.COLORS.WHITE_1}; */
    /* border-radius:10px; */
  }
  .bodySection {
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    width: 100%;
    height: 100%;
  }

  .formContainer {
    width: 100%;
    padding: 20px 20px;
    background-color: salmon;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
    row-gap: 20px;
  }

  /*==========================
Theme 
===============================================*/
  @media only screen and (max-width: ${({ theme }) =>theme.SCREENS.TABLET_EXTRA}) {
    .back {
      margin-left: 3rem;
      margin-bottom: 1rem;
    }
  }
`
