import React from 'react';
import { BackgroundStyled } from './styled';
import cx from 'classnames';
import { backgroundImages } from 'assets';

export const Background = (props) => {
  const {
    theme_standard,
    theme_standard_2,
    theme_standard_3,
    background,
    src
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3
  });
  return (
    <BackgroundStyled {...props}>
      <div className={customClass}>
        {background && <img src={backgroundImages[background]} alt='background' />}
        {src && <img className='logo' src={src} alt={src} />}
      </div>
    </BackgroundStyled>
  );
};
