import React from 'react';
import { InputCheckboxStyled } from './styled'
import cx from 'classnames';


export const InputCheckbox = (props) => {
  const {
    theme_standard,
    theme_standard_2,
    theme_standard_3,
    theme_standard_4,
    theme_transparent_1,
    theme_transparent_2,
    theme_transparent_3,
    theme_transparent_4,
    label,
    label2,
    placeholder,
    desc,
    input,
    type,
    disabled,
    meta
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3,
    theme_standard_4: theme_standard_4,
    theme_transparent_1: theme_transparent_1,
    theme_transparent_2: theme_transparent_2,
    theme_transparent_3: theme_transparent_3,
    theme_transparent_4: theme_transparent_4,

  });
  return (
    <InputCheckboxStyled>
      <div className={customClass}>
        {label2 && <div className="label">{label2}</div>}
        <label className="container">
          <input
            {...props}
            {...input}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
          />
          <span className={disabled && 'disabled'}>{label}</span>
        </label>
        <small className={disabled && 'disabled'}>
          <div style={{ marginTop: '-0.1rem' }}>
            {desc}
          </div>
        </small>
        {meta && (meta.touched && meta.error && <div className="error">{meta.error}</div>)}

      </div>
    </InputCheckboxStyled>
  );
};
