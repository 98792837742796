import React, { useState } from 'react';
import { InstitutionProfileStyled } from './styled';
import cx from 'classnames';
import { InstitutionProfileForm } from 'forms'
import {
  BoxConfirm,
  BoxModal
} from 'components'
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from 'assets';
import { useDispatch } from 'react-redux';
import { setTabActive } from 'store/actions'


export const InstitutionProfile = (props) => {
  const { theme_standard, is_creating, handleStep } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });
  const history = useHistory()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [modal, setModal] = useState()
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const handleSubmit = () => {
    setLoadingSubmit(true)
    if (is_creating) {
      setTimeout(() => {
        handleStep(1)
        setLoadingSubmit(false)
        // setIsOpen(true)
        // setModal(
        //   <BoxConfirm
        //     theme_success
        //     title='Success!'
        //     desc='สร้างบัญชีสถานพยาบาลของคุณสำเร็จแล้ว'
        //     onConfirm={() => {
        //       dispatch(setTabActive(4))
        //       history.push(ROUTE_PATH.SETTINGS_DETAIL + '1' + '-' + 'inv1')
        //       setIsOpen(false)
        //     }}
        //     onCancel={false}
        //   />
        // )
      }, 1000)
    } else {
      setTimeout(() => {
        setLoadingSubmit(false)
        setIsOpen(true)
        setModal(
          <BoxConfirm
            theme_success
            title='Success!'
            desc='อัพเดทข้อมูลทั่วไปสำเร็จแล้ว'
            onConfirm={() => {
              setIsOpen(false)
            }}
            onCancel={false}
          />
        )
      }, 1000)
    }
  }
  return (
    <InstitutionProfileStyled {...props}>
      <div className={customClass}>
        <BoxModal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          theme_standard
        >
          {modal}
        </BoxModal>
        <InstitutionProfileForm
          is_creating={is_creating}
          loadingSubmit={loadingSubmit}
          onSubmit={handleSubmit}
        />
      </div>
    </InstitutionProfileStyled>
  );
};
