import { iconImages } from 'assets'

export const getMedicineStatus = (data) => {
  const {
    is_treatment_delivery_required,
    is_treatment_delivery_confirmed,
  } = data


  const icon1 = iconImages['confirm-medicine-border.svg']
  const icon2 = iconImages['no-medicine-border.svg']

  //หมอบอกไม่มียา > ไม่สนใจคนไข้
  if (!is_treatment_delivery_required && !is_treatment_delivery_confirmed) {
    return {
      icon: icon2,
      title: 'ไม่มียาหรือไม่รับยา',
      desc: 'คนไข้ไม่มียาหรือไม่ตกลงรับยาตามที่แพทย์สั่ง',
    }
  }

  //หมอบอกมียา > คนไข้ ยังไม่ตอบ
  // is_treatment_delivery_confirmed must be null
  else if (
    is_treatment_delivery_required &&
    is_treatment_delivery_confirmed === null
  ) {
    return {
      icon: icon1,
      title: 'ยืนยันการรับยา',
      desc: 'รอคนไข้ตกลงรับยาตามที่แพทย์สั่ง',
    }
  }

  //หมอบอกมียา > คนไข้ เอายา
  else if (is_treatment_delivery_required && is_treatment_delivery_confirmed) {
    return {
      icon: icon1,
      title: 'ยืนยันการรับยา',
      desc: 'คนไข้ตกลงรับยาตามที่แพทย์สั่ง',
    }
  }

  //หมอบอกมียา > คนไข้ ไม่เอายา
  else if (is_treatment_delivery_required && !is_treatment_delivery_confirmed) {
    return {
      icon: icon1,
      title: 'ยืนยันการรับยา',
      desc: 'คนไข้ปฏิเสธการรับยาตามที่แพทย์สั่ง',
    }
  }
}
