import { SET_PERMISSION_FEATURES, CLEAR_PERMISSION_FEATURES } from "store/actions/actionTypes"

export const inititalState = {}
export const permission = (
    state = inititalState,
    action,
) => {
    switch (action.type) {
        case SET_PERMISSION_FEATURES:
            return { ...state, ...action.payload }
        case CLEAR_PERMISSION_FEATURES:
            return inititalState
        default:
            return state
    }
}
