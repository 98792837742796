import React from "react";
import { Button } from "react-bootstrap";
import { CheckBoxStyle } from "./styled";
import {RiEditBoxLine,RiDeleteBin5Fill} from 'react-icons/ri'
import { ButtonCustom } from "components/ButtonCustom";
export const Optionbox = (props) =>{

    const{
        intitalValue,
        handelToEditRole
    }= props;



    const handelToEdit =(e) => {
        // console.log(intitalValue);
        handelToEditRole&&handelToEditRole(intitalValue);
    }
    // const handelToDeleteRole =(e) => {
    //     console.log('Delete');
    // }

    return(
        <CheckBoxStyle>
            <div className="btn_warpper">
                <Button onClick={(e)=>handelToEdit(e)} className='edit_btn'><RiEditBoxLine/></Button>
                {/* <Button onClick={(e)=>{handelToDeleteRole(e)}} variant="danger"><RiDeleteBin5Fill/></Button> */}
            </div>
        </CheckBoxStyle>
    );
};