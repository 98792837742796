import styled from 'styled-components';
export const TableCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
.theme_standard {
    font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
    color: ${({ theme }) => theme.COLORS.PURPLE_1}; 
    .table_container {
      padding-bottom:2rem;
      .table_header {
        padding: 0.5rem 0rem;
        // background: ${({ theme }) => theme.COLORS.BLUE_10};
        // box-shadow: 0 0.1875rem 1rem 0 ${({ theme }) => theme.COLORS.BLACK_2};
        border-top-left-radius: 0.4375rem;
        border-top-right-radius: 0.4375rem;
        margin-bottom: 0.3125rem;
        // margin-bottom: 0.5rem;
        // border-radius: 0.25rem;
        // border-bottom: 1.5px solid  ${({ theme }) => theme.COLORS.GREY_7};
        font-weight: bold;
        .header_row {
          display: flex;
          align-items: center;
          .header_col {
            width: 100%;
            padding: 0 0.9375rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // font-family: prompt_light;
            color: ${({ theme }) => theme.COLORS.GREY_6};
            // font-size: ${({ theme }) => theme.FONTS.SIZE_8};
            display:flex;
            &:first-child {
              border-left: none;
            }
            .icon{
              margin-left : 0.5rem;
              width : 0.7rem;
              cursor: pointer;
              &:active{
                transform : translateY(1px);
              }
            }
          }
          .img_pic {
            margin-left: 0.1875rem;
          }
        }
      }
      .table_body {
        .body_row {
          display: flex;
          width: 100%; 
          cursor: ${props => props.cursor ? 'pointer' : ''};
          margin-bottom: 0.3125rem;
          // box-shadow: 0 0.1875rem 1rem 0 ${({ theme }) => theme.COLORS.BLACK_2};
          // background: ${({ theme }) => theme.COLORS.WHITE_1};
          border-top-left-radius: 0.4375rem;
          border-top-right-radius: 0.4375rem;
          border-bottom:1px solid ${({ theme }) => theme.COLORS.BLUE_15};
          margin-bottom: 0.3125rem;
          // border-radius: 0.75rem;
          padding: 0.6rem 0rem;
          align-items: center;
          color: ${({ theme }) => theme.COLORS.BLACK_2};
          border-bottom : none;
          &:active{
            transform: ${props => props.cursor ? 'translateY(1px)' : ''}; ;
          }
          // align-items: flex-start;
          /* &:first-child{
            border-bottom:none;
            margin-bottom:1rem;
            color: ${({ theme }) => theme.COLORS.BLACK_2};
          } */
          /* &:nth-child(2) {
            border-bottom:none;
            padding:0;
            color: ${({ theme }) => theme.COLORS.BLUE_5};
          }
          &:last-child{
            border-bottom : none;
          } */
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            // font-size: ${({ theme }) => theme.FONTS.SIZE_7};
            color: ${({ theme }) => theme.COLORS.GREY_6};
            padding: 0rem 0.9375rem;
            width: 100%;
            &:hover {
              opacity:0.8;
            }
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.GREY_4 + '80'};
          }
          &:last-child {
            margin-bottom: 0rem;
          }
        }
      }
      .checkbox_layout {
        float: left;
        margin-left: 1.1875rem;
      }
    }
    .empty_list{
        display:flex;
        justify-content:center;
        //background-color:red;
        padding:3rem 0; 
        width:100%;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        opacity:0.5;
    }
  }
  .theme_standard_2 {
    font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
    color: ${({ theme }) => theme.COLORS.PURPLE_1}; 
    .table_container {
      padding-bottom:   ${({ padding_bottom }) => padding_bottom ? padding_bottom : '2rem'};
      .table_header {
        padding: 0.5rem 0rem;
        // background: ${({ theme }) => theme.COLORS.BLUE_10};
        // box-shadow: 0 0.1875rem 1rem 0 ${({ theme }) => theme.COLORS.BLACK_2};
        border-top-left-radius: 0.4375rem;
        border-top-right-radius: 0.4375rem;
        margin-bottom: 0.3125rem;
        // margin-bottom: 0.5rem;
        // border-radius: 0.25rem;
        // border-bottom: 1.5px solid  ${({ theme }) => theme.COLORS.GREY_7};
        font-weight: bold;
        .header_row {
          display: flex;
          align-items: center;
          .filterBox{
            position: absolute;
            margin-top:30px;
          }
          .header_col {
            width: 100%;
            padding: 0 0.9375rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // font-family: prompt_light;
            color: ${({ theme }) => theme.COLORS.GREY_6};
            // font-size: ${({ theme }) => theme.FONTS.SIZE_8};
            display:flex;
            &:first-child {
              border-left: none;
            }
            .icon{
              margin-left : 0.5rem;
              width : 0.7rem;
              cursor: pointer;
              &:active{
                transform : translateY(1px);
              }
            }
          }
          .img_pic {
            margin-left: 0.1875rem;
          }
        }
      }
      .table_body {
        .body_row {
          display: flex;
          width: 100%; 
          cursor: ${props => props.cursor ? 'pointer' : ''};
          margin-bottom: 0.3125rem;
          
          // box-shadow: 0 0.1875rem 1rem 0 ${({ theme }) => theme.COLORS.BLACK_2};
          // background: ${({ theme }) => theme.COLORS.WHITE_1};
          //border-bottom:1px solid ${({ theme }) => theme.COLORS.BLUE_15}; //old style
          border-radius: 5px;
          border: 1px solid #ffff;

          background-color: ${({ theme }) => theme.COLORS.WHITE_1};
          margin-bottom: 1rem;
          // border-radius: 0.75rem;
          padding: 0.6rem 0rem;
          align-items: center;
          color: ${({ theme }) => theme.COLORS.BLACK_2};
          &:active{
            transform: ${props => props.cursor ? 'translateY(1px)' : ''}; ;
          }
          // align-items: flex-start;
          /* &:first-child{
            border-bottom:none;
            margin-bottom:1rem;
            color: ${({ theme }) => theme.COLORS.BLACK_2};
          } */
          /* &:nth-child(2) {
            border-bottom:none;
            padding:0;
            color: ${({ theme }) => theme.COLORS.BLUE_5};
          }
          &:last-child{
            border-bottom : none;
          } */
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            // font-size: ${({ theme }) => theme.FONTS.SIZE_7};
            color: ${({ theme }) => theme.COLORS.GREY_6};
            padding: 0rem 0.9375rem;
            width: 100%;
            &:hover {
              opacity:0.8;
            }
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.GREY_4 + '80'};
          }
          &:last-child {
            margin-bottom: 0rem;
          }
        }
      }
      .checkbox_layout {
        float: left;
        margin-left: 1.1875rem;
      }
    }
    .empty_list{
        display:flex;
        justify-content:center;
        //background-color:red;
        padding:3rem 0; 
        width:100%;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        opacity:0.5;
    }
  }

`;
