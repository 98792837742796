import React, { useEffect, useState } from 'react';
import { HeatmapStyled } from './styled';
import cx from 'classnames';
import Chart from 'react-apexcharts'

export const Heatmap = (props) => {
  const { theme_standard, title, data } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });
  const [series, setSeries] = useState([])
  useEffect(()=>{
    if(data?.series){
      setSeries(data?.series)
    }
  },[data])
  const [options, setOptions] = useState(
    {
      chart: {
        type: 'heatmap',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#008FFB"],
    },
  )
  return (
    <HeatmapStyled {...props}>
      <div className={customClass}>
        <Chart
          options={options}
          series={series}
          type='heatmap'
          height={448}
          width='100%'
        />
      </div>
    </HeatmapStyled >
  );
};
