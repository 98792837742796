import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { CmsMainLayout, PageMainLayout } from 'mainLayouts'
import { PageNotFound } from 'components'

//
const indexRoutes = [
  { path: '/', exact: true, name: 'page', component: PageMainLayout },
  { path: '/login', exact: true, name: 'page', component: PageMainLayout },
  { path: '/logout', exact: true, name: 'page', component: PageMainLayout },
  {
    path: '/settings',
    exact: false,
    name: 'settings',
    component: CmsMainLayout,
  },
  {
    path: '/history',
    exact: false,
    name: 'settings',
    component: CmsMainLayout,
  },
  {
    path: '/dashboard',
    exact: false,
    name: 'settings',
    component: CmsMainLayout,
  },
  {
    path: '/assessment',
    exact: false,
    name: 'settings',
    component: CmsMainLayout,
  },
]

class Routes extends React.Component {
  render() {
    return (
      <>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route
                exact={prop.exact}
                path={prop.path}
                component={prop.component}
                key={key}
              />
            )
          })}
          <Route path='*' exact={true}>
            s
            <PageNotFound notfound={true} />
          </Route>
        </Switch>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
