import styled from "styled-components"
 
export const DashboardContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
      .header_title{
          margin-top : 2.5rem;
          margin-bottom : 1rem;
      }
    }
    .row_custom{
        display : flex;
        margin-bottom : 1rem;
        .col_custom_2{
            width : 20%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_3{
            width : 25%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_6{
            width : 50%;
            &:first-child{
                margin-right : 0.5rem;
            }
            &:last-child{
                margin-left : 0.5rem;
            }
        }
        .col_custom_4{
            width : 33.3333333%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
    }

`
export const HighLightStyled = styled.div`
    .title{
        font-size : 14px;
        font-weight : 500;
    }
    .detail{
        padding : 0.25rem 0;
        font-size : 36px;
        font-weight : 600;
    }
    .desc{
        font-size : 14px;
    }
`