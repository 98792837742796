import React from 'react';
import { SkeletonFormStyled } from './styled';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';

export const SkeletonForm = (props) => {
  const {
    theme_standard,
    row,
    column,
    split_by_row,
    position_button,
    show_image
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderField = () => {
    let array_row = []
    let array_column = []
    for (let i = 0; i < row; i++) {
      array_row.push(i)
    }
    for (let i = 0; i < column; i++) {
      array_column.push(i)
    }
    return array_row.map((row, i) => {
      const split = split_by_row.some(number => number === (i + 1))
      return (
        <div key={i} style={{ marginBottom: '2rem' }}>
          {split
            ? <div style={{ display: 'flex' }}>
              {array_column.map((col, i2, arr) => {
                return (
                  <div
                    key={i2}
                    style={{
                      width: `${(100 / column)}%`,
                      marginRight: (arr.length - 1) !== i2 ? '1rem' : '',
                      marginLeft: 0 !== i2 ? '1rem' : ''
                    }}>
                    <Skeleton width='5rem' />
                    <Skeleton height='2.7rem' />
                  </div>
                )
              })}
            </div>
            : <div>
              <Skeleton width='5rem' />
              <Skeleton height='2.7rem' />
            </div>
          }
        </div>
      )
    })
  }
  return (
    <SkeletonFormStyled {...props}>
      <div className={customClass} style={{ marginTop: '1rem' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: show_image ? '30%' : '0%' }}>
            {show_image &&
              <>
                <Skeleton width='13rem' height='13rem' />
                <Skeleton width='13rem' height='2rem' style={{ marginTop: '1rem' }} />
              </>
            }
          </div>
          <div style={{ width: show_image ? '70%' : '100%' }}>
            {renderField()}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: position_button
        }}>
          <Skeleton width='10rem' height='2.7rem' />
        </div>
      </div>
    </SkeletonFormStyled>
  );
};
