import styled from 'styled-components'

export const ButtonCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  font-size: ${(props) => props.size && props.size};

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
  }

  .theme_blue {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.8rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_blue_9 {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.BLUE_19};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_19};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.8rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_gray_2 {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.GRAY_15};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_15};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.8rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_yellow {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.YELLOW_2};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.YELLOW_2};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.BLACK_6};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.8rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_outline_yellow {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.YELLOW_2};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.BLACK_6};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.8rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_blue_8 {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.BLUE_18};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_18};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 20px;
      height: 20px;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_blue_2 {
    line-height: 1.5;
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.3rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 0.5rem;
    font-weight: 300;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
      &.img_left {
        padding-left: 0.5rem;
      }
      &.img_right {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
      }
    }
  }
  .theme_blue_3 {
    line-height: 1.5;
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.4rem 1rem;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 3rem;
    font-weight: 300;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_blue_4 {
    line-height: 1.65;
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.4rem 1rem;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 0.5rem;
    font-weight: 600;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_blue_5 {
    line-height: 1.7;
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.6rem 3rem;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 3rem;
    font-weight: 300;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_blue_6 {
    line-height: 1.5;
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.6rem 1rem;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 3px;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_blue_7 {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.8rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_sky {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.BLUE_7};
    padding: 0.2rem 0;
    border: 3px solid ${({ theme }) => theme.COLORS.BLUE_8};
    border-radius: 0.5rem;
    font-weight: 500;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
    }
    .img_center {
      text-align: center;
    }
  }
  .theme_sky_2 {
    height: 42.59px;
    background: ${({ theme }) => theme.COLORS.GREEN_2};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_sky_3 {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.BLUE_7};
    padding: 0.5rem 1.5rem;
    border: 3px solid ${({ theme }) => theme.COLORS.BLUE_8};
    border-radius: 0.3rem;
    font-weight: 500;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_sky_4 {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.BLUE_7};
    padding: 0.5rem 1.5rem;
    border: 3px solid ${({ theme }) => theme.COLORS.BLUE_8};
    border-radius: 3rem;
    font-weight: 500;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_gray {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.GRAY_2};
    padding: 0.3rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_2};
    border-radius: 0.3rem;
    font-weight: 300;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
    }
    .img_center {
      text-align: center;
    }
  }
  .theme_red {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.RED_4};
    padding: 0.3rem 0;
    // border: 3px solid ${({ theme }) => theme.COLORS.RED_4};
    border-radius: 3rem;
    font-weight: 500;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
    }
    .img_center {
      text-align: center;
    }
  }
  .theme_red_2 {
    /* line-height:1.5; */
    background: ${({ theme }) => theme.COLORS.RED_4};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.RED_4};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 20px;
      height: 20px;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_outline_red {
    line-height: 1.8;
    //background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.3rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.RED_4};
    border-radius: 3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.RED_4};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_outline_red_2 {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 0.6rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.RED_5};
    border-radius: 0.3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.RED_5};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_outline_red_3 {
    line-height: 1.8;
    //background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.5rem 3rem;
    border: 1px solid ${({ theme }) => theme.COLORS.RED_4};
    border-radius: 3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.RED_4};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_outline_blue {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 0.3rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 0.3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.BLUE_3};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_outline_blue_2 {
    line-height: 1.5;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 0.4rem 1rem;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_3};
    border-radius: 3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.BLUE_3};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_outline_gray {
    line-height: 1.8;
    //background: ${({ theme }) => theme.COLORS.BLUE_3};
    padding: 0.3rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_2};
    border-radius: 0.3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_transparent {
    /* line-height:1.5; */
    background: transparent;
    padding: 0.6rem 0;
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.8rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_disable {
    height: 42.59px;
    background: ${({ theme }) => theme.COLORS.GRAY_3};
    border-radius: 0.5rem;
    font-weight: bold;
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.BLACK_4};
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      &.img_center {
        text-align: center;
      }
    }
  }

  .theme_disable_2 {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.GRAY_3};
    //opacity:0.5;
    padding: 0.3rem 0;
    border-radius: 3rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.BLACK_4};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }
  .theme_disable_3 {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.GRAY_3};
    //opacity:0.5;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    font-weight: 300;
    color: ${({ theme }) => theme.COLORS.BLACK_4};
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
      &.img_center {
        text-align: center;
      }
    }
  }

  .theme_back {
    z-index: -1;
    padding: 0.3rem 0;
    width: 7rem;
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    text-align: left;
    cursor: pointer;
    position: relative;
    padding-left: 1.7rem;
    padding-top: 0.45rem;
    img {
      margin-right: 0.3rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .home_page {
    line-height: 1.8;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 0.3rem 0;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_2};
    border-radius: 1rem;
    font-weight: 300;
    width: 100%;
    color: ${({ theme }) => theme.COLORS.BLUE_3};
    text-align: center;
    cursor: pointer;
    img {
      margin-right: 0.3rem;
    }
    .img_center {
      text-align: center;
    }
  }
  .theme_transparent_2 {
    background: ${({ theme }) => theme.COLORS.BLACK_2};
    border: 1px solid ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    padding: 0;
    position: relative;
    cursor: pointer;
    img {
      width: 2.5rem;
      position: absolute;
      top: 50%;
      right: 0;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .theme_green {
    background: ${({ theme }) => theme.COLORS.GREEN_2};
    border: 1px solid ${({ theme }) => theme.COLORS.GREEN_2};
    border-radius: 0.5rem;
    font-weight: bold;
    padding: 0.3rem 0;
    width: 100%;
    font-size: 15px;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      align-items: center;
      margin-right: 0.5rem;
      width: 0.7rem;
      &.img_center {
        text-align: center;
      }
    }
    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s, visibility 0.3s;
    }
    &:active {
      transform: translateY(1px);
    }
  }
`
