import { iconImages } from "assets"
import styled from "styled-components"
 
export const InputDateRangeSelectStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        select {
            cursor: pointer;
            outline: none;
            width : 100%;
            max-width : ${props => props.maxWidth ? props.maxWidth : '100%'};
            min-width : ${props => props.minWidth ? props.minWidth : '100%'};
            -webkit-appearance: none;
            -moz-appearance: none;
            /* border: 1px solid rgb(0,0,0,0.1); */
            border : none;
            font-weight: 600;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            background-image: url(${iconImages['dropdown_down.svg']});
            background-position: center right 0;
            background-repeat: no-repeat;
            background-size: 0.8rem;
            border-radius: 3px;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height:1;
            text-align : right;
            font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            /* padding: 0.3rem 1.85rem 0.3rem .5rem;  */
            padding-right : 1.3rem;
            span {
                margin-left: 10px;
            }
        }
        select:focus {
            outline: none;
        }
    }
`