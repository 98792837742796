import styled from 'styled-components'
import {iconImages} from 'assets'
export const InputSelectStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    position: relative;
    img {
      width: 14px;
      height: 14px;
      position : absolute;
      top: 50%;
      transform : translateY(-50%);
      right : 0.7rem;
      cursor: pointer;
    }
    
    /* .line{
      position : absolute;
      border-left: 1px solid rgb(0,0,0,0.2);
      height: 46px;
      right : 2.4rem;
    } */
    .readonly{
      line-height : 45.58px;
      align-items : center;
      padding-top: 0.1rem;
      text-transform: capitalize;
    }
    select {
      cursor: pointer;
      outline: none;
      width : 100%;
      max-width : ${props => props.maxWidth ? props.maxWidth : '100%'};
      min-width : ${props => props.minWidth ? props.minWidth : '100%'};
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid rgb(0,0,0,0.2);
      font-weight: 300;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      background-image: url(${iconImages['dropdown_down.svg']});
      background-position: center right 0.85rem;
      background-repeat: no-repeat;
      background-size: 1rem;
      border-radius: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      height:45.59px;
      font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
      border-radius: 0.5rem;  
      font-size:${({ theme }) => theme.FONTS.SIZE_16px};
      padding: 0.3rem 1.25rem; 
      span {
          margin-left: 10px;
      }
    }
    select:focus {
      outline: none;
    }
  }
  .theme_standard_2 {
    position: relative;
    img {
      width: 14px;
      height: 14px;
      position : absolute;
      top: 50%;
      transform : translateY(-50%);
      right : 0.7rem;
      cursor: pointer;
    }
    /* .line{
      position : absolute;
      border-left: 1px solid rgb(0,0,0,0.2);
      height: 46px;
      right : 2.4rem;
    } */
    .readonly{
      line-height : 45.58px;
      align-items : center;
      padding-top: 0.1rem;
      text-transform: capitalize;
    }
    select {
      cursor: pointer;
      outline: none;
      width : 100%;
      max-width : ${props => props.maxWidth ? props.maxWidth : '100%'};
      min-width : ${props => props.minWidth ? props.minWidth : '100%'};

      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid rgb(0,0,0,0.1);
      font-weight: 300;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      background-image: url(${iconImages['dropdown_down.svg']});
      background-position: center right 0.85rem;
      background-repeat: no-repeat;
      background-size: 1rem;
      border-radius: 3px;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height:1.5;
      font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
      font-size:${({ theme }) => theme.FONTS.SIZE_16px};
      padding: 0.6rem 2.5rem 0.6rem 1.25rem; 
      span {
          margin-left: 10px;
      }
    }
    select:focus {
      outline: none;
    }
  }
  .theme_standard_3 {
    z-index:0;
    position: relative;
    img {
      width: 14px;
      height: 14px;
      position : absolute;
      top: 50%;
      transform : translateY(-50%);
      right : 0.7rem;
      cursor: pointer;
    }
    /* .line{
      position : absolute;
      border-left: 1px solid rgb(0,0,0,0.2);
      height: 46px;
      right : 2.4rem;
    } */
    .readonly{
      line-height : 45.58px;
      align-items : center;
      padding-top: 0.1rem;
      text-transform: capitalize;
    }
    select {
      cursor: pointer;
      outline: none;
      width : 100%;
      max-width : ${props => props.maxWidth ? props.maxWidth : '100%'};
      min-width : ${props => props.minWidth ? props.minWidth : '100%'};

      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid rgb(0,0,0,0.1);
      font-weight: 300;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      background-image: url(${iconImages['dropdown_down.svg']});
      background-position: center right 0.85rem;
      background-repeat: no-repeat;
      background-size: 1rem;
      border-radius: 3px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
      font-size:${({ theme }) => theme.FONTS.SIZE_14px};
      padding: 0.4rem 2.5rem 0.4rem 1.25rem; 
      span {
          margin-left: 10px;
      }
    }
    select:focus {
      outline: none;
    }
  }
  .theme_standard_4{
    select {
        cursor: pointer;
        outline: none;
        width : 100%;
        max-width : ${props => props.maxWidth ? props.maxWidth : '100%'};
        min-width : ${props => props.minWidth ? props.minWidth : '100%'};
        -webkit-appearance: none;
        -moz-appearance: none;
        /* border: 1px solid rgb(0,0,0,0.1); */
        border : none;
        font-weight: 300;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        background-image: url(${iconImages['dropdown_down.svg']});
        background-position: center right 0;
        background-repeat: no-repeat;
        background-size: 0.8rem;
        border-radius: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height:1;
        text-align : right;
        font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        /* padding: 0.3rem 1.85rem 0.3rem .5rem;  */
        padding-right : 1.3rem;
        span {
            margin-left: 10px;
        }
    }
    select:focus {
        outline: none;
    }
  }
  .theme_outline {
    position: relative;
    font-family : noto_sans_thai_light;
    img {
      width: 14px;
      height: 14px;
      position : absolute;
      top: 50%;
      transform : translateY(-50%);
      right : 0.7rem;
      cursor: pointer;
    }
    .line{
      position : absolute;
      border-left: 1px solid rgb(0,0,0,0.5);
      height: 99%;
      right : 2.2rem;
    }
    select {
      cursor: pointer;
      outline: none;
      width : 100%;
      height: 100%;
      text-align-last: center;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid rgb(0,0,0,0.5);
      font-weight: bold;
      font-size: ${({ theme }) => theme.FONTS.SIZE_3};
      color: rgb(0,0,0,0.9);
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      background-image: url(${iconImages['dropdown_down.svg']});
      background-position: center right 0.8rem;
      background-repeat: no-repeat;
      background-size: 0.7rem;
      border-radius: 3px;
      padding: 10px 55px 10px 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
          /* margin-left: 20px; */
      }
    }
    select:focus {
      outline: none;
    }
  }
  .theme_standard_5 {
        select {
            cursor: pointer;
            outline: none;
            width : 100%;
            max-width : ${props => props.maxWidth ? props.maxWidth : '100%'};
            min-width : ${props => props.minWidth ? props.minWidth : '100%'};
            -webkit-appearance: none;
            -moz-appearance: none;
            /* border: 1px solid rgb(0,0,0,0.1); */
            border : none;
            font-weight: 600;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            background-image: url(${iconImages['dropdown_down.svg']});
            background-position: center right 0;
            background-repeat: no-repeat;
            background-size: 0.8rem;
            border-radius: 3px;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height:2;
            text-align : right;
            font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            /* padding: 0.3rem 1.85rem 0.3rem .5rem;  */
            padding-right : 1.3rem;
            span {
                margin-left: 10px;
            }
        }
        select:focus {
            outline: none;
        }
    }

    .theme_standard_6 {
    /* z-index:1; */
    position: relative;
    img {
      width: 14px;
      height: 14px;
      position : absolute;
      top: 50%;
      transform : translateY(-50%);
      right : 0.7rem;
      cursor: pointer;
    }
    /* .line{
      position : absolute;
      border-left: 1px solid rgb(0,0,0,0.2);
      height: 46px;
      right : 2.4rem;
    } */
    .readonly{
      line-height : 45.58px;
      align-items : center;
      padding-top: 0.1rem;
      text-transform: capitalize;
    }
    select {
      cursor: pointer;
      outline: none;
      width : 100%;
      max-width : ${props => props.maxWidth ? props.maxWidth : '100%'};
      min-width : ${props => props.minWidth ? props.minWidth : '100%'};

      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid rgb(0,0,0,0.1);
      font-weight: 300;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      background-image: url(${iconImages['dropdown_down.svg']});
      background-position: center right 0.85rem;
      background-repeat: no-repeat;
      background-size: 1rem;
      border-radius: 3px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
      font-size:${({ theme }) => theme.FONTS.SIZE_14px};
      padding: 0.4rem 2.5rem 0.4rem 1.25rem; 
      span {
          margin-left: 10px;
      }
    }
    select:focus {
      outline: none;
    }
  }
`
