import styled from "styled-components"
 
export const HomeContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
 
`