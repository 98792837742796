import styled from "styled-components"
 
export const InstitutionProfileContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .container_custom{
        margin-right: auto;
        margin-left: auto;
        max-width : 60rem;
        box-sizing : border-box;
        overflow : hidden;
    }
    @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
        .back{
            margin-left : 3rem;
            margin-bottom : 1rem;
        }
    }
`