export const ROUTE_PATH = {
  HOME: '/',
  LOGIN: '/login',
  SETTINGS: '/settings',
  SETTINGS_DETAIL: '/settings/',
  USER_ACCOUNT: '/user-account/',
  USER_ADMIN: '/user-admin/',
  USER_ROLE: '/user-role/',
  INSTITUTION_PROFILE: '/settings/institution-profile/',
  CLINIC_PROFILE: '/clinic-profile/',
  HISTORY: '/history',
  ASSESSMENT: '/assessment/',
  DASHBOARD: '/dashboard',
  NOT_FOUND: '/404',
}

export const ROLES = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
}

export const VALIDATE = {
  REQUIRE_FIELD: (value, allValues) =>
    value || typeof value === 'number' ? undefined : 'This field is required. ',
  REQUIRE: (value) =>
    value || typeof value === 'number' ? undefined : 'Required',
  NUMBER: (value) =>
    value
      ? isNaN(Number(value))
        ? 'Must be a number'
        : undefined
      : 'Must be a number',
  ALPHA_NUMBERIC: (value) =>
    value && !/^\w+$/i.test(value)
      ? 'Only alphanumeric characters (letters and numbers)'
      : undefined,
  LENGTH_4_32: (value) =>
    value
      ? value.length < 4 || value.length > 32
        ? `Must be between 4 and 32 characters.`
        : undefined
      : undefined,
  NUMBER_ONLY: (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined,
  EMAIL: (value) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'email invalid'
      : undefined,
  MIN_LENGTH: (value) =>
    value
      ? value.length < 6
        ? `Must be 6 characters or more`
        : undefined
      : `Must be 6 characters or more`,
  MIN_PW_LENGTH: (value) =>
    value
      ? value.length < 8
        ? `Must be 8 characters or more`
        : undefined
      : `Must be 8 characters or more`,
  PASSWORD_MATCH: (value, allValues) =>
    value !== allValues.password ? "Passwords don't match" : undefined,
  NUMBER_LENGTH: (value) =>
    value
      ? parseInt(value) > 99
        ? 'Must be 0-99 number'
        : undefined
      : 'Must be 0-99 number',
  NUMBER_NEGATIVE: (value) =>
    value
      ? parseInt(value) < 0
        ? 'Number can not be negative'
        : undefined
      : 'Number can not be negative',
  NUMBER_MAX_LENGTH_128: (value) =>
    value && value.length > 127
      ? 'Must be less than 128 characters'
      : undefined,
  NUMBER_MAX_LENGTH_255: (value) =>
    value && value.length > 255
      ? 'Must be less than 255 characters'
      : undefined,
  NUMBER_MAX_LENGTH_500: (value) =>
    value && value.length > 500
      ? 'Must be less than 500 characters'
      : undefined,
  NUMBER_MAX_LENGTH_512: (value) =>
    value && value.length > 512
      ? 'Must be less than 255 characters'
      : undefined,
  NUMBER_MAX_LENGTH_2000: (value) =>
    value && value.length > 2000
      ? 'Must be less than 2000 characters'
      : undefined,
  PASSWORD_LENGTH: (value) =>
    value && value.length < 5 && value.length > 255
      ? 'Must be 4-255 characters'
      : undefined,
  PHONE_NUMBER_LENGTH: (value) =>
    value && value.length > 10 ? 'Must be less than 10 characters' : undefined,
  PASSWORD_MIN_LENGTH: (value) =>
    value && value.length <= 5
      ? 'Password must be more than 6 characters'
      : undefined,
  USER_MIN_LENGTH: (value) =>
    value && value.length <= 3 ? 'Must be more than 3 characters' : undefined,
  MAX_VALUE: (value) =>
    value && parseInt(value) > 999999
      ? 'The cost thb must be between 0 and 999999'
      : undefined,
  AT_LEAST_ONE_NUMBER: (value) =>
    value && !/[0-9]/i.test(value) ? `Number is require` : undefined,
  PASSWORD_REGEX: (value) =>
    value && !/^(?=.*[0-9])(?=.*[A-Z]).{8,}$/.test(value)
      ? `Please Correctly password format`
      : undefined,
  URL_REGEX: (value) =>
    value &&
    !/\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/.test(
      value,
    )
      ? `Please Correctly URL format`
      : undefined,
}

export const IMAGE_PATH = 'http://localhost:8089/static/'
// export const IMAGE_PATH = "https://backend.test.com/static/"

export const permission = [
  {
    role: 'system_admin',
    can_active_clinic: true,
  },
  {
    role: 'account_admin',
    can_active_clinic: true,
  },
]
