export const USER_AUTHEN = 'USER_AUTHEN';
export const DESTROY_SESSION = 'DESTROY_SESSION';
export const TAB_ACTIVE = 'TAB_ACTIVE';
export const INSTITUTIONS = 'INSTITUTIONS';
export const INSTITUTION_DETAIL = 'INSTITUTION_DETAIL';
export const USER_PROFILE = 'USER_PROFILE';
export const CLINIC_PROFILE = 'CLINIC_PROFILE';
export const CLINICS = 'CLINICS';
export const INSTITUTION_ACTIVE = 'INSTITUTION_ACTIVE';
export const CLINIC_ACTIVE = 'CLINIC_ACTIVE';
export const THEMEPRIVACY = 'THEMEPRIVACY';
export const UPDATE_TERMS_EN = 'UPDATE_TERMS_EN';
export const UPDATE_TERMS_TH = 'UPDATE_TERMS_TH';

export const SET_PERMISSION_FEATURES = 'SET_PERMISSION_FEATURES';
export const CLEAR_PERMISSION_FEATURES = 'CLEAR_PERMISSION_FEATURES';













