import React, { useEffect, useState } from "react";
import { InputSelectFilterStyled } from "./styled";
import Select from "react-select";
import 'bootstrap/dist/css/bootstrap.min.css';
import { iconImages } from "assets";
import { RxCross2 } from "react-icons/rx";
import cx from 'classnames';

export const InputSelectFilter = ({
    options,
    profile,
    change,
    disabled,
    label,
    clear,
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
})=>{

    const customClass = cx({
        theme_standard: theme_standard,
        theme_standard_2: theme_standard_2,
    });

    const [clearActive, setClearActive] = useState(false);
    const [disableActive, setDisableActive] = useState(false);
    const [ValueActive, setValueActive] = useState('');
    useEffect(() => {
        if (ValueActive.length > 0 ) {
            setClearActive(true);
        } else {
            setClearActive(false);
        }
    }, [ValueActive]);
    
    useEffect(() => {
        if (profile) {
            setValueActive(profile)
            change(profile);
        }
        if(disabled){
            setDisableActive(true);
        }
    }, [profile,disabled]);

    function handleChangeOption(e) {
        let params = [];
        const value = e?.target?.value;
        setValueActive(value);
        params.code = value;
        change(params);
    }
    function handleClear() {
        let params = [];
        params.code = '';
        change(params);
        setValueActive('');
        setDisableActive(false);
    }
    
    return(
        <InputSelectFilterStyled>
            <div className={customClass}>
                <div className="box_container">
                    <div className="input_container">
                        <label>
                            <div className="label_text">{label}</div>
                            {/* <img src={iconImages['arrow_down_yellow.svg']}/> */}
                        </label>
                        <input
                            className={`${disableActive ? 'disable':''}`}
                            value={ValueActive}
                            disabled={disableActive}
                            onChange={(e)=>{handleChangeOption(e)}}
                            type="text"
                        />
                        {
                            clear &&<div onClick={()=>handleClear()} className={`clear_btn ${clearActive ? "active":""}`}>
                                        ลบ<RxCross2/>
                                    </div>
                        }
                        
                    </div>
                </div>
            </div>
        </InputSelectFilterStyled>
    )
}