import React from 'react';
import { connect } from 'react-redux';
import { DashboardContainerStyled, HighLightStyled } from './styled';
import { setReduxUserAuth, setReduxInstitutions, setReduxClinics, setReduxInstitutionActive, setReduxClinicActive } from 'store/actions';
import {
  BoxCustom,
  Typography,
  TabCustom,
  InputSelect
} from 'components';
import theme from 'assets/theme.json'
import { DashboardActive, DashboardProvider, DashboardPatient, DashboardHealthCheck } from 'widgets';
import { userService } from 'apiService';
import moment from 'moment'

const BoxHighlight = ({ title, data }) => {
  return <BoxCustom
    theme_standard_box_custom_3
    child={
      <HighLightStyled>
        <div className='title'>
          {title}
        </div>
        <div className='detail'>
          {data.values}
        </div>
        <div className='desc'>
          {data.increase && <div><span style={{ color: 'green', fontWeight: 'bold' }}>{data.increase}</span> vs prev 28 days</div>}
          {data.decrease && <div><span style={{ color: 'red', fontWeight: 'bold' }}>{data.decrease}</span> vs prev 28 days</div>}
        </div>

      </HighLightStyled>
    }
  />
}
class DashboardContainer extends React.Component {
  state = {
    institution_id: '',
    valuesOfId: {
      institution_id: '',
      clinic_id: 'show_all',
    },
    no_clinic: false
  }
  componentDidMount = async () => {
    const firstLoad = async (onfirstload) => {
      const setValues = (id) => {
        this.setState({ institution_id: id })
        this.beforeToFetch(id, '', onfirstload)
      }
      const { role, } = this.props.reduxAuth
      const { reduxInstitutionActive, reduxInstitutions } = this.props;
      const institutions = role === 'system_admin'
        ? reduxInstitutions ? reduxInstitutions : await this.fetchAccounts()
        : reduxInstitutionActive && [{ id: reduxInstitutionActive }]
      if (institutions) {
        let id = reduxInstitutionActive ? reduxInstitutionActive : institutions[0].id
        setValues(id)
      } else {
      }
    }

    firstLoad({ onfirstload: true })
    this.interval = setInterval(() => {
      firstLoad({ onreload: true })
    }, 60000);
  }

  componentWillUnmount = () => {
    const { setReduxClinics } = this.props
    setReduxClinics(false)
    clearInterval(this.interval);
  }

  beforeToFetch = async (institution_id, clinic_id, onload) => {
    const { setReduxInstitutionActive } = this.props;
    const { valuesOfId } = this.state
    setReduxInstitutionActive(institution_id)
    const clinics = clinic_id
      ? [{ id: clinic_id }]
      : await this.fetchClinics(institution_id)
    this.setState({
      valuesOfId: {
        institution_id: institution_id,
        clinic_id: !onload
          ? clinics[0]?.id
          : onload?.onreload
            ? valuesOfId.clinic_id
            : 'show_all'
        ,
      }
    })
    // clinics && this.fetchData(clinics[0].id, begin_date, end_date)
  }

  fetchClinics = async (institution_id) => {
    this.setState({ loadingClinic: true })
    const { setReduxClinics } = this.props;
    let res = await userService.GET_CLINICS_BY_ACCOUNT(institution_id)
    if (res && res.success) {
      if (res.data?.length > 0) {
        setReduxClinics(this.setNewClinics(res.data))
        this.setState({
          no_clinic: false,
          loadingClinic: false
        })
        return res.data
      } else {
        this.setState({
          no_clinic: true,
          loadingClinic: false
        })
      }
    } else {
    }
  }
  fetchAccounts = async () => {
    const { setReduxInstitutions } = this.props;
    let res = await userService.GET_ACCOUNTS()
    if (res && res.success) {
      this.setState({ institutions: this.setNewtInstitutions(res.data) })
      setReduxInstitutions(this.setNewtInstitutions(res.data))
      return res.data
    } else {
    }
  }
  setNewClinics = (data) => {
    return data && data.map(item => {
      return {
        ...item,
        name: `${item.name} (${item.description})`
      }
    })
  }
  setNewtInstitutions = (data) => {
    return data && data.map(item => {
      return {
        ...item,
        name: `${item.name}`,
        created_at: item.created_at ? moment(item.created_at).format('DD MMM YYYY') : '-'
      }
    })
  }

  renderOptions = () => {
    const { valuesOfId } = this.state
    const { reduxClinics } = this.props;
    const showall = [{ id: 'show_all', name: 'แสดงทั้งหมด' }]
    const clinics = reduxClinics ? [...showall, ...reduxClinics] : showall
    return clinics && clinics
      .map((clinic, i) => {
        return <option key={i} value={clinic.id} selected={clinic.id === valuesOfId.clinic_id} >{clinic.name}</option>
      })
  }
  renderInstitutionOptions = () => {
    const { reduxInstitutions, reduxInstitutionActive } = this.props;
    return reduxInstitutions && reduxInstitutions.map((institution, i) => {
      return <option key={i} value={institution.id} selected={reduxInstitutionActive === institution.id} >{institution.name}</option>
    })
  }
  handleChangeInsitution = (e) => {
    const { setReduxClinics } = this.props;
    const id = parseInt(e.target.value)
    setReduxClinics(false)
    this.beforeToFetch(id, '', { isShowAll: true })
  }

  handleChangeClinic = (e) => {
    const { valuesOfId } = this.state
    const id = e.target.value
    this.beforeToFetch(valuesOfId.institution_id, id)
  }

  render() {
    const { role } = this.props.reduxAuth;
    const { valuesOfId, no_clinic } = this.state
    return <DashboardContainerStyled>
      <div style={{ marginBottom: '1rem' }}></div>
      <div className='header_title' style={{ display: 'flex', justifyContent: "space-between", alignItems: 'flex-start' }}>
        <Typography
          label='Dashboard'
          size={theme.FONTS.SIZE_34px}
          color={theme.COLORS.PURPLE_1}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div >
            {role !== 'account_admin' &&
              <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                <span style={{ marginRight: '1rem', width: '100px' }}>Insitution : </span>
                <InputSelect
                  theme_standard_3
                  name='institution'
                  maxWidth='300px'
                  minWidth='300px'
                  onChange={this.handleChangeInsitution}
                >
                  {this.renderInstitutionOptions()}
                </InputSelect>
              </div>
            }
            {!no_clinic &&
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem', textAlign: 'right' }}>
                <span style={{ marginRight: '1rem', width: '100px' }}>Clinic : </span>
                <InputSelect
                  theme_standard_3
                  name='institution'
                  maxWidth='300px'
                  minWidth='300px'
                  onChange={this.handleChangeClinic}
                >
                  {this.renderOptions()}
                </InputSelect>
              </div>
            }
          </div>
        </div>
      </div>
      <div style={{ marginTop: '1.5rem', marginBottom: '1.4rem' }}>
        <TabCustom
          theme_standard
          tabLists={tabLists(valuesOfId)}
        />
      </div>
    </DashboardContainerStyled >;
  }
}

const data = [
  {
    "id": 4,
    "code": "udchopd",
    "name": "OPD",
    "description": "แผนกผู้ป่วยนอก",
    "provider_count": 20,
    "patient_count": 8,
    "total_visit_count": 8,
    "new_visit_count": 2,
    "waiting_holding_visit_count": 4,
    "calling_visit_count": 2,
    "finishing_visit_count": 0,
    "other_visit_count": 0
  },
  {
    "id": 4,
    "code": "udchgyn",
    "name": "Wellness",
    "description": "ศูนย์เฉพาะทางความงาม",
    "provider_count": 20,
    "patient_count": 10,
    "total_visit_count": 0,
    "new_visit_count": 6,
    "waiting_holding_visit_count": 0,
    "calling_visit_count": 0,
    "finishing_visit_count": 0,
    "other_visit_count": 0
  },
  {
    "id": 5,
    "code": "udchgyn",
    "name": "Gynecologic Oncology",
    "description": "ศูนย์เฉพาะทางมะเร็งนรีเวช",
    "provider_count": 20,
    "patient_count": 10,
    "total_visit_count": 0,
    "new_visit_count": 0,
    "waiting_holding_visit_count": 0,
    "calling_visit_count": 0,
    "finishing_visit_count": 0,
    "other_visit_count": 0
  }
]

const tabLists = (valuesOfId) => [
  {
    id: 0,
    permission: true,
    value: 'active',
    name: 'Active',
    isShow: true,
    components: <DashboardActive valuesOfId={valuesOfId}></DashboardActive>
  },
  {
    id: 1,
    permission: true,
    value: 'provider',
    name: 'Provider',
    isShow: true,
    components: <DashboardProvider valuesOfId={valuesOfId}></DashboardProvider>
  },
  {
    id: 2,
    permission: true,
    value: 'patient',
    name: 'Patient',
    isShow: true,
    components: <DashboardPatient valuesOfId={valuesOfId}></DashboardPatient>
  },
  {
    id: 3,
    permission: true,
    value: 'health_check',
    name: 'Health Check',
    isShow: true,
    components: <DashboardHealthCheck valuesOfId={valuesOfId}></DashboardHealthCheck>
  },
]

const mapStateToProps = (state) => ({
  reduxAuth: state.auth,
  reduxInstitutions: state.institutions,
  reduxClinics: state.clinics,
  reduxInstitutionActive: state.institution_active,
  reduxClincActive: state.clinic_active
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxInstitutions: (data) => dispatch(setReduxInstitutions(data)),
  setReduxClinics: (data) => dispatch(setReduxClinics(data)),
  setReduxInstitutionActive: (data) => dispatch(setReduxInstitutionActive(data)),
  setReduxClinicActive: (data) => dispatch(setReduxClinicActive(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
