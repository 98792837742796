import styled from "styled-components";


export const SelectRoleStyles = styled.div`
/*===============================================
 Container 
===============================================*/
    /* height: 600px; */
    .loading{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:${({theme})=> theme.COLORS.WHITE_1}; */
        /* border-radius:10px; */
    }
    .bodySection{
        /* margin-top: 100px; */
        display: flex;
        justify-content: center;
        /* background-color:red; */
        width: 100%;
        height:100%;
        
    }
    .body_item{
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color:blue; */
        /* max-width: 880px: */ //Tee long
        /* width: 900px; */
        
    }
    .item_grid_container{
        display: grid;
        grid-template-rows: repeat(4,1fr);
        row-gap: 2rem;
        width: 880px;
    }
    .item_title{
        display: flex;
        justify-content: flex-start;
        align-items: end;
        .title{
            font-size: ${({theme})=> theme.FONTS.SIZE_21px};
            color: ${({theme})=> theme.COLORS.BLUE_17}
        }
        .description{
            font-size: ${({theme})=> theme.FONTS.SIZE_16px};
        }
    }
    .item{
        width: 100%;
        height: 100px;
        background-color:${({theme})=> theme.COLORS.WHITE_1};
        border-radius:10px;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 10% 70% 20%;
        .icons_container{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .text_container{
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .btn_container{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

/*==========================
Theme 
===============================================*/
@media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
        .back{
            margin-left : 3rem;
            margin-bottom : 1rem;
        }
    }


`