import React, { useEffect, useState } from 'react'
import { ToggleSwitchStyled } from './styled'
import cx from 'classnames'

export const ToggleSwitch = (props) => {
  const { checked, input } = props
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (checked) {
      setValue(checked)
    }
  }, [checked])

  const handleChange = (e) => {
    // console.log('handleChange', e.target.checked)
    setValue(e.target.checked)
  }

  return (
    <ToggleSwitchStyled>
      <label class='switch'>
        <input
          // type='checkbox'
          {...props}
          {...input}
          onChange={(e) => input?.onChange(handleChange(e))}
          checked={value}
        />
        <span class='slider round'></span>
      </label>
    </ToggleSwitchStyled>
  )
}
