import styled from "styled-components"
export const Box = styled.div`
  .box_container {
        position:relative;
        background-color: ${({ theme }) => theme.COLORS.BLUE_9};
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        display: inline-block;
        &.active{
            background-color: ${({ theme }) => theme.COLORS.BLUE_3};
            color : ${({ theme }) => theme.COLORS.WHITE_1};
        }
        .number{
            position:absolute;
            margin: auto;
            top: 50%;  
            left: 50%; 
            transform: translate(-50%, -50%); 
            text-align: center;
            width:1rem;
        }
        .label{
            position:absolute;
            margin: auto;
            top:130%;
            left: 50%; 
            bottom:0;
            transform: translate(-50%, -50%); 
            text-align: center;
            opacity:0.4;
            color: ${({ theme }) => theme.COLORS.BLACK_3};
            font-weight:300;
            font-size: ${({ theme }) => theme.FONTS.SIZE_12px};
        }
    }
`
export const StepperStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        .tab_container{
            // width:100%;
            color:${({ theme }) => theme.COLORS.BLUE_4};
            margin-left : -12.4rem;
            ul{
                width:70%;
                display:flex;
                justify-content:flex-start;
                li{
                    list-style-type: none;
                    float: left;
                    width: 100%; 
                    position: relative;
                    text-align: center;
                    .title{
                        color: ${({ theme }) => theme.COLORS.BLUE_3};
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        width: 60%;
                        height: 3px;
                        background-color: ${({ theme }) => theme.COLORS.BLUE_4};
                        border: 1px solid ${({ theme }) => theme.COLORS.BLUE_4};
                        border-right:none;
                        border-radius:10rem; 
                        top: 20%;
                        left: -30%;
                        z-index: -1;
                    }
                    @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
                        &:after { 
                            /* top: 2.5rem; */
                            left: -30%;
                            z-index: -1;
                        }
                        .title{
                            font-size: ${({ theme }) => theme.FONTS.SIZE_12px};
                            padding-top:0.45rem;
                        }
                    }
                    &:first-child {
                        &:after{
                            display:none;
                            width:0;
                        }
                    }
                    &.active {
                        color: ${({ theme }) => theme.COLORS.BLUE_3};
                        &:before{
                            border-color: ${({ theme }) => theme.COLORS.BLUE_4};
                            background-color: ${({ theme }) => theme.COLORS.BLUE_4};
                        }
                        &:after {
                            background-color: ${({ theme }) => theme.COLORS.BLUE_3};
                        }
                    }
                }
            }
        }
    }
`