import { userService } from 'apiService';
import { BoxCustom, InputDatePicker, InputDateRangePicker } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  InputSelect
} from 'components'
import { DashboardHealthCheckStyled } from './styled';
import theme from 'assets/theme.json'
import {
  InputDateRangeSelect
} from 'widgets'
import {
  AreaLine,
  BarLine,
  Heatmap
} from 'chart'
import moment from 'moment'
import { Field, reduxForm } from 'redux-form';
import { getBeginDate, getCurrentDate, iconImages } from 'assets';
import { connect } from 'react-redux';
import { InputDateForm } from 'forms';
import InfiniteScroll from 'react-infinite-scroll-component';

export const DashboardHealthCheck = (props) => {
  const { valuesOfId } = props;

  const firstload = useRef(true)
  const defaultBeginDate = getBeginDate(7)
  const defaultTodayDate = getCurrentDate()

  const [dailyBeginDate, setDailyBeginDate] = useState(defaultBeginDate)
  const [dailyTodayDate, setDailyTodayDate] = useState(defaultTodayDate)

  const [period, setPeriod] = useState('hourly')
  const [dataHealthCheck, setDataHealthCheck] = useState()
  const [dataHealthError, setDataHealthError] = useState([])
  const [page, setPage] = useState()
  const [sort, setSort] = useState()


  useEffect(() => {
    if (valuesOfId?.institution_id && firstload.current) {
      firstload.current = false
      fetchHealthError(valuesOfId.institution_id)
    }
  }, [valuesOfId])

  useEffect(() => {
    if (valuesOfId?.institution_id) {
      fetchHealthStatus(valuesOfId.institution_id)
    }
  }, [valuesOfId, period, dailyBeginDate])


  const fetchHealthStatus = async (id, parameter) => {
    const today_date = parameter?.todayDate ? parameter?.todayDate : dailyTodayDate
    const begin_date = period === 'hourly'
      ? parameter?.todayDate ? parameter?.todayDate : dailyTodayDate
      : parameter?.beginDate ? parameter?.beginDate : dailyBeginDate
    let res = await userService.GET_DASHBOARD_HEALTH_STATUS(id, period, begin_date, today_date)
    if (res?.success) {
      switch (period) {
        case 'hourly':
          setDataHourly(res.data)
          break;
        case 'daily':
          setDataDaily(res.data, begin_date, today_date)
          break;
        default:
          break
      }
    }
  }

  const fetchHealthError = async (id, params) => {
    let per_page = params?.per_page || 20;
    let offset = params?.offset || 0;
    let res = await userService.GET_DASHBOARD_HEALTH_ERROR(id, per_page, offset)
    if (res?.success) {
      if (params?.append) {
        setDataHealthError([...dataHealthError, ...res.data])
      } else {
        setDataHealthError(res.data)
      }
      // setDataHealthError([...res.data, ...res.data, ...res.data, ...res.data, ...res.data, ...res.data, ...res.data])
      setPage(res.page)
      setSort(res.sort)
    } else {
      setDataHealthError([])
      setPage()
      setSort()
    }
  }
  const responseTime = [
    { name: 'Max', key: 'max', type: 'area', show: false, responseTime: true },
    { name: 'Avg', key: 'avg', type: 'area', show: false, responseTime: true },
    { name: 'Min', key: 'min', type: 'area', show: false, responseTime: true },
  ]
  const successRate = [
    { name: 'Success', key: 'success', type: 'area', successRate: true },
    { name: 'Fail', key: 'fail', type: 'area', show: false, successRate: true },
  ]
  const setDataDaily = (data, begin, end) => {
    const dates = getDates(begin, end)
    let categories = dates?.map((date) => {
      return moment.utc(date).utcOffset("+0700").format('DD MMM')
    })

    let findData = dates?.reduce((result, date, i) => {
      let findItem = data?.find((item => {
        return moment.utc(item?.healthcheck_datetime).utcOffset("+0700").format('YYYY-MM-DD') === date
      }))
      result?.min.push(Math.round(findItem?.min_time_ms) || 0)
      result?.max.push(Math.round(findItem?.max_time_ms) || 0)
      result?.avg.push(Math.round(findItem?.avg_time_ms) || 0)
      result?.success.push(Math.round(findItem?.success_percent) || 0)
      result?.fail.push(Math.round(findItem?.fail_percent) || 0)
      return result
    },
      { min: [], max: [], avg: [], success: [], fail: [] }
    )

    let seriesTime = responseTime?.map((item) => {
      return {
        ...item,
        data: findData[item?.key]
      }
    })
    let seriesRate = successRate?.map((item) => {
      return {
        ...item,
        data: findData[item?.key]
      }
    })
    setDataHealthCheck({ categories: categories, series: { seriesTime: seriesTime, seriesRate: seriesRate } })
  }


  const setDataHourly = (data) => {
    const hours = getHours()
    let categories = hours?.map((hour) => {
      return hour.render
    })

    let findData = hours?.reduce((result, hour, i) => {
      let findItem = data?.find((item => {
        return moment.utc(item?.healthcheck_datetime).utcOffset("+0700").format('HH') === hour.value
      }))
      result?.min.push(Math.round(findItem?.min_time_ms) || 0)
      result?.max.push(Math.round(findItem?.max_time_ms) || 0)
      result?.avg.push(Math.round(findItem?.avg_time_ms) || 0)
      result?.success.push(Math.round(findItem?.success_percent) || 0)
      result?.fail.push(Math.round(findItem?.fail_percent) || 0)
      return result
    },
      { min: [], max: [], avg: [], success: [], fail: [] }
    )
    let seriesTime = responseTime?.map((item) => {
      return {
        ...item,
        data: findData[item?.key]
      }
    })
    let seriesRate = successRate?.map((item) => {
      return {
        ...item,
        data: findData[item?.key]
      }
    })

    setDataHealthCheck({ categories: categories, series: { seriesTime: seriesTime, seriesRate: seriesRate } })
  }


  const _renderItemError = () => {
    return dataHealthError?.map((item, i) => {
      return <div className='health-error'>
        {/* <strong>{item?.status}</strong> */}
        <div className='health-error-date'>{moment.utc(item?.created_at).utcOffset("+0700").format('DD MMM YYYY, HH:mm:ss  ')}</div>
        <div className='health-error-label'>{item?.message}</div>
      </div>
    })
  }

  const handleChangeDate = (begin_date, end_date) => {
    setDailyBeginDate(begin_date)
    setDailyTodayDate(end_date)
  }
  const options = [
    { value: 'hourly', name: 'รายชั่วโมง' },
    { value: 'daily', name: 'รายวัน' },
  ]
  const renderSelectType = (chart) => {
    return (
      <InputDateForm
        mode={period === 'daily' ? 'range' : 'dropdown'}
        handleChangeDate={handleChangeDate}
      />
    )
  }
  const renderSelectDate = (chart) => {
    return (
      <InputSelect
        theme_standard_5
        onChange={(e) => {
          setPeriod(e.target.value),
            setDailyBeginDate(defaultBeginDate),
            setDailyTodayDate(defaultTodayDate)
        }}
      >
        {options?.map((item, i) => {
          return <option
            key={i}
            value={item.value}
            selected={period === item.value}
          >
            {item.name}
          </option>
        })}
      </InputSelect>
    )
  }
  const getDates = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  const getHours = () => {
    const items = [];
    moment.locale('en')
    new Array(24).fill().forEach((acc, index) => {
      items.push({
        render: moment({ hour: index }).format('hhA'), /// am pm
        value: moment({ hour: index }).format('HH') /// 24 hours
      });
      // items.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
    })
    moment.locale('th')
    return items;
  }

  const fetchData = () => {
    // console.log('fetchdata')
    let params = {
      per_page: 20,
      offset: page?.from - 1 + 20,
      append: true
    }
    fetchHealthError(valuesOfId?.institution_id, params)
    // setTimeout(() => {
    //   setDataHealthError([...dataHealthError, ...dataHealthError])
    // }, 1500);
  }
  return (
    <DashboardHealthCheckStyled {...props}>
      <div className=''>
        <div style={{ marginTop: '1rem', marginBottom: '0.25rem' }}>
          <Typography
            label='Statistics Health Check'
            size={theme.FONTS.SIZE_16px}
            color={theme.COLORS.PURPLE_1}
          />
        </div>
        <div className='row_custom'>
          <div className='col_custom_9'>
            <BoxCustom
              theme_standard_box_custom_title
              title={
                <>
                  <div style={{ marginTop: '0.5rem', marginRight: '.25rem', textTransform: 'capitalize' }}>Service Status</div>
                </>
              }
              title_right={
                <>
                  <div style={{ display: 'flex', marginTop: '0.4rem', alignItems: 'center' }}>
                    {renderSelectDate()}
                    {renderSelectType()}
                  </div>
                </>
              }
              child={
                <div style={{ padding: '1rem' }} >
                  {dataHealthCheck &&
                    <AreaLine
                      theme_standard
                      data={dataHealthCheck}
                    />
                  }
                </div>
              }
            />
          </div>
          <div className='col_custom_4_3'>
            <BoxCustom
              theme_standard_box_custom_title
              title={
                <>
                  <div style={{ marginTop: '0.5rem', marginRight: '.25rem', textTransform: 'capitalize' }}>Recent Service Failure</div>
                </>
              }
              child={
                <div className='' style={{}}>
                  <InfiniteScroll
                    className='items'
                    dataLength={dataHealthError?.length || 0} //This is important field to render the next data
                    hasMore={page?.to !== page?.total}
                    next={fetchData}
                    height={548}
                    loader={<small>Loading...</small>}
                  >
                    {_renderItemError()}
                    {/* 
                    Options If you wont to click
                    {page?.to !== page?.total && (
                      <div className='load-more' onClick={fetchData}>
                        <div className='button'>Load More...</div>
                      </div>
                    )} 
                    */}
                  </InfiniteScroll>
                </div>
              }
            />
          </div>
        </div>

      </div>
    </DashboardHealthCheckStyled>
  );
};


