import styled from 'styled-components'

export const ButtonStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    button {
      background: ${({ theme, bgColor }) =>
        bgColor ? bgColor : theme.COLORS.WHITE_1};
      color: ${({ theme, color }) => (color ? color : theme.COLORS.GREEN_1)};
      border-radius: 7px;
      border: none;
      padding: 6px 8px;
    }
  }
  .theme_red {
    button {
      width: ${(props) => (props.width ? props.width : 'auto')};
      background: ${({ theme, bgColor }) =>
        bgColor ? bgColor : theme.COLORS.WHITE_1};
      color: ${({ color }) => (color ? color : '#CC0000')};
      border-radius: 7px;
      border: 1px solid #cc0000;
      padding: 6px 8px;
      font-weight: 400;
      font-size: ${({ theme }) => theme.FONTS.SIZE_5};
    }
  }
  .theme_red_2 {
    button {
      width: ${(props) => (props.width ? props.width : 'auto')};
      background: ${({ theme, bgColor }) =>
        bgColor ? bgColor : theme.COLORS.WHITE_1};
      color: ${({ color }) => (color ? color : '#CC0000')};
      border-radius: 7px;
      border: 1px solid #cc0000;
      padding: 6px 8px;

      font-weight: 300;
      font-size: ${({ theme }) => theme.FONTS.SIZE_2};
    }
  }
  .theme_success {
    button {
      box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
      background: ${({ theme, bgColor, disabled }) =>
        disabled
          ? theme.COLORS.GREY_2
          : bgColor
          ? bgColor
          : theme.COLORS.BLUE_1};
      color: ${({ theme, color, disabled }) =>
        disabled ? theme.COLORS.BLACK_1 : color ? color : theme.COLORS.WHITE_1};
      border: none;
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      border-radius: 3px;
      padding: 12px 30px;
    }
  }
  .theme_cancle {
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
    button {
      background: ${({ theme, bgColor }) =>
        bgColor ? bgColor : theme.COLORS.WHITE_1};
      color: ${({ theme, color }) => (color ? color : theme.COLORS.BLACK_1)};
      border-radius: 3px;
      border: 1px solid ${({ theme }) => theme.COLORS.GREY_2};
      padding: 12px 30px;
    }
  }
  .theme_video {
    button {
      padding: 10px 22px;
      border: 2px solid ${({ theme }) => theme.COLORS.BLUE_2};
      border-radius: 8px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  .theme_green {
    button {
      padding: 10px 22px;
      border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
      border-radius: 8px;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_21 : theme.COLORS.GREEN_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  .theme_green2 {
    button {
      width: ${(props) => (props.width ? props.width : 'auto')};
      padding: 2px 10px;
      border: 2px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GREY_21 : theme.COLORS.GREEN_1};
      border-radius: 8px;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_21 : theme.COLORS.GREEN_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZE_2};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'poninter')};
      img {
        width: 20px;
        margin-right: ${({ isIconOnly }) => (isIconOnly ? '0' : '6px')};
      }
    }
  }
  .theme_green3 {
    button {
      width: ${(props) => (props.width ? props.width : 'auto')};
      padding: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GREEN_6};
      border-radius: 8px;
      background: ${({ theme }) => theme.COLORS.GREEN_6};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZE_3};
      font-family: noto_sans_thai_bold, noto_sans_bold;
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  .theme_blue {
    button {
      padding: 4px 22px;
      width: ${(props) => (props.width ? props.width : 'auto')};
      border: 2px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GREY_2 : theme.COLORS.BLUE_1};
      border-radius: 8px;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_2 : theme.COLORS.BLUE_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-weight: 700;
      font-size: ${({ theme }) => theme.FONTS.SIZE_5};
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  .theme_blue_2 {
    button {
      padding: 4px 10px;
      width: ${(props) => (props.width ? props.width : 'auto')};
      border: 2px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GREY_2 : theme.COLORS.BLUE_1};
      border-radius: 8px;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_2 : theme.COLORS.BLUE_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-weight: 300;
      font-size: ${({ theme }) => theme.FONTS.SIZE_3};
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  .theme_grey {
    button {
      padding: 4px 22px;
      width: ${(props) => (props.width ? props.width : 'auto')};
      border: 2px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GREY_2 : theme.COLORS.GREY_4};
      border-radius: 8px;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_2 : theme.COLORS.GREY_4};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-weight: 700;
      font-size: ${({ theme }) => theme.FONTS.SIZE_5};
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  .theme_green_border {
    button {
      padding: 10px 22px;
      width: ${(props) => (props.width ? props.width : 'auto')};
      border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
      border-radius: 8px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.GREEN_1};
      font-weight: 700;
      font-size: ${({ theme }) => theme.FONTS.SIZE_3};
      img {
        width: 20px;
        margin-right: 6px;
      }
    }
  }
  .theme_purple {
    button {
      width: ${(props) => (props.width ? props.width : 'auto')};
      background: ${({ theme, bgColor, disabled }) =>
        disabled
          ? theme.COLORS.GREY_21
          : bgColor
          ? bgColor
          : theme.COLORS.PURPLE_1};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'poninter')};
      color: ${({ color }) => (color ? color : '#ffffff')};
      border-radius: 7px;
      border: 1px solid #707070;
      padding: ${({ padCus }) => (padCus ? padCus : '6px 8px')};
      font-weight: 500;
      font-size: ${({ theme }) => theme.FONTS.SIZE_2};
    }
  }
  .theme_custom {
    width: 100%;
    button {
      padding: ${({ padCus }) => (padCus ? padCus : '10px 22px')};
      width: ${(props) => (props.width ? props.width : 'auto')};
      border: 1px solid;
      border-color: ${(props) =>
        props.borderColor ? props.borderColor : 'red'};
      border-radius: 3px;
      background: ${(props) => (props.bgColor ? props.bgColor : 'red')};
      color: ${(props) => (props.color ? props.color : 'white')};
      font-weight: 700;
      font-size: ${({ theme }) => theme.FONTS.SIZE_2_5};
      img {
        width: ${({ iconWidth }) => (iconWidth ? iconWidth : '20px')};
        height: ${({ iconHeight }) => (iconHeight ? iconHeight : 'auto')};
        margin-right: 6px;
      }
    }
  }
  .theme_blue_3 {
    width: 100%;
    button {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_2 : theme.COLORS.BLUE_1};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.BLACK_1 : theme.COLORS.WHITE_1};
      border-radius: ${(props) =>
        props.border_radius ? props.border_radius : '3px'};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      border: none;
      padding: 8px;

      font-weight: bold;
      font-size: ${({ theme }) => theme.FONTS.SIZE_2_5};
    }
  }
  .theme_blue_4 {
    width: 100%;
    button {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_21 : theme.COLORS.BLUE_1};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.BLACK_1 : theme.COLORS.WHITE_1};
      border-radius: ${(props) =>
        props.border_radius ? props.border_radius : '3px'};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      border: none;
      padding: 8px;
      font-weight: bold;
      font-size: ${({ theme }) => theme.FONTS.SIZE_3_5};
    }
  }
  .theme_purple2 {
    width: 100%;
    button {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_2 : theme.COLORS.PURPLE_1};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.BLACK_1 : theme.COLORS.WHITE_1};
      border-radius: ${(props) =>
        props.border_radius ? props.border_radius : '8px'};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      border: none;
      padding: 8px;

      font-weight: bold;
      font-size: ${({ theme }) => theme.FONTS.SIZE_3_5};
    }
  }
  .theme_outline_red {
    width: 100%;
    button {
      width: 100%;
      background: transparent;
      color: ${({ theme }) => theme.COLORS.RED_2};
      border-radius: 3px;
      border: 1px solid ${({ theme }) => theme.COLORS.RED_2};
      padding: 8px;

      font-weight: bold;
      img {
        width: ${({ iconWidth }) => (iconWidth ? iconWidth : '20px')};
        height: ${({ iconHeight }) => (iconHeight ? iconHeight : 'auto')};
        margin-right: 6px;
      }
    }
  }
  .theme_grey_2 {
    width: 100%;
    button {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GREY_21 : theme.COLORS.GREY_21};
      color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.BLACK_1 : theme.COLORS.WHITE_1};
      border-radius: ${(props) =>
        props.border_radius ? props.border_radius : '3px'};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      border: none;
      padding: 8px;
      font-weight: bold;
      font-size: ${({ theme }) => theme.FONTS.SIZE_3_5};
    }
  }
  .theme_disable {
    width: 100%;
    button {
      width: 100%;
      opacity: 0.5;
      background: ${({ theme }) => theme.COLORS.GREY_4};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      border-radius: 3px;
      border: 1px solid ${({ theme }) => theme.COLORS.GREY_4};
      padding: 8px;

      img {
        width: ${({ iconWidth }) => (iconWidth ? iconWidth : '20px')};
        height: ${({ iconHeight }) => (iconHeight ? iconHeight : 'auto')};
        margin-right: 6px;
      }
    }
  }
  .theme_disable_2 {
    width: 100%;
    button {
      background: ${({ theme, bgColor }) =>
        bgColor ? bgColor : theme.COLORS.GREY_2};
      color: ${({ theme, color }) => (color ? color : theme.COLORS.GREY_4)};
      border-radius: 7px;
      border: none;
      padding: 6px 8px;
      img {
        width: ${({ iconWidth }) => (iconWidth ? iconWidth : '20px')};
        height: ${({ iconHeight }) => (iconHeight ? iconHeight : 'auto')};
        margin-right: 6px;
      }
    }
  }

`
