import React from 'react';
import { connect } from 'react-redux';
import { SettingDetailContainerStyled } from './styled';
import { setReduxUserAuth, setReduxInstitutionDetail, setReduxInstitutionActive } from 'store/actions';
import {
  UserLists,
  ClinicLists,
  RecommendDoctor,
  // InstitutionProfile,
  // InstitutionSettings
} from 'widgets';
import {
  ButtonCustom,
  Typography,
  TabCustom,
  Background,
  PageNotFound
} from 'components';
import PrivacyPolicyContainer from 'containers/PrivacyPolicyContainer';
import { ROUTE_PATH, getPathnameID } from 'assets'
import theme from 'assets/theme.json'
import {
  // InfoInstitutionForm,
  // UserAccountForm ,
} from 'forms';
import { permissionService, userService } from 'apiService';
import Skeleton from 'react-loading-skeleton';
import { getPermissionActions } from 'store/actions/getPermissionActions';
import TopBackGroundImage from '../../assets/images/icons/top_background.svg'
class SettingDetailContainer extends React.Component {
  state = {
    institutionDetailData: [],
    access_denied: false
  }
  componentDidMount = () => {
    const { setReduxInstitutionActive } = this.props
    const { params } = this.props.match
    const institution = getPathnameID(params.institution_id)
    this.fetchData(institution)
    // this.fetchPermissionFeatures(institution)
    if (institution?.id) {
      setReduxInstitutionActive(institution.id)
    }
  }


  // Not yet used on this page
  // fetchPermissionFeatures = async (institution) => {
  //   const { getPermissionActions } = this.props
  //   let params = {
  //     institution_id : institution?.id,
  //     setting_type : 'features'
  //   }
  //   getPermissionActions(params)
  // }

  fetchData = (institution) => {
    const { history, setReduxInstitutionDetail } = this.props;
    Promise.all([
      this.fetchAccount(institution),
      this.fetchAccountSetting(institution),
      this.fetchAccountSettingFeature(institution),
    ]).then((values) => {
      const detail = values.find(value => value.type === 'detail')
      const setting = values.find(value => value.type === 'setting')
      const feature = values.find(value => value.type === 'feature')

      if (detail?.response?.status === 401) {
        this.setState({ access_denied: true })
        return false
      } else {
        this.setState({ access_denied: false })
      }
      const institution_detail = { ...detail.response.data, ...setting.response.data, ...feature.response.data }
      setReduxInstitutionDetail(institution_detail)
      
      this.setState({
        institutionDetailData: institution_detail
      })

      if (detail.response && detail.response.success) {
        history.push(ROUTE_PATH.SETTINGS_DETAIL + detail.response.data.id + '-' + detail.response.data.realm)
      }
    })
  }

  fetchAccount = async (institution) => {
    let response = await userService.GET_ACCOUNT_BY_ID(institution.id)
    if (response && response.success) {
      return { response, type: 'detail' }
    } else {
      return { response, type: 'detail' }
    }
  }
  fetchAccountSetting = async (institution) => {
    let response = await userService.GET_ACCOUNT_BY_ID_SETTING(institution.id, 'theme')
    if (response && response.success) {
      return { response, type: 'setting' }
    } else {
      return { response, type: 'setting' }
    }
  }
  fetchAccountSettingFeature = async (institution) => {
    let response = await userService.GET_ACCOUNT_BY_ID_SETTING(institution.id, 'features')
    if (response && response.success) {
      return { response, type: 'feature' }
    } else {
      return { response, type: 'feature' }
    }
  }
  render() {
    const { role } = this.props.reduxAuth;
    const { institutionDetailData, access_denied } = this.state;
    const { sitename, logo_square } = institutionDetailData
    return <SettingDetailContainerStyled> 
      {access_denied && <PageNotFound permission={true} />}
      {/* <div className='background_content'> */}
      {/* </div> */}
      <div className='back'>
        {role === 'system_admin'
          ? <ButtonCustom
            theme_back
            label="BACK"
            icon='arrow_left.svg'
            onClick={() => this.props.history.push(ROUTE_PATH.SETTINGS)}
          />
          : <div style={{ marginBottom: '2rem' }}></div>
        }
      </div>
        <Background theme_standard background='top_background.svg' />  
      {sitename ?
        <Typography
          src={logo_square}
          label={sitename}
          size={theme.FONTS.SIZE_30px}
          color={theme.COLORS.PURPLE_1}
        />
        : <Skeleton width='30%' height='2rem' />
      }
      <div className='content_space'>
        <div>
          <TabCustom
            theme_standard_3
            tabLists={tabLists(institutionDetailData, role)}
          />
        </div>
      </div>
    </SettingDetailContainerStyled>;
  }
}
const tabLists = (institutionDetailData, role) => [

  {
    id: 0,
    permission: true,
    isShow: true,
    value: 'user',
    name: 'บัญชีผู้ใช้งาน',
    components: <UserLists institutionDetailData={institutionDetailData}></UserLists>
  },
  {
    id: 1,
    permission: true,
    isShow: true,
    value: 'clinics',
    name: 'แผนก',
    components: <ClinicLists institutionDetailData={institutionDetailData}></ClinicLists>
  },
  {
    id: 2,
    permission: true,
    isShow: institutionDetailData.can_recommendation_template,
    value: 'recommend',
    name: 'คำแนะนำแพทย์',
    components: <RecommendDoctor institutionDetailData={institutionDetailData}></RecommendDoctor>
  },
  {
    id: 3,
    permission: true,
    isShow: true,
    value: 'privacypolicy',
    name: 'Theme',
    components: <PrivacyPolicyContainer/>
  },
  // {
  //   id: 2,
  //   permission: true,asd
  //   value: 'phr',
  //   name: 'Phr',
  //   components: <div></div>
  // },
  // {
  //   id: 3,
  //   permission: true,
  //   value: 'visit',
  //   name: 'ประวัติการรับบริการ',
  //   components: <div></div>
  // },
  // {
  //   id: 4,
  //   permission: true,
  //   value: 'settings',
  //   name: 'การตั้งค่า',
  //   components: <div style={{ maxWidth: '55rem', margin: '2rem auto' }}>
  //     <div>
  //       <InstitutionProfile />
  //     </div>
  //     <div>
  //       {role === 'system_admin' &&
  //         <InstitutionSettings />
  //       }
  //     </div>
  //   </div>
  // },

]
const mapStateToProps = (state) => ({
  reduxAuth: state.auth,
  reduxInstitutionDetail: state.institution_detail,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxInstitutionDetail: (data) => dispatch(setReduxInstitutionDetail(data)),
  setReduxInstitutionActive: (data) => dispatch(setReduxInstitutionActive(data)),
  getPermissionActions: (data) => dispatch(getPermissionActions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingDetailContainer);
