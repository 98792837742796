import React from "react";
import { SearchBarStyle } from "./style";
import SearchIcons from "../../assets/images/icons/search2.svg"
export const SearchBar = ({
    defaultValue,
    placeholder,
    onChange,
    onClick,
    onKeyPress,
}) =>{
    return(
        <SearchBarStyle>
            <div className="search_container">
                <div className="search_icons" onClick={onClick}>
                    <img src={SearchIcons}/>
                </div>
                <div className="search_input">
                    <input
                        onKeyPress={onKeyPress}
                        onChange={onChange}
                        className="rm_search_border"
                        type = 'search'
                        placeholder={placeholder}
                    />
                </div>
            </div>
        </SearchBarStyle>
    );
};
