import React from 'react';
import { ButtonCustomStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';
import ReactLoading from 'react-loading';

export const ButtonCustom = (props) => {
  const {
    theme_standard,
    theme_yellow,
    theme_outline_yellow,
    theme_blue,
    theme_blue_2,
    theme_blue_3,
    theme_blue_4,
    theme_blue_5,
    theme_blue_6,
    theme_blue_7,
    theme_blue_8,
    theme_blue_9,
    theme_sky,
    theme_sky_2,
    theme_sky_3,
    theme_sky_4,
    theme_gray,
    theme_gray_2,
    theme_red,
    theme_red_2,
    theme_outline_red,
    theme_outline_red_2,
    theme_outline_red_3,
    theme_outline_blue,
    theme_outline_blue_2,
    theme_outline_gray,
    theme_transparent,
    theme_disable,
    theme_disable_2,
    theme_disable_3,
    theme_transparent_2,
    theme_back,
    theme_green,
    label,
    label2,
    icon,
    icon2,
    size,
    specified_left,
    specified_right,
    home_page,
    loading,
  } = props;
  const customClass = cx({
    theme_yellow:theme_yellow,
    theme_outline_yellow:theme_outline_yellow,
    theme_standard: theme_standard,
    theme_blue: theme_blue,
    theme_blue_2: theme_blue_2,
    theme_blue_3: theme_blue_3,
    theme_blue_4: theme_blue_4,
    theme_blue_5: theme_blue_5,
    theme_blue_6: theme_blue_6,
    theme_blue_7: theme_blue_7,
    theme_blue_8: theme_blue_8,
    theme_blue_9: theme_blue_9,
    theme_sky: theme_sky,
    theme_sky_2: theme_sky_2,
    theme_sky_3: theme_sky_3,
    theme_sky_4: theme_sky_4,
    theme_gray: theme_gray,
    theme_gray_2: theme_gray_2,
    theme_red: theme_red,
    theme_red_2: theme_red_2,
    theme_outline_red: theme_outline_red,
    theme_outline_red_2: theme_outline_red_2,
    theme_outline_red_3: theme_outline_red_3,
    theme_outline_blue: theme_outline_blue,
    theme_outline_blue_2: theme_outline_blue_2,
    theme_outline_gray: theme_outline_gray,
    theme_transparent: theme_transparent,
    theme_disable: theme_disable,
    theme_disable_2: theme_disable_2,
    theme_disable_3: theme_disable_3,
    theme_back: theme_back,
    home_page: home_page,
    theme_transparent_2: theme_transparent_2,
    theme_green: theme_green


  });
  return (
    <ButtonCustomStyled {...props} size={size ? size : '14px'}>
      <div className={loading ? 'theme_disable' : customClass}>
        {icon && <img src={iconImages[icon]} alt={icon} />}
        {icon2 && <img className={specified_left ? 'img_left' : (specified_right ? 'img_right' : 'img_center')} src={iconImages[icon2]} alt={icon2} />}
        {!loading
          ? label
          : <ReactLoading type='bubbles' color='white' width='2rem' height='2rem' />
        }
        {label2 && <div>{label2}</div>}
      </div>
    </ButtonCustomStyled>
  );
};
