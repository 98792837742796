import styled from 'styled-components';

export const InputTextAreaStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  /*===============================================
Theme 
===============================================*/
    .theme_standard{
        textarea{ 
            width : 100%;
            border-radius: 3rem;  
            line-height:1.9;
            border: 0.0625rem solid ${({ theme }) => theme.COLORS.PURPLE_1};
            outline:none;
            box-sizing: border-box;
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            opacity: 0.5;
            padding: 0.3rem 1.25rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_standard_2{
        display:flex;
        align-items: center;
        .label{
            text-align: left;
            clear: both;
            float:left;
            margin-left:0.5rem;
            width:30%;
        }
        textarea{ 
            border-radius: 0.5rem;  
            width:70%;
            line-height:1.9;
            border: none;
            outline:none;
            box-sizing: border-box;
            text-align:center;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.3rem 1.25rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
  }
  .theme_standard_3{
        textarea{ 
            border-radius: 0.5rem;  
            width:100%;
            /* box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18); */
            border: 1px solid rgb(0,0,0,0.2);
            /* outline:none; */
            box-sizing: border-box;
            text-align:left;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size: 17px;
            padding: 1rem 1.25rem;
            font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_standard_4{
        textarea{ 
            border-radius: 1rem;  
            width:100%;
            line-height:1.9;
            border: 0.0625rem solid ${({ theme }) => theme.COLORS.BLUE_3};
            outline:none;
            box-sizing: border-box;
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            opacity: 0.5;
            padding: 0.3rem 1.25rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .num_show_theme_form{
        display:flex;
        margin-left: 10px;
        align-items: flex-end;
        padding-bottom: 2px;
        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        justify-content:flex-end;
    }

    .theme_standard_max_length {
        display: flex;
        position: relative;
        textarea{ 
            border-radius: 0.5rem;  
            resize: none;
            width:100%;
            line-height:1.9;
            border: none;
            outline:none;
            text-align:left;
            box-sizing: border-box;
            border:1px solid ${({ theme }) => theme.COLORS.BLUE_4};
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
            padding: 0.3rem 1.25rem;
            height: 100px;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            margin-top:0.3rem;
            margin-left: 10px;
            display: flex;
            align-items: flex-end;
            padding-bottom: 2px;
            position: absolute;
            bottom: -24px;
            left: -8px;
        }
        .num_show {
            margin-left: 10px;
            display: flex;
            align-items: flex-end;
            padding-bottom: 2px;
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_outline{
        textarea{ 
            border-radius: 0.5rem;  
            width:100%;
            line-height:1.9;
            border: none;
            outline:none;
            text-align:left;
            box-sizing: border-box;
            border:1px solid ${({ theme }) => theme.COLORS.BLUE_4};
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.3rem 1.25rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_outline_2{
        textarea{ 
            border-radius: 3rem;  
            width : 100%;
            box-sizing: border-box;
            border: none;
            outline:none;
            text-align:left;
            border:1px solid ${({ theme }) => theme.COLORS.BLUE_4};
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.7rem 1.25rem;
            font-weight :300;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            border:none;
            padding-left:0;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_search{
        textarea{ 
            border-radius: 4rem;  
            width:100%;
            line-height:1.9;
            border: none;
            outline:none;
            text-align:left;
            box-sizing: border-box;
            border:2px solid ${({ theme }) => theme.COLORS.BLUE_3};
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.2rem 2rem 0.2rem 1rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.BLUE_3};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        .icon {
            position: absolute;
            right: 1.8rem;
            top: 0.8rem;
        }
        textarea:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        textarea:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    
`;
