import { userService } from "apiService"
import { SET_PERMISSION_FEATURES } from "./actionTypes"




export const getPermissionActions = (params) => async (dispatch, getState) => {
  const data = getState().settings
  let institution_id = params?.institution_id
  let setting_type = params?.setting_type
  const res = await userService.GET_ACCOUNT_BY_ID_SETTING(institution_id, setting_type)
  if (res && res.success) {
    dispatch({ type: SET_PERMISSION_FEATURES, payload: { institution_id: institution_id, ...res.data } })
    return res
  } else {
    dispatch({ type: SET_PERMISSION_FEATURES, payload: data })
    return res
  }
  // const newConfig = params?.config ? Object.assign({}, config, params?.config) : config
  // // Detect in config.pendingTime Second to call function
  // let updatedAt = metadata.achievementUpdatedAt
  // if (conditionActions(updatedAt, newConfig)) {
  //   const res = await achievementService.GET_ACHIEVEMENT()
  //   if (res && res.success) {
  //     dispatch({ type: SET_MY_ACHIEVEMENT, payload: setData(res.data) })
  //     return { ...res, data: setData(res.data) }
  //   } else {
  //     dispatch({ type: SET_MY_ACHIEVEMENT, payload: data })
  //     return res
  //   }
  // } else {
  //   return { success: true, data: data, pending: true }
  // }
}
