import React, { useEffect, useState } from 'react';
import { InputTextStyled } from './styled'
import cx from 'classnames';
import { iconImages } from 'assets';

export const InputText = (props) => {
  const {
    theme_standard,
    theme_standard_2,
    theme_standard_3,
    theme_standard_4,
    theme_outline,
    theme_outline_2,
    theme_standard_max_length,
    theme_search,
    label,
    language,
    placeholder,
    input,
    icon,
    button,
    specialText,
    type,
    meta,
    disabled,
    readOnly,
    handleError,
    maxLength,
    value
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3,
    theme_standard_4: theme_standard_4,
    theme_search: theme_search,
    theme_outline: theme_outline,
    theme_outline_2: theme_outline_2,
    theme_standard_max_length: theme_standard_max_length,
  });

  useEffect(() => {
    handleError && handleError(meta, input)
  }, [meta])


  return (
    <InputTextStyled specialText={specialText}>
      <div className={customClass} isinvalid={meta && (meta.touched && meta.error)}  >
        {label && <div className="label">{label}</div>}
        {language &&<div className="label_language">{language}</div>}
        <input
          {...props}
          {...input}
          type={type}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          className={meta && ((meta.touched && meta.error) && ' isvalid')}
        />
        {meta && (meta.touched && meta.error && <div className="error">{meta.error}</div>)}
        {
          maxLength &&
          <div className="num_show">
            {`${input.value.length}/${maxLength && maxLength}`}
          </div>
        }
        {icon && <img className="icon" src={iconImages[icon]} alt={icon} />}
        {button &&
          <div className="button">
            <div className='button_wrapper'>
              {button}
            </div>
          </div>
        }
        {specialText &&
          <div className="specialText">
            <div className="specialText_wrapper">
              <div className={(meta && (meta.touched && meta.error)) ? 'specialText_wrapper_inner2' : 'specialText_wrapper_inner'}>
                {specialText}
              </div>
            </div>
          </div>
        }
      </div>
    </InputTextStyled>
  );
};
