import React, { useEffect, useState } from 'react'
import { BoxFilterStyled, BoxIconsStyled } from './styled'
import { iconImages, UploadIconsImages } from 'assets'
import cx from 'classnames'
import theme from 'assets/theme.json'
import {
  BoxModal,
  InputCheckbox,
  InputCheckboxNormal,
  Typography,
} from 'components'
export const BoxFilter = (props) => {
  const {
    theme_standard,
    intitalValue,
    title,
    desc,
    confirm,
    cancel,
    loading,
    onConfirm,
    onCancel,
    onFilter,
    _isModal,
    handleModal,
    handleChange,
    morepicture,
  } = props
  const customClass = cx({
    theme_standard: theme_standard,
  })
  const [roleCheckBox, setRoleCheckBox] = useState(
    intitalValue ? intitalValue : '',
  )
  const CheckBox = (e, item, index) => {
    const result = roleCheckBox?.map((item, i) => {
      if (i === index) {
        item.isCheck = e.target.checked
      }
      return item
    })
    onFilter(result)
    setRoleCheckBox(result)
  }

  return (
    <BoxFilterStyled>
      <div className={customClass}>
        <div className='content_box'>
          <div className='content_display'>
            {roleCheckBox?.map((item, index) => {
              return (
                <div className='item_box'>
                  <div className='checkInputbox'>
                    <InputCheckboxNormal
                      theme_transparent_5
                      value={item}
                      checked={item?.isCheck}
                      onChange={(e) => CheckBox(e, item, index)}
                    />
                  </div>
                  <div className='checkInputLabel'>{item?.label}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </BoxFilterStyled>
  )
}
