import styled from "styled-components"
 
export const TypographyStyled = styled.div`
/*===============================================
 Container 
===============================================*/
  
/*===============================================
Theme 
===============================================*/
    // background-color: ${props => props.colors && props.colors};
    display:flex;
    align-items: center;
    color: ${props => props.color && props.color};
    .icon{
        margin-right:0.5rem;
    }
    .logo{
        margin-right:0.5rem;
        width : 3rem;
    }
    .label{
        font-size: ${props => props.size && props.size};
        color:  ${props => props.color && props.color};
        font-weight:${props => props.weight && props.weight};
    }
    /* @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_MINI}) {
        .label{ 
            font-size: ${({ theme }) => theme.FONTS.SIZE_22px};
            color:  ${props => props.colors && props.color};
            font-weight:${props => props.weight && props.weight};
        }
    } */
    
`