import styled from "styled-components"

export const LoginContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    background-color : white;
    display : flex;
    justify-content :center;
    flex-direction: column;
    height : 100vh;
    /* background-image: linear-gradient(157deg, ${({ theme }) => theme.COLORS.GREEN_3},${({ theme }) => theme.COLORS.GREEN_3}, ${({ theme }) => theme.COLORS.GREEN_2}); */
    /* background-color : ${({ theme }) => theme.COLORS.GREEN_2}; */
    /* background-image: linear-gradient(135deg #fff 0%, #f3f6f8 100%); */
    background-image: linear-gradient(135deg, #fff, #f3f6f8);
    background-repeat: no-repeat;
    background-size: auto;
    .wrapper{
        z-index : 1;
    }
    .wrapper::before {
        z-index : -1;
        content: '';
        position: fixed;
        left: -22%;
        top: 60%;
        right: 0;
        width: 125%;
        height: 100%;
        background: linear-gradient(135deg, #f3f6f8 0%, #fff 100%); 
        transform: rotate(15deg);
    }
    .contianer_login{
        display : flex;
        justify-content :center;
        max-width : 1200px;
        min-width : 767px;
        margin-right : auto;
        margin-left : auto;
        .left{
            
            /* color: ${({ theme }) => theme.COLORS.WHITE_1}; */
            width : 60%;
            margin-right : 1rem;
            margin-left : 1rem;
            .left_title{
            }
            .left_desc1{
                margin:0.7rem 0;
                font-weight :bold;
            }
        } 
        .right{
            width : 40%;
            margin-left : 1rem;
            margin-right : 1rem;

        }
    }
`