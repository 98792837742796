import styled from "styled-components";

export const CheckBoxStyle = styled.div`

    .btn_warpper{
        .edit_btn{
            background-color: ${({theme})=> theme.COLORS.BLUE_18};
            color: ${({theme})=> theme.COLORS.WHITE_1 };
            border-color: ${({theme})=> theme.COLORS.BLUE_18};
        }
    }    
`