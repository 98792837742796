import React, { useState } from 'react';
import { ImgUploadStyled } from './styled';
//import { ButtonCustom } from 'components';
import { iconImages } from 'assets';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import Compress from 'compress.js'

export const ImgUpload = (props) => {
  const { readOnly, form , profile_picture_url } = props;
  const dispatch = useDispatch()
  // const Compress = require('compress.js')
  const compress = new Compress()

  const [display, setDisplay] = useState()
  const handleUploadImage = (value) => {
    dispatch(change(form?.formName, 'profile_picture_base64', value))
  }
  const handleChange = (event) => {
    resizeImg(event.target.files[0]).then(file => {
      let reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        // console.log('onloadend',reader)
        handleUploadImage(reader.result)
      };
    })
    setDisplay(URL.createObjectURL(event.target.files[0]))
  }
  const resizeImg = async (file) => {
    const resizedImage = await compress.compress([file], {
      // size: 2, // the max size in MB, defaults to 2MB
      quality: 0.8, // the quality of the image, max is 1,
      maxWidth: 512, // the max width of the output image, defaults to 1920px
      maxHeight: 512, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext

    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile
  }


  return (
    <ImgUploadStyled>
      <div className="row">
        <div className="upload_box">
          {!display
            ? profile_picture_url
              ? <div className="profile">
                <img src={profile_picture_url} alt="" />
              </div>
              : <div className="upload_box_border">
                <img src={iconImages['grayheadshot.svg']} alt="" />
              </div>
            : ''
          }
          {display &&
            <div className="profile">
              <img src={display} alt="" />
            </div>
          }
        </div>
        <div>
          {!readOnly &&
            <div className="upload_btn_container mt-3">
              <div className='upload_title'>รูปโปรไฟล์</div>
              <div className='upload_label'>
                <label for="file-upload" className="custom-file-uploads">
                  อัพโหลดรูปโปรไฟล์
                </label>
                <input
                id="file-upload"
                type="file"
                accept="image/png, image/jpeg , image/bmp, image/jpg"
                onChange={handleChange}
                onClick={(event) => {
                  event.target.value = null
                }}
                />              
              </div>
            </div>
          }
        </div>
      </div>
    
    </ImgUploadStyled >
  );
};
