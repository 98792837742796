import styled from "styled-components";


export const InputSelectFilterStyled = styled.div`

    .theme_standard{
        .box_container{
        border: 0.01rem solid #707070;
        min-height: 45px;
        background-color: #fff;
        border-radius: 0.5rem;
        .input_container{
            position: relative;
            width: 100%;
            height: 100%;
            label{
                max-width: 96px;
                padding:10px;
                position: absolute;
                border-right: 0.01rem solid #707070;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                column-gap:5px;
                
                .label_text{
                    font-size: ${({theme})=>theme.FONTS.SIZE_14px};
                    font-weight:600;
                }
            }
            input{
                padding-left: 96px;
                text-align: center;
                border: none;
                width: 100%;  
                height: 45px;
                border-radius: 0.5rem;
                &.disable{
                    opacity: 0.5;
                }
            }
            .clear_btn{
                position: absolute;
                top:0;
                right:0;
                margin:10px;
                color: red;
                display: none;
                &.active{
                    display: block;
                }
            }
        }       
        }
    }
    .theme_standard_2{
        .box_container{
        border: 0.01rem solid #707070;
        min-height: 45px;
        background-color: #fff;
        border-radius: 0.5rem;
        .input_container{
            position: relative;
            width: 100%;
            height: 100%;
            label{
                max-width: 96px;
                padding:10px;
                position: absolute;
                border-right: 0.01rem solid #707070;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                column-gap:5px;
                
                .label_text{
                    font-size: ${({theme})=>theme.FONTS.SIZE_14px};
                    font-weight:600;
                }
            }
            input{
                padding-left: 96px;
                text-align: center;
                border: none;
                width: 100%;  
                height: 45px;
                border-radius: 0.5rem;
                &.disable{
                    opacity: 0.5;
                }
            }
            .clear_btn{
                position: absolute;
                top:0;
                right:0;
                margin:10px;
                color: red;
                display: none;
                &.active{
                    display: block;
                }
            }
        }       
        }
    }


    
`