import React, { useEffect, useState } from 'react'
import { ROUTE_PATH, VALIDATE } from 'assets'
import { Field, reduxForm } from 'redux-form'
import { AssessmentFormStyled } from './styled'
import {
  BoxConfirm,
  BoxModal,
  ButtonCustom,
  InputText,
  ToggleSwitch,
  Typography,
} from 'components'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom'
import theme from 'assets/theme.json'
import { userService } from 'apiService'
import { connect, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

let AssessmentFormContainer = (props) => {
  const { handleSubmit, initialize } = props
  const { account_id, type } = useParams()
  const accountIdRedux = useSelector((state) => state?.institution_detail?.id)
  const history = useHistory()

  const [initDetails, setInitDetails] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [modal, setModal] = useState()

  useEffect(() => {
    if (history?.location?.state?.initialiData) {
      setInitDetails(history?.location?.state?.initialiData)
      initialize(
        history?.location?.state?.initialiData,
        'AssessmentFormContainer',
      )
    } else {
      initialize(
        {
          is_active: true,
        },
        'AssessmentFormContainer',
      )
    }
  }, [history])

  const handleSubmited = async (e) => {
    const payload = { ...e }
    if (type === 'create') {
      const res =
        await userService.POST_SATISFACTION_SURVEYS_LIST_BY_ACCOUNT_ID(
          accountIdRedux,
          payload,
        )
      if (res && res?.success) {
        setModal(
          <BoxConfirm
            theme_success
            title='บันทึกสำเร็จ!'
            confirm='ตกลง'
            onConfirm={() => {
              setIsOpen(false)
              history.push(ROUTE_PATH.ASSESSMENT)
            }}
            onCancel={false}
          />,
        )
        setIsOpen(true)
      } else {
        toast.error(`${res?.data?.error}`)
      }
    } else {
      const res = await userService.PATCH_SATISFACTION_SURVEYS_LIST_BY_ID(
        initDetails?.id,
        payload,
      )
      if (res && res?.success) {
        setModal(
          <BoxConfirm
            theme_success
            title='Success!'
            desc='อัพเดทสำเร็จแล้ว'
            confirm='ตกลง'
            onConfirm={() => {
              setIsOpen(false)
              history.push(ROUTE_PATH.ASSESSMENT)
            }}
            onCancel={false}
          />,
        )
        setIsOpen(true)
      } else {
        toast.error(`${res?.data?.error}`)
      }
    }
  }

  const handleDelete = async () => {
    const res = await userService.DELETE_SATISFACTION_SURVEYS_LIST_BY_ID(
      initDetails?.id,
    )
    if (res && res?.success) {
      history.push(ROUTE_PATH.ASSESSMENT)
    } else {
      toast.error(`${res?.data?.error}`)
    }
  }

  const OpenDeleteModal = () => {
    setModal(
      <BoxConfirm
        theme_standard
        confirm='ยืนยัน'
        cancel='ยกเลิก'
        desc={
          <div style={{ height: '100px' }}>
            <div
              style={{
                // border: '1px solid',
                borderBottomStyle: 'solid',
                borderBottomColor: '#9C9C9C',
                borderBottomWidth: '1px solid',
                // backgroundColor: 'red',
              }}>
              <Typography
                label='ลบแบบประเมินหรือไม่'
                size={theme.FONTS.SIZE_20px}
                color={theme.COLORS.BLUE_17}
                weight={'400'}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Typography
                label='เมื่อลบเเล้ว ไม่สามารถเรียกคืนข้อมูลได้'
                size={theme.FONTS.SIZE_16px}
                color={theme.COLORS.BLACK_1}
                weight={'400'}
              />
            </div>
          </div>
        }
        onConfirm={() => handleDelete()}
        onCancel={() => setIsOpen(false)}
      />,
    )
    setIsOpen(true)
  }

  return (
    <AssessmentFormStyled>
      <BoxModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        theme_standard>
        {modal}
      </BoxModal>
      <ToastContainer />
      <div className='TopSection'>
        <div className='back'>
          <ButtonCustom
            theme_back
            label='BACK'
            icon='arrow_left.svg'
            onClick={() => history.goBack()}
          />
        </div>
      </div>
      <div style={{ margin: '50px' }}>
        <form onSubmit={() => handleSubmit(handleSubmited)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}>
            {type !== 'create' && (
              <div style={{ width: '8rem' }}>
                <ButtonCustom
                  theme_outline_red_2
                  icon='trash.svg'
                  label='ลบ'
                  onClick={OpenDeleteModal}
                />
              </div>
            )}
            <div style={{ width: '8rem' }}>
              <ButtonCustom
                theme_yellow
                label='บันทึก'
                onClick={handleSubmit(handleSubmited)}
              />
            </div>
          </div>
          <div>
            <Typography
              label={type === 'create' ? 'สร้างแบบประเมิน' : 'แก้ไขแบบประเมิน'}
              size={theme.FONTS.SIZE_24px}
              color={theme.COLORS.BLUE_17}
              weight={'700'}
            />
            <Typography
              label={type === 'create' ? 'New Assessment' : 'Edit Assessment'}
              size={theme.FONTS.SIZE_20px}
              color={theme.COLORS.GRAY_2}
              weight={'500'}
            />
          </div>
          <div className='bodySection'>
            <div className='formContainer'>
              <div
                style={{
                  //   margin: '20px 0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <div>
                  <Typography
                    label='ข้อมูลแบบประเมิน'
                    size={theme.FONTS.SIZE_16px}
                    color={theme.COLORS.BLACK_1}
                    weight={'400'}
                  />
                  <Typography
                    label='Assessment Information'
                    size={theme.FONTS.SIZE_16px}
                    color={theme.COLORS.GRAY_2}
                    weight={'400'}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                  }}>
                  <Typography
                    label='สถานะ :'
                    size={theme.FONTS.SIZE_16px}
                    color={theme.COLORS.BLACK_1}
                    weight={'400'}
                  />
                  <div style={{ marginTop: '10px' }}>
                    <Field
                      name='is_active'
                      component={ToggleSwitch}
                      type='checkbox'
                      checked={
                        type === 'create'
                          ? true
                          : history?.location?.state?.initialiData?.is_active
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 10,
                  flex: 1,
                  margin: '15px 0px',
                }}>
                <div style={{ flex: 0.2 }}>
                  <Typography
                    label='หัวข้อ'
                    size={theme.FONTS.SIZE_16px}
                    color={theme.COLORS.BLACK_1}
                    weight={'400'}
                  />
                  <Typography
                    label='Topic'
                    size={theme.FONTS.SIZE_16px}
                    color={theme.COLORS.GRAY_2}
                    weight={'400'}
                  />
                </div>
                <div style={{ flex: 0.8 }}>
                  <Field
                    theme_standard_3
                    name='name'
                    component={InputText}
                    type='text'
                    placeholder='Add Topic'
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 10,
                  flex: 1,
                  margin: '15px 0px',
                }}>
                <div style={{ flex: 0.2 }}>
                  <Typography
                    label='ลิงค์แนบแบบประเมิน'
                    size={theme.FONTS.SIZE_16px}
                    color={theme.COLORS.BLACK_1}
                    weight={'400'}
                  />
                  <Typography
                    label='Assessment Link'
                    size={theme.FONTS.SIZE_16px}
                    color={theme.COLORS.GRAY_2}
                    weight={'400'}
                  />
                </div>
                <div style={{ flex: 0.8 }}>
                  <Field
                    theme_standard_3
                    name='uri'
                    component={InputText}
                    type='text'
                    placeholder='Add URL'
                    validate={[
                      VALIDATE.REQUIRE,
                      VALIDATE.NUMBER_MAX_LENGTH_255,
                      VALIDATE.URL_REGEX,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </AssessmentFormStyled>
  )
}

AssessmentFormContainer = reduxForm({
  // a unique name for the form
  form: 'AssessmentFormContainer',
})(AssessmentFormContainer)

export default AssessmentFormContainer
