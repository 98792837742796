import React from 'react';
import { connect } from 'react-redux';
import { PageMainLayoutStyled } from './styled';
import { Route, Switch } from 'react-router-dom';
import pageRoutes from 'routes/pageRoutes';

class PageMainLayout extends React.Component {
  state = {};

  render() {
    return (
      <PageMainLayoutStyled>
        <Switch>
          {pageRoutes &&
            pageRoutes.map((e, i) => {
              return !e.sub ? (
                <Route
                  key={i}
                  exact={e.exact}
                  path={e.path}
                  component={e.component}
                />
              ) : (
                e.subComponent.map((e2, i2) => (
                  <Route
                    key={i2}
                    exact={e2.exact}
                    path={e2.path}
                    component={e2.component}
                  />
                ))
              );
            })}
        </Switch>
      </PageMainLayoutStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageMainLayout);
