import styled from "styled-components";

export const AdminFormStyled = styled.div`

    width: 100%;
    .buttonSection{
        display: flex;
        margin-left : auto;
        margin-right : 0.1rem;
        padding-top : 0rem;
        width : 10rem;
        margin-bottom:10px;
    }
    .formContainer{
            border-radius: 10px;
            display: flex;
            flex-direction:column;
            background-color: ${({theme}) => theme.COLORS.WHITE_1};
            height: 100%;
            width: 100%;
            padding: 10px 20px;
        .row{
            display: flex;
            &.mtb10{
                margin: 10px 0;
            }
        }
        .col{
            display: flex;
            flex-direction: column ;
            margin: 5px 0px;
        }
        .gap{
            margin: 10px 0;
        }
        .label{
            margin: 10px 0;
        }
    }

`



