import React, { useEffect, useRef, useState } from 'react';
import { MessageLogStyled, ContainerStyled } from './styled';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';
import { BoxCustom, BoxModal } from 'components';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { iconImages } from 'assets';
import moment from 'moment'
import { userService } from 'apiService';



export const MessageLog = (props) => {
  const { theme_standard, room_id, visit_id, closeModal } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });
  const [messages, setMessages] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState('')
  useEffect(() => {
    if (room_id) {
      setIsOpen(true)
      fetchVisitChat(room_id)
    }
    if (visit_id) {
      setIsOpen(true)
      fetchInternalChat(visit_id)
    }
    // fetchVisitChat()
    // fetchInternalChat()
  }, [visit_id, room_id])

  const fetchVisitChat = async (room_id) => {
    // let room_id = '68537d9cded74737'
    let res = await userService.GET_VISIT_CHAT_LOG(room_id)
    // console.log(res)
    if (res?.success) {
      setType('VISIT')
      setMessages(res.data)
    }
  }

  const fetchInternalChat = async (visit_id) => {
    // let visit_id = 4340
    let res = await userService.GET_INTERNAL_CHAT_LOG(visit_id)
    if (res?.success) {
      setType('INTERNAL')
      setMessages(res.data)
    }
  }


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const onRequestClose = () => {
    setIsOpen(false)
    closeModal()
    setMessages([])
  }
  return (
    <BoxModal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      theme_standard
    >
      <>
        <BoxCustom
          {...props}
          theme_standard_box_custom
          child={(
            <ContainerStyled >
              {/* <button onClick={onRequestClose} style={{ position: 'absolute', borderRadius: '50%', width: '40px', height: '40px', cursor: 'pointer', fontSize: '20px', border: 'none', top: '-20px', right: '-15px', zIndex: '999' }}>X</button> */}
              <div style={{ display: "none" }}>
                <MessageLogToPrint messages={messages} type={type} ref={componentRef}></MessageLogToPrint>
              </div>
              <MessageLogStyled  >
                <div className='container-wrapper pagebreak'   >
                  <div className='title'>
                    <div className='left'>
                      <div className='label'> {type === 'VISIT' ? 'Visit Chat Log' : 'Internal Message Log'}</div>
                      <div className='label2'>
                        {messages[0]?.created_at && moment.utc(messages[0]?.created_at).utcOffset("+0700").format('DD MMMM YYYY')}
                      </div>
                    </div>
                    <ReactToPrint
                      content={() => componentRef.current}
                      // onBeforeGetContent={()=>setIsPrinting(true)}
                      trigger={() => (
                        <div className='print'
                          onClick={handlePrint}
                          onBeforePrint={(e) => console.log('onBeforePrint', e)}
                        >
                          <img
                            src={iconImages['print.png']}
                            alt='print'
                            style={{ width: '1.5rem' }}
                          />
                          {/* <div className='label'>
                      Print
                      </div> */}
                        </div>
                      )}
                    />
                  </div>
                  <div className='message-wrapper'>
                    <MessageLists messages={messages} type={type} />
                  </div>
                </div>
              </MessageLogStyled>
            </ContainerStyled>
          )}

        />
      </>
    </BoxModal>

  );
};

export const MessageLogToPrint = React.forwardRef((props, ref) => {
  return (
    <ContainerStyled  >
      <MessageLogStyled ref={ref} isPrinting={true}>
        <div className='container-wrapper'>
          <div className='title'>
            <div className='left'>
              <div className='label'>Message Log</div>
              <div className='label2'>
                {props.messages[0]?.created_at && moment.utc(props.messages[0]?.created_at).utcOffset("+0700").format('DD MMMM YYYY')}
              </div>
            </div>
          </div>
          <div className='message-wrapper'>
            <MessageLists messages={props.messages} type={props.type} isPrinting={true} />
          </div>
        </div>
      </MessageLogStyled>
    </ContainerStyled>
  )
})
const renderRole = (e) => {
  switch (e?.role) {
    case 'nurse':
      return 'N'
    case 'doctor':
      return 'D'
    case 'pharmacist':
      return 'P'
    case 'translator':
      return 'T'
    case 'accounting':
      return 'A'
    default:
      return 'U'
  }

}
const MessageLists = (props) => {
  const { messages, type } = props
  const renderMessageLog = () => {
    return messages?.map((item, i) => {
      let key = type === 'VISIT'
        ? item?.patient_sender !== null
          ? 'patient_sender'
          : item?.provider_sender !== null
            ? 'provider_sender'
            : ''
        : type === 'INTERNAL'
          ? 'sender'
          : ''
      return (
        <div key={i} className='item'>
          {(!key)
            ? <div className='system'>
              <div className='system_bubble'>
                <div className='' style={{ marginBottom: '0.4rem' }}>{moment.utc(item?.created_at).utcOffset("+0700").format('DD MMM YYYY, HH.mm') + ' น.'}</div>
                {item?.message}
              </div>
            </div>
            : <div className='row' style={{alignItems : 'flex-start'}}>
              <div className='circle'>
                <div className='label'>
                  {renderRole(item?.[key])}
                </div>
              </div>
              <div>
                <div className='name'>
                  {key === 'patient_sender' ? `${item?.[key]?.fname} ${item?.[key]?.lname}` : item?.[key]?.name}
                </div>
                <div className='row' style={{ alignItems: 'flex-end' }}>
                  <div className='bubble ' style={{ display: 'block' }}>
                    {item?.message}
                    {item?.attachments?.length > 0 &&
                      <div className='attachments'>
                        {handleRenderAttachments(item?.attachments)}
                      </div>
                    }
                  </div>
                  <div className='time'>{moment.utc(item?.created_at).utcOffset("+0700").format('HH.mm') + ' น.'}</div>
                </div>
              </div>
            </div>
          }
        </div>
      )
    })
  }
  const upload_extension = {
    document: ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"],
    image: ["png", "jpeg", "jpg", "bmp"],
    video: ["mp4"],
  }
  const handleRenderAttachments = (attachments) => {
    const findType = (attachment) => {
      const getType = attachment?.file_uri?.split('.').pop()

      const file_image = upload_extension?.image?.some(v => v === getType)
      const file_vdo = upload_extension?.video?.some(v => v === getType)
      const file_doc = upload_extension.document?.some(v => v === getType)
      return file_image ? 'image' : file_vdo ? 'vdo' : file_doc ? 'doc' : 'doc'
    }
    const setTypeAttacments = attachments?.map(attachment => {
      return {
        ...attachment,
        type: findType(attachment) /// if have type from typing or api data
      }
    })
    return setTypeAttacments?.map((attachment, i) => {
      return (
        <div key={i}>
          {attachment?.type === 'image' &&
            <div className='msg_image' >
              <a href={attachment?.file_uri} rel="noopener noreferrer" target="_blank" download >
                <img src={attachment?.file_uri} width={'100%'} height={'100%'} alt='message_image' />
              </a>
            </div>
          }
          {attachment?.type === 'vdo' &&
            <div >
              <a className="msg_doc" href={attachment?.file_uri} rel="noopener noreferrer" target="_blank" download >
                <img
                  src={iconImages['vdo.svg']}
                  alt='file_document'
                  style={{ width: '2rem' }}
                />
                <div className='name'>{attachment?.file_uri.substring(attachment?.file_uri.lastIndexOf('/') + 1)}</div>
              </a>
            </div>
          }
          {attachment?.type === 'doc' &&
            <div >
              <a className="msg_doc" href={attachment?.file_uri} rel="noopener noreferrer" target="_blank" download >
                <img
                  src={iconImages['document.svg']}
                  alt='file_document'
                  style={{ width: '1.6rem' }}
                />
                <div className='name'>{attachment?.file_uri.substring(attachment?.file_uri.lastIndexOf('/') + 1)}</div>
              </a>
            </div>
          }
        </div>
      )
    })
  }
  return (
    renderMessageLog()
  )
}

const data = [
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },
  {
    "id": 12,
    "message": "Test from Patient",
    "created_at": "2021-06-17T17:14:02.000000Z",
    "provider_sender": null,
    "patient_sender": {
      "id": 1,
      "fname": "ชื่อจริง",
      "lname": "นามสกุล",
      "profile_picture_url": "https://itele-api-local.invitrace.app/images/avatars/1/sfezYYyNXWXZmUUL4DeGii.png"
    },
    "attachments": []
  },

]
