import styled from "styled-components"
 
export const BoxProfileStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        .container{
            display : flex;
            align-items : center;
            img{
                width : 3rem;
                height : 3rem;
                object-fit : cover;
                border-radius : 50%;
                object-position: top;
            }
            .desc{
                margin-left : 1rem;
                display : flex;
                
                height :100%;
                flex-direction : column;
            }
        }
    }
`