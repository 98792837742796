import React from 'react';
import { connect } from 'react-redux';
import { SettingListsContainerStyled } from './styled';
import { setReduxUserAuth, setReduxInstitutions } from 'store/actions';
import {
  AllUserLists,
  SettingsLists
} from 'widgets';
import {
  TabCustom,
  Typography,
  Background,
  BoxModal
}
  from 'components'
import theme from 'assets/theme.json'

class SettingListsContainer extends React.Component {
  render() {
    return <SettingListsContainerStyled>
      <Background theme_standard_3 background='top_background.svg' />
      <div style={{ marginBottom: '3rem' }}></div>
      <Typography
        label='ระบบจัดการหลังบ้าน'
        size={theme.FONTS.SIZE_30px}
        color={theme.COLORS.BLUE_17}
      />
      <div style={{ marginTop: '1.5rem', marginBottom: '1.4rem' }}>
        <TabCustom
          theme_standard_3
          tabLists={tabLists()}
        />
      </div>
    </SettingListsContainerStyled>;
  }
}
const tabLists = (data) => [
  {
    id: 0,
    permission: true,
    isShow: true,
    value: 'institution',
    name: 'สถานพยาบาล',
    components: <SettingsLists  ></SettingsLists>
  },
  {
    id: 1,
    permission: true,
    isShow: true,
    value: 'users',
    name: 'บัญชีผู้ใช้ทั้งหมด',
    components: <AllUserLists ></AllUserLists>
  },
  // {
  //   id: 2,
  //   permission: true,
  //   value: 'history',
  //   name: 'บันทึกการโทร',
  //   components: <div>บันทึกการโทร</div>
  // },
  // {
  //   id: 3,
  //   permission: true,
  //   value: 'setting',
  //   name: 'การตั้งค่า',
  //   components: <div>การตั้งค่า</div>
  // },
]

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxInstitutions: state.institutions
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxInstitutions: (data) => dispatch(setReduxInstitutions(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingListsContainer);
