import styled from "styled-components"

export const LoginFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/
        // margin:0 1rem;
/*===============================================
Theme  
===============================================*/
    form{
        color: ${({ theme }) => theme.COLORS.GREEN_2};
        font-weight : 600;
            // display: flex;
            // flex-direction: column;
        .mt-3{
            margin-top:2.5rem;
        }
        .isvalid{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
            text-align:left;
            display: flex;
            justify-content: center;
            // margin-left:1rem;
            margin-top:0rem;
        }
        input{
            margin-top:1rem;
        }
        .button_layout{
            margin:2rem 0 1rem 0;
        }
    }
`