import styled from "styled-components"
 
export const RecommendDoctorStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    .clinic_search_container{
        display: flex;
        justify-content:space-between;
    }
/*===============================================
Theme 
===============================================*/

`