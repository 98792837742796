import styled from 'styled-components';

export const InputTextStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  /*===============================================
Theme 
===============================================*/
    .theme_standard{
        input{ 
            width : 100%;
            border-radius: 3rem;  
            line-height:1.9;
            border: 0.0625rem solid ${({ theme }) => theme.COLORS.PURPLE_1};
            outline:none;
            box-sizing: border-box;
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            opacity: 0.5;
            padding: 0.3rem 1.25rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        input:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_standard_2{
        input{ 
            border-radius: 0.5rem;  
            width:100%;
            /* box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18); */
            height:45.59px;
            font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
            border: 2px solid ${({ theme }) => theme.COLORS.BLUE_2};
            /* outline:none; */
            align-items:center;
            box-sizing: border-box;
            text-align:left;
            outline : none;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.3rem 3rem 0.3rem 1.25rem;
        }
        .button{
            position : relative;
           .button_wrapper{
                cursor: pointer;
                position: absolute;
                right: 1rem;
                top : -2rem;
           }
           &:active{
            transform : translateY(1px); 
           }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        input:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
          
  }
  .theme_standard_3{
      input{ 
            border-radius: 0.5rem;  
            width:100%;
            /* box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18); */
            height:45.59px;
            font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
            border: 1px solid rgb(0,0,0,0.2);
            /* outline:none; */
            align-items:center;
            box-sizing: border-box;
            text-align:left;
            outline : none;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.3rem 1.25rem;
            padding-right :${props => props.specialText ? '8rem' : '1.25rem'};
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        position : relative;
        .specialText{
           .specialText_wrapper{
               width : 100%;
               .specialText_wrapper_inner2{
                    position: absolute;
                    top : 0;
                    right: 0;
                    height:45.59px;
                    font-weight : bold;
                    color :${({ theme }) => theme.COLORS.BLACK_2};
                    line-height:45.59px;
                    width : 5rem;
                    padding-left : 1rem;
                    /* border : 1px solid ${({ theme }) => theme.COLORS.GRAY_1}; */
                    border-bottom-right-radius : 0.5rem;
                    border-top-right-radius : 0.5rem;
                    background-color :${({ theme }) => theme.COLORS.GRAY_1};
                }
                .specialText_wrapper_inner{
                    position: absolute;
                    right: 0;
                    top : 50%;
                    transform : translateY(-50%);
                    font-weight : bold;
                    color :${({ theme }) => theme.COLORS.BLACK_2};
                    line-height:45.59px;
                    width : 5rem;
                    padding-left : 1rem;
                    border-bottom-right-radius : 0.5rem;
                    border-top-right-radius : 0.5rem;
                    background-color :${({ theme }) => theme.COLORS.GRAY_1};
                }
           }
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_2};
        }
        input:read-only {
            background-color: transparent;
            border:none;
            padding : 0;
            /* font-size:${({ theme }) => theme.FONTS.SIZE_12px}; */
        }
        input::placeholder{
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
        }
          
    }
    .theme_standard_max_length {
        display: flex;
        .num_show {
            margin-left: 10px;
            display: flex;
            align-items: flex-end;
            padding-bottom: 2px;
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
        }
        input{ 
            border-radius: 0.5rem;  
            // width:100%;
            /* box-shadow: 1px 1px 10px -4px rgba(112,112,112,0.18); */
            height:45.59px;
            font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
            border: 1px solid rgb(0,0,0,0.2);
            /* outline:none; */
            align-items:center;
            box-sizing: border-box;
            text-align:left;
            outline : none;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.3rem 1.25rem;
            padding-right :${props => props.specialText ? '8rem' : '1.25rem'};
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            margin-left: 10px;
            display: flex;
            align-items: flex-end;
            padding-bottom: 2px;
        }
        position : relative;
        .specialText{
           .specialText_wrapper{
               width : 100%;
               .specialText_wrapper_inner2{
                    position: absolute;
                    top : 0;
                    right: 0;
                    height:45.59px;
                    font-weight : bold;
                    color :${({ theme }) => theme.COLORS.BLACK_2};
                    line-height:45.59px;
                    width : 5rem;
                    padding-left : 1rem;
                    /* border : 1px solid ${({ theme }) => theme.COLORS.GRAY_1}; */
                    border-bottom-right-radius : 0.5rem;
                    border-top-right-radius : 0.5rem;
                    background-color :${({ theme }) => theme.COLORS.GRAY_1};
                }
                .specialText_wrapper_inner{
                    position: absolute;
                    right: 0;
                    top : 50%;
                    transform : translateY(-50%);
                    font-weight : bold;
                    color :${({ theme }) => theme.COLORS.BLACK_2};
                    line-height:45.59px;
                    width : 5rem;
                    padding-left : 1rem;
                    border-bottom-right-radius : 0.5rem;
                    border-top-right-radius : 0.5rem;
                    background-color :${({ theme }) => theme.COLORS.GRAY_1};
                }
           }
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        input:read-only {
            background-color: transparent;
            border:none;
            padding : 0;
            /* font-size:${({ theme }) => theme.FONTS.SIZE_12px}; */
        }
        input::placeholder{
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
        }
    }
    .theme_standard_4{
        input{ 
            border-radius: 1rem;  
            width:100%;
            line-height:1.9;
            border: 0.0625rem solid ${({ theme }) => theme.COLORS.BLUE_3};
            outline:none;
            box-sizing: border-box;
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            opacity: 0.5;
            padding: 0.3rem 1.25rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        input:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_outline{
        input{ 
            border-radius: 0.5rem;  
            width:100%;
            line-height:1.9;
            border: none;
            outline:none;
            text-align:left;
            box-sizing: border-box;
            border:1px solid ${({ theme }) => theme.COLORS.BLUE_4};
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.3rem 1.25rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        input:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_outline_2{
        input{ 
            border-radius: 3rem;  
            width : 100%;
            box-sizing: border-box;
            border: none;
            outline:none;
            text-align:left;
            border:1px solid ${({ theme }) => theme.COLORS.BLUE_4};
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.7rem 1.25rem;
            font-weight :300;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        input:read-only {
            background-color: transparent;
            border:none;
            padding-left:0;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .theme_search{
        input{ 
            border-radius: 4rem;  
            width:100%;
            line-height:1.9;
            border: none;
            outline:none;
            text-align:left;
            box-sizing: border-box;
            border:2px solid ${({ theme }) => theme.COLORS.BLUE_3};
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            padding: 0.2rem 2rem 0.2rem 1rem;
        }
        .error{
            color: ${({ theme }) => theme.COLORS.BLUE_3};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            //margin-left:1rem;
            margin-top:0.3rem;
        }
        .isvalid{
            border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
        }
        .icon {
            position: absolute;
            right: 1.8rem;
            top: 0.8rem;
        }
        input:disabled{
            background-color: ${({ theme }) => theme.COLORS.WHITE_2};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
        input:read-only {
            background-color: transparent;
            font-size:${({ theme }) => theme.FONTS.SIZE_14px};
        }
    }
    .label_language{
        font-size:${({ theme }) => theme.FONTS.SIZE_14px};
    }
`;
