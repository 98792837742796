import React from 'react';
import { InputDateRangeSelectStyled } from './styled';
import cx from 'classnames';
import moment from 'moment'

export const InputDateRangeSelect = (props) => {
  const {
    theme_standard,
    maxWidth,
    minWidth,
    handleSelect
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const getDateCount = (value) => {
    switch (value) {
      case 'last_7d':
        return 6
      case 'last_14d':
        return 13
      case 'last_28d':
        return 27
      default:
    }
  }
  const handleChange = (e) => {
    const date = new Date(Date.now() - (getDateCount(e.target.value)) * 24 * 60 * 60 * 1000)
    const today_date = moment(new Date()).format('YYYY-MM-DD')
    const begin_date = moment(date).format('YYYY-MM-DD')
    handleSelect(begin_date , today_date)
  }

  return (
    <InputDateRangeSelectStyled {...props} maxWidth={maxWidth} minWidth={minWidth} >
      <div className={customClass}>
        <select onChange={(e) => handleChange(e)}>
          <option value='last_7d' >
            7 วันล่าสุด
          </option>
          <option value='last_14d' selected>
            14 วันล่าสุด
          </option>
          <option value='last_28d'>
            28 วันล่าสุด
          </option>
        </select>
      </div>
    </InputDateRangeSelectStyled>
  );
};
