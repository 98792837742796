import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	html{
		overflow:hidden;
		box-sizing: border-box;
		body { 
			margin: 0;
			font-size: 100%;
			// font-family: "Sukhumvit Set";
			// font-family: 'Lato', sans-serif;
			font-family: 'Sukhumvit Set','Lato','Prompt', sans-serif;
			position: absolute;
			background: ${({ theme }) => theme.COLORS.WHITE_3};
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			overflow: auto;
		}
	}
	@media print { html, body { height: initial !important; overflow: initial !important; }}
`;
