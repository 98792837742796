import React from 'react'
import { UserAccountFormStyled } from './styled'
import { change, Field, reduxForm } from 'redux-form'
import {
  ButtonCustom,
  InputSelect,
  InputText,
  SkeletonForm,
  ImgUpload,
  InputCheckbox,
  InputTextArea,
} from 'components'
import { connect } from 'react-redux'
import { VALIDATE } from 'assets'
import { userService } from 'apiService'
import { InputSelectFilter } from 'components/InputSelectFilter'
import { iconImages } from 'assets'
import ReactLoading from 'react-loading'
import { CheckPasswordBox } from 'components/CheckPasswordBox'

//
class UserAccountForm extends React.Component {
  state = {
    errorMsgDuplicate: '',
    errorMsgMaxLength: '',
    username: '',
    clinics: [],
    ToggleNext_btn: false,
    dp_profile_picture_url: false,
    isLoading: false,
    CheckLenghtpassword: null,
    CheckUpperPassword: null,
    CheckIsNumberpassword: null,
    requiredmentsList: [],
  }
  handleBlur = async (e) => {
    const { institution_id, realm } = this.props
    let username = `${e.target.value}@${realm}`
    this.setState({ username: username })
    if (username.length > 0) {
      let res = await userService.CHECK_USERNAME(institution_id, username)
      if (res && res.success) {
        if (res.data) {
          this.setState({
            errorMsgDuplicate: 'This username is already in use',
          })
        } else {
          this.setState({ errorMsgDuplicate: '' })
        }
      }
    }
    if (username.length < 4 || username.length > 32) {
      this.setState({
        errorMsgMaxLength: 'Must be between 4 and 32 characters.',
      })
    } else {
      this.setState({ errorMsgMaxLength: '' })
    }
  }

  renderClinics = () => {
    const { clinics } = this.props
    if (clinics) {
      const result = clinics.map((element, i) => {
        // console.log(element);
        return { value: element.id, label: element.name }
      })
      return result
    } else {
      return
    }
  }
  renderDefaultClinics = () => {
    const { profile } = this.props

    const result = profile?.clinics?.map((item) => {
      return { value: item.id, label: item.name }
    })
    return result
  }

  renderOptions = () => {
    const options = [
      { value: 'nurse', label: 'Nurse', permission: true },
      { value: 'doctor', label: 'Doctor', permission: true },
      {
        value: 'pharmacist',
        label: 'Pharmacist',
        permission: reduxPermission?.can_provider_pharmacist,
      },
      {
        value: 'translator',
        label: 'Translator',
        permission: reduxPermission?.can_consult_translator,
      },
      {
        value: 'accounting',
        label: 'Accounting',
        permission: reduxPermission?.should_accounting_on_finishing,
      },
    ]
    return options
      .filter((option) => option?.permission)
      .map((option, i) => {
        return (
          <option key={i} value={option?.value}>
            {option?.label}
          </option>
        )
      })
  }

  handleDoctorNextBtn = async () => {
    const { handleSubmit } = this.props
    const { creating } = this.state
    if (creating) {
      const res = await handleSubmit()
      if (res === true) {
        this.setState({ ToggleNext_btn: res })
      }
    } else {
      this.setState({ ToggleNext_btn: true })
    }
  }

  handleCallData = async () => {
    const { handlefetchbtn } = this.props

    this.setState({
      isLoading: true,
    })
    await handlefetchbtn()
    setTimeout(() => {
      this.setState({
        isLoading: false,
      })
    }, 1000)
  }

  render() {
    const {
      handleSubmit,
      loading,
      loadingSubmit,
      realm,
      creating,
      profile_picture_url,
      readonly,
      errorMsg,
      reduxPermission,
      handleMultiClinic,
      handleSelectFilter,
      handlefetchbtn,
      role,
      profile,
      doctorSync,
      doctor_profile,
    } = this.props

    const {
      errorMsgDuplicate,
      errorMsgMaxLength,
      username,
      isLoading,
      CheckIsNumberpassword,
      CheckLenghtpassword,
      CheckUpperPassword,
    } = this.state

    const handleChange = async (e) => {
      let password = null
      if (e.target.name === 'password') {
        password = e.target.value
        await checkPassword(password)
      }
    }

    const checkPassword = async (value) => {
      const requiredments = [
        { regex: /.{8,}/, index: 0, name: 'Lenght' },
        { regex: /[0-9]/, index: 1, name: 'Number' },
        { regex: /[A-Z]/, index: 2, name: 'UpperCase' },
      ]
      const checker = requiredments.map((item) => ({
        name: item.name,
        isValid: false,
      }))

      const newReq = checker.map((item, index) => {
        const isValid = requiredments[index].regex.test(value)
        return { ...item, isValid }
      })
      newReq.forEach((item) => {
        if (item?.name === 'Number') {
          this.setState({
            CheckIsNumberpassword: item?.isValid === true ? true : false,
          })
        }
        if (item?.name === 'Lenght') {
          this.setState({
            CheckLenghtpassword: item.isValid === true ? true : false,
          })
        }
        if (item?.name === 'UpperCase') {
          this.setState({
            CheckUpperPassword: item.isValid === true ? true : false,
          })
        }
      })
    }

    return (
      <UserAccountFormStyled>
        {errorMsg && <div className='error2'>{errorMsg}</div>}
        {loading ? (
          <SkeletonForm
            row={5}
            column={1}
            split_by_row={[1, 5]}
            position_button={'flex-end'}
            show_image={true}
          />
        ) : (
          <>
            <form onChange={handleChange} onSubmit={handleSubmit}>
              {role !== 'doctor' && (
                <div className='header_btn_container'>
                  <div>
                    {creating ? (
                      <div className='title_text'>
                        <div className='create_title'>
                          <b>
                            {role === 'nurse'
                              ? 'สร้างบัญชีสำหรับพยาบาล'
                              : role === 'pharmacist'
                              ? 'สร้างบัญชีสำหรับเภสัชกร'
                              : ''}
                          </b>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='button_container'>
                    {!errorMsgMaxLength && !errorMsgDuplicate ? (
                      <ButtonCustom
                        theme_yellow
                        loading={loadingSubmit}
                        label='บันทึก'
                        onClick={
                          handleSubmit
                          // CheckIsNumberpassword &&
                          // CheckLenghtpassword &&
                          // CheckUpperPassword
                          //   ? handleSubmit
                          //   : handleSubmit
                        }
                      />
                    ) : (
                      <ButtonCustom
                        theme_disable
                        label='บันทึก'
                        loading={loadingSubmit}
                      />
                    )}
                  </div>
                </div>
              )}
              {role === 'doctor' && (
                <div className='header_btn_container'>
                  {creating ? (
                    <div className='doctor_title_text'>
                      <div className='doctor_sync_title'>
                        <b>เชื่อมต่อบัญชีผู้ใช้งาน</b>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className='button_container'>
                    {!errorMsgMaxLength && !errorMsgDuplicate ? (
                      <ButtonCustom
                        theme_yellow
                        loading={this.state.isLoading}
                        label='บันทึก'
                        onClick={(e) => {
                          CheckIsNumberpassword &&
                          CheckLenghtpassword &&
                          CheckUpperPassword
                            ? handleSubmit(e, doctor_profile)
                            : handleSubmit(e, doctor_profile) //กรณี Doctor edit
                        }}
                      />
                    ) : (
                      <ButtonCustom
                        theme_disable
                        label='บันทึก'
                        loading={loadingSubmit}
                      />
                    )}
                  </div>
                </div>
              )}
              {/* here is ALl Role Edit Text Box */}
              <div className='row_container'>
                {
                  <div className='col_container_7'>
                    {role === 'doctor' && (
                      <div className='doctorInfomationTitle'>ข้อมูลแพทย์</div>
                    )}
                    {creating ? (
                      role === 'doctor' ? (
                        <></>
                      ) : (
                        <div className='row_container'>
                          <div className='fluid_col_container_Img'>
                            {/* <div className='label'>ชื่อ-นามสกุล</div> */}
                            <div className='field'>
                              <ImgUpload
                                readOnly={readonly?.image}
                                form={{ formName: 'UserAccountForm' }}
                                profile_picture_url={profile_picture_url}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      //กรณีEdit
                      <div className='row_container'>
                        <div className='fluid_col_container_Img'>
                          {/* <div className='label'>ชื่อ-นามสกุล</div> */}
                          <div className='field'>
                            <ImgUpload
                              readOnly={readonly?.image}
                              form={{ formName: 'UserAccountForm' }}
                              profile_picture_url={profile_picture_url}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {creating ? (
                      role === 'doctor' ? (
                        reduxPermission?.is_local_doctor ? (
                          <>
                            <div
                              className='row_container'
                              style={{ columnGap: '5px' }}>
                              <div className='fluid_col_container'>
                                <div className='label'>ชื่อ-นามสกุล</div>
                                <div className='field'>
                                  <Field
                                    theme_standard_3
                                    component={InputText}
                                    name='name'
                                    type='text'
                                    readOnly={readonly?.name}
                                    validate={[
                                      VALIDATE.REQUIRE_FIELD,
                                      VALIDATE.NUMBER_MAX_LENGTH_255,
                                    ]}
                                    placeholder='Firstname Lastname'
                                  />
                                </div>
                              </div>
                              <div className='fluid_col_container title_box'>
                                <div div className='row_container2'>
                                  <div className='label'>Title</div>
                                  <div className='field'>
                                    <Field
                                      theme_standard_3
                                      component={InputText}
                                      name='title'
                                      type='text'
                                      placeholder='หมอ'
                                      readOnly={readonly?.title}
                                      validate={[
                                        VALIDATE.REQUIRE_FIELD,
                                        VALIDATE.NUMBER_MAX_LENGTH_255,
                                      ]}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <div className='row_container'>
                          <div className='fluid_col_container'>
                            <div className='label'>ชื่อ-นามสกุล</div>
                            <div className='field'>
                              <Field
                                theme_standard_3
                                component={InputText}
                                name='name'
                                type='text'
                                readOnly={readonly?.name}
                                validate={[
                                  VALIDATE.REQUIRE_FIELD,
                                  VALIDATE.NUMBER_MAX_LENGTH_255,
                                ]}
                                placeholder='Firstname Lastname'
                              />
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      //กรณีEdit
                      <div className='row_container'>
                        <div className='fluid_col_container'>
                          <div className='label'>ชื่อ-นามสกุล</div>
                          <div className='field'>
                            <Field
                              theme_standard_3
                              component={InputText}
                              name='name'
                              type='text'
                              readOnly={readonly?.name}
                              validate={[
                                VALIDATE.REQUIRE_FIELD,
                                VALIDATE.NUMBER_MAX_LENGTH_255,
                              ]}
                              placeholder='Firstname Lastname'
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {reduxPermission?.can_ones_login && (
                      <div className='row_container2'>
                        <div className='label'>Ones ID </div>
                        <div className='field'>
                          <Field
                            theme_standard_3
                            component={InputText}
                            name='odid'
                            type='text'
                            // disabled={!readonly?.username && !creating}
                            // readOnly={!creating}
                            disabled={!creating}
                            readOnly={!creating}
                          />
                        </div>
                      </div>
                    )}
                    {creating ? (
                      role === 'doctor' ? (
                        <></>
                      ) : (
                        // role === 'admin' ? <></>
                        //   :
                        <div>
                          <div className='clinics_title_box'>
                            <div className='clinics_box'>
                              <div className='row_container2'>
                                <div className='label'>คลินิก</div>
                                <div className='field'>
                                  <Field
                                    is_newComponent
                                    isMulti
                                    theme_standard_6
                                    component={InputSelect}
                                    disabled={readonly?.clinic}
                                    profile={
                                      profile
                                        ? this.renderDefaultClinics()
                                        : false
                                    }
                                    name='clinics'
                                    handleChangeOption={handleMultiClinic}
                                    options={this.renderClinics()}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='title_box'>
                              <div div className='row_container2'>
                                <div className='label'>Title</div>
                                <div className='field'>
                                  <Field
                                    theme_standard_3
                                    component={InputText}
                                    name='title'
                                    type='text'
                                    placeholder='พยาบาล'
                                    readOnly={readonly?.title}
                                    validate={[
                                      VALIDATE.REQUIRE_FIELD,
                                      VALIDATE.NUMBER_MAX_LENGTH_255,
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div>
                        <div className='clinics_title_box'>
                          <div className='clinics_box'>
                            <div className='row_container2'>
                              <div className='label'>คลินิก</div>
                              <div className='field'>
                                <Field
                                  is_newComponent
                                  isMulti
                                  theme_standard_6
                                  component={InputSelect}
                                  disabled={readonly?.clinic}
                                  profile={
                                    profile
                                      ? this.renderDefaultClinics()
                                      : false
                                  }
                                  name='clinics'
                                  handleChangeOption={handleMultiClinic}
                                  options={this.renderClinics()}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='title_box'>
                            <div div className='row_container2'>
                              <div className='label'>Title</div>
                              <div className='field'>
                                <Field
                                  theme_standard_3
                                  in
                                  component={InputText}
                                  name='title'
                                  type='text'
                                  placeholder='พยาบาล'
                                  readOnly={readonly?.title}
                                  validate={[
                                    VALIDATE.REQUIRE_FIELD,
                                    VALIDATE.NUMBER_MAX_LENGTH_255,
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='username_password_box'>
                      <div className='username_box'>
                        <div className='row_container2'>
                          <div className='label'>
                            บัญชีผู้ใช้งาน
                            {username && (
                              <small
                                style={{
                                  opacity: 0.8,
                                }}>{` (${username})`}</small>
                            )}
                          </div>
                          <div className='field'>
                            <Field
                              theme_standard_3
                              component={InputText}
                              name='username'
                              type='text'
                              onBlur={(e) => this.handleBlur(e)}
                              specialText={creating && `@${realm}`}
                              disabled={!readonly?.username && !creating}
                              readOnly={readonly?.username}
                              placeholder='username'
                              validate={
                                creating && [
                                  VALIDATE.REQUIRE_FIELD,
                                  VALIDATE.ALPHA_NUMBERIC,
                                ]
                              }
                            />
                          </div>
                          {(errorMsgMaxLength || errorMsgDuplicate) && (
                            <div className='error'>
                              {errorMsgMaxLength}
                              {errorMsgDuplicate}
                            </div>
                          )}
                        </div>
                        {creating && role === 'doctor' && (
                          <div className='row_container2'>
                            <div className='label'>รหัสผ่าน</div>
                            <div className='field'>
                              <Field
                                theme_standard_3
                                component={InputText}
                                name='password'
                                type='password'
                                validate={
                                  creating
                                    ? [
                                        VALIDATE.REQUIRE_FIELD,
                                        VALIDATE.LENGTH_4_32,
                                        VALIDATE.PASSWORD_REGEX,
                                      ]
                                    : [
                                        VALIDATE.LENGTH_4_32,
                                        VALIDATE.PASSWORD_REGEX,
                                      ]
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='password_box'>
                        {creating && role === 'doctor' && (
                          <div className='clinics_box'>
                            <div className='row_container2'>
                              <div className='label'>คลินิก</div>
                              <div className='field'>
                                <Field
                                  is_newComponent
                                  isMulti
                                  theme_standard_6
                                  component={InputSelect}
                                  disabled={readonly?.clinic}
                                  profile={
                                    profile
                                      ? this.renderDefaultClinics()
                                      : false
                                  }
                                  name='clinics'
                                  handleChangeOption={handleMultiClinic}
                                  options={this.renderClinics()}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {!creating && role === 'doctor' ? (
                          <div className='row_container2'>
                            <div className='label'>รหัสผ่าน</div>
                            <div className='field'>
                              <Field
                                theme_standard_3
                                component={InputText}
                                name='password'
                                type='password'
                                validate={
                                  creating
                                    ? [
                                        VALIDATE.REQUIRE_FIELD,
                                        VALIDATE.LENGTH_4_32,
                                        VALIDATE.PASSWORD_REGEX,
                                      ]
                                    : [
                                        VALIDATE.LENGTH_4_32,
                                        VALIDATE.PASSWORD_REGEX,
                                      ]
                                }
                              />
                            </div>
                          </div>
                        ) : role !== 'doctor' ? (
                          <div className='row_container2'>
                            <div className='label'>รหัสผ่าน</div>
                            <div className='field'>
                              <Field
                                theme_standard_3
                                component={InputText}
                                name='password'
                                type='password'
                                validate={
                                  creating
                                    ? [
                                        VALIDATE.REQUIRE_FIELD,
                                        VALIDATE.LENGTH_4_32,
                                        VALIDATE.PASSWORD_REGEX,
                                      ]
                                    : [
                                        VALIDATE.LENGTH_4_32,
                                        VALIDATE.PASSWORD_REGEX,
                                      ]
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className='row_container2'>
                          <div className='label'>ยืนยันรหัสผ่าน</div>
                          <div className='field'>
                            <Field
                              theme_standard_3
                              component={InputText}
                              name='confirm_password'
                              type='password'
                              validate={
                                creating
                                  ? [
                                      VALIDATE.REQUIRE_FIELD,
                                      // VALIDATE.LENGTH_4_32,
                                      VALIDATE.PASSWORD_MATCH,
                                    ]
                                  : VALIDATE.PASSWORD_MATCH
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`checkpasswordContainer ${
                        role === 'doctor'
                          ? creating
                            ? 'left'
                            : 'right'
                          : 'right'
                      }`}>
                      <CheckPasswordBox
                        CheckIsNumberpassword={CheckIsNumberpassword}
                        CheckLenghtpassword={CheckLenghtpassword}
                        CheckUpperPassword={CheckUpperPassword}
                      />
                    </div>
                  </div>
                }
              </div>
              {/* here is Doctor Role sync */}
              {role === 'doctor' && !reduxPermission?.is_local_doctor && (
                <div className='doctor_container'>
                  <div className='doctor_lower_container'>
                    <div className='doctor_box'>
                      <div className='doctor_sync_container'>
                        <div className='doctor_sync_box'>
                          {/* <div className='doctor_sync_box_title'>เรียกข้อมูลผู้ใช้งาน</div> */}
                          <div className='doctor_filter_box'>
                            <div>
                              <InputSelectFilter
                                theme_standard
                                label='Doctor ID'
                                change={handleSelectFilter}
                                profile={
                                  profile?.doctor
                                    ? profile?.doctor?.code
                                    : false
                                }
                                disabled={
                                  profile?.doctor?.code || !doctorSync
                                    ? true
                                    : false
                                }
                                clear={doctorSync}
                              />
                            </div>
                            {doctorSync ? (
                              <div style={{ width: '148px' }}>
                                <ButtonCustom
                                  icon='arrow_round.svg'
                                  theme_blue_8
                                  label='เรียกข้อมูล'
                                  onClick={() => this.handleCallData()}
                                />
                              </div>
                            ) : (
                              <div style={{ width: '148px' }}>
                                <ButtonCustom
                                  icon='arrow_round.svg'
                                  theme_disable
                                  label='เรียกข้อมูล'
                                  // onClick={()=> this.handleCallData()}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='doctor_profile_container'>
                        {doctor_profile.length > 0 ? (
                          <>
                            {doctor_profile.map((item) => {
                              return (
                                <>
                                  <div className='doctor_profile_box'>
                                    <div className='mb10'>รูปโปรไฟล์</div>
                                    <div>
                                      <img
                                        className='pic_box_border'
                                        src={
                                          item?.profile_picture_url
                                            ? item?.profile_picture_url
                                            : iconImages['grayheadshot.svg']
                                        }
                                        style={{
                                          width: '77px',
                                          height: '77px',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='doctor_profile_description'>
                                    <div className='content_profile_container'>
                                      <div className='dp_name bb'>
                                        <div>
                                          {`ชื่อ-สกุล :`}{' '}
                                          <span>{item?.name_th}</span>
                                        </div>
                                      </div>
                                      <div className='dp_id bb'>
                                        <div>
                                          {`Doctor ID :`}{' '}
                                          <span>{item?.code}</span>
                                        </div>
                                        <div></div>
                                      </div>
                                    </div>
                                    <div className='content_profile_container'>
                                      <div className='dp_hospital bb'>
                                        <div>
                                          {`โรงพยาบาล :`}{' '}
                                          <span>
                                            {item?.account?.name || '-'}
                                          </span>
                                        </div>
                                      </div>
                                      <div className='dp_clinic bb'>
                                        <div>
                                          {`คลินิก :`}{' '}
                                          <span>{`${item?.clinic_ref_name}`}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='content_profile_container'>
                                      <div className='dp_school bb'>
                                        <div className='pt10'>
                                          ประวัติการศึกษา
                                        </div>
                                        {item?.studies?.map((studies) => {
                                          return (
                                            <div className='subItemWapper'>
                                              <div>
                                                {`วุฒิการศึกษา :`}{' '}
                                                <span>
                                                  {studies?.education}
                                                </span>
                                              </div>
                                              <div>
                                                {`สถาบัน :`}{' '}
                                                <span>
                                                  {studies?.institution}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                      <div className='dp_exp bb'>
                                        <div className='pt10'>
                                          ประวัติการทำงาน
                                        </div>
                                        {item?.works?.map((works) => {
                                          return (
                                            <div className='subItemWapper'>
                                              <div>
                                                {`ประสบการณ์ :`}{' '}
                                                <span>{works?.place}</span>{' '}
                                              </div>
                                              <div>
                                                {`ตำแหน่ง :`}{' '}
                                                <span>{works?.position}</span>
                                              </div>
                                              <div>
                                                {`ระยะเวลา :`}{' '}
                                                <span>
                                                  {works?.start_year &&
                                                  works?.end_year
                                                    ? `${works.start_year}-${works.end_year}`
                                                    : '-'}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </>
                        ) : (
                          <>
                            <div className='doctor_not_found_container'>
                              {isLoading && (
                                <ReactLoading
                                  type='bubbles'
                                  color='#00A59B'
                                  width='2rem'
                                  height='2rem'
                                />
                              )}
                              {doctor_profile?.length === 0 && !isLoading && (
                                <div className='doctor_not_found_title'>
                                  ไม่พบข้อมูล
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </>
        )}
      </UserAccountFormStyled>
    )
  }
}

const mapStateToProps = (state) => ({
  reduxPermission: state.permission,
})
UserAccountForm = reduxForm({
  form: 'UserAccountForm', // a unique identifier for this form
  enableReinitialize: true,
})(UserAccountForm)

UserAccountForm = connect(
  (state) => ({
    initialValues: {
      ...state.user_profile,
      odid: state.user_profile?.odid || '-',
      role: state.user_profile ? state.user_profile.role : 'doctor',
      name: state.user_profile.name === '' ? '-' : state.user_profile.name,
      title: state.user_profile.title || '-',
      [state.clinics?.length > 0 &&
      state.clinics.map(
        (clinic, i) => i === 0 && `code_${clinic.code}`,
      )[0]]: true,
    },
  }),
  {},
)(UserAccountForm)

export default connect(mapStateToProps)(UserAccountForm)
