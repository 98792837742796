import {
  USER_AUTHEN,
  DESTROY_SESSION,
  TAB_ACTIVE,
  INSTITUTIONS,
  INSTITUTION_DETAIL,
  USER_PROFILE,
  CLINIC_PROFILE,
  CLINICS,
  INSTITUTION_ACTIVE,
  CLINIC_ACTIVE,
  THEMEPRIVACY,
  UPDATE_TERMS_EN,
  UPDATE_TERMS_TH
} from './actionTypes';

export function setReduxUserAuth(data) {
  return {
    type: USER_AUTHEN,
    data,
  };
}
export function clearStore(data) {
  return {
    type: DESTROY_SESSION,
    data,
  };
}
export function setTabActive(data) {
  return {
    type: TAB_ACTIVE,
    data,
  };
}
export function setReduxInstitutions(data) {
  return {
    type: INSTITUTIONS,
    data,
  };
}
export function setReduxInstitutionDetail(data) {
  return {
    type: INSTITUTION_DETAIL,
    data,
  };
}
export function setReduxUserProfile(data) {
  return {
    type: USER_PROFILE,
    data,
  };
}
export function setReduxClinicProfile(data) {
  return {
    type: CLINIC_PROFILE,
    data,
  };
}
export function setReduxClinics(data) {
  return {
    type: CLINICS,
    data,
  };
}
export function setReduxInstitutionActive(data) {
  return {
    type: INSTITUTION_ACTIVE,
    data,
  };
}
export function setReduxClinicActive(data) {
  return {
    type: CLINIC_ACTIVE,
    data,
  };
}

export function setEditTextTH(data) {
  return {
    type: UPDATE_TERMS_TH,
    data,
  };
}
export function setEditTextEN(data) {
  return {
    type: UPDATE_TERMS_EN,
    data,
  };
}

export function setReduxTheme(data) {
  return {
    type: THEMEPRIVACY,
    data,
  };
}










