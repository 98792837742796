import styled from "styled-components"

export const InputDatePickerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    position: relative;
    .close{
        position : absolute;
        top : -1.8rem;
        font-size : 16px;
    }
`