import styled from 'styled-components'
import { iconImages } from 'assets'

export const AssessmentContainerStyled = styled.div`
  /* height: 100vh; */
  /* background-color: aliceblue; */
  .header {
    font-family: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`
