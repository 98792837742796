import React, { useState } from 'react'
import { TableToggleCustomStyled } from './styled'
import cx from 'classnames'
import { CSSTransitionGroup } from 'react-transition-group'

export const TableToggleCustom = ({
  theme_standard,
  theme_standard_2,
  columns,
  columns2,
  columns3,
  columns4,
  data,
  useNo,
  borderColorHead,
  bodyRowBg,
  bgClassNameRow,
  detailOpen,
  detailID
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
  })
  const [index, setindex] = useState()

  const handleClickCollape = (e) => {
    if (e.id === index) {
      setindex(false)
    } else {
      setindex(e.id)
    }
  }
  const getDescendantProp = (obj, desc) => {
    var arr = desc.split('.')
    while (arr.length && (obj = obj[arr.shift()]));
    return obj
  }
  return (
    <TableToggleCustomStyled
    // borderColorHead={borderColorHead}
    // bodyRowBg={bodyRowBg}
    >
      <div className={customClass}>
        <div className='table_container'>
          <div className='table_header'>
            <div className='header_row'>
              {columns &&
                columns.map((e, i) => (
                  <div key={i} className='header_col' style={e.headerStyle}>
                    {e.renderTitle ? e.renderTitle : e.title}
                  </div>
                ))}
            </div>
          </div>
          <div className={'table_body'}>
            <CSSTransitionGroup
              transitionName={{
                enter: 'fade-enter',
                leave: 'fade-leave',
                appear: 'fade-appear',
              }}
              transitionAppear={true}
              transitionAppearTimeout={500}
              transitionLeaveTimeout={500}
              transitionEnterTimeout={500}>
              {data &&
                data.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className='body_row'
                      style={{
                        background: e.bodyRowBg,
                      }}>
                      <div className='detail_row'>
                        {columns.map((e1, i2) => (
                          <div key={i2} className='body_col' style={e1.style}
                            onClick={() => {
                              !e1.cantCollape && handleClickCollape(e)
                            }}
                          >
                            {useNo && i2 === 0 ? (
                              <div className='no'>
                                {e1.render
                                  ? e1.render({
                                    origin: e,
                                    value: i + 1,
                                  })
                                  : i + 1}
                              </div>
                            ) : e1.render ? (
                              e1.render({
                                origin: e,
                                value: getDescendantProp(e, e1.field),
                              })
                            ) : (
                              getDescendantProp(e, e1.field)
                            )}
                            {/* {isShow && (data[i].id === index ? true : false) && (
                                <div
                                  className='col_right'
                                  onClick={() => handleClickCollape(e)}>
                                  <img
                                    src={iconImages['chevron_top.png']}
                                    alt='cl'
                                    className={`${
                                      isShow &&
                                      (data[i].id === index ? true : false)
                                        ? ''
                                        : 'rotate'
                                    }`}
                                  />
                                  {`${
                                    isShow &&
                                    (data[i].id === index ? true : false)
                                      ? 'ย่อหน้าต่าง'
                                      : ' '
                                  }`}
                                </div>
                              )} */}
                          </div>
                        ))}
                      </div>
                      {(data[i].id === index ? true : false) && (
                        <div className='table_container'>
                          <div className='table_header2'>
                            <div className='header_row2'>
                              <div className='col_title'>ข้อมูลเพิ่มเติม</div>
                              <div className='col_head'>
                                <div className='flex_head'>
                                  {columns2.map((e1, i1) => (
                                    <div key={i1} className='header_col2'>
                                      {e1.title}
                                    </div>
                                  ))}
                                </div>
                                <div className='flex_head'>
                                  {columns2.map((e1, i2) => (
                                    // <div key={i2} className="body_col2" style={{ color: "#9966FF" }}>
                                    <div key={i2} className='header_col2'>
                                      {e1.render({
                                        origin: data[i],
                                        value: getDescendantProp(e, e1.field),
                                      })}
                                    </div>
                                  ))}
                                </div>

                                <div className='flex_head'>
                                  {columns3.map((e1, i1) => (
                                    <div key={i1} className='header_col2'>
                                      {e1.title}
                                    </div>
                                  ))}
                                </div>
                                <div className='flex_head'>
                                  {columns3.map((e1, i2) => (
                                    // <div key={i2} className="body_col2" style={{ color: "#9966FF" }}>
                                    <div key={i2} className='header_col2'>
                                      {e1.render({
                                        value: getDescendantProp(e, e1.field),
                                      })}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <div className='table_body'>
                            <div
                              key={i}
                              className='body_row2'
                              style={
                                {
                                  // background: e.bodyRowBg,
                                }
                              }>
                              <div className='flex_head2'>
                                {columns4.map((e2, i1) => (
                                  // <div key={i2} className="body_col2" style={{ color: "#9966FF" }}>
                                  <div key={i1} className='header_col2'>
                                    {e2.render({
                                      value: getDescendantProp(e, e2.field),
                                    })}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
            </CSSTransitionGroup>
          </div>
        </div>
      </div>
    </TableToggleCustomStyled>
  )
}
