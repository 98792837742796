import React from 'react';
import { ClinicProfileFormStyled } from './styled';
import { Field, reduxForm } from 'redux-form';
import {
  ButtonCustom,
  SkeletonForm,
  InputText,
  InputCheckbox,
  ImgUpload3,
  // Typography,
} from 'components';
import { connect } from 'react-redux';
import { VALIDATE } from 'assets';
import { userService } from 'apiService';
import { InputSelectFilter } from 'components/InputSelectFilter';
// import theme from 'assets/theme.json'


class ClinicProfileForm extends React.Component {
  state = {
    duplicateCode: ''
  }

  handleBlur = async (e) => {
    let code = e.target.value
    if (code.length > 0) {
      let res = await userService.CHECK_CLINIC_CODE(e.target.value)
      if (res && res.success) {
        if (res.data) {
          this.setState({ duplicateCode: 'This code is already in use' })
        } else {
          this.setState({ duplicateCode: '' })
        }
      }
    }
  }

  render() {
    const { duplicateCode } = this.state;
    const { handleSubmit, onIcons,  creating, loading, profile_picture_url, loadingSubmit, errorMsg, permission } = this.props;
    return (
      <ClinicProfileFormStyled  >
        {errorMsg && <div className='error2'>{errorMsg}</div>}
        {loading
          ? <SkeletonForm
            row={2}
            column={2}
            split_by_row={[1]}
            position_button={'flex-end'}
          />
          : <form onSubmit={handleSubmit}>
            <>
            <div className='button_container2'>
              <ButtonCustom
                theme_yellow={!duplicateCode}
                label='บันทึก'
                loading={loadingSubmit}
                onClick={handleSubmit}
              />
            </div>
            <div className='form_container'>
              <div className='form_content'>
                <div className='header_title'>
                  <div className='title'>การเพิ่มรายชื่อคลินิก</div>
                  <div className='sub_title'>คุณจะไม่สามารถเพิ่มรายชื่อในคลินิกที่มีอยู่แล้วแต่หากต้องการแก้ไขกรุณาไปที่ชื่อคลินิกที่ต้องการแก้ไข</div>
                </div> 
                <div className='body_container'>
                  {/* <div className='body_call_data' style={{padding: '10px 0'}}>
                    <div style={{width:'100%'}}>
                      <InputSelectFilter
                        theme_standard_2
                        label='Clinic ID'
                      />
                    </div>
                    <div style={{width:'10rem'}}>
                      <ButtonCustom
                        icon='arrow_round.svg'
                        theme_blue_8
                        label='เรียกข้อมูล'
                      />  
                    </div>
                  </div> */}
                  <div className='body_form_container'>
                    <div className='col_container'>
                      <ImgUpload3
                        form={{ formName: 'ClinicProfileForm' }}
                        handleIconsmodal = {onIcons}
                        profile_picture_url = {profile_picture_url}
                      />
                    </div>
                    <div className='row_container'>

                      <div className='col_container_2'>
                        <div className='label'>CODE</div>
                        <div className='field'>
                          <Field
                            theme_standard_3
                            component={InputText}
                            name='code'
                            type='text'
                            disabled={!creating}
                            onBlur={(e) => this.handleBlur(e)}
                            validate={[VALIDATE.LENGTH_4_32, VALIDATE.ALPHA_NUMBERIC]}
                          />
                        </div>
                        {duplicateCode && <div className='error'>{duplicateCode}</div>}
                      </div>
                      <div className='col_container_2'>
                        <div className='label'>Ref Code</div>
                        <div className='field'>
                          <Field
                            theme_standard_3
                            component={InputText}
                            name='ref_id'
                            type='text'
                            validate={ VALIDATE.ALPHA_NUMBERIC}
                          />
                        </div>
                        {duplicateCode && <div className='error'>{duplicateCode}</div>}
                      </div>

                    </div>
                    {/* <div> */}
                      <div className='row_container'>
                        <div className='fluid_col_container'>
                          <div className='label'>Clinic Name</div>
                          <div className='field'>
                            <Field
                              theme_standard_3
                              component={InputText}
                              name='name'
                              type='text'
                              validate={[VALIDATE.REQUIRE_FIELD, VALIDATE.NUMBER_MAX_LENGTH_255]}
                            />
                          </div>
                        </div>
                      </div>
                    {/* </div> */}
                    {/* <div> */}
                      <div className='row_container'>
                        <div className='fluid_col_container'>
                          <div className='label'>Description</div>
                          <div className='field'>
                            <Field
                              theme_standard_3
                              component={InputText}
                              name='description'
                              type='text'
                              validate={VALIDATE.NUMBER_MAX_LENGTH_255}
                            />
                          </div>
                        </div>
                      </div>
                    {/* </div> */}
                  </div>

                  <div> {/* Section Option */}
                    <div className='label' style={{ marginBottom: '0.5rem' }}>Option</div>
                    <div className='checkbox' style={{ width: '7rem' }} >
                      <Field
                        theme_transparent_4
                        component={InputCheckbox}
                        name='is_active'
                        disabled={!permission?.can_active_clinic}
                        type='checkbox'
                        label='เปิดบริการ'
                      />
                    </div>
                    <div className='checkbox' style={{ width: '14.5rem' }} >
                      <Field
                        theme_transparent_4
                        component={InputCheckbox}
                        name='is_visible'
                        type='checkbox'
                        label='แสดงให้คนไข้เห็นรายชื่อคลินิก'
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
            
            </>
            {/* <div className='row_container'>
              <div className='col_container'>
                <div className='label'>Clinic Name</div>
                <div className='field'>
                  <Field
                    theme_standard_3
                    component={InputText}
                    name='name'
                    type='text'
                    validate={[VALIDATE.REQUIRE_FIELD, VALIDATE.NUMBER_MAX_LENGTH_255]}
                  />
                </div>
              </div>
              <div className='col_container'>
                <div className='label'>CODE</div>
                <div className='field'>
                  <Field
                    theme_standard_3
                    component={InputText}
                    name='code'
                    type='text'
                    disabled={!creating}
                    onBlur={(e) => this.handleBlur(e)}
                    validate={[VALIDATE.LENGTH_4_32, VALIDATE.ALPHA_NUMBERIC]}
                  />
                </div>
                {duplicateCode && <div className='error'>{duplicateCode}</div>}
              </div>
            </div>
            <div className='row_container'>
              <div className='fluid_col_container'>
                <div className='label'>Description</div>
                <div className='field'>
                  <Field
                    theme_standard_3
                    component={InputText}
                    name='description'
                    type='text'
                    validate={VALIDATE.NUMBER_MAX_LENGTH_255}
                  />
                </div>
              </div>
            </div>
            <div className='row_container'>
              <div className='fluid_col_container'>
                <div className='label'>Clinic Photo</div>
                <div className='field' style={{paddingLeft:'15px'}}>
                  <ImgUpload2
                    form={{ formName: 'ClinicProfileForm' }}
                    profile_picture_url={profile_picture_url}
                  />
                </div>
              </div>
            </div>
            <div className='label' style={{ marginBottom: '0.5rem' }}>Option</div>
            <div className='checkbox' style={{ width: '7rem' }} >
              <Field
                theme_transparent_2
                component={InputCheckbox}
                name='is_active'
                disabled={!permission?.can_active_clinic}
                type='checkbox'
                label='เปิดบริการ'
              />
            </div>
            <div className='checkbox' style={{ width: '14.5rem' }} >
              <Field
                theme_transparent_2
                component={InputCheckbox}
                name='is_visible'
                type='checkbox'
                label='แสดงให้คนไข้เห็นรายชื่อคลินิก'
              />
            </div> */}
            {/* <div className='button_container'> */}
              {/* {!duplicateCode
                ? <ButtonCustom
                  theme_blue={!duplicateCode}
                  label='บันทึก'
                  loading={loadingSubmit}
                  onClick={handleSubmit}
                />
                : <ButtonCustom
                  theme_disable
                  label='บันทึก'
                  loading={loadingSubmit}
                />
              } */}
              {/* <ButtonCustom
                theme_blue={!duplicateCode}
                label='บันทึก'
                loading={loadingSubmit}
                onClick={handleSubmit}
              />
            </div> */}
          </form>
        }
      </ClinicProfileFormStyled>
    );
  }
}

ClinicProfileForm = reduxForm({
  form: 'ClinicProfileForm', // a unique identifier for this form
  enableReinitialize: true,
})(ClinicProfileForm);

ClinicProfileForm = connect(
  state => ({
    initialValues: {
      ...state.clinic_profile,
    }
  }),
  {}
)(ClinicProfileForm)


export default ClinicProfileForm

