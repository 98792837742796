import styled from "styled-components";

export const SearchBarStyle =  styled.div`

    .search_container{
        width:282px;
        height: 40px;
        border: 1px solid #eee;
        border-radius: 5px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        column-gap: 5px;
        font-family: "noto_sans_thai_medium, noto_sans_regular";
        background-color: ${({theme})=> theme.COLORS.WHITE_1};
        .search_icons{
        margin-left: 20px;
        }
        .rm_search_border{
            border: none;
            outline: none;
            font-family: "noto_sans_thai_medium, noto_sans_regular";
            max-width: 200px;
            width: 200px;
            font-size: ${({ theme }) => theme.FONTS.SIZE_5};
        }
    }



`