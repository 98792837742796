import React from 'react'
import { connect } from 'react-redux'
import { userService } from 'apiService'
import { withRouter } from 'react-router-dom'
import { ROUTE_PATH } from 'assets'
import {
  clearStore,
  setReduxInstitutionActive,
  // setReduxUserPermission,
  setReduxUserAuth,
} from 'store/actions'
import { Emitter } from 'assets'

class TokenManager extends React.Component {
  state = {
    isToken: false,
  }
  componentDidMount = () => {
    Emitter.on('GET_LOGOUT', async (e) => {
      this.getLogout(e)
    })
    Emitter.on('REDIRECT_HOME', (e) => {
      this.redirectHome()
    })
    window.addEventListener('beforeunload', this.unMountToken, false)
    const pathname = window.location.pathname
    const redirectToHome = pathname === '/login' || pathname === '/'
    this.hasSession(redirectToHome)
  }
  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.unMountToken, false)
    this.unMountToken()
  }
  unMountToken = () => {
    // Emitter.off('GET_LOGOUT')
    // Emitter.off('REDIRECT_HOME')
  }
  hasSession = (redirect) => {
    const sessionToken = sessionStorage.getItem('itele_admin_protal_token')
    // const localToken = JSON.parse(localStorage.getItem('tokenList'));
    if (sessionToken) {
      this.checkSessionToken(redirect)
    } else {
      this.hasLocal(redirect)
    }
  }
  hasLocal = (redirect) => {
    const localToken = localStorage.getItem('itele_admin_protal_token')
    // const localToken = JSON.parse(localStorage.getItem('tokenList'));
    if (localToken) {
      this.checkStorageToken(redirect)
    } else {
      this.getLogout()
    }
  }
  checkStorageToken = async (redirect) => {
    let res = await userService.POST_LOGIN_WITHTOKEN_BY_LOCALSTORGAE()
    if (res && res.success) {
      localStorage.setItem('itele_admin_protal_token', res.token)
      sessionStorage.setItem('itele_admin_protal_token', res.token)
      redirect ? this.redirectHome() : this.setState({ isToken: true })
    } else {
      this.getLogout()
    }
  }
  checkSessionToken = async (redirect) => {
    let res = await userService.POST_LOGIN_WITHTOKEN()
    if (res && res.success) {
      localStorage.setItem('itele_admin_protal_token', res.token)
      sessionStorage.setItem('itele_admin_protal_token', res.token)
      redirect ? this.redirectHome() : this.setState({ isToken: true })
    } else {
      this.hasLocal()
    }
  }

  getAccount = async () => {
    let res = await userService.GET_ACCOUNTS()
    if (res && res.success) {
      return res.data[0]
    } else {
      return false
    }
  }
  redirectHome = () => {
    const { auth, history, setReduxInstitutionActive } = this.props;
    const { role } = auth;
    switch (role) {
      case 'system_admin':
        history.push(ROUTE_PATH.SETTINGS)
        break
      case 'account_admin':
        this.getAccount().then((e) => {
          setReduxInstitutionActive(e.id)
          const account_id = e.id
          history.push(ROUTE_PATH.SETTINGS_DETAIL + account_id)
        })
        break
      default:
        history.push(ROUTE_PATH.LOGIN)
        break
    }
    this.setState({ isToken: true })

  }
  getLogout = async (e) => {
    const { clearStore } = this.props;
    localStorage.removeItem('itele_admin_protal_token');
    sessionStorage.removeItem('itele_admin_protal_token');
    await clearStore(false)
    e && await userService.GET_LOGOUT()
    this.setState({ isToken: true })
    this.props.history.push(ROUTE_PATH.LOGIN)
  }

  render() {
    const { children } = this.props
    return this.state.isToken ? children : <div></div>
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  reduxPermission: state.permission,
})

const mapDispatchToProps = (dispatch) => ({
  clearStore: (data) => dispatch(clearStore(data)),
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxInstitutionActive: (data) => dispatch(setReduxInstitutionActive(data)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TokenManager),
)
