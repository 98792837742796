
import moment from 'moment'

export const getBeginDate = (days) => {
  const back_date = days ? (days - 1) : 13
  const date = moment(new Date(Date.now() - back_date * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
  return date
}

export const getCurrentDate = () => {
  const date = moment(new Date()).format('YYYY-MM-DD')
  return date
}