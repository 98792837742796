import React, { useEffect, useState } from 'react'
import { InputSelectStyled } from './styled'
import cx from 'classnames'
import Select from 'react-select'

export const InputSelect = ({
  theme_standard,
  theme_standard_2,
  theme_standard_3,
  theme_standard_4,
  theme_standard_5,
  theme_standard_6,
  theme_outline,
  children,
  input,
  disabled,
  readOnly,
  maxWidth,
  minWidth,
  onChange,
  is_newComponent,
  options,
  isMulti,
  handleChangeOption,
  profile,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_standard_3: theme_standard_3,
    theme_standard_4: theme_standard_4,
    theme_standard_5: theme_standard_5,
    theme_standard_6: theme_standard_6,
    theme_outline: theme_outline,
  })
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (profile) {
      setValue(profile);
    }
  }, []);

  const changing =(e) => {
    handleChangeOption(e)
    setValue(e)
  }


const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    height: '45.5px',
    borderRadius:'0.5rem',
    overflow:'unset',
    overflowX: 'hidden',
  }),
};


  return (
    <InputSelectStyled maxWidth={maxWidth} minWidth={minWidth}>
      <div className={customClass}>
        <div className="line"></div>
        {
          is_newComponent ? 
          <div className='select_index'>
            <Select
              value={value} 
              styles={customSelectStyles}
              isMulti={isMulti}
              isDisabled={disabled}
              options={options}
              onChange={(e)=>changing(e)}
            />
          </div>
          :
          (
            <div>
              {readOnly
                ? <div className='readonly'>
                  {readOnly.value}
                </div>
                : <select {...input} disabled={disabled} onChange={(e) => onChange ? onChange(e) : input.onChange(e)} >
                  {children}
                </select>
              }
            </div>
          )
        }
        

      </div>
    </InputSelectStyled>
  )
}
