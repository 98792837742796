import styled from "styled-components"

export const ImgUploadStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    .row{
        padding-right:0;
        display: flex;
        justify-content: flex-start;
        input[type="file"] {
            display: none;
        }
        column-gap: 2rem;
    }
    h3{
        font-size:${({ theme }) => theme.FONTS.SIZE_22px}; 
        color:${({ theme }) => theme.COLORS.BLUE_3}; 
    }
    .upload_box{
        //position:relative;
        background-color:${({ theme }) => theme.COLORS.WHITE_1}; 
        border:1px solid ${({ theme }) => theme.COLORS.BLUE_18}; 
        // min-width:60%;
        // max-width:100%;
        margin-top:10px;
        width:77px;
        height:77px;
        text-align:center; 
        border-radius:10rem;
        overflow: hidden;
        /* padding: 0.3rem 0.3rem;  */
        box-sizing : border-box;
        cursor:potiner;
        .upload_box_border{
            background-color:${({ theme }) => theme.COLORS.WHITE_1}; 
            border-radius:0.5rem;
            text-align:center; 
            height : 100%;
            img{
                width : 3rem;
                display : flex;
                justify-content : center;
                height : 100%;
                flex-direction : column;
                text-align : center;
                margin-left : auto ;
                margin-right : auto;
            }
            /* padding: 1.5625rem 1.5625rem; */
        }
        .profile{
            margin: auto;
            object-fit: cover;
            width:77px;
            height:77px;
            //position:absolute
            box-sizing : border-box;
            img{ 
                width : 100%;
                height : 100%;
                border-radius:0.5rem;
                object-fit: cover;
            }
        }
    }
    .mt-3{
        margin-top:1rem;
    }
    .upload_btn_container{
        padding-right:0;
        display: block;
        flex-direction: column;
        column-gap: 1.5rem;
        .upload_title{
            font-size: ${({ theme }) => theme.FONTS.SIZE_18px};
        }
        .upload_label{
            margin-top: 10px;
            background: ${({ theme }) => theme.COLORS.BLUE_18};
            display: flex;
            width: 144px;
            height: 32px;
            border-radius: 0.5rem;
            border: none;
            outline:none;
            cursor: pointer;
            &:hover {  
                opacity:0.9
            } 
        }
        input[type="file"] {
            display: none;
        }
        .custom-file-uploads {
            color:${({ theme }) => theme.COLORS.WHITE_1};
            font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
            font-weight: bold;
            width:100%;
            padding: 0.5rem 0;
            text-align : center;
        }
    }
`