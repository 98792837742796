import styled from "styled-components"
 
export const InputDateFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    .content{
        margin-left: 1.5rem;
        position: relative;
        .input-date{
            display: flex;
            .input-label{
                cursor: pointer;
                font-size:12px;
                margin-right: 1rem;
            }
            .icon{
                cursor: pointer;
                width: 0.8rem;
            }
        }
        .box-date{
            position: absolute;
            right: 0;
            top : 100;
            z-index: 2;
            margin-top: 10;
        }
        .backdrop {
            position: relative;
            .backdrop_outer {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 1;
                opacity: 0;
            }
            .backdrop_inner {
             
            }
        }
    }
`