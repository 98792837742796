import React from 'react';
import { InstitutionProfileFormStyled } from './styled';
import { Field, reduxForm } from 'redux-form';
import {
  ButtonCustom,
  InputSelect,
  InputTextArea,
  InputText,
  Typography,
  InputCheckbox
} from 'components';
import { connect } from 'react-redux';
// import { VALIDATE } from 'assets';
import theme from 'assets/theme.json'


class InstitutionProfileForm extends React.Component {

  render() {
    const { handleSubmit, is_creating, loadingSubmit } = this.props;
    return (
      <InstitutionProfileFormStyled  >
        {/* <div className="title" >
          <Typography label="Confirm Payment" color={({ theme }) => theme.COLORS.BLUE_3} size={({ theme }) => theme.FONTS.SIZE_20px} />
        </div> */}
        {!is_creating &&
          <Typography label={is_creating ? "Create New Institution Profile" : 'ข้อมูลทั่วไป'} weight={500} color={theme.COLORS.BLUE_3} size={theme.FONTS.SIZE_30px} />
        }
        <form onSubmit={handleSubmit}>
          <div className='row_container'>
            <div className='fluid_col_container'>
              <div className='label'>Institution Name</div>
              <div className='field'>
                <Field
                  theme_standard_3
                  component={InputText}
                  name='username'
                  type='text'
                />
              </div>
            </div>
          </div>
          <div className='row_container'>
            <div className='fluid_col_container'>
              <div className='label'>Address</div>
              <div className='field'>
                <Field
                  theme_standard_3
                  component={InputText}
                  name='address'
                  type='text'
                />
              </div>
            </div>
          </div>
          <div className='row_container'>
            <div className='fluid_col_container'>
              <div className='label'>Description</div>
              <div className='field'>
                <Field
                  theme_standard_3
                  component={InputTextArea}
                  name='description'
                  type='text'
                />
              </div>
            </div>
          </div>
          <div className='button_container'>
            <ButtonCustom
              theme_blue
              loading={loadingSubmit}
              label='บันทึก'
              onClick={handleSubmit}
            />
          </div>
        </form>
      </InstitutionProfileFormStyled>
    );
  }
}

InstitutionProfileForm = reduxForm({
  form: 'InstitutionProfileForm', // a unique identifier for this form
  enableReinitialize: true,
})(InstitutionProfileForm);

InstitutionProfileForm = connect(
  state => ({
    initialValues: {
    }
  }),
  {}
)(InstitutionProfileForm)


export default InstitutionProfileForm

