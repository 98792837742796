import React from 'react'
import { connect } from 'react-redux'
import { CmsMainLayoutStyled } from './styled'
import { Route, Switch } from 'react-router-dom'
import systemRoutes from 'routes/systemRoutes'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import 'moment/locale/th.js'
import { Sidebar } from 'widgets'
import { getAllowedRoutes } from 'assets'
import { PageNotFound} from 'components'

class CmsMainLayout extends React.Component {
  state = {
    isActive: false,
  }
  componentDidMount() {
    this.fetchPermission()
    this.setLocale()
  }
  setLocale = () => {
    moment.locale('th')
    momentTZ.defineLocale('th', moment.localeData()._config)
    momentTZ.locale('th')
  }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.onListeningScreenSize)
  // }

  fetchPermission = () => {
    const { role } = this.props.reduxAuth;
    this.setState({
      routeSelected: role ? getAllowedRoutes(systemRoutes, role) :systemRoutes
    })
  }

  handelActive = (e)=>{
    this.setState({
      isActive: e?.event
    })
  }

  render() {
    const { routeSelected ,isActive} = this.state
    // const { authRedux, reduxPermission } = this.props
    return (
      <CmsMainLayoutStyled>
        <div className='sidebar_layout'>
          <Sidebar theme_standard 
            isActive={this.handelActive}
          />
        </div>
        <div className={`content_layout ${isActive ? 'content_width':'content_ml'}`}>
          <div className='inner_content_layout'>
            <Switch>
              {routeSelected &&
                routeSelected.map((e, i) => {
                  return !e.sub ? (
                    <Route
                      key={i}
                      exact={e.exact}
                      path={e.path}
                      component={e.component}
                    />
                  ) : (
                      e.subComponent.map((e2, i2) => (
                        <Route
                          key={i2}
                          exact={e2.exact}
                          path={e2.path}
                          component={e2.component}
                        />
                      ))
                    )
                })}
              <Route path="*" exact={true}>
              <PageNotFound permission={true} />
              </Route>
            </Switch>
          </div>
        </div>
        {/* <IconFullScreen />
        <Sidebar
          styles={{ sidebar: { background: 'white' } }}
          sidebar={
            <SidebarContent
              menuList={routeSelected}
              name={authRedux && authRedux.user.name}
              avatar={authRedux && authRedux.user.profile_picture_url}
              logo={reduxPermission && reduxPermission.theme.logo_white}
              propsHistory={this.props.history}
            />
          }
          open={sidebarOpen}
          docked={docked}
          onSetOpen={this.onSetSidebarOpen}>
          <div className='content_layout'>
            <div className='hamburger' onClick={this.onSetSidebarOpen}>
              <div className='line' />
              <div className='line' />
              <div className='line' />
            </div>
            <ContainerCustom>
              // <Switch>
              //   {routeSelected &&
              //     routeSelected.map((e, i) => {
              //       return !e.sub ? (
              //         <Route
              //           key={i}
              //           exact={e.exact}
              //           path={e.path}
              //           component={e.component}
              //         />
              //       ) : (
              //         e.subComponent.map((e2, i2) => (
              //           <Route
              //             key={i2}
              //             exact={e2.exact}
              //             path={e2.path}
              //             component={e2.component}
              //           />
              //         ))
              //       )
              //     })}
              // </Switch>
            </ContainerCustom>
          </div>
        </Sidebar> */}
      </CmsMainLayoutStyled>
    )
  }
}

const mapStateToProps = (state) => ({
  reduxAuth: state.auth,
  reduxPermission: state.permission,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CmsMainLayout)
