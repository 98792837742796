import styled from "styled-components"
 
export const SettingsListsStyled = styled.div`
/*===============================================
 Container 
===============================================*/
.middleline{
            /* แถวสีฟ้าแก้ ตรงนี้ */
            margin-top: 1rem;
            display: flex;
            justify-content: flex-start;
            height: 38px;
            align-items: center;
            background-color: ${({ theme }) => theme.COLORS.BLUE_17};
            border-radius: 5px;
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            .middleline_text{
                display: flex;
                justify-content: center;
                padding-left: 20px;
            }
        }
/*===============================================
Theme 
===============================================*/

`