import React, { useState } from 'react'
import { InputDatePickerStyled } from './styled'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

export const InputDatePicker = ({
  theme_standard,
  input,
  meta,
}) => {
  const [startDate, setStartDate] = useState(new Date())

  return (
    <InputDatePickerStyled>
      <DatePicker
        selected={input.value ? moment(input.value).toDate() : null}
        onChange={input.onChange}
        className={`input_datepicker ${meta && (meta.touched && meta.error && "isvalid")}`}
        dateFormat="dd MMM yyyy"
        // minDate={isMinDate ? new Date(isMinDate) : ''}
        // maxDate={isMaxDate ? new Date(isMaxDate) : ''}
        dropdownMode="select"
        inline
      // disabled={disabled}
      // placeholderText={placeholder}
      // ref={(c) => _calendar = c}
      // readOnly={readOnly}
      />
      {/* <div className='close' onClick={handleClose}>Close</div> */}
    </InputDatePickerStyled>
  )
}
