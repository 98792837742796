import React, { useEffect, useRef, useState } from 'react';

const BoxComponent = ({ children}) => {
    const wrapperRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setIsOpen(true);
    }, []);

    return (
        <div ref={wrapperRef}>
            {isOpen && children}
        </div>
    );
};

export default BoxComponent;
