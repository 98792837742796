import React, { useEffect, useState } from 'react';
import { BarLineStyled } from './styled';
import cx from 'classnames';
import Chart from 'react-apexcharts'

export const BarLine = (props) => {
  const { theme_standard, data } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });
  useEffect(() => {
    if (data) {
      // setSeries(data.map((item) => item.value))
      setOptions({
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
        },
        stroke: {
          width: data?.series.map(item => { return item.type === 'line' ? 3 : 0 }),
          // curve: 'strag'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        // labels: data?.categories ,
        markers: {
          size: 4
        },
        xaxis: {
          categories: data?.categories,
        },
      })
    }
  }, [data])

  useEffect(() => {
    if (data) {
      setSeries(data?.series)
    }
  }, [data])

  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])
  return (
    <BarLineStyled {...props}>
      <div className={customClass}>
        <Chart
          options={options}
          series={series}
          height={400}
          width='100%'
          style={{
            paddingBottom: '1rem'
          }}
        />
      </div>
    </BarLineStyled>
  );
};
