import styled from "styled-components"

export const InfoInstitutionFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
   
`