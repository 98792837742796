import React, { useEffect, useState } from 'react';
import { AreaLineStyled } from './styled';
import cx from 'classnames';
import Chart from 'react-apexcharts'

export const AreaLine = (props) => {
  const { theme_standard, data } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });
  useEffect(() => {
    if (data) {
      // setSeries(data.map((item) => item.value))
      setOptionsType1({
        chart: {
          stacked: true,
          toolbar: {
            show: false
          },
        },
        colors: ['#05cd05', '#f51d30'],
        stroke: {
          curve: 'smooth',
          width: 0,
        },
        markers: {
          size: 0
        },
        xaxis: {
          categories: data?.categories,
          tickAmount: data?.categories?.length > 16 ? data?.categories?.length / 4 : undefined,
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Sukhumvit Set,Lato,Prompt, sans-serif',
              fontWeight: 400,
            },
          }
        },
        yaxis: {
          max: 100,
          tickAmount: 5,
          title: {
            text: "Success Rate (%)"
          }
        },
      })
    }
    setOptionsType2({
      chart: {
        // stacked: true,
        toolbar: {
          show: false
        },
      },
      colors: ['#feb019', '#008ffb', '#00e396'],
      stroke: {
        curve: 'smooth',
        width: 0,
      },
      markers: {
        size: [0, 2, 0],
        strokeWidth: 1,
      },
      xaxis: {
        categories: data?.categories,
        tickAmount: data?.categories?.length > 16 ? data?.categories?.length / 4 : undefined,
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'Sukhumvit Set,Lato,Prompt, sans-serif',
            fontWeight: 400,
          },

        }
      },
      yaxis: {
        tickAmount: 5,
        title: {
          text: "Response Time (ms)"
        }
      },
      // yaxis: data?.series?.seriesRate,
    })
  }, [data])

  useEffect(() => {
    if (data) {
      setSeriesType1(data?.series?.seriesRate)
      setSeriesType2(data?.series?.seriesTime)
    }
  }, [data])

  const [optionsType1, setOptionsType1] = useState({})
  const [seriesType1, setSeriesType1] = useState([])

  const [optionsType2, setOptionsType2] = useState({})
  const [seriesType2, setSeriesType2] = useState([])
  return (
    <AreaLineStyled {...props}>
      <div className={customClass}>
        <Chart
          options={optionsType1}
          series={seriesType1}
          height={250}
          width='100%'
          style={{
          }}
        />
        <Chart
          options={optionsType2}
          series={seriesType2}
          height={250}
          width='100%'
          style={{
            paddingBottom: '1rem',

          }}
        />
      </div>
    </AreaLineStyled>
  );
};
