import styled from "styled-components"

export const DashboardProviderStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
.row_custom{
        height : 100%;
        display : flex;
        .col_custom_2{
            width : 20%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_3{
            width : 25%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_9{
            width : 65%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_4_3{
            width : 35%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
        .col_custom_6{
            width : 50%;
            &:first-child{
                margin-right : 0.5rem;
            }
            &:last-child{
                margin-left : 0.5rem;
            }
        }
        .col_custom_4{
            width : 33.3333333%;
            padding : 0 0.25rem;
            &:first-child{
                padding-left : 0;
            }
            &:last-child{
                padding-right : 0;
            }
        }
    }
`