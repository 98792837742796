import styled from "styled-components"

export const InstitutionProfileFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    width : 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE_16px};
    border-bottom : 1px solid  ${({ theme }) => theme.COLORS.BLUE_15};
    padding-bottom : 2rem;
    margin-bottom : 2rem;
    .field{
       width : 100%;
       margin-top:0.5rem;
    }
    .label{
        font-weight : 500;
    }
    .button_container{
        margin-left : auto;
        margin-right : 0.1rem;
        margin-bottom : 1rem;
        padding-top : 1rem;
        width : 10rem;
    }
    .title{
        margin:1rem 0 1.5rem 0;
        padding-bottom : 0.5rem;
        border-bottom : 1px solid  ${({ theme }) => theme.COLORS.BLUE_15};
    }
    .row_container2{
        margin-bottom:0.2rem;
        display : flex;
        width : 100%;
        &:first-child{
            margin-top : 1rem;
        }
        .col_container{
            width : 50%;
            &:nth-child(odd) {
                margin-right : 1rem;
            }
            &:nth-child(even) {
                margin-left : 1rem;
            }
        }
        .fluid_col_container{
            width : 100%;
        }
    }
    .row_container{
        margin-bottom:1.4rem;
        display : flex;
        width : 100%;
        &:first-child{
            margin-top : 1rem;
        }
        .col_container{
            width : 50%;
            &:nth-child(odd) {
                margin-right : 1rem;
            }
            &:nth-child(even) {
                margin-left : 1rem;
            }
        }
        .fluid_col_container{
            width : 100%;
        }
    }
   
`