import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { reducer as formReducer } from 'redux-form';
import {
  CLINICS,
  CLINIC_ACTIVE,
  CLINIC_PROFILE,
  DESTROY_SESSION,
  INSTITUTIONS,
  INSTITUTION_ACTIVE,
  INSTITUTION_DETAIL,
  TAB_ACTIVE,
  USER_AUTHEN,
  USER_PROFILE,
  THEMEPRIVACY,
  UPDATE_TERMS_EN,
  UPDATE_TERMS_TH
} from '../actions/actionTypes';
import { permission } from './PermissionState'
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";


const auth = (state = false, action) => {
  switch (action.type) {
    case USER_AUTHEN:
      return action.data;
    default:
      return state;
  }
};
const tab_active = (state = false, action) => {
  switch (action.type) {
    case TAB_ACTIVE:
      return action.data;
    default:
      return state;
  }
};
const institutions = (state = false, action) => {
  switch (action.type) {
    case INSTITUTIONS:
      return action.data;
    default:
      return state;
  }
};
const institution_detail = (state = false, action) => {
  switch (action.type) {
    case INSTITUTION_DETAIL:
      return action.data;
    default:
      return state;
  }
};
const institution_active = (state = false, action) => {
  switch (action.type) {
    case INSTITUTION_ACTIVE:
      return action.data;
    default:
      return state;
  }
};
const user_profile = (state = false, action) => {
  switch (action.type) {
    case USER_PROFILE:
      return action.data;
    default:
      return state;
  }
};
const clinics = (state = false, action) => {
  switch (action.type) {
    case CLINICS:
      return action.data;
    default:
      return state;
  }
};
const clinic_profile = (state = false, action) => {
  switch (action.type) {
    case CLINIC_PROFILE:
      return action.data;
    default:
      return state;
  }
};
const clinic_active = (state = false, action) => {
  switch (action.type) {
    case CLINIC_ACTIVE:
      return action.data;
    default:
      return state;
  }
};

const ThemePrivacy = (state = false, action) => {
  switch (action.type) {
    case THEMEPRIVACY:
      return action.data;

    case UPDATE_TERMS_EN:
      return {
        ...state,
        terms: {
          ...state.terms,
          en: action.data
        }
      };
    case UPDATE_TERMS_TH:
      return {
        ...state,
        terms: {
          ...state.terms,
          th: action.data
        }
      };
    
    default:
      return state;
  }
};

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    state = undefined
  }
  return appReducer(state, action)
}
const appReducer = combineReducers({
  form: formReducer,
  permission,
  auth,
  tab_active,
  institutions,
  institution_detail,
  user_profile,
  clinics,
  clinic_profile,
  institution_active,
  clinic_active,
  ThemePrivacy
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'tabNueseDetailActive'],
};
const middlewares = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares)));
let persistor = persistStore(store);

export { store, persistor };