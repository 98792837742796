import styled from "styled-components"

export const MessageLogStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
        .container-wrapper{
            .title{
                display: flex;
                justify-content: flex-start;
                padding : 0 ${props => props.isPrinting ? '1rem' : '0'};
                padding-top : ${props => props.isPrinting ? '1rem' : '0'};
                margin-bottom: 1rem;
                .left{
                    .label{
                        color: ${({ theme }) => theme.COLORS.PURPLE_1};
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .label2{
                        color: ${({ theme }) => theme.COLORS.PURPLE_1};
                        font-size: 13px;
                        opacity: 0.8;
                    }
                }
                .print{
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    :hover{
                        cursor: pointer;
                    }
                    .label{
                        color:  ${({ theme }) => theme.COLORS.GRAY_11};
                    }
                }
            }
            .message-wrapper{
                background-color:  ${props => props.isPrinting ? ({ theme }) => theme.COLORS.WHITE_1 : ({ theme }) => theme.COLORS.GRAY_12};
                font-size:  ${({ theme }) => theme.FONTS.SIZE_14px};
                min-height: 55vh;
                 width: 100%;
                 box-sizing: border-box;
                .item{
                    padding : 0 1rem;
                    padding-bottom : 1rem;
                    :first-child{
                        padding-top : 1rem;
                    }
                    .row{
                        display: flex;
                        align-items: center;
                    }
                    .avatar{
                        border-radius: 5rem;
                        object-fit: contain;
                        margin-right: 0.5rem;
                        .pic{
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                    .circle{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 0.5rem;
                        border-radius: 5rem;
                        width: 2rem;
                        height: 2rem;
                        background-color: white;
                        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_11};
                        .label{
                            font-size:  ${({ theme }) => theme.FONTS.SIZE_16px};
                            font-weight: 600;
                            color: ${({ theme }) => theme.COLORS.PURPLE_1};
                        }
                    }
                    .name{
                        font-weight: 600;
                    }
                    .time{
                        min-width: 4rem;
                        max-width: 4rem;
                        margin-left: 0.5rem;
                    }
                    .bubble{
                        margin-top: 0.25rem;
                        background-color:  ${props => props.isPrinting ? ({ theme }) => theme.COLORS.GRAY_10 : ({ theme }) => theme.COLORS.WHITE_1};
                        padding : 0.5rem 1rem;
                        border-radius: 0.4rem;
                        font-size:  ${({ theme }) => theme.FONTS.SIZE_14px};
                        max-width: 40rem;
                        width: 100%;
                        box-sizing: border-box;
                        word-wrap: break-word;
                        @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
                            max-width: 30rem;
                        }
                        @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
                            max-width: 15rem;
                        }
                        .attachments {
                            width: 100%;
                            .msg_image {
                                /* border-top : 1px solid rgb(0,0,0,0.1);
                                padding-top : 0.5rem; */
                                height: 200px;
                                width: 200px;
                                border-radius: 8px;
                                margin-bottom: 0.5rem;
                                margin-top: 0.5rem;
                                box-sizing: border-box;
                                img {
                                    object-fit: cover;
                                    border-radius: 3px;
                                }
                            }
                            .msg_doc {
                                /* border-top : 1px solid rgb(0,0,0,0.1);
                                padding-top : 1rem; */
                                margin: 0.5rem 0;
                                display: flex;
                                flex-direction: row;
                                width:100%;
                                align-items: center;
                                color : ${props => props.isPrinting && 'black'};
                                text-decoration: ${props => props.isPrinting && 'none'};
                                .name{
                                    font-weight: 500;
                                    white-space: pre;
                                    margin-left: 1rem;
                                }
                                .dowload {
                                    cursor: pointer;
                                    color: #5a4095;
                                    &:hover {
                                    transform: translateY(1px);
                                    }
                                }
                            }
                        }
                    }
                    .system{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .system_bubble{
                        display : flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        font-weight: 600;
                        margin-top: 0.25rem;
                        background-color:  ${({ theme }) => theme.COLORS.GRAY_10};
                        color:  ${({ theme }) => theme.COLORS.GRAY_11};
                        padding : 0.7rem 1.5rem;
                        border-radius: 0.4rem;
                        font-size:  ${({ theme }) => theme.FONTS.SIZE_14px};

                    }
                }
            }
        }      
`
export const ContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    max-height: 80vh;
    min-height: 60vh;
    max-width: 50rem;
    min-width: 35rem;
    @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
        min-width: 10rem;
    }
    overflow: scroll;
    ::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
    }
    ::-webkit-scrollbar-button {
    width: 0.375rem;
    height: 0.375rem;
    }
    ::-webkit-scrollbar-thumb {
    background:${({ theme }) => theme.COLORS.GRAY_2};
    border: 0rem solid #ffffff;
    border-radius: 3.125rem;

    }
    ::-webkit-scrollbar-thumb:hover {

    }
    ::-webkit-scrollbar-thumb:active {
    background:${({ theme }) => theme.COLORS.BLUE_3};
    }
    ::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0rem none #ffffff;
    border-radius: 0rem;
    margin-top:3.4rem;
    }
    ::-webkit-scrollbar-track:hover {
    }
    ::-webkit-scrollbar-track:active {
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    }
    ::-webkit-scrollbar-corner {
    background: transparent;
    }
/*===============================================
Theme 
===============================================*/
   
`