import styled from "styled-components"
 
export const PageNotFoundStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .notfound {
        .container_custom{
            display : flex ;
            justify-content : center;
            flex-direction : column;
            height : 100vh;
            text-align : center;
            .container_custom_wrapper{
                margin-top: -2rem;
                margin-left : auto;
                margin-right: auto;
                .title{
                    display : flex ;
                    justify-content : center;
                }
                .button{
                    cursor: pointer; 
                    margin-left : 0.5rem;
                    text-decoration-line: underline;
                    &:hover{
                        opacity : 0.8;
                    }
                    &:active{
                        transform : translateY(1px);
                    }
                }
            }
        }
    }
    .permission {
        .container_custom{
            display : flex ;
            justify-content : center;
            flex-direction : column;
            height : 100vh;
            text-align : center;
            .container_custom_wrapper{
                margin-top: -5rem;
                margin-left : auto;
                margin-right: auto;
                .title{
                    display : flex ;
                    justify-content : center;
                }
                .button{
                    cursor: pointer; 
                    margin-left : 0.5rem;
                    text-decoration-line: underline;
                    &:hover{
                        opacity : 0.8;
                    }
                    &:active{
                        transform : translateY(1px);
                    }
                }
            }
        }
    }
`