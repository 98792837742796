import React, { } from 'react';
import { GlobalStyle } from 'assets';
import { Provider } from 'react-redux';
import { store, persistor } from 'store/reducers';
import { ThemeProvider } from 'styled-components';
import theme from 'assets/theme.json';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import TokenManager from './contexts/TokenManager'
import Routes from 'routes'
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <TokenManager>
            <Routes />
            </TokenManager>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
