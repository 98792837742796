import styled from "styled-components"
 
export const BoxIconsStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
/* style={{display:'block',padding: '20px',justifyContent:'space-between',width:'90vw',height:'679px',backgroundColor:'#F1F2F4',color:'black'}} */

    .theme_Icons {
        line-height: 1.25;
        width: 80vw;
        /* box-sizing: border-box; */
        border-radius: 10px;
        height:auto;
        background-color:#F1F2F4;
        border-radius: 8px;
        position: relative;
        .iconsModalSection{
            display: flex;
            padding: 20px;
            justify-content:space-between;
            
            color:black;
        }
        .bodySection{
            .optionSection
            {
                display: flex;
                column-gap: 20px;
                width: 100%;
                background-color: none;
                padding: 0px 20px;
                .optionItem{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                    color: black;
                    width:200px;
                    height:50px;
                    box-shadow: 0 -5px 10px -8px rgba(0, 0, 0, 0.3);
                    background-color: ${({ theme }) => theme.COLORS.BLUE_18};
                    color: ${({ theme }) => theme.COLORS.WHITE_1};
                    opacity: 0.5;
                    &.active{
                        background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                        color: ${({ theme }) => theme.COLORS.BLUE_17};
                        opacity: 1;
                    }
                }
            }
            .uploadContainer{
                display: flex;
                align-items: center;
                justify-content: center;
                /* min-height: 565px; */
                width: 100%;
                .body_Container{
                    min-height: 565px;
                    width:100%;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    background-color: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
                .btn-upload{
                    width: 20rem;
                }
            }
            .previewsIconsContainer{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
            }
            .selectIconsContainer{
                padding: 20px;
                background-color: #fff;
                /* min-height: 500px; */
                display: flex;
                justify-content: center;
                align-items: center;
                .iconsDisplay{
                    display: grid;
                    /* width: 100%; */
                    grid-template-columns: repeat(9, 1fr);
                    grid-column-gap: 10px;
                    grid-row-gap: 10px;
                    .iconsItems{
                        display: flex;
                        justify-content:center;
                        align-items:center;
                        /* border: 1px solid ${({theme})=> theme.COLORS.BLUE_18}; */
                    }
                    .icons{
                        max-width:100px;
                        max-height:100px;
                        border-radius: 8rem;
                        &.active{
                            border: 4px solid ${({theme})=> theme.COLORS.BLUE_18};
                            border-radius: 8rem;
                        }
                    }
                }
                @media only screen and (max-width: 1400px) {
                    .iconsDisplay{
                        grid-template-columns: repeat(8, 1fr);
                    }
                }
                @media only screen and (max-width: 1250px) {
                    .iconsDisplay{
                        grid-template-columns: repeat(7, 1fr);
                        overflow: scroll;
                    }
                }
                .transparent_container{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    border: 2px solid rgba(0,0,0,0.0);
                    padding:5px;
                    &.active{
                        border: 2px solid ${({theme}) => theme.COLORS.YELLOW_2};
                    }
                    .icons{
                        z-index:0;        
                    }
                    .icons_name{
                        display: flex;
                        justify-content: center;
                        align-items: end;
                        background-color: rgba(0,0,0,0.1);
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        .icons_text{
                            display: flex;
                            justify-content: center;
                            align-items: end;
                            width:100%;
                            height:fit-content;
                            font-size: ${({theme})=>theme.FONTS.SIZE_14px};
                            background-color: rgba(0,0,0,0.3);
                            /* text-align: center; */
                            color: #fff;
                        }
                        /* bottom: 0; */
                        /* left: 30px; */
                    }
                }
            }
            .uploadSelectIconsContainer{
                display: flex;
                justify-content: end;
                align-items: center;
                width: 100%;
                padding: 10px;
                .uploadButtonSelect{
                    width: 10rem;
                }
            }
        }
    }




    .theme_standard {
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
       .box{
        width : 25rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 1rem;
                padding-bottom : 1rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align :left;
                padding-bottom : 1rem;
            }
       }
       .button_layout{
           margin-top:1rem;
           display :flex ;
           justify-content : center;
           .div1{
               width : 40%;
           }
           .div2{
               width : 40%;
           }
       }
    }
    .theme_error {
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
       .box{
        max-width : 25rem;
        min-width : 14rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                padding-bottom : 0.5rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 2rem;
                margin-top : 1.7rem;
                color : ${({ theme }) => theme.COLORS.BLUE_3}; 
                font-weight: 500;
            }
       }
       
    }
    .theme_success{
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
       .box{
        width : 25rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 1rem;
                padding-bottom : 1rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align :center;
                font-weight : lighter;
                padding-bottom : 2rem;
            }
       }
    }
    .theme_confirm {
        display : flex;
        justify-content: center;
        margin-right : auto;
        margin-left:auto;
        text-align : center;
        border: 5px solid ${({theme})=>theme.COLORS.YELLOW_2};
        border-radius:15px;
        .box{
        width : 25rem;
        margin:0.5rem;
        font-weight :lighter;
            .title{
                text-align : center;
                display : flex;
                justify-content: center;
                margin-bottom : 1rem;
                padding-bottom : 1rem;
                border-bottom :1px solid  ${({ theme }) => theme.COLORS.BLUE_15}; 
            }
            .desc{
                text-align :left;
                padding-bottom : 1rem;
            }
        }
        .button_layout{
            margin-top:1rem;
            display :flex ;
            justify-content : center;
            column-gap:10px;
            .div1{
                width : 40%;
            }
            .div2{
                width : 40%;
            }
        }
    }
`