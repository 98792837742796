import styled from 'styled-components'

export const TableToggleCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
    .table_container {
      .table_header {
        padding-top: 17px;
        /* padding-bottom: 14px; */
        // border-radius: 4px;
        .header_row {
          display: flex;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 16px 0;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            background: ${({ theme }) => theme.COLORS.BLUE_10};
            border: 1px solid ${({ theme }) => theme.COLORS.BLUE_10};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      hr {
        width: 80%;
        opacity: 0.2;
        margin-bottom: 24px;
      }
      .table_body {
        .body_row_onchange,
        .body_row {
          width: 100%;
          cursor: pointer;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          padding: 6px 0;
          /* align-items: center; */
          border-bottom: 1px solid rgb(0, 0, 0, 0.1);
          /* &:last-child{
            border: 1px solid rgb(0,0,0,0.1);
          } */
          .col_right {
            opacity: 0.2;
            font-weight: 400;
            position: absolute;
            left: 88%;
            bottom: 35%;
            img {
              width: 12px;
              animation: roll 10s infinite;
              margin-right: 4px;
              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
          .no {
            width: fit-content;
          }
          .detail_row {
            display: flex;
            .body_col {
              font-weight: 400;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
              /* background-color : red; */
              text-overflow: ellipsis;
            }
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.WHITE_1 + '80'};
          }
          /* .col {
            display: flex;
            flex-direction: column;
            .flex {
             display: flex;
            }
          }  */
        }
      }
      .table_header2 {
        padding-top: 17px;
        padding-bottom: 14px;
        margin-bottom: 14px;
        .header_row2 {
          display: flex;
          margin: 0px 9%;
          padding-left: 1rem;
          .col_title {
            width: 20%;
            flex-shrink: 0;
          }
          .col_head {
            display: flex;
            width: 88%;
            flex-direction: column;
            margin-left: 2%;
            .flex_head {
              display: flex;
              .header_col2 {
                flex-direction: column;
                width: 300px;
                padding: 0px;
                color: ${({ theme }) => theme.COLORS.BLACK_1};
                &:first-child {
                  border-left: none;
                }
              }
            }
          }
        }
      }
      .body_row2 {
        cursor: pointer;
        margin-bottom: 14px;
        padding: 0px 0;
        align-items: center;
        justify-content: space-evenly;
        margin: 0px 2%;
        .body_col2 {
          width: 100%;
          font-weight: 400;
          padding: 0;
          margin-left: 5px;
          height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .col {
          display: flex;
          flex-direction: column;
          .flex {
            display: flex;
          }
        }
      }
      .checkbox_layout {
        float: left;
        margin-left: 19px;
      }
    }
  }
  .flex_head2 {
    display: flex;
    justify-content: space-evenly;
  }


  .theme_standard_2 {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
    .table_container {
      .table_header {
        padding-top: 17px;
        /* padding-bottom: 14px; */
        // border-radius: 4px;
        .header_row {
          display: flex;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 16px 0;
            /* color: ${({ theme }) => theme.COLORS.GREY_6}; */
            /* background: ${({ theme }) => theme.COLORS.BLUE_10}; */
            border: 1px solid ${({ theme }) => theme.COLORS.BLUE_10};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      hr {
        width: 80%;
        opacity: 0.2;
        margin-bottom: 24px;
      }
      .table_body {
        .body_row_onchange,
        .body_row {
          width: 100%;
          margin: 14px 0;
          cursor: pointer;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          padding: 6px 0;
          /* align-items: center; */
          border-bottom: 1px solid rgb(0, 0, 0, 0.1);
          border-radius: 8px;
          /* &:last-child{
            border: 1px solid rgb(0,0,0,0.1);
          } */
          .col_right {
            opacity: 0.2;
            font-weight: 400;
            position: absolute;
            left: 88%;
            bottom: 35%;
            img {
              width: 12px;
              animation: roll 10s infinite;
              margin-right: 4px;
              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
          .no {
            width: fit-content;
          }
          .detail_row {
            display: flex;
            .body_col {
              font-weight: 400;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
              /* background-color : red; */
              text-overflow: ellipsis;
            }
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.WHITE_1 + '80'};
          }
          /* .col {
            display: flex;
            flex-direction: column;
            .flex {
             display: flex;
            }
          }  */
        }
      }
      .table_header2 {
        padding-top: 17px;
        padding-bottom: 14px;
        margin-bottom: 14px;
        .header_row2 {
          display: flex;
          margin: 0px 9%;
          padding-left: 1rem;
          .col_title {
            width: 20%;
            flex-shrink: 0;
          }
          .col_head {
            display: flex;
            width: 88%;
            flex-direction: column;
            margin-left: 2%;
            .flex_head {
              display: flex;
              .header_col2 {
                flex-direction: column;
                width: 300px;
                padding: 0px;
                color: ${({ theme }) => theme.COLORS.BLACK_1};
                &:first-child {
                  border-left: none;
                }
              }
            }
          }
        }
      }
      .body_row2 {
        cursor: pointer;
        margin-bottom: 14px;
        padding: 0px 0;
        align-items: center;
        justify-content: space-evenly;
        margin: 0px 2%;
        .body_col2 {
          width: 100%;
          font-weight: 400;
          padding: 0;
          margin-left: 5px;
          height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .col {
          display: flex;
          flex-direction: column;
          .flex {
            display: flex;
          }
        }
      }
      .checkbox_layout {
        float: left;
        margin-left: 19px;
      }
    }
  }

`
