import React, { useEffect, useState } from 'react'
import { SettingsListsStyled } from './styled'
import { ButtonCustom, Middleline, Pagination, TableCustom } from 'components'
import { useHistory } from 'react-router-dom'
import { ROUTE_PATH } from 'assets'
import { useDispatch, useSelector } from 'react-redux'
import { userService } from 'apiService'
import { setReduxInstitutionActive, setReduxInstitutions } from 'store/actions'
import { calculateSkeleton } from 'assets'
import moment from 'moment'

export const SettingsLists = (props) => {
  const current_instiution = useSelector((state) => state?.institution_active)

  const history = useHistory()
  const dispatch = useDispatch()
  const [page, setPage] = useState()
  const [sort, setSort] = useState({
    sort_by: 'name',
    order_by: 'ASC',
  })

  const [filter, setFilter] = useState({
    offset: 0,
    per_page: 20,
  })
  useEffect(() => {
    fetchData()
  }, [filter])

  useEffect(() => {
    if (current_instiution) {
      dispatch(setReduxInstitutionActive(false))
    }
  }, [current_instiution])

  const fetchData = async () => {
    let res = await userService.GET_ACCOUNTS(
      true,
      filter.per_page,
      filter.offset,
      sort.sort_by,
      sort.order_by,
    )
    if (res && res.success) {
      setPage(res.page)
      setSort(res.sort)
      setInstitutions(setNewtInstitutions(res.data))
      dispatch(setReduxInstitutions(setNewtInstitutions(res.data)))
    } else {
    }
  }
  const setNewtInstitutions = (data) => {
    return (
      data &&
      data.map((item) => {
        // delete item.users_count
        return {
          ...item,
          name: `${item.name}`,
          created_at: item.created_at
            ? moment(item.created_at).format('DD MMM YYYY')
            : '-',
        }
      })
    )
  }
  const columns = [
    {
      // title: <div>&nbsp;</div>,
      title: 'ลำดับ',
      field: 'index',
      // headerStyle: { display: 'flex', justifyContent: 'center', width: '1%', paddingTop: '1rem', paddingBottom: '1rem' },
      headerStyle: {
        display: 'flex',
        justifyContent: 'left',
        width: '6%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'left', width: '6%' },
      icon: 'setting.svg',
    },
    {
      title: ' ชื่อสถานพยาบาล',
      field: 'name',
      // headerStyle: { display: 'flex', justifyContent: 'center', width: '54%',  paddingTop: '1rem', paddingBottom: '1rem' },
      headerStyle: {
        display: 'flex',
        justifyContent: 'left',
        width: '40%',
        paddingLeft: '80px',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'left', width: '40%', paddingLeft: '80px' },
      icon: 'setting.svg',
    },
    {
      title: 'วันที่สร้าง',
      field: 'created_at',
      // headerStyle: { display: 'flex', justifyContent: 'center', width: '21%',  paddingTop: '1rem', paddingBottom: '1rem' },
      headerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '20%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'center', width: '20%' },
    },
    {
      title: 'จำนวนบัญชีผู้ใช้งาน',
      field: 'users_count',
      // headerStyle: { display: 'flex', justifyContent: 'center', width: '25%',  paddingTop: '1rem', paddingBottom: '1rem' },
      headerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '40%',
        backgroundColor: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid #b3b3b3',
      },
      style: { textAlign: 'center', width: '40%' },
    },
    // {
    //   title: 'จำนวนแผนก',
    //   field: 'clinics_count',
    //   // headerStyle: { display: 'flex', justifyContent: 'center', width: '17%',  paddingTop: '1rem', paddingBottom: '1rem' },
    //   headerStyle: { display: 'flex', justifyContent: 'center', width: '20%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem',borderBottom:'1px solid #b3b3b3' },
    //   style: { textAlign: 'center', width: '17%' },
    // },
  ]
  const [institutions, setInstitutions] = useState(calculateSkeleton(columns))
  const loading = institutions[0].loading
  const onPagination = async (perpage, offset, current_page) => {
    setFilter({
      offset: offset,
      per_page: perpage,
    })
  }
  return (
    <SettingsListsStyled {...props}>
      {/* <div style={{ width: '20rem' }}>
        <ButtonCustom
          theme_blue
          label="สถานพยาบาล"
          onClick={() => history.push(ROUTE_PATH.INSTITUTION_PROFILE + 'create')}
          icon='plus.svg'
        />
      </div> */}
      <div style={{ marginTop: '1rem' }}>
        <Middleline theme_standard title='รายชื่อเครือโรงพยาบาล' />
        <TableCustom
          useNo={!loading}
          theme_standard_2
          columns={columns}
          data={institutions}
          onClick={(e) =>
            history.push(ROUTE_PATH.SETTINGS_DETAIL + e.id + '-' + e.realm)
          }
          // isClick={goToSettingOntarget}
          // responsiveWidth='970px'
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Pagination
          label
          arrows
          title
          onPagination={onPagination}
          sort={sort}
          page={page}
          per_page={filter?.per_page}
        />
      </div>
    </SettingsListsStyled>
  )
}
