import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ClinicProfileContainerStyled } from './styled';
import { setReduxUserAuth, setReduxClinicProfile } from 'store/actions';
import { BoxModal, BoxConfirm, ButtonCustom, InputCheckboxNormal, TableCustom, Typography, Pagination } from 'components';
import { ClinicProfileForm } from 'forms'
import { ROUTE_PATH, getPathnameID, calculateSkeleton, permission } from 'assets';
import theme from 'assets/theme.json'
import { userService } from 'apiService';
import moment from 'moment'

class ClinicProfileContainer extends React.Component {
  state = {
    clinic_id: '',
    creating: false,
    loading: false,
    loadingSubmit: false,
    loadingCheck: false,
    modal: '',
    isOpen: false,
    users: calculateSkeleton(columns),
    page:false,
    sort:{
      sort_by: "",
      order_by: "DESC"
    },
    filter:{
      offset: 0,
      per_page: 10,
    },
  }
  componentDidMount = () => {
    this.beforeToFetch()
  }

  componentDidUpdate = () => {
    const { clinic_id } = this.state;
    const { params } = this.props.match
    const clinic = getPathnameID(params.clinic_id)
    if (!clinic.is_creating && (clinic_id !== clinic.id)) { // Not create and Id not equal
      this.beforeToFetch()
    }
  }

  componentWillUnmount = () => {
    const { setReduxClinicProfile } = this.props;
    setReduxClinicProfile(false)
  }

  beforeToFetch = () => {
    this.setState({ users: calculateSkeleton(columns), })
    const { params } = this.props.match
    const clinic = getPathnameID(params.clinic_id)
    const institution = getPathnameID(params.institution_id)
    this.setState({
      creating: clinic.is_creating,
      clinic_id: clinic.id
    })
    !clinic.is_creating && this.fetchData(clinic, institution)
  }

  fetchData = (clinic, institution) => {
    const { filter } = this.state
    this.setState({ loading: true })
    Promise.all([
      this.fetchClinic(clinic, institution),
      this.fetchUsers(institution,filter.per_page,filter.offset),
      this.fetchUsersByClinic(clinic)
    ]).then((values) => {
      const user_lists = values[1]
      const user_by_clinic = values[2]
      // const clincis = values[1]
      if (user_lists && user_by_clinic) {
        // console.log('user_lists: ',values);
        this.setState({ users: this.setRenderUsers(user_lists, user_by_clinic) })
      }
      this.setState({ loading: false })
    })
  }
  fetchClinic = async (clinic, institution) => {
    const { setReduxClinicProfile } = this.props;
    let res = await userService.GET_CLINIC_BY_ID(clinic.id)
    // console.log('res clin', res)
    if (res && res.success) {
      setReduxClinicProfile(res.data)
      return res.data
    } else {
      this.setErrorModal(institution?.id, res)
    }
  }
  fetchUsersByClinic = async (clinic) => {
    let res = await userService.GET_USERS_BY_CLINIC(clinic.id)
    if (res && res.success) {
      return res.data
    }
  }

  fetchUsers = async (institution,per_page,offset) => {
    let res = await userService.GET_USERS(institution.id,true,per_page,offset)
    if (res && res.success) {
      this.setState({
        page: res.page,
        sort: res.sort,
      })
      return res.data
    }
  }
  beforePagination = async(perpage,offset)=>{
    this.setState({
      filter:{
        offset: offset,
        per_page: perpage,
      },
    })
  }
  onPagination = async (perpage,offset) => {
    const { params } = this.props.match
    console.log(params);
    const institution = getPathnameID(params.institution_id)
    const clinic = getPathnameID(params.clinic_id)

    await this.beforePagination(perpage,offset).then(async ()=>{
      await this.fetchData(clinic,institution)
    })
  }
  setRenderUsers = (user_lists, user_by_clinic) => {
    return user_lists && user_lists.map(item => {
      const findActive = user_by_clinic.find(item2 => item2.id === item.id)
      const active = findActive ? true : false
      return {
        ...item,
        associated_at: findActive ? (findActive.associated_at ? moment(findActive.associated_at).format('DD MMM YYYY') : 'ไม่มีข้อมูล') : '-',
        action: <div style={{ marginTop: '-0.2rem' }}>
          <InputCheckboxNormal
            theme_transparent_5
            value={JSON.stringify(item)}
            checked={active}
            onChange={(e) => this.setModal(e, active)}
          />
        </div>
      }
    })
  }
  setErrorModal = (id, e) => {
    const action = () => {
      this.setState({ isOpen: false })
      this.props.history.push(ROUTE_PATH.SETTINGS_DETAIL + id)
    }
    this.setState({
      modal: <BoxConfirm
        theme_error
        confirm='กลับไปหน้าหลัก'
        title='Alert'
        desc={`${e?.data?.error}`}
        onConfirm={() => action()}
      // onCancel={() => this.setState({ isOpen: false })}
      />,
      isOpen: true,
    })
  }
  setModal = (e, active) => {
    const { loadingCheck } = this.state
    const { name, description } = this.props.reduxClinicProfile;
    let user = JSON.parse(e.target.value)
    this.setState({
      modal: <BoxConfirm
        theme_standard
        desc={
          <div>
            ต้องการ
            <span style={{ color: active ? theme.COLORS.RED_1 : theme.COLORS.GREEN_3, fontWeight: 600 }}> {active ? ' ปิด ' : ' เปิด '}</span>การใช้่งาน
            <span style={{ color: theme.COLORS.BLUE_2, fontWeight: 600 }}>{` ${user.name} `}</span>
            ใน
            <span style={{ color: theme.COLORS.BLUE_2, fontWeight: 600 }}>{` ${name} (${description})  `}</span>
            ใช่หรือไม่?
          </div>
        }
        loadingCheck={loadingCheck}
        onConfirm={() => this.handleCheck(e, { ...user, active: active })}
        onCancel={() => this.setState({ isOpen: false })}
      />,
      isOpen: true,
    })
  }
  handleCheck = (event, values) => {
    values.active
      ? this.handleRemoveToClinic(event, values)
      : this.handleAddToClinic(event, values)
  }
  handleAddToClinic = async (event, values) => {
    await this.setState({ loadingCheck: true })
    this.setModal(event, values.active)
    const user_id = values.id
    const clinic_id = this.props.reduxClinicProfile.id
    let res = await userService.ADD_USER_TO_CLINIC(clinic_id, user_id)
    if (res && res.success) {
      await this.setState({ loadingCheck: false, isOpen: false, modal: '' })
      this.beforeToFetch()
    } else {
      await this.setState({
        loadingCheck: false,
        modal: <BoxConfirm
          theme_standard
          confirm='ตกลง'
          desc={
            <div style={{ color: theme.COLORS.BLACK_1, fontWeight: 300 }}>
              {`Unable to add user to the clinic. `}
              <span style={{ color: theme.COLORS.RED_6, fontWeight: 600 }}>{`(${res.data.error})`}</span>
            </div>
          }
          onConfirm={() => this.setState({ isOpen: false })}
        />
      })
    }
  }
  handleRemoveToClinic = async (event, values) => {
    await this.setState({ loadingCheck: true })
    this.setModal(event, values.active)
    const user_id = values.id
    const clinic_id = this.props.reduxClinicProfile.id
    let res = await userService.REMOVE_USER_FORM_CLINIC(clinic_id, user_id)
    if (res && res.success) {
      await this.setState({ loadingCheck: false, isOpen: false, modal: '' })
      this.beforeToFetch()
    } else {
      await this.setState({
        loadingCheck: false,
        modal: <BoxConfirm
          theme_standard
          confirm='ตกลง'
          desc={
            <div style={{ color: theme.COLORS.BLACK_1, fontWeight: 300 }}>
              {`Unable to remove user to the clinic. `}
              <span style={{ color: theme.COLORS.RED_6, fontWeight: 600 }}>{`(${res.data.error})`}</span>
            </div>
          }
          onConfirm={() => this.setState({ isOpen: false })}
        />
      })
    }
  }
  handleIconsmodal = (e) =>{
    return true
  }

  handleSubmit = () => {
    const { creating } = this.state;
    this.setState({ loadingSubmit: true })
    const { values } = this.props.reduxForm;
  
    if (creating) {
      let params = {
        ...values,
        icon_base64: values.profile_picture_base64 ? values.profile_picture_base64 : null
      }
      if (!params.icon_base64) {
        delete params.icon_base64;
      }
      this.postClinic(params)
    } else {
      let params = {
        ...values,
        name: values?.name,
        description: values?.description,
        is_active: values?.is_active,
        is_visible: values?.is_visible,
        icon_base64: values?.profile_picture_base64,
        ref_id: values?.ref_id !== '' ? values?.ref_id : null
      }
      this.patchClinic(params)
    }
  }

  postClinic = async (params) => {
    const { id } = this.props.reduxInstitutionDetail
    let res = await userService.POST_CLINIC_BY_ACCOUNT(id, params)
    if (res && res.success) {
      this.setState({
        loadingSubmit: false,
        isOpen: true,
        errorMsg: '',
        modal: <BoxConfirm
          theme_success
          title='Success!'
          desc='สร้างแผนกของคุณสำเร็จแล้ว'
          confirm='ตกลง'
          onConfirm={() => this.redirectToDetail(res.data)}
          onCancel={false}
        />
      })
    } else {
      this.setState({
        loadingSubmit: false,
        errorMsg: `Fail to create clinic (${res.data?.error})`,
      })
    }
  }
  patchClinic = async (params) => {
    const { id } = this.props.reduxClinicProfile
    let res = await userService.PATCH_CLINIC_BY_ACCOUNT(id, params)
    if (res && res.success) {
      this.setState({
        loadingSubmit: false,
        isOpen: true,
        errorMsg: '',
        modal: <BoxConfirm
          theme_success
          title='Success!'
          desc='อัพเดทข้อมูลแผนกของคุณสำเร็จแล้ว'
          confirm='ตกลง'
          onConfirm={() => {
            this.setState({ isOpen: false })
            this.beforeToFetch()
          }}
          onCancel={false}
        />
      })
    } else {
      this.setState({
        loadingSubmit: false,
        errorMsg: `Fail to update clinic (${res.data?.error})`,
      })
    }
  }

  redirectToDetail = (e) => {
    const { id, realm } = this.props.reduxInstitutionDetail;
    const { history } = this.props;
    this.setState({ isOpen: false })
    history.push(ROUTE_PATH.SETTINGS_DETAIL + id + '-' + realm + ROUTE_PATH.CLINIC_PROFILE + e.id)
  }
  render() {
    const { id, realm } = this.props.reduxInstitutionDetail
    const { name, description, icon_uri } = this.props.reduxClinicProfile
    const { authRedux } = this.props
    const { creating, loading, loadingSubmit, users, modal, isOpen, errorMsg ,page,filter,sort } = this.state;
    return <ClinicProfileContainerStyled>
      <BoxModal
        isOpen={isOpen}
        onRequestClose={() => this.setState({ isOpen: false })}
        theme_standard
      >
        {modal}
      </BoxModal>
      <div className='back'>
        <ButtonCustom
          theme_back
          label="BACK"
          icon='arrow_left.svg'
          onClick={() => this.props.history.push(ROUTE_PATH.SETTINGS_DETAIL + id + '-' + realm)}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: '1rem' }}>
      </div>
      <div className='container_custom'>
        <div className='container_custom_form'>
          <Typography label={creating ? "Create New Clinic" : name && <div>{name + ' (' + description + ')'}</div>} loading={loading} weight={500} color={theme.COLORS.BLUE_17} size={theme.FONTS.SIZE_30px} />
          <ClinicProfileForm
            creating={creating}
            loading={loading}
            loadingSubmit={loadingSubmit}
            profile_picture_url={icon_uri}
            errorMsg={errorMsg}
            permission={permission?.find((item) => item.role === authRedux?.role)}
            onSubmit={this.handleSubmit}
            onIcons = {this.handleIconsmodal}
          />
        </div>
        {!creating &&
          <div style={{ marginTop: '3rem' }}>
            <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: '-0.1rem' }}>
              <Typography label={`บัญชีผู้ใช้ที่เปิดใช้งานใน ${description ? description : ''}`} loading={loading} weight={500} color={theme.COLORS.BLUE_17} size={theme.FONTS.SIZE_30px} />
            </div>
            <TableCustom
              theme_standard_2
              columns={columns}
              data={users}
            />
            <Pagination 
              label
              arrows
              onPagination={this.onPagination} 
              page={page}
              per_page = {filter.per_page}
              sort = {sort}
            />
          </div>
        }
      </div>
    </ClinicProfileContainerStyled>;
  }
}
const columns = [
  {
    title: 'Active',
    field: 'action',
    headerStyle: { display: 'flex', justifyContent: 'center', width: '6%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem', borderBottom:'1px solid #b3b3b3'},
    style: { textAlign: 'center', width: '6%', },
    icon: 'setting.svg',
    fluid_skeleton: true
  },
  // 17
  {
    title: 'ชื่อ-นามสกุล',
    field: 'name',
    headerStyle: { display: 'flex', justifyContent: 'center', width: '40%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem', borderBottom:'1px solid #b3b3b3'},
    style: { textAlign: 'left', width: '40%', },
    icon: 'setting.svg',
  },
  {
    title: 'บทบาท',
    field: 'title',
    headerStyle: { display: 'flex', justifyContent: 'center', width: '29%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem', borderBottom:'1px solid #b3b3b3'},
    style: { textAlign: 'center', width: '29%' },
  },
  {
    title: 'เปิดใช่้งานเมื่อ',
    field: 'associated_at',
    headerStyle: { display: 'flex', justifyContent: 'center', width: '29%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem', borderBottom:'1px solid #b3b3b3'},
    style: { textAlign: 'center', width: '29%' },
  },

]

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxInstitutionDetail: state.institution_detail,
  reduxClinicProfile: state.clinic_profile,
  reduxForm: state.form.ClinicProfileForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxClinicProfile: (data) => dispatch(setReduxClinicProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicProfileContainer);
