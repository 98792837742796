import React, { useEffect, useState } from 'react';
import { DonutStyled } from './styled';
import cx from 'classnames';
import {
  BoxCustom
} from 'components'
import Chart from 'react-apexcharts'

export const Donut = (props) => {
  const { theme_standard, title, data, hide } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  useEffect(() => {
    if (data) {
      setSeries(data.map((item) => item.value))
    }
  }, [data])

  useEffect(() => {
    if (data) {
      setOptions({
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '80%',
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Total',
                  fontWeight: 600,
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        labels: data.map((item) => `${item.name} (${item.value})`),
        legend: {
          position: 'bottom',
          marker: {
            width: 10,
            height: 10
          }
        },
        responsive: [{
          breakpoint: 1400,
          options: {
            plotOptions: {
              pie: {
              }
            }
          }
        }]
      })
    }
  }, [data])

  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({})
  return (
    <DonutStyled {...props}>
      <div className={customClass}>
        <BoxCustom
          theme_standard_box_custom_title
          title={title}
          noData={hide}
          child={
            <div style={{ margin: 'auto' }}>
              <Chart
                options={options}
                series={series}
                type='donut'
                height={250}
                width='100%'
                style={{
                  paddingBottom: '1rem'
                }}
              />
            </div>
          }
        />
      </div>
    </DonutStyled>
  );
};
