import React,{ Component,useEffect,useState } from "react";
import { ContentBlock, ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TextEditerStyle } from "./style";
import { ButtonCustom } from "components/ButtonCustom";
import { SkeletonForm } from "components/SkeletonForm";
import { Form } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { setEditTextEN, setEditTextTH } from "store/actions";
import draftToHtml from 'draftjs-to-html';



function TextEditer(props) {
    
    const { handledText,
        maxLength,
    } = props
    const dispatch = useDispatch()
    const termsData = useSelector(state => state.ThemePrivacy)
    const [UploadImages, setUploadImages] = useState([]);

    const blocksFromHtml = convertFromHTML(props.content || '');
    const state = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
    )
    
    const [EditText,SetEditText] = useState(EditorState.createWithContent(state))

    const [Text, setText] = useState();
    const [CallbackText, setCallbackText] = useState();
    const [curentLenght, setcurentLenght] = useState(0);


    async function UploadImage(file) {        
        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        
        setUploadImages(UploadImages.push(imageObject))
        return new Promise((resolve, reject) => {
            resolve({data:{ link: imageObject.localSrc}})
        });
        
    }

    const handleEditedChange = (e,language)=>{
        SetEditText(e)
        if (language === 'en') {
            dispatch(setEditTextEN(draftToHtml(convertToRaw(e.getCurrentContent()))))
        } else {
            dispatch(setEditTextTH(draftToHtml(convertToRaw(e.getCurrentContent()))))
        }
    }

    const handledSaveBTN = () => {
        if (EditText?.getCurrentContent().getPlainText()!== '') {
            setText(EditText?.getCurrentContent())
        }else{
            props.language === 'en' ? alert('Please Fill Text in Box') : alert('โปรดพิมข้อความในกล่องข้อความ')
        }
        
    }

    const handleBeforeInput = (input) => {
        if (EditText) {

            // setcurentLenght(EditText.getCurrentContent().getPlainText().length);

            if (EditText.getCurrentContent().getPlainText().length >= (maxLength - 1 )) {
                return 'handled';
            } else {
                return ;
            }
        }else{
            return ; 
        }
    }

    useEffect(() => {
        if (EditText) {
            setcurentLenght(EditText.getCurrentContent().getPlainText().length)
        }
    }, [EditText]);

    return(
        <TextEditerStyle>
            <div>
                <div className="grid_label_te">
                    {
                        props.language === 'en' ? (
                            <div className="te_title">
                                Terms for English
                            </div>
                        ) 
                        : 
                        (
                            <div className="te_title" >
                                Terms for Thai
                            </div>
                        )
                    }
                    {
                        maxLength && (
                            <div className="maxLength_text">
                                {curentLenght} / {maxLength}
                            </div>
                        )
                    }
                </div>
            <div>

                </div>
                <Editor
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        placeholder={props.language === 'en' ? 'Type Here' : 'เขียนข้อความ'}
                        editorState={EditText}
                        handleBeforeInput = {handleBeforeInput}
                        onEditorStateChange ={ 
                            (e)=> {
                            handleEditedChange(e,props.language)
                            }
                        }
                        toolbar={
                            {
                                image:{
                                    urlEnabled:false,
                                    previewImage: true,
                                    uploadCallback: UploadImage,
                                    alt:{ present : true , mandatory : true}
                                
                                }
                            }
                        }
                    />
                
            </div>
        </TextEditerStyle>
    )

}

export default TextEditer  