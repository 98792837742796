import React from 'react';
import { StepperStyled, Box } from './styled';
import cx from 'classnames';

export const Stepper = (props) => {
  const { theme_standard, steps, activeStep } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderStep = () => {
    return steps && steps.map((step, i) => {
      return (
        <li class={activeStep >= i ? ' tab_item active ' : 'tab_item'}>
          <Box height='2rem' width="2rem">
            <div className={activeStep >= i ? 'box_container active' : 'box_container'}>
              <div className='number'>
              {i + 1}
              </div>
            </div>
          </Box>
          {step.label}
        </li>
      )
    })
  }
  return (
    <StepperStyled {...props}>
      <div className={customClass}>
        <div className="tab_container">
          <ul className="container">
            {renderStep()}
          </ul>
        </div>
      </div>
    </StepperStyled>
  );
};
