import React from 'react';
import { LoginFormStyled } from './styled';
import { Field, reduxForm } from 'redux-form';
import { VALIDATE } from 'assets';
import { BoxCustom, ButtonCustom, InputText } from 'components';


class LoginForm extends React.Component {
  state = {
    setMarginUsername: false,
    setMarginPassword: false
  };
  handleError = (meta, input) => {
    switch (input.name) {
      case 'username':
        if (meta.touched && meta.error) {
          input.name === 'username' && this.setState({ setMarginUsername: true })
        } else {
          input.name === 'username' && this.setState({ setMarginUsername: false })
        }
        break
      case 'password':
        if (meta.touched && meta.error) {
          input.name === 'password' && this.setState({ setMarginPassword: true })
        } else {
          input.name === 'password' && this.setState({ setMarginPassword: false })
        }
        break
      default:
    }

  }
  render() {
    const { handleSubmit, error_msg, loading } = this.props;
    const { setMarginUsername, setMarginPassword } = this.state;
    return (
      <LoginFormStyled>
        {/* <BoxCustom
          theme_standard_box_custom_gray
          child={<div> */}
        <form onSubmit={handleSubmit} >
          <div style={{ textAlign: "left", marginBottom: setMarginUsername ? '0' : '-.8rem' }}>Username</div>
          <div style={{ marginBottom: '1rem' }}>
            <Field
              name="username"
              component={InputText}
              type="text"
              theme_standard_3
              // placeholder="Username"
              validate={VALIDATE.REQUIRE}
              handleError={this.handleError}
            />
          </div>
          <div style={{ textAlign: "left", marginBottom: setMarginPassword ? '0' : '-.8rem' }}>Password</div>
          <div style={{ marginBottom: '1rem' }}>
            <Field
              name="password"
              component={InputText}
              type="password"
              theme_standard_3
              // placeholder="Password"
              validate={VALIDATE.REQUIRE}
              handleError={this.handleError}
            />
          </div>
          {error_msg &&
            <div className="isvalid">
              {error_msg}
            </div>
          }
          <button style={{ display: "none" }} type="submit">Submit</button>
          <div className="button_layout">
            <ButtonCustom loading={loading} theme_sky_2 label="LOGIN" onClick={() => !loading && handleSubmit()} />
          </div>
        </form>
        {/* </div>
          }
        /> */}
      </LoginFormStyled>
    )
  }
}

export default reduxForm({
  form: 'LoginForm', // a unique identifier for this form
  enableReinitialize: true,
})(LoginForm);
