import styled from "styled-components"

export const TabCustomStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        //font-weight:600;
        display:flex;
        justify-content:center;
        flex-direction:column;
        .tab_container{
            display:flex;
            justify-content:space-between;
            border-bottom:1px solid ${({ theme }) => theme.COLORS.GRAY_1};
            color:${({ theme }) => theme.COLORS.BLUE_7};
            .tab_container_list{
                
                flex-direction:row;
                display:flex;
                .tab_item{
                    margin-right:2rem;
                    cursor:pointer;
                    font-weight:600;
                    .icon{
                        margin-top:-1rem;
                        margin-left:0.2rem;
                    }
                    &:active{
                        transform: translateY(1px);
                    }
                    &:hover{
                        transform: translateY(0.5px);
                    }
                }
                @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
                    .tab_item{
                        
                        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
                    }
                }
                .active{
                    color:${({ theme }) => theme.COLORS.BLUE_3};
                    &:after { 
                        content:""; 
                        float:left; 
                        background: ${({ theme }) => theme.COLORS.BLUE_3};
                        width:90%; 
                        height:3px; 
                        border-radius: 1rem;
                    }
                }
            }
            @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
                .repeat_button{
                    font-size:${({ theme }) => theme.FONTS.SIZE_12px};
                }
            }
            .repeat_button{
                cursor:pointer;
                color: ${({ theme }) => theme.COLORS.BLUE_5};
            }
        }
        .content_container{
            margin-top:1.3rem;
        }
        .button_container{
            margin-top:1rem;
        }
    }
    .theme_standard_2 {
        //font-weight:600;
        z-index:-3;
        display:flex;
        justify-content:center;
        flex-direction:column;
        .tab_container{
            display:flex;
            width:100%;
            // justify-content:space-between;
            border-bottom:1px solid ${({ theme }) => theme.COLORS.GRAY_1};
            color:${({ theme }) => theme.COLORS.BLUE_4};
            .tab_container_list{
                flex-direction:row;
                display:flex;
                .tab_item{
                    margin-right:2rem;
                    cursor:pointer;
                    font-weight:600;
                    .icon{
                        margin-top:-1rem;
                        margin-left:0.2rem;
                    }
                }
                @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
                    .tab_item{
                        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
                    }
                }
                .active{
                    color:${({ theme }) => theme.COLORS.BLUE_3};
                    &:after { 
                        content:""; 
                        float:left; 
                        background: ${({ theme }) => theme.COLORS.BLUE_3};
                        width:90%; 
                        height:3px; 
                        border-radius: 1rem;
                    }
                }
            }
            @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
                .repeat_button{
                    font-size:${({ theme }) => theme.FONTS.SIZE_12px};
                }
            }
            .repeat_button{
                cursor:pointer;
                color: ${({ theme }) => theme.COLORS.BLUE_5};
            }
        }
        .content_container{
            margin-top:0.5rem;
        }
        .button_container{
            margin-top:1rem;
        }
    }

    .theme_standard_3 {
        //font-weight:600;
        z-index: 2;
        display:flex;
        justify-content:center;
        flex-direction:column;
        .tab_container{
            display:flex;
            justify-content:space-between;
            border-bottom:1px solid ${({ theme }) => theme.COLORS.BLACK_7};
            color:${({ theme }) => theme.COLORS.BLUE_18};
            .tab_container_list{
                
                flex-direction:row;
                display:flex;
                .tab_item{
                    margin-right:2rem;
                    cursor:pointer;
                    font-weight:600;
                    .icon{
                        margin-top:-1rem;
                        margin-left:0.2rem;
                    }
                    &:active{
                        transform: translateY(1px);
                    }
                    &:hover{
                        transform: translateY(0.5px);
                    }
                }
                @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
                    .tab_item{
                        
                        font-size:${({ theme }) => theme.FONTS.SIZE_12px};
                    }
                }
                .active{
                    color:${({ theme }) => theme.COLORS.BLUE_17};
                    &:after { 
                        content:""; 
                        float:left; 
                        background: ${({ theme }) => theme.COLORS.YELLOW_1};
                        width:90%; 
                        height:3px; 
                        border-radius: 1rem;
                    }
                }
            }
            @media only screen and (max-width: ${({ theme }) => theme.SCREENS.PHONE_MINI}) {
                .repeat_button{
                    font-size:${({ theme }) => theme.FONTS.SIZE_12px};
                }
            }
            .repeat_button{
                cursor:pointer;
                color: ${({ theme }) => theme.COLORS.BLUE_5};
            }
        }
        .content_container{
            margin-top:1.3rem;
        }
        .button_container{
            margin-top:1rem;
        }
    }

`