import styled from "styled-components";

export const  CheckPasswordBoxStyled = styled.div`

    .checkPasswordBox{
        display: flex;
        justify-content:start;
        align-items:center;
        column-gap:5px;
        .text{
            font-size:${({ theme }) => theme.FONTS.SIZE_16px};
            font-weight:600;

        }
    }
`