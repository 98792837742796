import React, { useState } from 'react';
import { ImgUpload3Styled } from './styled';
//import { ButtonCustom } from 'components';
import { iconImages } from 'assets';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import Compress from 'compress.js'
import { BoxModal } from 'components/BoxModal';
import { BoxConfirm } from 'components/BoxConfirm';
import { BoxIcons } from 'components/BoxIcons';


export const ImgUpload3 = (props) => {

  const [_isModal, setIsModal] = useState(false);
  const [modal, setModal] = useState(false);
  const { readOnly, form , profile_picture_url ,handleIconsmodal } = props;
  const dispatch = useDispatch()
  // const Compress = require('compress.js')
  const compress = new Compress()

  const [display, setDisplay] = useState()
  const handleUploadImage = (value) => {
    dispatch(change(form?.formName, 'profile_picture_base64', value))
  }
  const handleChange = async (event,isSelect) => {
    
      const allFile = isSelect ? event : event?.target?.files[0] ;
      // setDisplay(URL.createObjectURL(allFile))
      resizeImg(allFile).then(file => {
        setDisplay(URL.createObjectURL(file))
        let reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          // console.log('onloadend',reader)
          handleUploadImage(reader.result)
        };
      })
      handleCloseModal()
  }

  const resizeImg = async (file) => {
    if (file?.type === 'image/png') {
      return file;
    }else{
      const resizedImage = await compress.compress([file], {
        // size: 2, // the max size in MB, defaults to 2MB
        quality: 0.8, // the quality of the image, max is 1,
        maxWidth: 250, // the max width of the output image, defaults to 1920px
        maxHeight: 250, // the max height of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
      })
      const img = resizedImage[0];
      const base64str = img?.data
      const imgExt = img?.ext === "image/png" ? "image/jpeg" : img?.ext
      const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
      return resizedFiile
    }
  }
  
  const handleModal = (e)=>{
    setModal(
      <BoxIcons 
      theme_Icons
      handleModal = {handleCloseModal}
      handleChange = {handleChange}
    />
    )
    setIsModal(true)
  }
  const handleCloseModal = (e)=>{
    setIsModal(false)
  }

  return (
    <ImgUpload3Styled>
    <BoxModal
    isOpen={_isModal}
    onRequestClose={() => handleCloseModal()}
    theme_standard_2
    >
      {modal}
    </BoxModal>
      <div className="row">
        <div className="upload_box">
          {!display
            ? profile_picture_url
              ? <div className="profile">
                <img src={profile_picture_url} alt="" />
              </div>
              : <div className="upload_box_border">
                <img src={iconImages['grayheadshot.svg']} alt="" />
              </div>
            : ''
          }
          {display &&
            <div className="profile">
              <img src={display} alt="" />
            </div>
          }
        </div>
        <div>
          {!readOnly &&
            <div className="upload_btn_container ">
              <div className='upload_title'>ไอคอนคลินิก</div>
              <div className='upload_label' onClick={handleModal}>
                <label for="file-open" className="custom-file-uploads">
                  อัพโหลดไอคอนคลินิก
                </label>
              </div>
            </div>
          }
        </div>
      </div>
    
    </ ImgUpload3Styled>
  );
};
