import { ROUTE_PATH } from 'assets'
import { ROLES } from 'assets'
import SettingListsContainer from 'containers/SettingListsContainer'
import SettingDetailContainer from 'containers/SettingDetailContainer'
import UserAccountContainer from 'containers/UserAccountContainer'
import AadminUserAccountContainer from 'containers/AadminUserAccountContainer'
import InstitutionProfileContainer from 'containers/InstitutionProfileContainer'
import ClinicProfileContainer from 'containers/ClinicProfileContainer'
import HistoryContainer from 'containers/HistoryContainer'
import DashboardContainer from 'containers/DashboardContainer'
import SelectRoleContainer from 'containers/SelectRoleContainer'
import AdminEditProfile from 'containers/AadminEditProfile'
import AssessmentContainer from 'containers/Assessment'
import { AssessmentFormContainer } from 'forms'

const systemRoutes = [
  {
    keyIndex: 1,
    path: ROUTE_PATH.SETTINGS,
    exact: true,
    component: SettingListsContainer,
    permission: [ROLES.SYSTEM_ADMIN],
  },
  {
    keyIndex: 2,
    path: ROUTE_PATH.SETTINGS_DETAIL + ':institution_id',
    exact: true,
    component: SettingDetailContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 3,
    path:
      ROUTE_PATH.SETTINGS_DETAIL +
      ':institution_id' +
      ROUTE_PATH.USER_ACCOUNT +
      ':user_id' +
      ROUTE_PATH.USER_ROLE +
      ':role_id',
    exact: true,
    component: UserAccountContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 4,
    path: ROUTE_PATH.INSTITUTION_PROFILE + ':institution_id',
    exact: true,
    component: InstitutionProfileContainer,
    permission: [ROLES.SYSTEM_ADMIN],
  },
  {
    keyIndex: 5,
    path:
      ROUTE_PATH.SETTINGS_DETAIL +
      ':institution_id' +
      ROUTE_PATH.CLINIC_PROFILE +
      ':clinic_id',
    exact: true,
    component: ClinicProfileContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 5,
    path: ROUTE_PATH.SETTINGS + ROUTE_PATH.USER_ACCOUNT + ':user_id',
    exact: true,
    component: AadminUserAccountContainer,
    permission: [ROLES.SYSTEM_ADMIN],
  },
  {
    keyIndex: 6,
    path: ROUTE_PATH.HISTORY,
    exact: true,
    component: HistoryContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 6,
    path: ROUTE_PATH.DASHBOARD,
    exact: true,
    component: DashboardContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 7,
    path:
      ROUTE_PATH.SETTINGS_DETAIL +
      ':institution_id' +
      ROUTE_PATH.USER_ROLE +
      ':user_id',
    exact: true,
    component: SelectRoleContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 8,
    path: ROUTE_PATH.SETTINGS + ROUTE_PATH.USER_ADMIN + ':admin_id',
    exact: true,
    component: AdminEditProfile,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 9,
    path: ROUTE_PATH.ASSESSMENT,
    exact: true,
    component: AssessmentContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
  {
    keyIndex: 9,
    path: ROUTE_PATH.ASSESSMENT + ':account_id' + '/' + ':type',
    exact: true,
    component: AssessmentFormContainer,
    permission: [ROLES.SYSTEM_ADMIN, ROLES.ACCOUNT_ADMIN],
  },
]

export default systemRoutes
