import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { ThemeFromStyles } from "./style";
import { 
    InputText,
    InputTextArea,
    ButtonCustom
    } from "components";
import { VALIDATE } from 'assets';
import TextEditer from "components/TextEditer";
import { useDispatch, useSelector } from "react-redux";


function ThemeFrom(props) {
    
    const { handleSubmit ,handledTHText,handledENText ,data} = props
    return(
        <ThemeFromStyles>
            <form onSubmit={handleSubmit}>
            <div className="ThemeGrid">
                <div className="ThemeBox">
                    <div className="ThemeRightBox">
                        <div className="fluid_col_container">
                        <div className='field'>
                            {
                            data ? (
                                <Field
                                label = 'Platform Name'
                                defaultValue ={data?.platform_name}
                                theme_standard_3
                                component={InputText}
                                name='platform_name'
                                type='text'
                                readOnly={false}
                                validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                                placeholder= ''
                                />
                                ):(
                                <Field
                                label = 'Platform Name'
                                theme_standard_3
                                component={InputText}
                                name='platform_name'
                                type='text'
                                readOnly={false}
                                validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                                placeholder= ''
                                />
                                )
                            }
                            
                        </div>
                        </div>
                    </div>
                    <div className="ThemeLeftBox">
                        <div className="fluid_col_container">
                        <div className='field'>
                            {
                                data ?(
                                    <Field
                                    label = 'Site Name'
                                    defaultValue={data?.sitename}
                                    theme_standard_3
                                    component={InputText}
                                    name='sitename'
                                    type='text'
                                    readOnly={false}
                                    validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                                    placeholder=''
                                    />
                                ):(
                                <Field
                                    label = 'Site Name'
                                    theme_standard_3
                                    component={InputText}
                                    name='sitename'
                                    type='text'
                                    readOnly={false}
                                    validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                                    placeholder=''
                                />
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ThemeBox">
                    <div className="ThemeRightBox">
                        <div className="fluid_col_container">
                        <div className='field'>
                        {
                            data ? (
                            <Field
                                upperMaxLength={true}
                                maxLength='500'
                                label='Description'
                                theme_standard_3
                                defaultValue={data?.description}
                                component={InputTextArea}
                                name='description'
                                type='text'
                                readOnly={false}
                                validate={[ VALIDATE.NUMBER_MAX_LENGTH_500]}
                                placeholder=''
                            />                                
                            ):(
                                <Field
                                upperMaxLength={true}
                                maxLength='500'
                                label='Description'
                                theme_standard_3
                                component={InputTextArea}
                                name='description'
                                type='text'
                                readOnly={false}
                                validate={[ VALIDATE.NUMBER_MAX_LENGTH_500]}
                                placeholder=''
                            />
                            )
                        }
                        </div>
                        </div>
                    </div>
                    <div className="ThemeLeftBox">
                        <div className="fluid_col_container">
                            <div className='field'>
                            <Field
                                upperMaxLength={true}
                                maxLength='500'
                                label='Address'
                                theme_standard_3
                                defaultValue={data?.address}
                                component={InputTextArea}
                                name='address'
                                type='text'
                                readOnly={false}
                                validate={[ VALIDATE.NUMBER_MAX_LENGTH_500]}
                                placeholder=''
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ThemeBox">
                    <div className="ThemeRightBox">
                        <div className="fluid_col_container">
                        <div className='field'>
                        <Field
                            upperMaxLength={true}
                            label='Cancellation URL'
                            language='EN'
                            theme_standard_3
                            defaultValue={data?.cancellation_url?.en}
                            component={InputText}
                            name='cancellation_url_en'
                            type='text'
                            readOnly={false}
                            validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                            placeholder=''
                        />
                        </div>
                        </div>
                    </div>
                    <div className="ThemeLeftBox">
                        <div className="fluid_col_container">
                            <div className='label'><br/></div>
                        <div className='field'>
                        <Field
                            language='TH'
                            theme_standard_3
                            defaultValue={data?.cancellation_url?.th}
                            component={InputText}
                            name='cancellation_url_th'
                            type='text'
                            readOnly={false}
                            validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                            placeholder=''
                        />
                        </div>
                        </div>
                    </div>
                </div>
                <div className="ThemeBox">
                    <div className="ThemeRightBox">
                        <div className="fluid_col_container">
                        <div className='field'>
                        <Field
                            label='Contact URL'
                            language='EN'
                            theme_standard_3
                            defaultValue={data?.contact_url?.en}
                            component={InputText}
                            name='contact_url_en'
                            type='text'
                            readOnly={false}
                            validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                            placeholder=''
                        />
                        </div>
                        </div>
                    </div>
                    <div className="ThemeLeftBox">
                        <div className="fluid_col_container">
                            <div className='label'><br/></div>
                        <div className='field'>
                        <Field
                            theme_standard_3
                            language='TH'
                            defaultValue={data?.contact_url?.th}
                            component={InputText}
                            name='contact_url_th'
                            type='text'
                            readOnly={false}
                            validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                            placeholder=''
                        />
                        </div>
                        </div>
                    </div>
                </div>
                <div className="ThemeBox">
                    <div className="ThemeRightBox">
                        <div className="fluid_col_container">
                        <div className='field'>
                        <Field
                            label='Privacy Policy URL'
                            language='EN'
                            theme_standard_3
                            component={InputText}
                            defaultValue={data?.privacy_url?.en}
                            name='privacy_url_en'
                            type='text'
                            readOnly={false}
                            validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                            placeholder=''
                        />
                        </div>
                        </div>
                    </div>
                    <div className="ThemeLeftBox">
                        <div className="fluid_col_container">
                            <div className='label'><br/></div>
                        <div className='field'>
                        <Field
                            theme_standard_3
                            language='TH'
                            defaultValue={data?.privacy_url?.th}
                            component={InputText}
                            name='privacy_url_th'
                            type='text'
                            readOnly={false}
                            validate={[ VALIDATE.NUMBER_MAX_LENGTH_255]}
                            placeholder=''
                        />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </form>
            <TextEditer 
            language ='en'
            content = {data?.terms?.en}
            maxLength = '65535'
            />
            <br/>
            <TextEditer 
            language ='th'
            content = {data?.terms?.th}
            maxLength ='65535'
            />
            <div className="SaveBTN">
                    <div style={{ width: '20rem' }}>
                        <ButtonCustom
                        theme_blue
                        label="SAVE"
                        icon='plus.svg'
                        onClick={handleSubmit}
                        />
                    </div>
            </div>
        </ThemeFromStyles>
    );


}
export default reduxForm({
    form: 'themeform',
})(ThemeFrom)
