import React from 'react';
import { InfoInstitutionFormStyled } from './styled';
import { reduxForm } from 'redux-form';
import { BoxCustom, } from 'components';
import { Container, } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { VALIDATE } from 'assets';


class InfoInstitutionForm extends React.Component {

  render() {
    const { loading, handleSubmit } = this.props;
    return (
      <InfoInstitutionFormStyled  >
        {/* <div className="title" >
          <Typography label="Confirm Payment" color={({ theme }) => theme.COLORS.BLUE_3} size={({ theme }) => theme.FONTS.SIZE_20px} />
        </div> */}
        <div style={{ height: '20rem' }}>
          <BoxCustom
            theme_standard_box_custom_gray
            loading={loading}

            child={
              <React.Fragment>
                <Container fluid={true} className="box_container">
                  <form onSubmit={handleSubmit}>

                  </form>
                </Container>
              </React.Fragment>
            }
          />
        </div>
      </InfoInstitutionFormStyled>
    );
  }
}

InfoInstitutionForm = reduxForm({
  form: 'InfoInstitutionForm', // a unique identifier for this form
  enableReinitialize: true,
})(InfoInstitutionForm);

InfoInstitutionForm = connect(
  state => ({
    initialValues: {
    }
  }),
  {}
)(InfoInstitutionForm)


export default InfoInstitutionForm

