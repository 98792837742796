import React from 'react';
import { InstitutionSettingsFormStyled } from './styled';
import { Field, reduxForm } from 'redux-form';
import {
  ButtonCustom,
  InputSelect,
  InputTextArea,
  InputText,
  Typography,
  InputCheckbox
} from 'components';
import { connect } from 'react-redux';
// import { VALIDATE } from 'assets';
import theme from 'assets/theme.json'


class InstitutionSettingsForm extends React.Component {

  render() {
    const { handleSubmit, handleSkip, is_creating ,loadingSubmit } = this.props;
    return (
      <InstitutionSettingsFormStyled  >
        {/* <div className="title" >
          <Typography label="Confirm Payment" color={({ theme }) => theme.COLORS.BLUE_3} size={({ theme }) => theme.FONTS.SIZE_20px} />
        </div> */}
        {!is_creating &&
          <Typography label='Features' weight={500} color={theme.COLORS.BLUE_3} size={theme.FONTS.SIZE_30px} />
        }
        <form onSubmit={handleSubmit}>
          <div className='row_container2'>
            <div className='col_container'>
              <div className='field'>
                <Field
                  theme_transparent_2
                  component={InputCheckbox}
                  name='can_chat'
                  type='checkbox'
                  label='Chat'
                  desc='Is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy'
                />
              </div>
            </div>
            <div className='col_container'>
              <div className='label'>&nbsp;</div>
              <div className='field'>
                <Field
                  theme_transparent_2
                  component={InputCheckbox}
                  name='can_screen_capture'
                  type='checkbox'
                  label='Screen Capture'
                  desc='There are many variations of passages of Lorem Ipsum available,'
                />
              </div>
            </div>
          </div>
          <div className='row_container2'>
            <div className='col_container'>
              <div className='field'>
                <Field
                  theme_transparent_2
                  component={InputCheckbox}
                  name='can_visit_text'
                  type='checkbox'
                  label='Visit Text'
                  desc='Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'
                />
              </div>
            </div>
            <div className='col_container'>
              <div className='field'>
                <Field
                  theme_transparent_2
                  component={InputCheckbox}
                  name='can_visit_appointment'
                  type='checkbox'
                  label='Appointment'
                  desc='Is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy'
                />
              </div>
            </div>
          </div>
          <div className='row_container2'>
            <div className='col_container'>
              <div className='field'>
                <Field
                  theme_transparent_2
                  component={InputCheckbox}
                  name='can_skip_visit_finishing_requirement'
                  type='checkbox'
                  label='Skip finishing'
                  desc='Sometimes by accident, sometimes on purpose (injected humour and the like).'
                />
              </div>
            </div>
          </div>
          <div className='button_container'>
            {is_creating &&
              <div className='button_wrapper'>
                <ButtonCustom
                  theme_transparent
                  label='ข้าม'
                  onClick={handleSkip}
                />
              </div>
            }
            <div className='button_wrapper'>
              <ButtonCustom
                theme_blue
                loading={loadingSubmit}
                label='บันทึก'
                onClick={handleSubmit}
                
              />
            </div>
          </div>
        </form>
      </InstitutionSettingsFormStyled>
    );
  }
}

InstitutionSettingsForm = reduxForm({
  form: 'InstitutionSettingsForm', // a unique identifier for this form
  enableReinitialize: true,
})(InstitutionSettingsForm);

InstitutionSettingsForm = connect(
  state => ({
    initialValues: {
    }
  }),
  {}
)(InstitutionSettingsForm)

export default InstitutionSettingsForm

