import React from 'react';
import { connect } from 'react-redux';
import { AadminUserAccountContainerStyled } from './styled';
import { setReduxUserAuth, setReduxUserProfile } from 'store/actions';
import { ButtonCustom, BoxModal, BoxConfirm, TableCustom, Typography, InputCheckboxNormal } from 'components';
import { UserAccountForm } from 'forms'
import { ROUTE_PATH, getPathnameID, calculateSkeleton } from 'assets';
import theme from 'assets/theme.json'
import { userService } from 'apiService';
import moment from 'moment'

class AadminUserAccountContainer extends React.Component {
  state = {
    creating: false,
    loading: false,
    loadingSubmit: false,
    errorMsg: '',
    modal: '',
    isOpen: false,
    clinics: calculateSkeleton(columns),
    readonly: '',
    profile:''
  }
  componentDidMount = () => {
    const { params } = this.props.match
    const { authRedux } = this.props
    const user = getPathnameID(params.user_id)
    if (params.user_id === 'me') {
      this.fetchData({id:authRedux?.id})
    }else{
      this.fetchData(user)
    }
    // !user.is_creating && this.fetchData(user, institution)
  }

  setReadOnlyValues = () => {
    const { role } = this.props.reduxUserProfile;
    const title = role?.replace('_', ' ')
    this.setState({
      readonly: {
        username: false,
        clinic:true,
        name: true,
        surname: true,
        role: { role: true, value: title },
        title: true,
        image: false
      }
    })
  }
  componentWillUnmount = () => {
    const { setReduxUserProfile } = this.props;
    setReduxUserProfile(false)
  }

  fetchData = (user) => {
    this.setState({ loading: true })
    const {setReduxUserAuth ,authRedux} = this.props;
    const { params } = this.props.match
    Promise.all([
      this.fetchProfile(user),
    ]).then(async (values) => {
      const profile = values[0]
      if (profile) {
        // await this.fetchAppointmentSchedule(profile)
        if (params.user_id === 'me') {
          const newAdminAuth = {...authRedux,profile_picture_url: profile.profile_picture_url};
          setReduxUserAuth(newAdminAuth);
        }
        this.setState({ 
          clinics: this.setRenderClinics(profile.clinics),
          profile: profile,
          doctor_profile:[profile.doctor]
        })
      }
      this.setState({ loading: false })
    })
  }
  // fetchAppointmentSchedule = async (profile) =>{
  //   const startOfWeek = moment().locale("th")
  //     .startOf("week")
  //     .format("YYYY-MM-DD");
  //   const endOfWeek = moment().locale("th").endOf("week").format("YYYY-MM-DD");

  //   const res = await userService.GET_APPOINTMENT_SCHEDULE_BY_CLINIC_ID_USER_ID(
  //     '2',
  //     '2',
  //     startOfWeek,
  //     endOfWeek
  //     )
  // }

  fetchProfile = async (user) => {
    const { setReduxUserProfile } = this.props;
    let res = await userService.GET_USER_PROFILE_ADMIN(user.id)
    if (res && res.success) {
      setReduxUserProfile(res.data)
      this.setReadOnlyValues()
      return res.data
    } else {
      this.setErrorModal(res)
    }
  }

  fetchClinics = async (institution) => {
    let res = await userService.GET_CLINICS_BY_ACCOUNT(institution.id)
    if (res && res.success) {
      return res.data
      // this.setState({ clinics: this.setRenderClinics(res.data) })
    }
  }
  setRenderClinics = (clinic_me) => {
    return clinic_me && clinic_me.map(clinic => {
      return {
        ...clinic,
        name: clinic.name + '  (' + clinic.description + ')',
        associated_at: clinic.associated_at ? moment(clinic.associated_at).format('DD MMM YYYY') : '-',
        action: <div style={{ marginTop: '-0.2rem' }}>
          <InputCheckboxNormal
            theme_transparent_2
            value={JSON.stringify(clinic)}
            checked={true}
            disabled={true}
          />
        </div>
      }
    })
  }
  setErrorModal = (e) => {
    const action = () => {
      this.setState({ isOpen: false })
      this.props.history.push(ROUTE_PATH.SETTINGS)
    }
    this.setState({
      modal: <BoxConfirm
        theme_error
        confirm='กลับไปหน้าหลัก'
        title='Alert'
        desc={`${e?.data?.error}`}
        onConfirm={() => action()}
      // onCancel={() => this.setState({ isOpen: false })}
      />,
      isOpen: true,
    })
  }
  setModal = (e, enabled) => {
    const { name } = this.props.reduxUserProfile;
    let clinic = JSON.parse(e.target.value)
    this.setState({
      modal: <BoxConfirm
        theme_standard
        title='ยืนยัน'
        desc={
          <div>
            ต้องการ
            <span style={{ color: theme.COLORS.BLUE_2, fontWeight: 600, textDecoration: 'underline' }}> {enabled ? ' ปิด ' : ' เพิ่ม '}</span>{enabled ? 'การใช้งาน' : 'สิทธิ์การใช้งานให้'}
            <span style={{ color: theme.COLORS.BLUE_2, fontWeight: 600 }}>{` ${name} `}</span>
            ใน
            <span style={{ color: theme.COLORS.BLUE_2, fontWeight: 600 }}>{` ${clinic.name}  `}</span>
            ใช่หรือไม่?
          </div>
        }
        onConfirm={() => this.setState({ isOpen: false })}
        onCancel={() => this.setState({ isOpen: false })}
      />,
      isOpen: true,

    })
  }
  handleSubmit = async () => {
    this.setState({ loadingSubmit: true })
    const { id } = this.props.reduxUserProfile
    const { values } = this.props.reduxForm
    let params = {
      ...values,
      username: undefined,
      role: undefined,
      clinics: undefined,
      doctor: undefined,
      title: undefined,
      password: values?.password,
      profile_picture_url: values?.profile_picture_url
    }    
    if (values) {
      let res = await userService.PATCH_USER_BY_ID(id, params)
      // let res = await userService.PATCH_USER_BY_ID(id, { password: values.password })
      if (res && res.success) {
        this.setState({
          loadingSubmit: false,
          isOpen: true,
          modal: <BoxConfirm
            theme_success
            title='Success!'
            desc='อัพเดทบัญชีสำเร็จแล้ว'
            confirm='ตกลง'
            onConfirm={() => {
              this.setState({ isOpen: false })
              this.fetchData({ id: res.data.id })
            }}
            onCancel={false}
          />
        })
      } else {
        this.setState({
          loadingSubmit: false,
          errorMsg: `Fail to create clinic (${res.data?.error})`,
        })
      }
    } else {
      this.setState({
        loadingSubmit: false,
        errorMsg: ``,
      })
    }
  }
  handleSelectFilter = (e) => {
    if (e) {
      this.setState({doctor_code: e?.code});
    }
  }

  render() {
    const { realm } = this.props.reduxInstitutionDetail
    const { name, profile_picture_url } = this.props.reduxUserProfile
    const { creating, clinics,doctor_profile, loading,profile, loadingSubmit, modal, readonly, isOpen, errorMsg } = this.state;
    return <AadminUserAccountContainerStyled>
      <BoxModal
        isOpen={isOpen}
        onRequestClose={() => this.setState({ isOpen: false })}
        theme_standard
      >
        {modal}
      </BoxModal>
      <div className='back'>
        <ButtonCustom
          theme_back
          label="BACK"
          icon='arrow_left.svg'
          onClick={() => this.props.history.push(ROUTE_PATH.SETTINGS)}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: '1rem' }}>
      </div>
      <div className='container_custom'>
        <div className='container_custom_form'>
          <Typography label={creating ? "Create New User Account" : name} weight={500} loading={loading} color={theme.COLORS.BLUE_17} size={theme.FONTS.SIZE_30px} />
          <UserAccountForm
            loading={loading}
            loadingSubmit={loadingSubmit}
            errorMsg={errorMsg}
            realm={realm}
            creating={creating}
            profile={profile}
            doctor_profile={doctor_profile}
            role={profile.role}
            readonly={readonly && readonly}
            profile_picture_url={profile_picture_url}
            onSubmit={this.handleSubmit}
            handleSelectFilter={this.handleSelectFilter}
            doctorSync ={false} 
          />
        </div>
        {/* {!creating &&
          <div style={{ marginTop: '3rem' }}>
            <Typography label="แผนก" weight={500} loading={loading} color={theme.COLORS.BLUE_3} size={theme.FONTS.SIZE_30px} />
            <TableCustom
              theme_standard
              columns={columns}
              data={clinics}
            />
          </div>
        } */}
      </div>

    </AadminUserAccountContainerStyled>;
  }
}
const columns = [
  {
    title: 'Active',
    field: 'action',
    headerStyle: { display: 'flex', justifyContent: 'center', width: '5%', backgroundColor: '#F7F2FF', paddingTop: '1rem', paddingBottom: '1rem' },
    style: { textAlign: 'center', width: '5%', },
    icon: 'setting.svg',
    fluid_skeleton: true
  },
  {
    title: 'ชื่อแผนก',
    field: 'name',
    headerStyle: { textAlign: 'left', width: '90%', backgroundColor: '#F7F2FF', paddingTop: '1rem', paddingBottom: '1rem' },
    style: { textAlign: 'left', width: '90%', },
    icon: 'setting.svg',
  },
  {
    title: 'เปิดบริการเมื่อ',
    field: 'associated_at',
    headerStyle: { textAlign: 'left', width: '11%', backgroundColor: '#F7F2FF', paddingTop: '1rem', paddingBottom: '1rem' },
    style: { textAlign: 'left', width: '11%', },
    icon: 'setting.svg',
  },
]
const data = [
  {
    clinics: 'OPD (แผนกผู้ป่วยนอก)',
    action: <div style={{ marginTop: '-0.2rem' }}>
      <InputCheckboxNormal
        theme_transparent_2
        value={1}
        checked={true}
        onChange={(e) => console.log(e)}
      />
    </div>
  },
  {
    clinics: 'Wellness Center (ศูนย์สุขภาพครบวงจร)',
    action: <div style={{ marginTop: '-0.2rem' }}>
      <InputCheckboxNormal
        theme_transparent_2
        value={1}
        checked={true}
        onChange={(e) => console.log(e)}
      />
    </div>
  },
]
const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxInstitutionDetail: state.institution_detail,
  reduxUserProfile: state.user_profile,
  reduxForm: state.form.UserAccountForm
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxUserProfile: (data) => dispatch(setReduxUserProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AadminUserAccountContainer);
