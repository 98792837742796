import styled from "styled-components"
// import sidebar_background from '../../assets/'
import { backgroundImages } from 'assets';
export const SidebarStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        .sidenav {
            z-index: 1;
            text-align:center;
            width: 120px;
            position: fixed;
            top: 0;
            left: 0;
            border:none;
            /* background-image: linear-gradient(157deg, ${({ theme }) => theme.COLORS.PURPLE_1},${({ theme }) => theme.COLORS.PURPLE_1},${({ theme }) => theme.COLORS.PURPLE_1}, ${({ theme }) => theme.COLORS.BLUE_3}); */
            background-image: ${ () => `url(${backgroundImages['side_bar.svg']})`};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            overflow-x: hidden;
            padding-top: 1rem;
            font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
            height: 100%;
            font-weight : 500;
            .logo_container{
                color:black;
                /* display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: 90% 5%; */
                margin: 30px 0;
                display: flex;
                justify-content:center;
                align-items:center;
                position:relative;
                .arrow_menu{
                    right:0;
                    position:absolute;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
                    background-color: ${({theme}) => theme.COLORS.WHITE_1 };
                    cursor: pointer;
                    border-radius: 6px 0px 0px 6px;
                    width:20px;
                    height:60px;
                    &:active {
                    -webkit-transform: scale(0.97, 0.97);
                    transform: scale(0.97, 0.97);
                    }
                }
            }
            .menu_center{
                text-align:center;
                cursor:pointer;
                padding:1rem 0;
                position : relative;
                &.first_menu{
                    padding-top:6.3rem;
                }
                &.active{
                    /* z-index:2;
                    text-
                    align:right; */
                }
                a{
                    text-decoration:none;
                }
                img{
                    width :1.5rem;
                }
                .name_layout{
                    margin-top : 0;
                    color : ${({ theme }) => theme.COLORS.GRAY_16};

                }
                &:hover{
                    opacity : 0.8;
                    .name_layout{
                        opacity : 1;
                        display : block;
                    }
                }
            }
            .menu_profile{
                position: absolute;
                text-align:center;
                bottom: 6rem;
                left:0;
                right:0;
                cursor:pointer;
                .name_layout{
                    display :none;
                    color : ${({ theme }) => theme.COLORS.GRAY_16};
                }
                &:hover{
                    opacity : 0.8;
                    .name_layout{
                        opacity : 1;
                        display : block;
                    }
                }
            }
            .menu_bottom{
                position: absolute;
                bottom :3rem;
                margin:auto;
                left : 0;
                right:0;
                cursor:pointer;
                img{
                    width :1.6rem;
                    margin-left : -0.5rem;
                }
                .name_layout{
                    display:flex;
                    justify-content:center;
                    align-items: center;
                    column-gap:5px;
                    width:100%;
                    color : ${({ theme }) => theme.COLORS.GRAY_16};
                    margin-top : 0.5rem;
                }
                &:hover{
                    opacity : 0.8;
                    .name_layout{
                        opacity : 1;
                        /* display : block; */
                    }
                }
                &:active{
                    transform  : translateY(1px);
                }
            }
        }
        
        .block{
            position: fixed;
            top: 0;
            left: 0;
            /* z-index: 1; */
            padding:30px 0;
            .arrow_menu{
                left:0;
                position:absolute;
                display:flex;
                justify-content:center;
                align-items:center;
                box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
                background-color: ${({theme}) => theme.COLORS.WHITE_1 };
                cursor: pointer;
                border-radius: 0px 6px 6px 0px;
                width:20px;
                height:60px;
                &.active {
                    img{
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .sidenav_modal{
            text-align:center;
            width: 100px;
            position: fixed;
            top: 0;
            /* z-index : 1; */
            left: 0;
            border:none;
            background-image: ${ () => `url(${backgroundImages['side_bar.svg']})`};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            overflow-x: hidden;
            padding-top: 1rem;
            font-size: ${({ theme }) => theme.FONTS.SIZE_14px};
            height: 100%;
            font-weight : 500;
            .menu_center{
                text-align:center;
                cursor:pointer;
                padding:1rem 0;
                position : relative;
                &.first_menu{
                    padding-top:6.3rem;
                }
                &.active{
                    /* z-index:2;
                    text-
                    align:right; */
                }
                a{
                    text-decoration:none;
                }
                img{
                    width :1.5rem;
                }
                .name_layout{
                    margin-top : 0;
                    color : ${({ theme }) => theme.COLORS.GRAY_16};

                }
                &:hover{
                    opacity : 0.8;
                    .name_layout{
                        opacity : 1;
                        display : block;
                    }
                }
            }
            .menu_profile{
                position: absolute;
                text-align:center;
                bottom: 6rem;
                left:0;
                right:0;
                cursor:pointer;
                .name_layout{
                    display :none;
                }
                &:hover{
                    opacity : 0.8;
                    .name_layout{
                        opacity : 1;
                        display : block;
                        color : ${({ theme }) => theme.COLORS.GRAY_16};
                    }
                }
            }
            .menu_bottom{
                position: absolute;
                bottom :3rem;
                margin:auto;
                left : 0;
                right:0;
                cursor:pointer;
                &:hover{
                    opacity : 0.8;
                    .name_layout{
                        opacity : 1;
                        display : block;
                    }
                }
                &:active{
                   transform  : translateY(1px);
                }
                .name_layout{
                    display: flex;
                    column-gap: 5px;
                    color : ${({ theme }) => theme.COLORS.GRAY_16};
                }
            }
        }
        @media only screen and (max-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
            .sidenav {
                /* display:none; */
            }
            .sidenav_modal{
                display:none;
            }
        }
        @media only screen and (min-width: ${({ theme }) => theme.SCREENS.TABLET_EXTRA}) {
            .sidenav_modal{
                display:none;
            }
        }
        // @media only screen and (min-width: ${({ theme }) => theme.SCREENS.LABTOP}) {
        //     .sidenav {
        //        width:10rem;
        //     }
        // }
    }
`