import React, { useEffect, useState } from 'react';
import { DashboardActiveStyled, HighLightStyled } from './styled';
import { Typography, BoxCustom } from 'components'
import { Donut, BarHorizon } from 'chart'
import theme from 'assets/theme.json'
import { userService } from 'apiService';
import ReactTooltip from 'react-tooltip';
import millify from 'millify';
import { iconImages } from 'assets';


export const DashboardActive = (props) => {
  const { valuesOfId } = props;
  const [data, setData] = useState()
  const [highlights, setHighlights] = useState()
  const [visitCallingHide, setVisitCallingHide] = useState(false)

  useEffect(() => {
    if (valuesOfId?.institution_id) {
      Promise.all[
        fetchData(valuesOfId.institution_id),
        fetchHighLight(valuesOfId.institution_id)
      ]
    }
  }, [valuesOfId])

  const fetchHighLight = async (institution_id) => {
    let res = await userService.GET_DASHBOARD_HIGHLIGHT(institution_id)
    if (res && res.success) {
      setHighlights(setHighlightValue(res.data))
    }
  }
  const fetchData = async (institution_id) => {
    let res = await userService.GET_DASHBOARD_ACTIVE(institution_id)
    if (res && res.success) {
      setData(filterShowAll(res.data))
    }
    if (valuesOfId?.clinic_id !== 'show_all') {
      setVisitCallingHide(true)
    } else {
      setVisitCallingHide(false)
    }
  }

  const filterShowAll = (values) => {
    return valuesOfId?.clinic_id !== 'show_all'
      ? values && values.filter((value) => {
        return parseInt(valuesOfId?.clinic_id) === value.id
      })
      : values
  }

  const setHighlightValue = (data) => {
    const convertToInt = data && Object.keys(data).map((key) => {
      return {
        [key]: parseInt(data[key])
      }
    })
    const newData = (Object.assign({}, ...convertToInt))
    return items.map((item) => {
      return {
        ...item,
        data: setVisitHighlight(item.type, newData, item)
      }
    })
  }
  const setVisitHighlight = (type, data, obj) => {
    switch (type) {
      case 'total':
        return {
          values: (
            <div>
              <ReactTooltip />
              <span style={{ marginRight: '0.4rem' }} data-tip={obj.tooltip_main}>
                {millify(data?.visit_total_count)}
              </span>
                /
              <span style={{ fontSize: '18px', marginLeft: '0.2rem' }} data-tip={obj.tooltip_sub}>
                {millify(data?.patient_total_count)}
              </span>
            </div>
          ),
          percent: <div>&nbsp;</div>
        }
      case 'week':
        let not_calc_week = data?.visit_lastweek_finishing_count === 0
        let percent_week = ((data?.visit_thisweek_finishing_count - data?.visit_lastweek_finishing_count) / data?.visit_lastweek_finishing_count) * 100
        return {
          values: (
            <div >
              <ReactTooltip />
              <span style={{ marginRight: '0.4rem' }} data-tip={obj.tooltip_main}>
                {millify(data?.visit_thisweek_finishing_count)}
              </span>
              /
              <span style={{ marginRight: '0.4rem', fontSize: '18px', }} data-tip={obj.tooltip_sub}>
                {millify(data?.visit_thisweek_count)}
              </span>
            </div>
          ),
          percent: not_calc_week ? '-' : `${percent_week?.toFixed(2)}%`,
          previous: 'last week',
          status: data?.visit_lastweek_finishing_count === data?.visit_thisweek_finishing_count
            ? ''
            : data?.visit_lastweek_finishing_count < data?.visit_thisweek_finishing_count
              ? 'increase'
              : 'decrease'
        }
      case 'today':
        let not_calc_day = data?.visit_yesterday_finishing_count === 0
        let percent_day = ((data?.visit_today_finishing_count - data?.visit_yesterday_finishing_count) / data?.visit_yesterday_finishing_count) * 100
        return {
          values: (
            <div >
              <ReactTooltip />
              <span style={{ marginRight: '0.4rem' }} data-tip={obj.tooltip_main}>
                {millify(data?.visit_today_finishing_count)}
              </span>
              /
              <span style={{ marginRight: '0.4rem', fontSize: '18px', }} data-tip={obj.tooltip_sub}>
                {millify(data?.visit_today_count)}
              </span>
            </div>
          ),
          percent: not_calc_day ? '-' : `${percent_day?.toFixed(2)}%`,
          previous: 'yesterday',
          status: data?.visit_yesterday_finishing_count === data?.visit_today_finishing_count
            ? ''
            : data?.visit_yesterday_finishing_count < data?.visit_today_finishing_count
              ? 'increase'
              : 'decrease'
        }
      default:
    }
  }

  const setVisitBarHorizon = (values, type) => {
    const categories = values && values
      .map(value => {
        return value.name
      })
    const series = (values && values.length > 0) &&
      Object.keys(values[0]?.visit)
        .filter(value => value.indexOf('visit_count') === -1)
        .map(value => {
          return {
            name: setLabel(value, type),
            data : values?.map(value1 => {
              return  parseInt(value1.visit[value])
            })
          }
        })
    // const categories = values &&
    //   Object.keys(values[0].visit)
    //     .filter(value => value.indexOf('visit_count') === -1)
    //     .map(value => {
    //       return { key: value, name: setLabel(value, type), }
    //     })
    // const series1 = values &&
    //   Object.keys(values[0].visit)
    //     .filter(value => value.indexOf('visit_count') === -1)
    //     .map(value => {
    //       return { key: value, name: setLabel(value, type), }
    //     })
    //     console.log(series1)
    // const series1 = values && values
    //   .map(value => {
    //     const data = Object.keys(value.visit)
    //       .filter(value1 => value1.indexOf('visit_count') === -1)
    //       .map((value1, i) => {
    //         return parseInt(value.visit[value1])
    //       })
    //     return {
    //       name: value.name,
    //       data: data
    //     }
    //   })
    // console.log('series1',series1)

    let params = {
      categories: categories && categories,
      series: series && series
      // categories: categories && categories.map((category) => category.name),
      // series: series && series
    }
    return params
  }

  const setPieDonutChartData = (values, type, field) => {
    let result = values?.length > 0 &&
      valuesOfId?.clinic_id !== 'show_all' && values.length === 1
      ? mapNewVisitValues(values, type, field)
      : values && values.map((value) => {
        return {
          id: value.id,
          code: value.code,
          name: value.name,
          description: value.description,
          value: field
            ? parseInt(value[type][field])
            : sumObjValues(value[type])
        }
      })
    return result
  }
  const mapNewVisitValues = (values, type, field) => {
    let is_visit_calling = field.indexOf('calling') !== -1
    // is_visit_calling && setVisitCallingHide(is_visit_calling)
    let filters = values && Object.keys(values[0][type])
      .filter(value => {
        return type === 'visit'
          ? value.indexOf('visit_count') === -1
          : value.indexOf('provider') === -1
      })
      .map((value) => {
        const data = values[0]
        const keys = data[type]
        return {
          key: value,
          value: keys[value],
          [value]: keys[value]
        }
      })
    let result = filters && filters
      .map((value) => {
        const findValue = (filters.find((filter) => filter.key === value.key))
        const data = values[0]
        return {
          id: data.id,
          code: data.code,
          name: setLabel(value.key, type),
          description: data.description,
          value: findValue ? parseInt(findValue.value) : 0
        }
      })
    return !is_visit_calling ? result : []
  }
  const sumObjValues = (obj) => Object.values(obj).reduce((a, b) => parseInt(a) + parseInt(b));
  // const sumArrayValues = (arrays) => arrays.reduce((a, b) => parseInt(a) + parseInt(b), 0)

  const setLabel = (str, type) => {
    return str && type === 'visit'
      ? capitalizeFirstLetter(str.substring(str.indexOf('_') + 1, str.lastIndexOf('_')))
      : capitalizeFirstLetter(str.substring(0, str.indexOf('_')))
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderHighLight = () => {
    const arrays = highlights ? highlights : items
    return arrays?.map((highlight) => {
      return (
        <div className='col_custom_4'>
          <BoxHighlight
            {...highlight}
          //
          //   values: <div>555 / <span style={{ fontSize: '16px' }}>122</span></div>,
          //   increase: '128%',
          // }
          />
        </div>
      )
    })
  }

  return (
    <DashboardActiveStyled {...props}>
      <div style={{ marginTop: '1rem', marginBottom: '0.25rem' }}>
        <Typography
          label='Highlight'
          size={theme.FONTS.SIZE_16px}
          color={theme.COLORS.PURPLE_1}
        />
      </div>
      <div className='row_custom'>
        {renderHighLight()}
      </div>
      <div style={{ marginTop: '1.5rem', marginBottom: '0.25rem' }}>
        <Typography
          label='Online Statistics'
          size={theme.FONTS.SIZE_16px}
          color={theme.COLORS.PURPLE_1}
        />
      </div>
      <div className='row_custom'>
        <div className='col_custom_3'>
          <Donut
            theme_standard
            title={'Provider Session'}
            data={setPieDonutChartData(data, 'session', 'provider_session_count')}
          />
        </div>
        <div className='col_custom_3'>
          <Donut
            theme_standard
            title={'Provider User'}
            data={setPieDonutChartData(data, 'user', 'provider_user_count')}

          />
        </div>
        <div className='col_custom_3'>
          <Donut
            theme_standard
            title={'Total Visit'}
            data={setPieDonutChartData(data, 'visit', 'visit_count')}
          />
        </div>
        <div className='col_custom_3'>
          <Donut
            theme_standard
            hide={visitCallingHide}
            title={'Calling Visit'}
            data={setPieDonutChartData(data, 'visit', 'visit_calling_count')}
          />
        </div>
      </div>
      {valuesOfId?.clinic_id === 'show_all' &&
        <>
          {/* <div style={{ marginTop: '1.5rem', marginBottom: '0.25rem' }}>
            <Typography
              label='Stacked Bar Statistics'
              size={theme.FONTS.SIZE_16px}
              color={theme.COLORS.PURPLE_1}
            />
          </div> */}
          <div style={{ marginTop: '-0.5rem' }}>
            <BarHorizon
              theme_standard
              title='Visit By Status'
              data={setVisitBarHorizon(data, 'visit')}
            />
          </div>
        </>
      }
    </DashboardActiveStyled>
  );
};

const items = [
  {
    title: 'Visits vs Patients',
    type: 'total',
    tooltip: 'จำนวน Visit ทั้งหมด / จำนวน คนไข้ ทั้งหมด',
    tooltip_main: 'จำนวน Visit ทั้งหมด',
    tooltip_sub: 'จำนวน คนไข้ ทั้งหมด',
  },
  {
    title: 'Visits this week',
    type: 'week',
    tooltip: 'Visit ที่ได้รับการสรุปผล สัปดาห์นี้  / Visit ทั้งหมด สัปดาห์นี้',
    tooltip_main: 'Visit ที่ได้รับการสรุปผล สัปดาห์นี้',
    tooltip_sub: 'Visit ทั้งหมด สัปดาห์นี้',
  },
  {
    title: 'Visits Today',
    type: 'today',
    tooltip: 'Visit ที่ได้รับการสรุปผล วันนี้  / Visit ทั้งหมด วันนี้',
    tooltip_main: 'Visit ที่ได้รับการสรุปผล วันนี้',
    tooltip_sub: 'Visit ทั้งหมด วันนี้',
  }
]

const BoxHighlight = ({ title, data, tooltip }) => {
  return <BoxCustom
    theme_standard_box_custom_3

    child={
      <HighLightStyled style={{ height: data ? '100%' : '5rem' }}>
        <div className='title'>
          {title}
          <ReactTooltip />
          <img
            style={{ marginLeft: '0.2rem', cursor: 'pointer' }}
            src={iconImages['tooltip.svg']} alt='tooltip'
            data-tip={tooltip} width={12} height={12}
          />
        </div>
        <div className='detail'>
          {data?.values}
        </div>
        <div className='desc'>
          {(data?.percent && data?.percent !== '-')
            ? <div>
              <span style={{
                color: data?.status === 'increase'
                  ? 'green'
                  : data?.status === 'decrease'
                    ? 'red'
                    : 'gray'
                ,
                fontWeight: 'bold'
              }}>
                {data?.percent}
              </span>  {data?.previous ? `vs ${data?.previous}` : ''}
            </div>
            : data?.percent
          }
        </div>
        {/* } */}
      </HighLightStyled>
    }
  />
}
