import { VALIDATE } from 'assets'
import { ButtonCustom, ImgUpload, InputText } from 'components'
import { CheckPasswordBox } from 'components/CheckPasswordBox'
import React, { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { AdminFormStyled } from './styled'

let AdminForm = ({
  handleSubmit,
  profile_picture_url,
  realm,
  institution_id,
  creating,
  readonly,
  isReset,
}) => {
  const [CheckUpperPassword, setCheckUpperPassword] = useState(null)
  const [CheckLenghtpassword, setCheckLenghtpassword] = useState(null)
  const [CheckIsNumberpassword, setCheckIsNumberpassword] = useState(null)
  const [errorMsgDuplicate, setErrorMsgDuplicate] = useState('')
  const [errorMsgMaxLength, setErrorMsgMaxLength] = useState('')
  const [username, setUsername] = useState('')

  const handleBlur = async (e) => {
    let username = `${e.target.value}@${realm}`
    setUsername(username)
    if (username.length > 0) {
      let res = await userService.CHECK_USERNAME(institution_id, username)
      if (res && res.success) {
        if (res.data) {
          setErrorMsgDuplicate('This username is already in use')
        } else {
          setErrorMsgDuplicate('')
        }
      }
    }
    if (username.length < 4 || username.length > 32) {
      setErrorMsgMaxLength('Must be between 4 and 32 characters.')
    } else {
      setErrorMsgMaxLength('')
    }
  }

  const handleChange = async (e) => {
    let password = null
    if (e.target.name === 'password') {
      password = e.target.value
      await checkPassword(password)
    }
  }

  const checkPassword = async (value) => {
    const requiredments = [
      { regex: /.{8,}/, index: 0, name: 'Lenght' },
      { regex: /[0-9]/, index: 1, name: 'Number' },
      { regex: /[A-Z]/, index: 2, name: 'UpperCase' },
    ]
    const checker = requiredments.map((item) => ({
      name: item.name,
      isValid: false,
    }))

    const newReq = checker.map((item, index) => {
      const isValid = requiredments[index].regex.test(value)
      return { ...item, isValid }
    })
    newReq.forEach(async (item) => {
      if (item?.name === 'Number') {
        setCheckIsNumberpassword(item?.isValid === true ? true : false)
      }
      if (item?.name === 'Lenght') {
        setCheckLenghtpassword(item?.isValid === true ? true : false)
      }
      if (item?.name === 'UpperCase') {
        setCheckUpperPassword(item?.isValid === true ? true : false)
      }
    })
  }
  const reset = async (e) => {
    setCheckUpperPassword(null)
    setCheckLenghtpassword(null)
    setCheckIsNumberpassword(null)
  }

  useEffect(() => {
    reset()
  }, [isReset])

  return (
    <AdminFormStyled>
      <form onChange={handleChange} onSubmit={handleSubmit}>
        <div className='buttonSection'>
          <div style={{ width: '10rem' }}>
            <ButtonCustom
              theme_yellow
              label='บันทึก'
              onClick={
                handleSubmit
                // CheckIsNumberpassword &&
                // CheckLenghtpassword &&
                // CheckUpperPassword
                //   ? handleSubmit
                //   : undefined
              }
            />
          </div>
        </div>
        <div className='formContainer'>
          <div className='row mtb10'>
            <div className='col' style={{ marginLeft: '20px' }}>
              <div className='field'>
                <ImgUpload
                  form={{ formName: 'AdminForm' }}
                  profile_picture_url={profile_picture_url}
                />
              </div>
            </div>
          </div>
          <div className='row mtb10'>
            <div className='col'>
              <div className='label'>ชื่อ-นามสกุล</div>
              <Field
                theme_standard_3
                name='name'
                component={InputText}
                type='text'
                placeholder='Firstname Lastname'
              />
            </div>
          </div>
          <div className='row mtb10'>
            <div className='col'>
              <div className='row'>
                <div className='col gap'>
                  <div className='label'>
                    บัญชีผู้ใช้งาน
                    {/* {username && <small style={{ opacity: 0.8 }}>{` (${username})`}</small>} */}
                  </div>
                  <div className='field'>
                    <Field
                      theme_standard_3
                      component={InputText}
                      name='username'
                      type='text'
                      onBlur={(e) => handleBlur(e)}
                      specialText={creating && `@${realm}`}
                      disabled={readonly?.username}
                      readOnly={readonly?.username}
                      placeholder='username'
                      validate={
                        creating && [
                          VALIDATE.REQUIRE_FIELD,
                          VALIDATE.ALPHA_NUMBERIC,
                        ]
                      }
                    />
                  </div>
                  {(errorMsgMaxLength || errorMsgDuplicate) && (
                    <div className='error'>
                      {errorMsgMaxLength}
                      {errorMsgDuplicate}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='label'>รหัสผ่าน</div>
              <div className='field'>
                <Field
                  theme_standard_3
                  component={InputText}
                  name='password'
                  type='password'
                  validate={
                    creating
                      ? [
                          VALIDATE.REQUIRE_FIELD,
                          VALIDATE.LENGTH_4_32,
                          VALIDATE.PASSWORD_REGEX,
                        ]
                      : [
                          VALIDATE.LENGTH_4_32,
                          VALIDATE.AT_LEAST_ONE_NUMBER,
                          VALIDATE.PASSWORD_REGEX,
                        ]
                  }
                />
              </div>
              <div className='label'>ยืนยันรหัสผ่าน</div>
              <div className='field'>
                <Field
                  theme_standard_3
                  component={InputText}
                  name='confirm_password'
                  type='password'
                  validate={
                    creating
                      ? [
                          VALIDATE.REQUIRE_FIELD,
                          VALIDATE.LENGTH_4_32,
                          VALIDATE.PASSWORD_MATCH,
                        ]
                      : [VALIDATE.PASSWORD_MATCH]
                  }
                />
              </div>
              <div className='gap'>
                <CheckPasswordBox
                  CheckIsNumberpassword={CheckIsNumberpassword}
                  CheckLenghtpassword={CheckLenghtpassword}
                  CheckUpperPassword={CheckUpperPassword}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </AdminFormStyled>
  )
}

AdminForm = reduxForm({
  // a unique name for the form
  form: 'AdminForm',
})(AdminForm)

export default AdminForm
