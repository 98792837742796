import { userService } from 'apiService';
import { BoxCustom } from 'components';
import React, { useEffect, useState } from 'react';
import {
  Typography,
  InputSelect
} from 'components'
import { DashboardProviderStyled } from './styled';
import theme from 'assets/theme.json'
import {
  InputDateRangeSelect
} from 'widgets'
import {
  BarLine,
  Heatmap
} from 'chart'
import moment from 'moment'

export const DashboardProvider = (props) => {
  const { valuesOfId } = props;
  const defaultBeginDate = moment(new Date(Date.now() - 13 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
  const defaultTodayDate = moment(new Date()).format('YYYY-MM-DD')
  //// data of session/user daily
  const [dataDaily, setDataDaily] = useState()
  const [dataDailyApi, setDataDailyApi] = useState()
  //// data of session/user hour
  const [dataHour, setDataHour] = useState()
  const [dataHourApi, setDataHourApi] = useState()

  const [typeDaily, setTypeDaily] = useState('session')
  const [typeHour, setTypeHour] = useState('session')
  //// date of daily /////
  const [dailyBeginDate, setDailyBeginDate] = useState(defaultBeginDate)
  const [dailyTodayDate, setDailyTodayDate] = useState(defaultTodayDate)
  //// date of hours /////
  const [hourBeginDate, setHourBeginDate] = useState(defaultBeginDate)
  const [hourTodayDate, setHourTodayDate] = useState(defaultTodayDate)



  useEffect(() => {
    if (valuesOfId?.institution_id) {
      Promise.all[
        fetchDataDaily(valuesOfId.institution_id),
        fetchDataHours(valuesOfId.institution_id)
      ]
    }
  }, [valuesOfId])

  const filterShowAll = (values) => {
    return valuesOfId?.clinic_id !== 'show_all'
      ? values && values.filter((value) => {
        return parseInt(valuesOfId?.clinic_id) === value.id
      })
      : values
  }
  ///////// Statistics Daily /////////
  const fetchDataDaily = async (id, beginDate, todayDate) => {
    // const date = new Date(Date.now() - 13 * 24 * 60 * 60 * 1000) /// default date
    const today_date = todayDate ? todayDate : dailyTodayDate
    const begin_date = beginDate ? beginDate : dailyBeginDate
    let res = await userService.GET_DASHBOARD_DAILY_USER_SESSION(id, begin_date, today_date)
    if (res && res.success) {
      const dataFilters = filterShowAll(res.data)
      setDataDailyApi(dataFilters)
      setDataDaily(setDailyValues(dataFilters, begin_date, today_date, typeDaily))
    }
  }

  const setDailyValues = (data, begin_date, today_date, type) => {
    let dateRange = getDates(begin_date, today_date)
    if (valuesOfId?.clinic_id !== 'show_all' && data?.length === 1) {
      let has_daily = data[0]?.clinic_statistic_dailies
      if (has_daily) {
        let active_filters = data && Object.keys(has_daily[0])
          .filter(value => {
            return type === 'session'
              ? value.indexOf('active_session') !== -1
              : value.indexOf('active_user') !== -1
          })
          .filter(value => {
            return value.indexOf('provider') === -1 && value.indexOf('patient') === -1
          })
        const role_filters = data && Object.keys(has_daily[0])
          .filter(value => {
            return value.indexOf('provider') === -1 && value.indexOf('patient') === -1
          })
          .filter(value => {
            return value.indexOf('total_user_count') !== -1
          })
        const bar_series = role_filters?.map((key) => {
          return {
            name: setLabel(key),
            type: 'bar',
            data: dateRange?.map((date) => {
              return summaryDailyByRole(data, date, key)
            })
          }
        })
        const line_series = [{
          name: type === 'session' ? 'Concurrent Sessions' : 'Concurrent Users',
          type: 'line',
          data: dateRange?.map((date) => {
            return summaryDailyTotal(data, date, type)
          })
        }]
        const series = [...bar_series, ...line_series]
        return {
          categories: dateRange?.map((date) => moment(date).format('DD MMM')),
          series: series
        }
      } else {
        return {
          categories: [],
          series: []
        }
      }
    } else if (data?.length > 0) {
      const bar_series = data?.map((value) => {
        return {
          name: value.name,
          type: 'bar',
          // data: getDates(begin_date, today_date).map((date,i) => i)
          data: dateRange?.map((date) => {
            let stat_date = value.clinic_statistic_dailies?.find(daily => daily.stat_date === date)
            return stat_date ? stat_date.provider_total_user_count : 0
          })
        }
      })
      const line_series = [{
        name: type === 'session' ? 'Concurrent Sessions' : 'Concurrent Users',
        type: 'line',
        data: dateRange?.map((date) => {
          return summaryDailyTotal(data, date, type)
        })
      }]
      const series = [...bar_series, ...line_series]
      const params = {
        categories: dateRange?.map((date) => moment(date).format('DD MMM')),
        series: series && series
      }
      return params
    }
  }
  // provider_total_user_count
  const summaryDailyTotalByRole = (values, filters, date, type) => {
    let key = type === 'session' ? 'provider_max_active_session_count' : 'provider_max_active_user_count'
    const count = values?.map((value) => {
      const stat_date = value.clinic_statistic_dailies.find(daily => daily.stat_date === date)
      return stat_date
        ? filters && filters
          .map((filter) => parseInt(stat_date[filter]))
          .reduce((sum, x) => sum + x)
        : 0
    })
    return count.reduce((sum, x) => sum + x);
  }
  const summaryDailyTotal = (values, date, type) => {
    let key = type === 'session' ? 'provider_max_active_session_count' : 'provider_max_active_user_count'
    const count = values?.map((value) => {
      const stat_date = value.clinic_statistic_dailies.find(daily => daily.stat_date === date)
      return stat_date ? parseInt(stat_date[key]) : 0
    })
    return count.reduce((sum, x) => sum + x);
  }
  const summaryDailyByRole = (values, date, key) => {
    const stat_date = values[0]?.clinic_statistic_dailies.find((daily) => daily.stat_date === date)
    return stat_date ? stat_date[key] : 0
  }
  // const summaryDaily = (values, type) => {
  //   return type === 'session'
  //     ? values.provider_max_active_session_count
  //     : type === 'user'
  //       ? values.provider_max_active_user_count
  //       : ''
  // }
  const getDates = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  ///////// Statistics Daily /////////

  ///////////// **************************************************************************************************************************************************************************************** ///////

  ///////// Statistics Hour /////////
  const fetchDataHours = async (id, beginDate, todayDate) => {
    const today_date = todayDate ? todayDate : dailyTodayDate
    const begin_date = beginDate ? beginDate : dailyBeginDate
    let res = await userService.GET_DASHBOARD_HOUR_USER_SESSION(id, begin_date, today_date)
    if (res && res.data) {
      const dataFilters = filterShowAll(res.data)
      setDataHourApi(dataFilters)
      setDataHour(setHourValues(dataFilters, begin_date, today_date, typeHour))
    }
  }
  const setHourValues = (data, begin_date, today_date, type) => {
    const hours = getHours()
    let series = hours?.map((hour) => {
      return {
        name: hour.render,
        data: daysOfWeek.map((day, index) => {
          return summaryHour(data, day, hour, type, index)
        })
      }
    })
    return {
      series: series && series
    }
  }
  const summaryHour = (values, day, hour, type, index) => {
    // .reduce((sum, x) => sum + x);
    let key = type === 'session' ? 'provider_max_active_session_count' : 'provider_max_active_user_count'
    const valueOfDateHours = values && values
      .map((value) => {
        return value.clinic_statistic_hourlies.filter(stat_hour => {
          return (daysOfWeek[new Date(stat_hour?.stat_date).getDay()] === day && parseInt(hour.value) === stat_hour?.stat_hour)
        })
      })
    const arrays = []
    valueOfDateHours?.map(value => {
      return value.map((value2) => arrays.push(value2.stat_date))
    })
    const arraysDate = [...new Set(arrays)]
    const sumValues = arraysDate && arraysDate
      .map((value) => {
        return valueOfDateHours?.map(value2 => {
          return value2.find(value3 => value3.stat_date === value)
        })
      })
      .map((value) => {
        const sum = value?.length !== 0
          ? value
            .map((value2) => {
              return value2
                ? value2[key] ? parseInt(value2[key]) : 0
                : 0
            })
            .reduce((sum, x) => sum + x)
          : 0
        return sum
      })
    // .map((value) => {
    //   const arrayOfValue = value?.length !== 0
    //     ? value
    //       .map((value2) => {
    //         return value2[key] ? value2[key] : 0
    //         // return type === 'session'
    //         //   ? value2.provider_max_active_session_count
    //         //   : type === 'user'
    //         //     ? value2.provider_max_active_user_count
    //         //     : 0
    //       })
    //     // .reduce((sum, x) => sum + x)
    //     : 0
    //   return arrayOfValue !== 0 ? Math.max(...arrayOfValue) : 0
    // })
    // .reduce((sum, x) => sum + x)
    // console.log('day', day, 'hour', hour, 'day', Math.max(...yxis))
    const yxis = sumValues?.length !== 0 ? Math.max(...sumValues) : 0
    return {
      x: day,
      y: yxis
    }
  }
  ///////// Statistics Hour /////////

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const getHours = () => {
    const items = [];
    moment.locale('en')
    new Array(24).fill().forEach((acc, index) => {
      items.push({
        render: moment({ hour: index }).format('hh:mm A'), /// am pm
        value: moment({ hour: index }).format('HH') /// 24 hours
      });
      // items.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
    })
    moment.locale('th')
    return items;
  }
  const setLabel = (str, type) => {
    return capitalizeFirstLetter(str.substring(0, str.indexOf('_')))
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const renderTitle = (type) => {
    return type === 'session'
      ? 'Concurrent Sessions'
      : 'Concurrent Users'
  }
  const renderSelectType = (chart) => {
    return (
      <InputSelect
        theme_standard_4
        name='institution'
        maxWidth='8rem'
        minWidth='8rem'
        onChange={(e) => handleSelectType(e.target.value, chart)}
      >
        <option selected value='session'>Concurrent Sessions</option>
        <option x value='user'>Concurrent Users</option>
      </InputSelect>
    )
  }
  const renderSelectDate = (chart) => {
    return (
      <InputDateRangeSelect
        theme_standard
        handleSelect={(begin_date, today_date) => handleSelectDate(begin_date, today_date, chart)}
      >
      </InputDateRangeSelect>
    )
  }


  const handleSelectDate = (begin_date, today_date, chart) => {
    switch (chart) {
      case 'daily_user_session':
        setDailyBeginDate(begin_date)
        setDailyTodayDate(today_date)
        fetchDataDaily(valuesOfId?.institution_id, begin_date, today_date)
        break;
      case 'hour_user_session':
        setHourBeginDate(begin_date)
        setHourTodayDate(today_date)
        fetchDataHours(valuesOfId?.institution_id, begin_date, today_date)
        break;
      default:
    }
  }
  const handleSelectType = (type, chart) => {
    switch (chart) {
      case 'daily_user_session':
        setTypeDaily(type)
        setDataDaily(setDailyValues(dataDailyApi, dailyBeginDate, dailyTodayDate, type))
        break;
      case 'hour_user_session':
        setTypeHour(type)
        setDataHour(setHourValues(dataHourApi, hourBeginDate, hourTodayDate, type))
        break;
      default:
    }
  }
  return (
    <DashboardProviderStyled {...props}>
      <div className=''>
        <div style={{ marginTop: '1rem', marginBottom: '0.25rem' }}>
          <Typography
            label='Statistics Provider'
            size={theme.FONTS.SIZE_16px}
            color={theme.COLORS.PURPLE_1}
          />
        </div>
        <div className='row_custom'>
          <div className='col_custom_9'>
            <BoxCustom
              theme_standard_box_custom_title
              title={
                <>
                  {/* // < style={{display : 'flex' , alignItems : 'center'}}> */}
                  <div style={{ marginRight: '.25rem', textTransform: 'capitalize' }}>{renderTitle(typeDaily)}</div>
                  <div style={{ marginTop: '0.5rem' }}>
                    {renderSelectType('daily_user_session')}
                  </div>
                </>
              }
              title_right={
                <div style={{ marginTop: '0.4rem' }}>
                  {renderSelectDate('daily_user_session')}
                </div>
              }
              child={
                <div style={{ padding: '1rem' }} >
                  <BarLine
                    theme_standard
                    data={dataDaily}
                  />
                </div>
              }
            />
          </div>
          <div className='col_custom_4_3'>
            <BoxCustom
              theme_standard_box_custom_title
              title={
                <>
                  {/* // < style={{display : 'flex' , alignItems : 'center'}}> */}
                  <div style={{ marginRight: '.25rem', textTransform: 'capitalize' }}> Hourly Concurrent {(typeHour)}s</div>
                  <div style={{ marginTop: '0.5rem' }}>
                    {renderSelectType('hour_user_session')}
                  </div>
                </>
              }
              title_right={
                <div style={{ marginTop: '0.4rem' }}>
                  {renderSelectDate('hour_user_session')}
                </div>
              }
              child={
                <div >
                  <Heatmap
                    theme_standard
                    data={dataHour}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </DashboardProviderStyled>
  );
};
