import React, { useEffect, useState } from 'react';
import { ClinicListsStyled } from './styled';
import { BoxConfirm, BoxModal, ButtonCustom, InputCheckboxNormal, Middleline, TableCustom } from 'components'
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH, calculateSkeleton } from 'assets';
import { userService } from 'apiService';
import moment from 'moment';
import theme from 'assets/theme.json'

export const ClinicLists = (props) => {
  const history = useHistory()
  const { institutionDetailData } = props

  const [modal, setModal] = useState({
    isOpen: false,
    content: null
  })
  // const [isCheck, setIsCheck] = useState({
  //   id: null,
  //   checked: null,
  //   is_active: null,
  //   is_visible: null,
  // })
  useEffect(() => {
    if (institutionDetailData && institutionDetailData.id) { fetchData() }
  }, [institutionDetailData])

  const fetchData = async () => {
    let res = await userService.GET_CLINICS_BY_ACCOUNT(institutionDetailData.id)
    if (res && res.success) {
      setClinicLists(setNewClinicLists(res.data))
    } else {

    }
  }
  const setNewClinicLists = (data) => {
    return data
      ? data.map((item, i) => {
        return {
          ...item,
          created_at: item.created_at ? moment(item.created_at).format('DD MMM YYYY') : '-',
          name: item.name + '  (' + item.description + ')',
          // is_active: item.is_active === 1 ? 'Active' : 'Inactive',
          // is_visible: item.is_visible === 1 ? 'แสดง' : 'ซ่อน',
          is_active: <InputCheckboxNormal
            theme_transparent_4
            value={JSON.stringify(item)}
            checked={item?.is_active === 1}
            onChange={(e) => handlerCheckbox(e, 'is_active')}
          />,
          is_visible: <InputCheckboxNormal
            theme_transparent_4
            value={JSON.stringify(item)}
            checked={item?.is_visible === 1}
            onChange={(e) => handlerCheckbox(e, 'is_visible')}
          />
        }
      })
      : []
  }
  const handlerCheckbox = (event, type) => {
    const convertValue = { ...JSON.parse(event?.target.value), checked: event?.target?.checked }
    let active = type === 'is_active' ? convertValue?.is_active : convertValue?.is_visible
    let label = type === 'is_active' ? 'บริการ' : 'การรับคนไข้'
    let name = convertValue?.name + ' ' + convertValue?.description
    // setIsCheck({
    //   id: convertValue?.id,
    //   checked: active,
    //   is_active: type === 'is_active',
    //   is_visible: type === 'is_visible',
    // })
    setModal({
      isOpen: true,
      content: (
        <BoxConfirm
          theme_standard
          confirm='ยืนยัน'
          cancel='ยกเลิก'
          desc={
            <div>
              ต้องการ
              <span style={{ color: active ? theme.COLORS.RED_1 : theme.COLORS.GREEN_3, fontWeight: 600, }}> {active ? ` ปิด ` : ` เปิด `}</span>
              {label}
              <span style={{ color: theme.COLORS.BLUE_2, fontWeight: 600 }}>{` ${name} `}</span>
              ใช่หรือไม่?
            </div>
          }
          // loading={loadingCheck}
          onConfirm={() => handleSubmitCheckbox(convertValue, type)}
          onCancel={() => closeModal()}
        />
      )
    })
  }
  const handleSubmitCheckbox = async (data, type) => {
    let id = data?.id
    let params = {
      is_active: type === 'is_active' ? data?.checked : undefined,
      is_visible: type === 'is_visible' ? data?.checked : undefined,
    }
    let res = await userService.PATCH_CLINIC_BY_ACCOUNT(id, params)
    if (res?.success) {
      fetchData()
    }
    closeModal()
  }

  const closeModal = () => {
    setModal({ isOpen: false, content: null })
    // setIsCheck({ id: null, checked: null, is_active: null, is_visible: null, })
  }


  const columns = [
    {
      title: 'ลำดับ',
      field: 'index',
      headerStyle: { display: 'flex', justifyContent: 'center', width: '4%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem' ,borderBottom:'1px solid #b3b3b3' },
      style: { textAlign: 'left', width: '4%', },
      icon: 'setting.svg',
    },
    {
      title: 'ชื่อแผนก ',
      field: 'name',
      headerStyle: { display: 'flex', justifyContent: 'start', paddingLeft: '100px', width: '49%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem',borderBottom:'1px solid #b3b3b3' },
      style: { textAlign: 'left', width: '49%', paddingLeft: '100px' },
      icon: 'setting.svg',
    },
    {
      title: 'Clinic ID ',
      field: 'ref_id',
      headerStyle: { display: 'flex', justifyContent: 'left', width: '15%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem',borderBottom:'1px solid #b3b3b3' },
      style: { textAlign: 'left', width: '15%', },
      icon: 'setting.svg',
    },
    {
      title: 'จำนวนผู้ใช้งาน ',
      field: 'users_count',
      headerStyle: { display: 'flex', justifyContent: 'left', width: '15%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem',borderBottom:'1px solid #b3b3b3' },
      style: { textAlign: 'left', width: '15%', },
      icon: 'setting.svg',
    },
    {
      title: 'วันที่สร้างบัญชี',
      field: 'created_at',
      headerStyle: { display: 'flex', justifyContent: 'center', width: '20%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem',borderBottom:'1px solid #b3b3b3' },
      style: { textAlign: 'center', width: '20%' },
    },
    // {
    //   title: 'รับคนไข้',
    //   field: 'is_visible',
    //   disabled : true,
    //   headerStyle: { display: 'flex', justifyContent: 'center', width: '15%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem',borderBottom:'1px solid #b3b3b3' },
    //   style: { display: 'flex', justifyContent: 'center', textAlign: 'center', width: '15%' },
    // },
    // {
    //   title: 'เปิดบริการ',
    //   field: 'is_active',
    //   disabled : true,
    //   headerStyle: { display: 'flex', justifyContent: 'center', width: '15%', backgroundColor: 'none', paddingTop: '1rem', paddingBottom: '1rem',borderBottom:'1px solid #b3b3b3' },
    //   style: { display: 'flex', justifyContent: 'center',textAlign: 'center', width: '15%' },
    // },
  ]
  const [clinicLists, setClinicLists] = useState(calculateSkeleton(columns))
  const loading = (clinicLists[0]?.loading)

  return (
    <ClinicListsStyled {...props}>
      <BoxModal
        isOpen={modal?.isOpen}
        onRequestClose={() => closeModal()}
        theme_standard
      >
        {modal?.content}
      </BoxModal>
      <div className='header_section_content'>
        <div className='header_title'>
          การจัดการคลินิก
        </div>
        <div className='btn_container'>
          {/* <div className='btn_edit' style={{ width: '10rem' ,borderRight: '0.5px solid #707070'}}>
            <ButtonCustom
              theme_outline_yellow
              label="แก้ไขลำดับ"
              // onClick={(e) => history.push(ROUTE_PATH.SETTINGS_DETAIL + institutionDetailData.id + ROUTE_PATH.CLINIC_PROFILE + 'create')}
              icon='editPen.svg'
            />
          </div> */}
          <div className='btn_create' style={{ width: '10rem' ,
          // borderLeft: '0.5px solid #707070'
          }
          }>
            <ButtonCustom
              theme_yellow
              label="สร้างคลินิก"
              onClick={(e) => history.push(ROUTE_PATH.SETTINGS_DETAIL + institutionDetailData.id + ROUTE_PATH.CLINIC_PROFILE + 'create')}
              icon='plus2.svg'
            />
          </div>
        </div>
      </div>
      <Middleline
        theme_standard
        title='การจัดการคลินิก'
      />
      <div style={{ marginTop: '1rem' }}>
        <TableCustom
          theme_standard_2
          useNo={!loading}
          columns={columns}
          data={clinicLists}
          onClick={(e) => history.push(ROUTE_PATH.SETTINGS_DETAIL + institutionDetailData.id + '-' + institutionDetailData.realm + ROUTE_PATH.CLINIC_PROFILE + e.id)}
        // isClick={goToSettingOntarget}
        // responsiveWidth='970px'
        />
      </div>
    </ClinicListsStyled>
  );
};
