import { ROUTE_PATH } from 'assets';
import LoginContainer from 'containers/LoginContainer';
import HomeContainer from 'containers/HomeContainer';

var pageRoutes = [
  {
    keyIndex: 1,
    path: ROUTE_PATH.LOGIN,
    exact: true,
    name: 'login',
    icon: 'login',
    component: LoginContainer
  },
  {
    keyIndex: 2,
    path: ROUTE_PATH.HOME,
    exact: true,
    name: 'home',
    icon: 'home',
    component: HomeContainer
  },
];

export default pageRoutes;
