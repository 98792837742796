import styled from "styled-components"
 
export const BackgroundStyled = styled.div`
/*===============================================
 Container 
===============================================*/
position: relative;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        top : 1.2rem;
        position : absolute;
        right : 0;
    }

    .theme_standard_2 {
        top : 2.5rem;
        position : absolute;
        right : 0;
        .logo{
            width : 5rem;
        }
    }

    .theme_standard_3 {
        top : -3.1rem;
        position : absolute;
        right : 0;
    }
    
`