import React from 'react'
import { connect } from 'react-redux'
import { UserAccountContainerStyled } from './styled'
import {
  setReduxUserAuth,
  setReduxUserProfile,
  setReduxClinics,
} from 'store/actions'
import {
  ButtonCustom,
  BoxModal,
  BoxConfirm,
  TableCustom,
  Typography,
  InputCheckboxNormal,
  Background,
} from 'components'
import { UserAccountForm } from 'forms'
import { ROUTE_PATH, getPathnameID, calculateSkeleton } from 'assets'
import theme from 'assets/theme.json'
import { userService } from 'apiService'
import { getPermissionActions } from 'store/actions/getPermissionActions'
import { CLEAR_PERMISSION_FEATURES } from 'store/actions/actionTypes'
import { withRouter } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
class UserAccountContainer extends React.Component {
  state = {
    user_id: '',
    creating: false,
    loading: false,
    loadingSubmit: false,
    loadingCheck: false,
    modal: '',
    isOpen: false,
    clinics: calculateSkeleton(columns),
    clinicLists: '', /// for creating
    multiClinic: [],
    role: '',
    doctor_code: false,
    filterBy: false,
    doctor_profile: false,
    profile:false,
    doctorSync:false,
  }
  componentDidMount = () => {
    this.beforeToFetch()
  }

  componentWillUnmount = () => {
    const { setReduxUserProfile, setReduxClinics, dispatchFunc } = this.props
    setReduxUserProfile(false)
    setReduxClinics(false)
    dispatchFunc({ type: CLEAR_PERMISSION_FEATURES, payload: {} })
  }

  componentDidUpdate = () => {
    const { user_id } = this.state
    const { params } = this.props.match
    const user = getPathnameID(params.user_id)
    if (!user.is_creating && user_id !== user.id) {
      // Not create and Id not equal
      this.beforeToFetch()
    }
  }
  beforeToFetch = () => {
    this.setState({ clinics: calculateSkeleton(columns) })
    const { params } = this.props.match
    const user = getPathnameID(params.user_id)
    const institution = getPathnameID(params.institution_id)
    const role = params.role_id
    this.setState({
      creating: user.is_creating,
      user_id: user.id,
      role: role,
    })
    user.is_creating
      ? this.fetchClinics(institution, user)
      : this.fetchData(institution, user)
    this.fetchPermissionFeatures(institution)
  }

  fetchPermissionFeatures = async (institution) => {
    const { getPermissionActions } = this.props
    let params = {
      institution_id: institution?.id,
      setting_type: 'features',
    }
    getPermissionActions(params)
  }

  fetchData = async (institution, user) => {
    this.setState({ loading: true })
    Promise.all([
      this.fetchProfile(user, institution),
      this.fetchClinics(institution),
    ]).then( async (values) => {
      const profile = values[0]
      const clincis = values[1]
      if (profile && clincis) {
        await this.setUpState(profile,clincis)
        await this.setMultiClinic(profile)
        await this.handlefetchbtn(profile?.doctor?.code)
      } else {
        this.setState({ clinics: [], loading: false })
      }
    })
  }
  setUpState = async (profile,clincis)=>{
    this.setState({
      // clinics: this.setRenderClinics(clincis, profile.clinics),
      profile: profile,
      clinics: clincis,
      loading: false,
      doctor_code: profile?.doctor?.code
    });
  }

  fetchProfile = async (user, institution) => {
    const { setReduxUserProfile } = this.props
    let res = await userService.GET_USER_PROFILE(institution.id, user.id)
    if (res && res.success) {
      setReduxUserProfile(res.data)
      return res.data
    } else {
      this.setErrorModal(institution.id, res)
      return ''
    }
  }

  fetchClinics = async (institution, user) => {
    const { setReduxClinics } = this.props
    let res = await userService.GET_CLINICS_BY_ACCOUNT(institution.id)
    if (res && res.success) {
      if (user) {
        setReduxClinics(res.data)
        this.setState({ clinicLists: res.data })
      }
      return res.data
    }
  }
  setRenderClinics = (clinic_lists, clinic_me) => {
    return (
      clinic_lists &&
      clinic_lists.map((item) => {
        const active = clinic_me.some((me) => me.id === item.id)
        return {
          ...item,
          name: item.name + '  (' + item.description + ')',
          is_active: item.is_active === 1 ? 'Active' : 'Inactive',
          action: (
            <div style={{ marginTop: '-0.2rem' }}>
              <InputCheckboxNormal
                theme_transparent_2
                value={JSON.stringify(item)}
                checked={active}
                onChange={(e) => this.setModal(e, active)}
              />
            </div>
          ),
        }
      })
    )
  }
  setErrorModal = (id, e) => {
    const action = () => {
      this.setState({ isOpen: false })
      this.props.history.push(ROUTE_PATH.SETTINGS_DETAIL + id)
    }
    this.setState({
      modal: (
        <BoxConfirm
          theme_error
          confirm='กลับไปหน้าหลัก'
          title='Alert'
          desc={`${e?.data?.error}`}
          onConfirm={() => action()}
          // onCancel={() => this.setState({ isOpen: false })}
        />
      ),
      isOpen: true,
    })
  }
  setModal = (e, active) => {
    const { loadingCheck } = this.state
    const { name, description } = this.props.reduxUserProfile
    let clinic = JSON.parse(e.target.value)
    this.setState({
      modal: (
        <BoxConfirm
          theme_standard
          confirm='ยืนยัน'
          cancel='ยกเลิก'
          desc={
            <div>
              ต้องการ
              <span
                style={{
                  color: active ? theme.COLORS.RED_1 : theme.COLORS.GREEN_3,
                  fontWeight: 600,
                }}>
                {' '}
                {active ? ' ปิด ' : ' เพิ่ม '}
              </span>
              {active ? 'การใช้งาน' : 'สิทธิ์การใช้งานให้'}
              <span
                style={{
                  color: theme.COLORS.BLUE_2,
                  fontWeight: 600,
                }}>{` ${name} `}</span>
              ใน
              <span
                style={{
                  color: theme.COLORS.BLUE_2,
                  fontWeight: 600,
                }}>{` ${clinic.name} (${clinic.description}) `}</span>
              ใช่หรือไม่?
            </div>
          }
          loading={loadingCheck}
          onConfirm={() => this.handleCheck(e, { ...clinic, active: active })}
          onCancel={() => this.setState({ isOpen: false })}
        />
      ),
      isOpen: true,
    })
  }
  setClinicArrays = (values) => {
    const { clinicLists } = this.state
    let keys = Object.keys(values)
    let filters = keys
      .filter((key, i) => key.indexOf('code_') !== -1)
      .map((key) => {
        return { value: values[key], code: key.replace('code_', '') }
      })
      .filter((key) => key.value)
      .map((key) => key.code)
    let clinics =
      clinicLists &&
      clinicLists
        .map((clinic) => {
          return { id: filters.includes(clinic.code) ? clinic.id : '' }
        })
        .filter((clinic) => clinic.id)
    return clinics ? clinics : []
  }

  setMultiClinic = async (profile) =>{
    const result = profile?.clinics.map((clinics) => {return { id: clinics?.id}})
    this.setState({multiClinic: result})
  }

  handleCheck = (event, values) => {
    values.active
      ? this.handleRemoveToClinic(event, values)
      : this.handleAddToClinic(event, values)
  }

  handleAddToClinic = async (event, values) => {
    await this.setState({ loadingCheck: true })
    this.setModal(event, values.active)
    const clinic_id = values.id
    const user_id = this.props.reduxUserProfile.id
    let res = await userService.ADD_USER_TO_CLINIC(clinic_id, user_id)
    if (res && res.success) {
      await this.setState({ loadingCheck: false, isOpen: false, modal: '' })
      this.beforeToFetch()
    } else {
      await this.setState({
        loadingCheck: false,
        modal: (
          <BoxConfirm
            theme_standard
            confirm='ตกลง'
            desc={
              <div style={{ color: theme.COLORS.BLACK_1, fontWeight: 300 }}>
                {`Unable to add user to the clinic. `}
                <span
                  style={{
                    color: theme.COLORS.RED_6,
                    fontWeight: 600,
                  }}>{`(${res.data.error})`}</span>
              </div>
            }
            onConfirm={() => this.setState({ isOpen: false })}
          />
        ),
      })
    }
  }
  handleRemoveToClinic = async (event, values) => {
    await this.setState({ loadingCheck: true })
    this.setModal(event, values.active)
    const clinic_id = values.id
    const user_id = this.props.reduxUserProfile.id
    let res = await userService.REMOVE_USER_FORM_CLINIC(clinic_id, user_id)
    if (res && res.success) {
      await this.setState({ loadingCheck: false, isOpen: false, modal: '' })
      this.beforeToFetch()
    } else {
      await this.setState({
        loadingCheck: false,
        modal: (
          <BoxConfirm
            theme_standard
            confirm='ตกลง'
            desc={
              <div style={{ color: theme.COLORS.BLACK_1, fontWeight: 300 }}>
                {`Unable to remove user to the clinic. `}
                <span
                  style={{
                    color: theme.COLORS.RED_6,
                    fontWeight: 600,
                  }}>{`(${res.data.error})`}</span>
              </div>
            }
            onConfirm={() => this.setState({ isOpen: false })}
          />
        ),
      })
    }
  }

  handleMultiClinic=(e)=>{
    const { creating ,profile} = this.state
      if (e.length > 0) {
        const result = e.map((id) => {return { id: id?.value}})
        this.setState({multiClinic: result})
      }else{
        this.setState({multiClinic: []})
      }
  }

  handleSelectFilter = (e) => {
    if (e) {
      this.setState({doctor_code: e?.code});
    }
  }

  handlefetchbtn = async (values) => {
    const {doctor_code,profile , creating} = this.state
    const { params } = this.props.match
    if (values) {
      this.setState({doctor_code: values});
    }else{
      // console.log('out');
    }
    const institution = getPathnameID(params.institution_id)
    const {success,data,page,sort} = await userService.GET_DOCTOR_LIST_BY_ACCOUNT_ID(
      institution.id,
      values ? values: doctor_code || 'emtpy',
      creating ? false : profile?.doctor?.code
      )
    if (success && data) {
      this.setState({doctor_profile: data,loading:false})
    }else{
      return ;
    }
  
  }


  beforeToSubmit = async (params) =>{

    const { creating } = this.state
    if (creating) {
      this.setState({
        loadingSubmit: false,
        errorMsg: '',
        isOpen: true,
        modal: (
          <BoxConfirm
            theme_confirm
            title='กรุณายืนยันการสร้างบัญชี'
            confirm='ยืนยัน'
            cancelช='ยกเลิก'
            // onConfirm={() => this.redirectToDetail(res.data)}
            
            onConfirm={() => this.postUser(params)}
            onCancel={()=> this.setState({modal:false,isOpen: false,})}
          />
        ),
      })
    } 
  }
  handleSubmit = async () => {
    const { creating ,role ,doctor_profile,multiClinic} = this.state
    this.setState({ loadingSubmit: true })
    const { realm } = this.props.reduxInstitutionDetail
    const { values } = this.props.reduxForm
    const { reduxPermission } = this.props
    if (creating) {
      let params = {
        ...values,
        username: `${values?.username}@${realm}`,
        clinics: this.state?.multiClinic || null,
        // clinics: this.setClinicArrays(values),
        odid:
          reduxPermission?.can_ones_login &&
          values?.odid &&
          values?.odid !== '-'
            ? values?.odid
            : undefined,
        role: role || 'nurse',
      }
      if (multiClinic.length === 0 ) {
        toast.error('โปรดระบุคลินิก');
      }
      if(doctor_profile && role === 'doctor') {
        const findDoctorId = doctor_profile?.map((e)=> {return e.id});
        params.doctor_id = Number(findDoctorId);
      }
      await this.beforeToSubmit(params);
    } else {
      let params = {
        username: undefined,
        role: undefined,
        ...values,
        odid:
          reduxPermission?.can_ones_login &&
          values?.odid &&
          values?.odid !== '-'
            ? values?.odid
            : undefined,
        clinics: multiClinic,
      }
      if(doctor_profile && role === 'doctor' && !reduxPermission?.is_local_doctor) {
        const findDoctorId = doctor_profile?.map((e)=> {return e.id});
        params.doctor_id =  findDoctorId.length > 0  ? Number(findDoctorId) : null;
      }
      this.patchUser(params)
    }
  }
  postUser = async (params) => {
    const { id } = this.props.reduxInstitutionDetail
    let res = await userService.POST_USER_BY_ACCOUNT(id, params)
    if (res && res.success) {
      this.setState({
        loadingSubmit: false,
        errorMsg: '',
        isOpen: true,
        modal: (
          <BoxConfirm
            theme_success
            title='Success!'
            desc='สร้างบัญชีสำเร็จแล้ว'
            confirm='ตกลง'
            onConfirm={() => this.redirectToDetail(res.data)}
            onCancel={false}
          />
        ),
      })
    } else {
      this.setState({
        loadingSubmit: false,
        errorMsg: `Fail to create clinic (${res.data?.error})`,
      })
    }
  }
  patchUser = async (params) => {
    const clinic_id = this.props.reduxInstitutionDetail.id
    const user_id = this.props.reduxUserProfile.id
    let res = await userService.PATCH_USER_BY_ACCOUNT(
      clinic_id,
      user_id,
      params,
    )
    if (res && res.success) {
      this.setState({
        loadingSubmit: false,
        isOpen: true,
        errorMsg: '',
        modal: (
          <BoxConfirm
            theme_confirm
            title='อัพเดทบัญชีสำเร็จ'
            confirm='ตกลง'
            onConfirm={() => {
              this.setState({ isOpen: false })
              this.beforeToFetch()
            }}
            onCancel={false}
          />
        ),
      })
    } else {
      this.setState({
        loadingSubmit: false,
        errorMsg: `${res.data?.error}`,
      })
      toast.error(`${res.data?.error}`)
    }
  }

  redirectToDetail = (e) => {
    const { id, realm} = this.props.reduxInstitutionDetail
    const { history } = this.props
    this.setState({ isOpen: false })
    history.push(
      ROUTE_PATH.SETTINGS_DETAIL + `${id+'-'+realm}`
    )
  }

  render() {
    const { id, realm } = this.props.reduxInstitutionDetail
    const { name, profile_picture_url } = this.props.reduxUserProfile
    const {
      creating,
      clinics,
      clinicLists,
      loading,
      loadingSubmit,
      modal,
      isOpen,
      errorMsg,
      role,
    } = this.state
    return (
      <UserAccountContainerStyled>
        <BoxModal
          isOpen={isOpen}
          onRequestClose={() => this.setState({ isOpen: false })}
          theme_standard
          center
          top
          >
          {modal}
        </BoxModal>
        <ToastContainer/>
        {/* <Background theme_standard background={'background3.svg'} /> */}
        <div className='back'>
          <ButtonCustom
            theme_back
            label='BACK'
            icon='arrow_left.svg'
            onClick={() =>
              this.props.history.goBack()
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}></div>
        <div className='container_custom'>
          {
            creating ? (<>
              <div className='container_custom_form'>
                <Typography
                  label={creating ? '' : name}
                  weight={500}
                  loading={loading}
                  color={theme.COLORS.BLUE_17}
                  size={theme.FONTS.SIZE_30px}
                />
                <UserAccountForm
                  loading={loading}
                  loadingSubmit={loadingSubmit}
                  errorMsg={errorMsg}
                  realm={realm}
                  creating={creating}
                  profile_picture_url={profile_picture_url}
                  doctor_profile={this.state.doctor_profile}
                  institution_id={id}
                  role={this.state.role}
                  clinics={clinicLists}
                  handleMultiClinic={this.handleMultiClinic}
                  handleSelectFilter={this.handleSelectFilter}
                  handlefetchbtn={this.handlefetchbtn}
                  onSubmit={this.handleSubmit}
                  doctorSync ={true} 
                />
              </div>
            </>):(
            <div className='container_custom_form'>
              <Typography
                  label={creating ? '' : name}
                  weight={500}
                  loading={loading}
                  color={theme.COLORS.BLUE_17}
                  size={theme.FONTS.SIZE_30px}
                />
                <UserAccountForm
                  loading={loading}
                  loadingSubmit={loadingSubmit}
                  errorMsg={errorMsg}
                  realm={realm}
                  creating={creating}
                  profile_picture_url={profile_picture_url}
                  institution_id={id}
                  role={this.state.role}
                  profile={this.state.profile}
                  doctor_profile={this.state.doctor_profile}
                  clinics={creating? clinicLists : clinics}
                  handleMultiClinic={this.handleMultiClinic}
                  handleSelectFilter={this.handleSelectFilter}
                  handlefetchbtn={this.handlefetchbtn}
                  onSubmit={this.handleSubmit}
                  doctorSync ={true} 
                />
            </div>
            )
          }
        </div>
      </UserAccountContainerStyled>
    )
  }
}
const columns = [
  {
    title: 'Active',
    field: 'action',
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '4%',
      backgroundColor: '#F7F2FF',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    style: { textAlign: 'center', width: '4%' },
    icon: 'setting.svg',
    fluid_skeleton: true,
  },
  {
    title: 'ชื่อแผนก',
    field: 'name',
    headerStyle: {
      textAlign: 'left',
      width: '90%',
      backgroundColor: '#F7F2FF',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    style: { textAlign: 'left', width: '90%' },
    icon: 'setting.svg',
  },
  {
    title: 'เปิดบริการ',
    field: 'is_active',
    headerStyle: {
      textAlign: 'left',
      width: '11%',
      backgroundColor: '#F7F2FF',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    style: { textAlign: 'left', width: '11%' },
    icon: 'setting.svg',
  },
]

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxInstitutionDetail: state.institution_detail,
  reduxUserProfile: state.user_profile,
  reduxForm: state.form.UserAccountForm,
  reduxPermission: state.permission,
})

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxUserProfile: (data) => dispatch(setReduxUserProfile(data)),
  setReduxClinics: (data) => dispatch(setReduxClinics(data)),
  getPermissionActions: (data) => dispatch(getPermissionActions(data)),
  dispatchFunc: (func) => dispatch(func),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAccountContainer)
