import React from 'react';
import { TypographyStyled } from './styled';
import { iconImages } from 'assets';
import Skeleton from 'react-loading-skeleton';

export const Typography = (props) => {
  const { src, icon, size, label, color, weight, loading } = props;
  return (
    <TypographyStyled color={color ? color : 'black'} size={size ? size : '1rem'} weight={weight ? weight : '600'}>
      {loading 
      ? <div style={{ marginBottom : '1rem'}}><Skeleton width='20rem' height='2.7rem' /></div>
      : <>
          {icon && <img className='icon' src={iconImages[icon]} alt={icon} />}
          {src && <img className='logo' src={src} alt={src} />}
          <div className="label">{label}</div>
        </>
      }
    </TypographyStyled>
  );
};
