import styled from 'styled-components';
import { iconImages } from 'assets';

export const InputCheckboxNormalStyled = styled.div`
  /*===============================================
 Container 
  /*===============================================
Theme 
===============================================*/
    .theme_standard{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            // display: flex;
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    background-color:  ${({ theme }) => theme.COLORS.BLUE_3};
                    color :${({ theme }) => theme.COLORS.WHITE_1};
                    &:before {
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        // background-color:${({ theme }) => theme.COLORS.BLUE_3};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.GRAY_4};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                line-height:2.75;
                justify-content:center;
                border-radius: 0.5rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLACK_2};
                // &:hover {
                //     background-color: ${({ theme }) => theme.COLORS.BLUE_3};
                //     color :${({ theme }) => theme.COLORS.WHITE_1};
                // }
            
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1.2em;
                    height: 1.2em;
                    border-radius: 0.3rem;
                    margin-right: 0.375em;
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLACK_2};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_standard_2{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            // display: flex;
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    background-color:  ${({ theme }) => theme.COLORS.BLUE_3};
                    color :${({ theme }) => theme.COLORS.WHITE_1};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        /*   background:color:${({ theme }) => theme.COLORS.BLUE_3}*/
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.GRAY_4};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                line-height:3;
                justify-content:flex-start;
                padding-left:1rem;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLACK_2};
                // &:hover {
                //     background-color: ${({ theme }) => theme.COLORS.BLUE_3};
                //     color :${({ theme }) => theme.COLORS.WHITE_1};
                // }
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1.2em;
                    height: 1.2em;
                    border-radius: 0.3rem;
                    margin-right: 0.375em;
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.075em ${({ theme }) => theme.COLORS.BLACK_2};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_standard_3{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            // display: flex;
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    background-color:  ${({ theme }) => theme.COLORS.BLUE_3};
                    color :${({ theme }) => theme.COLORS.WHITE_1};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        /*   background:color:${({ theme }) => theme.COLORS.BLUE_3}*/
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                line-height:3;
                justify-content:center;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLACK_2};
                // &:hover {
                //     background-color: ${({ theme }) => theme.COLORS.BLUE_3};
                //     color :${({ theme }) => theme.COLORS.WHITE_1};
                // }
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1.2em;
                    height: 1.2em;
                    border-radius: 0.3rem;
                    margin-right: 0.375em;
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.075em ${({ theme }) => theme.COLORS.BLACK_2};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_standard_4{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            // display: flex;
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    background-color:  ${({ theme }) => theme.COLORS.BLUE_3};
                    color :${({ theme }) => theme.COLORS.WHITE_1};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        /*   background:color:${({ theme }) => theme.COLORS.BLUE_3}*/
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                /* width:100%; */
                line-height:2;
                /* justify-content:center; */
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                padding-left:0.4rem;
                padding-right:0.4rem;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLACK_2};
                // &:hover {
                //     background-color: ${({ theme }) => theme.COLORS.BLUE_3};
                //     color :${({ theme }) => theme.COLORS.WHITE_1};
                // }
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1.2em;
                    height: 1.2em;
                    border-radius: 0.3rem;
                    margin-right: 0.375em;
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.075em ${({ theme }) => theme.COLORS.BLACK_2};
                }
            }
        }
        
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_transparent_1{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    &:before {
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_7};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.BLUE_7};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.GRAY_4};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                line-height:3;
                justify-content:center;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLUE_7};
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1.2em;
                    height: 1.2em;
                    border-radius: 0.3rem;
                    margin-right: 0.375em;
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_7};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_transparent_2{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            cursor: pointer;
            font-weight: 500;
            z-index : 0;
            position: relative;
            width:fit-content;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    &:before {
                        /* box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_16}; */
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none; 
                        background-color:${({ theme }) => theme.COLORS.BLUE_16};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.BLACK_1};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.GRAY_4};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                justify-content:flex-start;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLACK_2};
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1.2em;
                    height: 1.2em;
                    border-radius: 0.3rem;
                    /* margin-right: 0.375em; */
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_16};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_transparent_3{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    &:before {
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.YELLOW_1};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none; 
                        background-color:${({ theme }) => theme.COLORS.YELLOW_1};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.GRAY_4};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                line-height:3;
                justify-content:center;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.YELLOW_1};
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1.2em;
                    height: 1.2em;
                    border-radius: 0.3rem;
                    margin-right: 0.375em;
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.YELLOW_1};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_transparent_4{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    &:before {
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 0.7rem;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        border:none; 
                        background-color:${({ theme }) => theme.COLORS.BLUE_3};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.GRAY_4};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size:  0.7rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                justify-content:center;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLUE_3};
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_4};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1rem;
                    height: 1rem;
                    border-radius: 0.3rem;
                    /* margin-right: 0.375em; */
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_3};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_transparent_5{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    &:before {
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_18};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 0.7rem;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        border:none; 
                        background-color:${({ theme }) => theme.COLORS.BLUE_18};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size:  0.7rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.BLUE_3};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                justify-content:center;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLUE_3};
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_2};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1rem;
                    height: 1rem;
                    border-radius: 0.3rem;
                    /* margin-right: 0.375em; */
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_2};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_transparent_6{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    &:before {
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.YELLOW_1};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 0.7rem;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        border:none; 
                        background-color:${({ theme }) => theme.COLORS.YELLOW_1};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size:  0.7rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.BLUE_3};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                justify-content:center;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLUE_3};
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_2};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1rem;
                    height: 1rem;
                    border-radius: 0.3rem;
                    /* margin-right: 0.375em; */
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_2};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
    .theme_transparent_7{
        *:after,
        *:before {
            box-sizing: border-box;
        }
        .container{ 
            cursor: pointer;
            font-weight: 500;
            position: relative;
            width:100%;
            overflow: hidden;
            padding:0;
            margin:0;
            input {
                position: absolute;
                display:none;
                &:checked + span {
                    &:before {
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.BLUE_17};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size: 0.7rem;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        border:none; 
                        background-color:${({ theme }) => theme.COLORS.BLUE_17};
                    }
                }
                &.disabled + span {
                    background-color:  ${({ theme }) => theme.COLORS.WHITE_2};
                    color :${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        box-shadow: inset 0 0 0 0 ${({ theme }) => theme.COLORS.BLUE_3};
                        background-image: url('${iconImages['check2.svg']}');
                        background-size:  0.7rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border:none;
                        background-color:${({ theme }) => theme.COLORS.BLUE_3};
                    }
                }
            }
            span {
                display: flex;
                align-items: center;
                width:100%;
                justify-content:center;
                border-radius: 0.3rem; // or something higher...
                // transition: 0.25s ease;
                background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                color:${({ theme }) => theme.COLORS.BLUE_3};
                &.disabled{
                    background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                    color: ${({ theme }) => theme.COLORS.GRAY_4};
                    &:before {
                        background-color: ${({ theme }) => theme.COLORS.WHITE_2};
                        box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_2};
                    }
                }
                &:before {
                    content: "";
                    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
                    width: 1rem;
                    height: 1rem;
                    border-radius: 0.3rem;
                    /* margin-right: 0.375em; */
                    // transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em ${({ theme }) => theme.COLORS.GRAY_2};
                }
            }
        }
        .error{
            color: ${({ theme }) => theme.COLORS.RED_1};
            font-size:${({ theme }) => theme.FONTS.SIZE_12px};
            text-align:left;
            font-weight:400;
            // margin-left:1rem;
            margin-top:0.3rem;
        }
    }
`;
